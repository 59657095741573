import axios from "./api";

const Company = {
  getAll(query=null) {
    return axios.get(`/business/company?${query ? query : ""}`);
  },
  add(company) {
    return axios.post("/business/company", company);
  },
  update(id, company) {
    return axios.put(`/business/company/${id}`, company);
  },
};

export default Company;
