import React, { useEffect, useState } from "react";

import "./ChatListItem.css";
import dayjs from "dayjs";
import { defaultUserImage } from "../../../assets/images";

const ChatListItem = ({
  className,
  conversation,
  chat,
  onClick,
  selected,
  preSelect = false,
}) => {
  const [lastMessage, setLastMessage] = useState(null);

  const loadLastMessage = () => {
    if (!conversation) {
      return;
    }

    conversation
      .getMessages(1)
      .then((messages) => {
        if (messages.items.length > 0) {
          setLastMessage(messages.items[0]);
        }
      })
      .catch(() => {
        setTimeout(() => {
          loadLastMessage();
        }, 5000);
      });

    conversation.on("messageAdded", (message) => {
      setLastMessage(message);
    });
  };

  const getFormattedDate = (date) => {
    const timeDifference = Date.now() - date.getTime();
    if (timeDifference < 60000) {
      return "Just Now";
    } else if (timeDifference < 86400000) {
      return dayjs(date).format("h:mm A");
    } else {
      return dayjs(date).format("D MMM");
    }
  };

  useEffect(() => {
    loadLastMessage();
  }, [conversation]);

  useEffect(() => {
    if (preSelect) {
      const element = document.getElementById("conversation-" + preSelect);
      if (element) {
        element.click();
      }
    }
  }, []);

  if (!chat || !conversation) return <></>;
  else
    return (
      <div
        id={`conversation-` + conversation.sid}
        className={
          "flex p-3 rounded-sm border border-gray-300 hover:bg-primary-100 cursor-pointer " +
          className +
          (selected && " bg-primary-200")
        }
        onClick={onClick}
      >
        <img
          src={chat?.seeker?.photo?.thumb_url || defaultUserImage}
          className={"profile-picture rounded-full"}
          alt="Profile"
        />
        <div className="ml-4">
          <span className="block text-primary-500 font-bold">
            {chat ? chat.seeker.first_name + " " + chat.seeker.last_name : ""}
          </span>
          <span className="block text-gray-500 text-sm line">
            {lastMessage && lastMessage.body
              ? lastMessage.body.length > 20
                ? lastMessage.body.substring(0, 19) + "..."
                : lastMessage.body
              : "No messages here."}
          </span>
          <span className="block text-xs text-gray-500 mt-1">
            {conversation.lastMessage &&
              getFormattedDate(conversation.lastMessage.dateCreated)}
          </span>
        </div>
      </div>
    );
};

export default ChatListItem;
