import axios from "./api";

const SuperUser = {
  updateBrandCompany(data) {
    return axios.post("/business/super-user/change-brands-company", data);
  },
  changePassword(data) {
    return axios.post("/business/super-user/change-user-password", data);
  },
  resendWelcomeEmail(id) {
    return axios.get(`/business/super-user/resend-welcome-email/${id}`);
  }
};

export default SuperUser;
