import React from "react";

const DropdownMenuItem = ({ label, icon, onClick, isMobile }) => {
  const eventHandler = isMobile ? "onPointerEnter" : "onClick";
  return (
    <a
      href="#nav"
      className="hover:text-white hover:bg-primary-500 block px-4 py-2"
      role="menuitem"
      tabIndex="-1"
      {...{ [eventHandler]: onClick }}
      id="menu-item-0"
    >
      {icon ? <i className={icon + " mr-2"} /> : ""}
      {label}
    </a>
  );
};

export default DropdownMenuItem;
