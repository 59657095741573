import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  darkmode,
  docs,
  selectSelectedLocation,
  selectUser,
  setChatApplicant,
  setJobApplicantTitle,
  setJobPositionTitle,
} from "../../../store/authSlice";
import { DashboardLayout } from "../../../components/organisms/Layout";
import FormTextarea from "../../../components/atoms/FormTextarea";
import Application from "../../../api/Application";
import JobPosition from "../../../api/JobPosition";
import JobSeeker from "../../../api/JobSeeker";
import Location from "../../../api/Location";
import { DateFormat, DateFormatWithTime, distance } from "../../../utils";
import {
  defaultUserImage,
  startChatIcon,
  hireIcon,
  declineIcon,
  resumeIcon,
  certificationsGrayIcon,
  languagesIcon,
  certificationsIcon,
  positionsIcon,
  skillIcon,
  instagramIcon,
  interviewIcon,
  activityIcon,
  questionIcon,
  languagesGrayIcon,
  calenderIcon,
  positionsGrayIcon,
  skillGrayIcon,
  instagramGrayIcon,
  interviewGrayIcon,
  activityGrayIcon,
  questionIconGray,
} from "../../../assets/images";
import { decodeIds } from "../../../utils";
import ProfileLoading from "../../../components/Loading/ProfileLoading";
import ResumeViewer from "./ResumeViewer";
import "./ResumeViewer.scss";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import NewConfirmationModal from "../../../components/modals/NewConfirmationModal";

const ViewApplicant = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const hiredMode = location.pathname.search("hired-applicant") !== -1;
  const [instagramImages, setInstagramImages] = useState([]);
  const [applicant, setApplicant] = useState({});
  const [jobLocation, setJobLocation] = useState({});
  const [application, setApplication] = useState({});
  const [loading, setLoading] = useState(true);
  const [dloading, setDLoading] = useState(false);
  const [activityLogs, setActivityLogs] = useState([]);
  const selectedLocation = useSelector(selectSelectedLocation);
  const navigate = useNavigate();
  const [positionId, setPositionId] = useState(
    decodeIds(location.pathname.split("/")[3])
  );
  const [seekerId, setSeekerId] = useState(
    decodeIds(location.pathname.split("/")[4])
  );
  const [notes, setNotes] = useState("");
  const user = useSelector(selectUser);
  const apiUrl = process.env.REACT_APP_API_BASE_URL.split("/api/v1")[0];
  const dispatch = useDispatch();
  const [isNarrow, setIsNarrow] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const doc = useSelector(docs);
  const mode = useSelector(darkmode);
  const [positionTitle, setPositionTitle] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [apiAvailabilityData, setApiAvailabilityData] = useState([]);

  useEffect(() => {
    setPositionId(decodeIds(location.pathname.split("/")[3]));
    setSeekerId(decodeIds(location.pathname.split("/")[4]));
  }, [location.pathname]);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    jobPositionTitle();
    loadApplicant();
    loadLocation(user?.location_id);
    // loadAvailability();
    if (hiredMode) {
      getApplicationData();
    }
  }, [positionId, seekerId]);

  const jobPositionTitle = async () => {
    var position = await JobPosition.getById(positionId);
    setPositionTitle(position?.data?.data?.title);
    dispatch(setJobPositionTitle(position?.data?.data));
  };

  // Methods
  const getApplicationData = async () => {
    try {
      const _hireApplicants = await JobPosition.getHiredEmployees(positionId);
      const _application = _hireApplicants.data.data.filter(
        (i) => i.applicant_id == seekerId
      );
      setApplication(_application[0]);
    } catch (error) {
      toast.error("Failed to load Current Employees. Please try again.");
    }
  };

  const loadLocation = (id) => {
    Location.getById(id)
      .then((response) => {
        setJobLocation(response.data.data);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("an error occurred");
        }
      });
  };

  const loadApplicant = async () => {
    try {
      const job = await JobSeeker.getById(seekerId);
      dispatch(
        setJobApplicantTitle(
          job?.data?.data?.first_name + " " + job?.data?.data?.last_name
        )
      );
      setApplicant(job.data.data);
      document.title = `HeyHire Portal-Viewing-Application-${job?.data?.data?.first_name} ${job?.data?.data?.last_name}`;
      const app = await Application.getByPositionId(positionId);
      const appls = app.data.data;
      // find by seeker id
      const fg = appls.filter(
        (r) => r.job_seeker_id === Number(seekerId) && r.status === "applied"
      );
      if (fg[0]) {
        setApplication(fg[0]);
        getActivity(fg[0].id);
        // record ApplicationView
        //recordApplicationView(fg[0].job_id, job.data.data.id);
      }
      loadAvailability(seekerId);
      collectInterviewNotes();
      setLoading(false);
      if (job?.data?.data?.instagram_token) {
        getInstagramProfile(seekerId);
      }
    } catch (error) {
      if (error.response.status !== 403) {
        toast.error("Failed to load job by Id. Please try again.");
      }
      setLoading(false);
    }
  };

  const hireApplicant = async () => {
    try {
      await Application.hireByApplicationId(application?.id);
      toast.success("Applicant Hired successfully");
      setIsModalVisible(false);
    } catch (error) {
      setIsModalVisible(false);
      toast.error("an error occurred");
    }
  };

  const fireApplicant = async () => {
    // fire hired employee
  };

  const chatApplicant = async () => {
    dispatch(setChatApplicant(applicant?.id));
  };

  const callForInterview = async () => {
    return (window.location.href = `tel:${applicant?.phone}`);
  };

  const downloadApplication = async () => {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = `${apiUrl}/employee_application/${seekerId}/${positionId}`;
    a.click();
  };

  const rejectApplication = async () => {
    try {
      await Application.rejectByApplicationId(application?.id);
      toast.success("Applicant Rejected");
      setIsModalVisible(false);
    } catch (error) {
      setIsModalVisible(false);
      toast.error("an error occurred");
    }
  };

  const getInstagramProfile = async (id) => {
    try {
      const insta = await Application.getInstagramProfile(id);
      setInstagramImages(insta.data.data);
    } catch (error) {}
  };

  const getActivity = async (id) => {
    try {
      const response = await Application.getApplicationLogs(id);
      setActivityLogs(response.data.data);
    } catch (error) {}
  };

  const setInterviewNotes = (val) => {
    setNotes(val);
  };

  const saveInterviewNotes = async () => {
    const data = {
      notes: notes,
      job_seeker_id: seekerId[0],
      job_id: positionId[0],
    };
    await JobPosition.addInterviewNotes(data);
    toast.success("Notes saved!");
  };

  const collectInterviewNotes = async () => {
    const data = {
      job_seeker_id: seekerId,
      job_id: positionId,
    };
    const interviewNotes = await JobPosition.collectInterviewNotes(data);

    if (interviewNotes.data) {
      setNotes(interviewNotes.data.data.note);
    }
  };

  const availabilityData = [
    { day: "Monday" },
    { day: "Tuesday" },
    { day: "Wednesday" },
    { day: "Thursday" },
    { day: "Friday" },
    { day: "Saturday" },
    { day: "Sunday" },
  ];

  const handleHireApplicant = () => {
    setType("hire");
    setIsModalVisible(true);
  };

  const handleRejectApplication = () => {
    setType("decline");
    setIsModalVisible(true);
  };

  const loadAvailability = async (id) => {
    try {
      const data = await Application.getByIdForAvailability(id);
      setApiAvailabilityData(data?.data?.availability || []);
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  const getAvailabilityForDay = (day) => {
    const dayAvailability = apiAvailabilityData?.find(
      (item) => item?.day === day
    );
    return dayAvailability ? dayAvailability?.available : [];
  };

  return (
    <DashboardLayout darmo={true}>
      {loading && <ProfileLoading />}
      {!loading && (
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={8}>
            <div className="sticky top-0 bg-gradient-to-b from-primary-100 via-purple-100 to-white dark:bg-[#242526] flex flex-col text-center md:mx-0 rounded-xl border shadow-md border-warmgray-300 dark:border-gray-400 dark:bg-none">
              <div className="px-4 pt-4 flex flex-col">
                <div className="flex justify-center">
                  <img
                    src={applicant?.photo?.thumb_url || defaultUserImage}
                    alt="Profile"
                    className="object-contain rounded-full"
                    style={{ maxHeight: "132px", maxWidth: "132px" }}
                    height={132}
                    width={132}
                  />
                </div>

                <span className="block mt-4 text-lg font-bold overflow-hidden text-gray-900 dark:text-gray-300">
                  {applicant?.first_name} {applicant?.last_name}
                </span>

                {applicant?.city && applicant?.state && (
                  <span
                    className={`block mt-0 text-sm ${
                      distance(
                        jobLocation?.address?.lat,
                        jobLocation?.address?.lng,
                        applicant?.latitude,
                        applicant?.longitude
                      ) < 25
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    <i className="fas fa-map-marker-alt text-primary-500 dark:text-gray-500" />{" "}
                    <span className="mx-1 text-sm">
                      {applicant?.city}, {applicant?.state}{" "}
                    </span>
                    <i
                      className={`fas ${
                        distance(
                          jobLocation?.address?.lat,
                          jobLocation?.address?.lng,
                          applicant?.latitude,
                          applicant?.longitude
                        ) < 25
                          ? "fa-check-circle text-green-500"
                          : "fa-times-circle text-red-500"
                      }`}
                    />
                  </span>
                )}
              </div>

              <div className="px-4 py-2 flex flex-col">
                <span className="font-bold mt-1 text-sm text-gray-900 dark:text-gray-500">
                  {hiredMode ? "Hired for:" : "Applied for:"}
                </span>{" "}
                <span className="my-1 block font-bold text-primary-500 dark:text-gray-300 text-lg">
                  {positionTitle ? positionTitle : ""}
                </span>
                <div className="mx-auto">
                  <span className="block mb-1 text-xs dark:text-gray-300">
                    {hiredMode ? "Hired on " : "Applied on: "}
                    {dayjs(application?.created_at).format(DateFormat)}
                  </span>
                  <span className="block text-left mx-auto text-sm dark:text-gray-500">
                    <i
                      // className={`fas ${
                      //   applicant?.eligible
                      //     ? "fa-check-circle text-green-500"
                      //     : "fa-times-circle text-red-500"
                      // }`}
                      className={`fas fa-check-circle text-green-500`}
                    />{" "}
                    Eligible to Work in the US
                  </span>
                  <span className="block text-left mx-auto text-sm dark:text-gray-500">
                    <i
                      // className={`fas ${
                      //   applicant?.sixteen
                      //     ? "fa-check-circle text-green-500"
                      //     : "fa-times-circle text-red-500"
                      // }`}
                      className={`fas fa-check-circle text-green-500`}
                    />{" "}
                    16 Years of Age or Older{" "}
                  </span>
                  {applicant?.has_apployme_chat === 1 ? (
                    <span className="block text-left mx-auto text-sm">
                      <i className="fas fa-check-circle text-blue-500" /> Chat
                      started on apployMe
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="p-4 flex flex-col">
                <div className="flex custom-flex-direction">
                  <button
                    className="w-full button-position rounded-xl p-2 flex items-center justify-center text-white text-sm font-semibold bg-primary-500 dark:bg-darkGray shadow-md hover:bg-primary-400 transition-all hover:opacity-50"
                    onClick={chatApplicant}
                  >
                    <img
                      src={startChatIcon}
                      alt="chat-logo"
                      className="mr-1.5"
                    />
                    <span className="my-auto">Start Chat</span>
                  </button>

                  <button
                    //  className={`flex items-center justify-center w-full rounded-xl p-2 text-sm font-semibold shadow-md hover:opacity-50 transition-all text-white mt-3 ${ doc.length > 0
                    //       ? "cursor-not-allowed bg-opacity-50 bg-[#594A9E80]"
                    //       : "bg-primary-500 hover:bg-primary-400"
                    //   }`}
                    className={`flex items-center justify-center w-full rounded-xl p-2 text-sm font-semibold shadow-md hover:opacity-50 transition-all text-white mt-3 cursor-not-allowed bg-opacity-50 bg-[#594A9E80] dark:bg-darkGray`}
                    // disabled={doc.length > 0}
                    disabled={true}
                    // onClick={downloadApplication}
                  >
                    <img
                      src={resumeIcon}
                      alt="resume-logo"
                      className="mr-1.5"
                    />
                    <span className="my-auto">Request Resume</span>
                  </button>
                </div>

                <div className="flex custom-flex-direction">
                  {hiredMode ? (
                    <button
                      className="w-full rounded-xl p-2 flex items-center justify-center text-white text-sm font-semibold bg-red-500 shadow-md mt-3 hover:bg-red-400 transition-all hover:opacity-50"
                      onClick={fireApplicant}
                    >
                      <img
                        src={declineIcon}
                        alt="decline-logo"
                        className="mr-1.5"
                      />
                      <span className="my-auto">Remove Employee</span>
                    </button>
                  ) : (
                    <button
                      className="w-full rounded-xl p-2 flex items-center justify-center text-white text-sm font-semibold shadow-md bg-primary-500 dark:bg-darkGray button-position hover:bg-primary-400 transition-all hover:opacity-50"
                      onClick={handleHireApplicant}
                    >
                      <img src={hireIcon} alt="hire-logo" className="mr-1.5" />
                      <span className="my-auto">{t("applicant.hire")}</span>
                    </button>
                  )}

                  {!hiredMode && (
                    <button
                      className={`w-full rounded-xl p-2 flex items-center justify-center text-white text-sm font-semibold bg-red-700 shadow-md mt-3 hover:bg-red-400 transition-all hover:opacity-50`}
                      onClick={handleRejectApplication}
                      disabled={dloading}
                    >
                      <img
                        src={declineIcon}
                        alt="decline-logo"
                        className="mr-1.5"
                      />
                      <span className="my-auto">
                        {dloading ? "..." : "Decline"}
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={16}>
            <div className="rounded-xl border-2 border-gray-300 dark:border-darkGray mb-6 shadow-md">
              <div className="py-2 px-4 flex">
                <i className="fas fa-user text-primary-500 dark:text-gray-500 text-lg mr-2 my-auto" />
                <span className="font-bold text-lg dark:text-gray-300">
                  Biography
                </span>
              </div>
              <div className="px-4 pb-4">
                <p className="text-sm md:text-base dark:text-warmgray-300">
                  {applicant?.note || "No biography available"}
                </p>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 mb-2 pr-0 md:pr-2">
                <div className="flex flex-col items-center justify-center rounded-xl border-[3px] border-warmgray-200 dark:border-darkGray text-center py-4 md:h-full text-sm md:text-base">
                  <i className="fas fa-phone text-primary-500 dark:text-gray-500 text-lg my-auto" />
                  <span className="block font-bold text-gray-900 dark:text-warmgray-300">
                    Contact Number
                  </span>
                  <span className="block mt-1 dark:text-warmgray-300">
                    {applicant?.phone || "-"}
                  </span>
                </div>
              </div>
              <div className="w-full md:w-1/2 mb-2">
                <div className="flex flex-col items-center justify-center rounded-xl border-[3px] border-warmgray-200 dark:border-darkGray text-center py-4 md:h-full text-sm md:text-base">
                  <i className="fas fa-envelope text-primary-500 dark:text-gray-500 text-lg my-auto" />
                  <span className="block font-bold text-gray-900 dark:text-warmgray-300">
                    Email
                  </span>
                  <span className="block mt-1 dark:text-warmgray-300">
                    {applicant?.email}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 mb-2 md:mb-0 pr-0 md:pr-2">
                <div className="flex flex-col items-center justify-center rounded-xl border-[3px] border-warmgray-200 dark:border-darkGray text-center py-4 md:h-full text-sm md:text-base">
                  <img
                    src={mode ? languagesIcon : languagesGrayIcon}
                    alt="languages-logo"
                    className="w-5 h-5 md:w-6 md:h-6"
                  />
                  <span className="block font-bold text-gray-900 dark:text-warmgray-300">
                    Languages
                  </span>
                  <span className="block mt-1 dark:text-warmgray-300">
                    {applicant?.language || "-"}
                  </span>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="flex flex-col items-center justify-center rounded-xl border-[3px] border-warmgray-200 dark:border-darkGray text-center py-4 md:h-full text-sm md:text-base">
                  <img
                    src={mode ? certificationsIcon : certificationsGrayIcon}
                    alt="certification-logo"
                    className="text-primary-500 w-5 h-5 md:w-6 md:h-6 mb-0.5"
                  />
                  <span className="block font-bold text-gray-900 dark:text-warmgray-300">
                    Certifications
                  </span>
                  <span className="block mt-1 dark:text-warmgray-300">
                    {(() => {
                      try {
                        const certificationsArray = JSON.parse(
                          applicant?.certifications
                        );
                        return certificationsArray
                          .map((certification) => certification?.name)
                          .join(" / ");
                      } catch (error) {
                        return applicant?.certifications !== "null"
                          ? applicant?.certifications
                          : "-";
                      }
                    })()}
                  </span>
                </div>
              </div>
            </div>

            <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray my-6">
              <div className="py-2 px-4 flex">
                {mode ? (
                  <img
                    src={calenderIcon}
                    alt="calender-logo"
                    className="mr-2 w-5 h-5 my-auto"
                  />
                ) : (
                  <i className="fas fa-calendar mr-2 text-primary-500 dark:text-gray-500 text-lg my-auto" />
                )}
                <span className="font-bold text-lg my-auto dark:text-gray-300">
                  Availability
                </span>
              </div>
              <div className="pb-4 px-4 pt-2">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-darkGray pb-10">
                    <thead className="bg-[#f6f6f6]">
                      <tr>
                        {availabilityData?.map(({ day }) => (
                          <th
                            key={day}
                            scope="col"
                            className="font-bold border border-gray-300 dark:border-darkGray dark:bg-[#2C2D30] dark:text-gray-500 p-2 min-w-10 lg:min-w-20"
                          >
                            <span className="block 2xl:hidden text-xs">
                              {day.slice(0, 3)}
                            </span>
                            <span className="hidden 2xl:block">{day}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-[#242526] divide-gray-200 dark:divide-darkGray">
                      <tr>
                        {availabilityData.map(({ day }) => (
                          <td
                            key={day}
                            scope="col"
                            className="p-1 lg:p-2 border-r border-gray-300 dark:border-darkGray border"
                          >
                            <div className="tbl-cell text-center text-[9px] lg:text-[12px] dark:text-warmgray-400">
                              {getAvailabilityForDay(day)?.includes("Morning")
                                ? "Morning"
                                : ""}
                            </div>
                          </td>
                        ))}
                      </tr>
                      <tr>
                        {availabilityData.map(({ day }) => (
                          <td
                            key={day}
                            scope="col"
                            className="p-1 lg:p-2 border border-gray-300 dark:border-darkGray"
                          >
                            <div className="tbl-cell text-center text-[9px] lg:text-[12px] dark:text-warmgray-400">
                              {getAvailabilityForDay(day)?.includes("Afternoon")
                                ? "Afternoon"
                                : ""}
                            </div>
                          </td>
                        ))}
                      </tr>
                      <tr>
                        {availabilityData.map(({ day }) => (
                          <td
                            key={day}
                            scope="col"
                            className="p-1 lg:p-2 border border-gray-300 dark:border-darkGray"
                          >
                            <div className="tbl-cell text-center text-[9px] lg:text-[12px] dark:text-warmgray-400">
                              {getAvailabilityForDay(day)?.includes("Evening")
                                ? "Evening"
                                : ""}
                            </div>
                          </td>
                        ))}
                      </tr>
                      <tr>
                        {availabilityData.map(({ day }) => (
                          <td
                            key={day}
                            scope="col"
                            className="p-1 lg:p-2 border border-gray-300 dark:border-darkGray"
                          >
                            <div className="tbl-cell text-center text-[9px] lg:text-[12px] dark:text-warmgray-400">
                              {getAvailabilityForDay(day)?.includes("Night")
                                ? "Night"
                                : ""}
                            </div>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray my-6">
              <div className="py-2 px-4 flex">
                <img
                  src={mode ? positionsIcon : positionsGrayIcon}
                  alt="position-logo"
                  className="mr-2 w-6 h-6 my-auto"
                />{" "}
                <span className="font-bold text-lg my-auto dark:text-gray-300">
                  Past Positions
                </span>
              </div>
              {applicant?.past_positions &&
              applicant?.past_positions.length > 0 ? (
                applicant?.past_positions.map((p, i) => (
                  <div key={i}>
                    <div className="flex justify-between flex-col md:flex-row py-3 px-4 text-sm md:text-base dark:text-gray-300">
                      <span className="flex flex-start">
                        {p?.location} - {p?.employer}
                      </span>
                      <span className="flex flex-end whitespace-nowrap">
                        {dayjs(p?.start_date).format(DateFormat)} -{" "}
                        {p?.end_date === null
                          ? "Present"
                          : dayjs(p?.end_date).format(DateFormat)}
                      </span>
                    </div>
                    <hr className="border-warmgray-200 dark:border-darkGray mx-3" />
                  </div>
                ))
              ) : (
                <p className="p-4 max-h-[300px] dark:text-gray-500 overflow-y-auto">
                  No Past Positions Available
                </p>
              )}
            </div>

            <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray my-6">
              <div className="py-2 px-4 flex">
                <img
                  src={mode ? skillIcon : skillGrayIcon}
                  alt="skill-logo"
                  className="mr-2 w-5 h-5 my-auto"
                />{" "}
                <span className="font-bold text-lg my-auto dark:text-gray-300">
                  Skills
                </span>
              </div>
              <div className="pb-4 pt-2 px-4">
                {applicant?.skill ? (
                  <div className="flex flex-wrap gap-2">
                    {applicant?.skill?.split(",")?.map((skill, index) => (
                      <div
                        key={index}
                        className="rounded-full border border-primary-500 dark:border-darkGray dark:bg-darkGray px-2 py-1 text-sm dark:text-gray-300"
                      >
                        {skill.trim()}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="dark:text-gray-500">No Skills</p>
                )}
              </div>
            </div>

            {application?.questions && (
              <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray mt-2 mb-8">
                <div className="py-2 px-4 flex">
                  <img
                    src={mode ? questionIcon : questionIconGray}
                    alt="activity-logo"
                    className="mr-2 w-6 h-6 my-auto"
                  />{" "}
                  <span className="font-bold text-lg my-auto dark:text-gray-300">
                    Questions
                  </span>
                </div>
                <div className="divide-y divide-gray-900/10 dark:divide-darkGray px-4 pb-2">
                  {JSON.parse(application?.questions).map((faq, index) => (
                    <div key={index} className="py-4 text-sm md:text-base">
                      <div className="lg:col-span-5 font-bold dark:text-gray-300">
                        {faq?.description}{" "}
                      </div>
                      <div className="mt-4 lg:col-span-7 lg:mt-0 dark:text-gray-200">
                        {faq?.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <ResumeViewer applicant={applicant} mode={mode} />

            <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray my-6">
              <div className="py-2 px-4 flex items-start justify-between">
                <span className="flex">
                  <img
                    src={mode ? instagramIcon : instagramGrayIcon}
                    alt="insta-logo"
                    className="mr-2 w-5 h-5 my-auto"
                  />{" "}
                  <span className="font-bold text-lg my-auto dark:text-gray-300">
                    Instagram Feed
                  </span>
                </span>
                <span
                  className="ml-6 flex h-7 items-center cursor-pointer dark:text-gray-500"
                  onClick={toggleSection}
                >
                  {isOpen ? (
                    <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                  ) : (
                    <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                  )}
                </span>
              </div>
              {isOpen && (
                <div className="px-4 py-4 max-h-[700px] overflow-y-auto">
                  {instagramImages?.length > 0 && (
                    <div className="grid grid-cols-2 gap-4">
                      {instagramImages?.map((int, i) => (
                        <div key={i}>
                          <img
                            src={int.photo_url || "https://fakeimg.pl/300x200/"}
                            className="block h-full w-full rounded-lg object-cover object-center"
                            alt={`img-instagram-${i}`}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {instagramImages.length === 0 && (
                    <p className="dark:text-gray-500">
                      No Instagram Profile Yet
                    </p>
                  )}
                </div>
              )}
            </div>

            <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray my-6">
              <div className="py-2 px-4 flex">
                <img
                  src={mode ? interviewIcon : interviewGrayIcon}
                  alt="interview-logo"
                  className="mr-2 w-6 h-6 my-auto"
                />{" "}
                <span className="font-bold text-lg my-auto dark:text-gray-300">
                  Interview Notes
                </span>
              </div>
              <div className="p-4">
                <FormTextarea
                  placeholder={"Enter interview notes here"}
                  callBack={setInterviewNotes}
                  value={notes}
                  darmo={mode}
                />

                <div className="text-center mt-4">
                  <button
                    onClick={saveInterviewNotes}
                    className="px-6 py-2 rounded-full bg-primary-500 dark:bg-darkGray text-sm font-bold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                  >
                    Save Note
                  </button>
                </div>
              </div>
            </div>

            <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray my-6">
              <div className="py-2 px-4 flex">
                <img
                  src={mode ? activityIcon : activityGrayIcon}
                  alt="activity-logo"
                  className="mr-2 w-6 h-6 my-auto"
                />{" "}
                <span className="font-bold text-lg my-auto dark:text-gray-300">
                  {" "}
                  Activity Log
                </span>
              </div>
              <div className="overflow-auto max-h-56">
                {activityLogs?.map((app, i) => (
                  <div key={i}>
                    <div className="flex flex-column justify-between p-3 text-xs md:text-sm dark:text-gray-300">
                      <span className="flex flex-start mr-1">{app.action}</span>
                      <span className="flex flex-end">
                        {dayjs(app.created_at).format(DateFormatWithTime)}
                      </span>
                    </div>
                    <hr className="border-warmgray-200 dark:border-darkGray mx-3" />
                  </div>
                ))}
                {activityLogs?.length === 0 && (
                  <p className="p-4 max-h-[300px] overflow-y-auto dark:text-gray-500">
                    No Activity Logs
                  </p>
                )}
              </div>
            </div>
          </Col>
        </Row>
      )}
      {isModalVisible && (
        <NewConfirmationModal
          open={isModalVisible}
          setOpen={setIsModalVisible}
          type={type}
          onClick={type === "hire" ? hireApplicant : rejectApplication}
          applicant={applicant}
          positionTitle={positionTitle}
          defaultUserImage={defaultUserImage}
        />
      )}
    </DashboardLayout>
  );
};

export default ViewApplicant;
