import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../components/organisms/Layout";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import SimpleTable from "../../components/atoms/Tables/SimpleTable";
import JobSeeker from "../../api/JobSeeker";
import Notification from "../../api/Notification";
import { defaultUserImage } from "../../assets/images";

const Notifications = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationTableData, setNotificationTableData] = useState([]);
  const [notificationTableHeader, setNotificationTableHeader] = useState([
    {
      name: "",
    },
    {
      name: "Type",
    },
    {
      name: "Message",
    },
    {
      name: "Duration",
    },
    {
      name: "Status",
    },
  ]);

  const getTimeDifference = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    const timeDifference = Math.abs(date2 - date1);
    const secondsAgo = Math.floor(timeDifference / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    return { secondsAgo, minutesAgo, hoursAgo, daysAgo };
  };

  const getNotifications = () => {
    Notification.getAllNotifications()
      .then((response) => {
        let notifications = response.data.data.payload;

        notifications.map((item) => {
          const data = JSON.parse(item.data);
          const jobSeekerId = data.job_seeker_id;
          const jobPositionId = data.job_position_id;
          const body = data.body;
          const conversation_sid = data.conversation_sid;

          const { secondsAgo, minutesAgo, hoursAgo, daysAgo } =
            getTimeDifference(item.created_at, new Date().toUTCString());

          const details = {
            type: item.type,
            id: item.id,
            conversation_sid: conversation_sid,
            name: "",
            status: item.read_at,
            email: "",
            body: data.body,
            message: data.message,
            seconds: secondsAgo,
            minutes: minutesAgo,
            hours: hoursAgo,
            days: daysAgo,
            href: "#",
          };

          constructTableDataWithJobSeekerInfo(jobSeekerId, details);
        });
      })
      .catch(() => {
        console.log("Error");
      });
  };

  const constructTableDataWithJobSeekerInfo = async (id, details) => {
    const seeker = await JobSeeker.getById(id);

    if (seeker.data.data) {
      details.name = seeker.data.data.first_name;
      details.email = seeker.data.data.email;

      if (seeker.data.data.avatar_image === 1) {
        details.imageUrl = seeker.data.data.photo.thumb_url;
      } else {
        details.imageUrl = defaultUserImage;
      }
      constructData(details);
    }
  };

  const constructData = (details) => {
    let tableData = [];
    let type =
      details.type === "App\\Notifications\\MessageNotification"
        ? "Messaging"
        : details.type;
    tableData = {
      1: details.status ? (
        ""
      ) : (
        <div class="rounded-full w-3 h-3 bg-primary-500"></div>
      ),
      2: type,
      3: (
        <div className="flex">
          <div>
            <img
              className="h-5 w-5 mr-2 rounded-full bg-gray-50"
              src={details.imageUrl}
              alt=""
            />
          </div>
          <div className="text-sm">
            <span className="font-bold">{details.name} </span>
            {details.message}
          </div>
        </div>
      ),
      4:
        details.days === 0
          ? details.hours === 0
            ? details.minutes === 0
              ? details.seconds + " seconds ago"
              : details.minutes + " minutes ago"
            : details.hours + " hours ago"
          : details.days + " days ago",
      5: details.status ? "read" : "unread",
      6: details.conversation_sid,
    };

    setNotificationTableData((notificationTableData) => [
      ...notificationTableData,
      tableData,
    ]);
  };
  useEffect(() => {
    document.title = "HeyHire Portal-Billing Information";
    getNotifications();
  }, []);

  return (
    <DashboardLayout darmo={true}>
      {notificationTableData && (
        <div className="text-[13px] text-green-400">
          <SimpleTable
            title="Notifications"
            content="List of all notifications"
            data={notificationTableData}
            showButton={false}
            showActionButton={true}
            actionButtonName="View"
            actionButtonUrl="/messages?conversation_sid="
            header={notificationTableHeader}
            onClickButton={() => {
              setShowModal(true);
            }}
          />
        </div>
      )}
      {loading && <Loading />}
    </DashboardLayout>
  );
};

export default Notifications;
