import { object, string } from "yup";

export const createQRcodeInitialValues = {
    name: "",
    entity_type: "",
    entity_id: "",
}

export const createQRcodeSchema = object().shape({
    name: string().required("Name is required"),
    entity_type: string().required("Entity Type is required"),
    entity_id: string().required("Entity ID is required"),
})
