import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import { useSelector } from "react-redux";
import { selectRoles } from "../../store/authSlice";
import AddLocationModal from "../../components/molecules/FormLocation";
import TableActionDropdown from "../../components/TableActionDropdown";
import LocationApi from "../../api/Location";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { DashboardLayout } from "../../components/organisms/Layout";
import { FormModal } from "../../components/organisms/FormModal";
import DeleteModal from "../../components/modals/DeleteModal";
import Region from "../../api/Region";
import PaginationView from "../../components/molecules/Pagination";
import BreadCrumbs from "../../components/organisms/BreadCrumb";
import { encodeIds } from "../../utils";
import SearchBar from "../../components/atoms/SearchBar";
import useQuery from "../../hooks/useQuery";
import Table from "../../components/atoms/Tables/Table";
import { addIcon } from "../../assets/images";

const Locations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const roles = useSelector(selectRoles);

  const [showModal, setShowModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchLocation, setSearchLocation] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 50);
  const isSuperUser = false;

  useEffect(() => {
    document.title = "HeyHire Portal-Locations";
    loadLocations();
  }, []);

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    setLocationToDelete(null);
  });

  useCustomEventListener("dataChanged", () => {
    loadLocations();
  });

  const searchLocationName = (txt) => {
    setSearchLocation(txt);
    let text = txt.toLowerCase();
    if (text == "") {
      setFilteredLocations(locations);
    } else {
      let _locations = locations.filter((j) =>
        j.name.toLowerCase().includes(text)
      );
      setFilteredLocations(_locations);
    }
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  const addLocation = (values) => {
    setLoading(true);

    Location.add(values)
      .then(() => {
        toast.success(t("locations.location-added"));
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  const loadLocations = () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const limit = query.get("limit") || pageLimit;
    const pageData = page ? `page=${page}` : "";
    const queryData = pageData + `&limit=${limit}`;

    LocationApi.getAll(queryData)
      .then((response) => {
        setLocations(response.data.data);
        setFilteredLocations(response.data.data);
        setMetaData(response.data.meta);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  const onLocationDeleted = () => {
    setLocationToDelete(null);
    loadLocations();
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex flex-col md:flex-row justify-between mt-6">
        <div className="w-full lg:w-3/5 xl:w-1/3">
          <SearchBar
            searchValue={searchLocation}
            placeholder="Search Location By Name"
            searchFunction={(value) => searchLocationName(value)}
          />
        </div>
      </div>

      <div className="overflow-x-auto h-full mt-3 pb-12">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Table
              mainHeader="Locations"
              headers={
                !loading && filteredLocations?.length > 0
                  ? [
                      "Name",
                      isSuperUser ? "Company Name" : "Address",
                      "Category",
                      "Region",
                      "Edit",
                    ]
                  : ["No data available"]
              }
              data={
                !loading &&
                filteredLocations?.map((location, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1">
                      {location.name}
                    </td>
                    <td className="whitespace-nowrap xl:whitespace-normal pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {isSuperUser
                          ? location?.company?.name
                          : location?.address?.address}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {location.category && location.category.name}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {location.region && location.region.name}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      <div
                        className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/companies/locations/${encodeIds(
                              location.id
                            )}/edit`
                          )
                        }
                      >
                        Edit
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      <div
                        className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                        onClickDelete={() => {
                          setLocationToDelete(location.id);
                        }}
                      >
                        Delete
                      </div>
                    </td>
                  </tr>
                ))
              }
              buttonData={
                <>
                  {roles[0] === "super-user" && (
                    <button
                      onClick={() => navigate("add")}
                      className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                    >
                      <div className="flex">
                        <img
                          src={addIcon}
                          alt="logo"
                          className="mr-2 my-auto w-4 h-4"
                        />
                        Add
                      </div>
                    </button>
                  )}
                </>
              }
            />
            <div className="flex w-full h-20 mt-2 justify-end items-center">
              {!loading && (
                <PaginationView
                  current={metaData?.current_page}
                  next={metaData?.to}
                  pageSize={metaData?.per_page}
                  total={metaData?.total}
                  onChange={handlecPageChange}
                />
              )}
            </div>
          </>
        )}
      </div>

      <FormModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        title={t("locations.add-a-location")}
        subTitle="Vivamus venenatis eros sit amet lectus gravida, volutpat dictum ex pellentesque. "
        // onSubmit={addLocation}
      >
        <AddLocationModal />
      </FormModal>

      {locationToDelete && (
        <DeleteModal
          title="Delete Location"
          name="Location"
          deleteRequest={() => LocationApi.delete(locationToDelete)}
          onSuccess={onLocationDeleted}
        />
      )}
    </DashboardLayout>
  );
};

export default Locations;
