export default function Promotion({
  header = "Boost your productivity.",
  subHeader = "Start using our app today",
  content = "Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua proident excepteur commodo do ea.",
  buttonText = "Button"
}) {
  return (
    <div className="bg-white">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {header}
            <div className="pt-5 text-[17px]">{subHeader}</div>
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            {content}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            {/* <a
              href="#a"
              className="rounded-md bg-primary-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm
               hover:bg-primary-700 hover:text-white
                focus-visible:outline-white"
            >
              {buttonText}
            </a> */}
            <a
              href="#d"
              className="text-sm font-bold leading-6 text-primary-500 z-50"
            >
              {buttonText} <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
