import React from "react";

const HButton = ({
  children,
  variant = "contained",
  type = "button",
  color = "primary",
  loading = false,
  disabled = false,
  className,
  handleSubmit,
}) => {
  return variant === "contained" ? (
    <button
      className={`${className} rounded-md bg-${color}-500 text-white hover:bg-${color}-400 py-2 transition-all hover:opacity-50 disabled:opacity-50`}
      disabled={disabled}
      type={type}
      onClick={handleSubmit}
    >
      {children}
      {loading ? (
        <i className="fad fa-circle-notch fa-spin ml-2 my-auto" />
      ) : (
        ""
      )}
    </button>
  ) : (
    <button
      className={`${className} rounded-md border border-${color}-500 text-${color}-500 hover:border-${color}-400 py-2 transition-all`}
      disabled={disabled}
      type={type}
    >
      {children}
      {loading ? (
        <i className="fad fa-circle-notch fa-spin ml-2 my-auto" />
      ) : (
        ""
      )}
    </button>
  );
};

export default HButton;
