import React, { createContext, useContext, useState } from "react";

const SettingsContext = createContext("");

export function SettingsContextProvider({children}) {

  const scrollUpRef = React.createRef();

    const companyObject= [
      {
        companyId: null,
        companyName: ""
      }
    ];

     const businessObject = [
       {
         businessId: "",
         businessName: "",
       }
     ];

     const turnKeyJSONObject = [{}];

    const [company, setCompanyName] = useState(companyObject); 
    const [businessType, setBusinessType] = useState(businessObject);
    const [turnKeyObject, setTurnKeyObject] = useState(turnKeyJSONObject);
    const [imageUploadFile, setImageUploadFile] = useState([]);
    const [existingTurnKeyAccount,setExistingTurnKeyAccounts] = useState([]);
    const [turnKeyAccountId, setTurnKeyAccountId] = useState(null);

    return (
      <SettingsContext.Provider
        value={{
          company,
          setCompanyName,
          businessType,
          setBusinessType,
          turnKeyObject,
          setTurnKeyObject,
          scrollUpRef,
          imageUploadFile,
          setImageUploadFile,
          existingTurnKeyAccount,
          setExistingTurnKeyAccounts,
          turnKeyAccountId,
          setTurnKeyAccountId
        }}
      >
        {children}
      </SettingsContext.Provider>
    );
}

export function useSettingsContext() {
    return useContext(SettingsContext);
}

