import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Loading from "../../../../components/Loading";
import useQuery from "../../../../hooks/useQuery";
import {
  selectRoles,
  selectUser,
  selectPermissions,
} from "../../../../store/authSlice";
import { DashboardLayout } from "../../../../components/organisms/Layout";
import { Fragment, useState } from "react";
import {
  Popover,
  Dialog,
  Switch,
  Transition,
  Listbox,
} from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  Bars3BottomLeftIcon,
  BellIcon,
  BriefcaseIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CogIcon,
  DocumentMagnifyingGlassIcon,
  HomeIcon,
  AcademicCapIcon,
  BuildingOffice2Icon,
  UserIcon,
  LockOpenIcon,
  CurrencyDollarIcon,
  QuestionMarkCircleIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import SimpleTable from "../../../../components/atoms/Tables/SimpleTable";
import Table from "../../../../components/atoms/Tables/SimpleTable";
import ControlPanel from "../../../../api/ControlPanel";
import { data } from "autoprefixer";
import StackedAvatar from "../../../../components/atoms/StackedAvatar/StackedAvatar";
import FlyoutMenu from "../../../../components/atoms/FlyoutMenu/FlyoutMenu";
import RoundEdgeButton from "../../../../components/atoms/Buttons/RoundEdgeButton";
import PaginationView from "../../../../components/molecules/Pagination";
import LogoCards from "../../../../components/atoms/Cards/LogoCards";
import WalletModal from "../../../../components/molecules/WalletModal/WalletModal";
import PlainModal from "../../../../components/molecules/WalletModal/PlainModal";
import CreditCardSvg from "../../../../components/svg/CreditCardSvg";
import CreditCards from "../../../../components/atoms/Cards/CreditCards";
import NarrowFlyoutMenu from "../../../../components/atoms/FlyoutMenu/NarrowFlyoutMenu";
import PaymentDetails from "../../../../api/PaymentDetails";
import SlideOverPanel from "../../../../components/atoms/SildeOverPanel/SlideOverPanel";
import SlideOverPanel1 from "../../../../components/atoms/SildeOverPanel/SlideOverPanel1";
import SlidePanelForm from "./Forms/SlidePanelForm";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  StopCircleIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";

import { stringify } from "query-string";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";

const PaymentDetailsPanel = ({ callback = false }) => {
  const [customersList, setCustomersList] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);
  const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] =
    useState(false);
  const { t } = useTranslation();
  const roles = useSelector(selectRoles);
  const [businessNameShowPanel, setBusinessNameShowPanel] = useState(false);
  const [lastFetchedCustomerId, setLastFetchedCustomerId] = useState("");
  const [firstFetchedCustomerId, setFirstFetchedCustomerId] = useState("");
  const [openBlockConfirmationModal, setOpenBlockConfirmationModal] =
    useState(false);
  const query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasMoreData, setHasMoreData] = useState(false);
  const [moreDetailsCustomerName, setMoreDetailsCustomerName] = useState([]);
  const [moreDetailsBusinessName, setMoreDetailsBusinessName] = useState([]);
  const [moreDetailsOwnerName, setMoreDetailsOwnerName] = useState([]);
  const [detailBusinessData, setDetailBusinessData] = useState([]);
  const [detailBusinessAction, setDetailBusinessAction] = useState([]);
  const [refreshPanel, setRefreshPanel] = useState(false);
  const [triggerEditForm, setTriggerEditForm] = useState(false);
  const [slideFormCustomer, setSlideFormCustomer] = useState([]);
  const [showLocationDropDown, setShowLocationDropDown] = useState(true);
  const [businessLocationName, setBusinessLocationName] = useState("");
  const [latestPayout, setLatestPayout] = useState([]);
  const [latestRefund, setLatestRefund] = useState([]);
  const [latestSubscription, setLatestSubscription] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [showCards, setShowCards] = useState(false);
  const [actionCardCustomerId, setActionCardCustomerId] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState(false);
  const [blockCustomerId, setBlockCustomerId] = useState("");
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 10);

  useEffect(() => {
    document.title = "HeyHire Admin-Control-Panel";
    getAllCustomers("");
    getStats();
  }, []);

  useEffect(() => {
    if (cardData.length === 3) {
      setShowCards(true);
    }
    callback("Payment");
  }, [cardData]);

  const handleActionCardOnClick = (itemId, customer) => {
    if (itemId === 1) {
      handleOnClickView(customer);
    }
    if (itemId === 2) {
      handleOnClickBlock(customer);
    }
  };

  const handleOnClickView = (customer) => {
    window.open(
      "https://dashboard.stripe.com/test/customers/" + customer.id,
      "_blank"
    );
  };
  const handleOnClickBlock = (customer) => {
    setBlockCustomerId(customer.id);
    setOpenBlockConfirmationModal(true);
  };
  const handleOnClickInvoice = () => {};
  const handleOnClickPaymentStatus = () => {};

  const handleOpen = (customer) => {};
  const solutions = [
    {
      id: 1,
      name: "View",
      description: "View the account in strip",
      onClick: handleOnClickView,
      icon: ChartPieIcon,
    },
    // {
    //   id: 2,
    //   name: "Suspend Subscription",
    //   description: "Pausing payment collection",
    //   onClick: handleOnClickPaymentStatus,
    //   icon: ArrowPathIcon,
    // },
    // {
    //   id: 2,
    //   name: "Cancel Subscription",
    //   description: "Cancel all subscriptions for this account",
    //   onClick: handleOnClickPaymentStatus,
    //   icon: ArrowPathIcon,
    // },
    {
      id: 2,
      name: "Block",
      description: "Block Account",
      onClick: handleOnClickBlock,
      icon: StopCircleIcon,
    },
    // {
    //   id: 3,
    //   name: "Invoice",
    //   description: "Download the latest payment invoice",
    //   onClick: handleOnClickInvoice,
    //   icon: CursorArrowRaysIcon,
    // },
    // {
    //   id: 4,
    //   name: "Payment Status",
    //   description: "Card validation",
    //   onClick: handleOnClickPaymentStatus,
    //   icon: ArrowPathIcon,
    // },
  ];

  const getPayout = async () => {
    let payoutData = "";

    await PaymentDetails.getLatestPayout()
      .then((response) => {
        if (response.data && response.data.data) {
          setLatestPayout(response.data.data[0]);
          if (response.data.data[0]) {
            payoutData = {
              id: 1,
              name: "Payouts : " + response.data.data[0].description,
              imageUrl: "https://tailwindui.com/img/logos/48x48/tuple.svg",
              lastInvoice: {
                Name: response.data.data[0].arrival_date,
                dateTime: timeStampConvertion(
                  response.data.data[0].arrival_date
                ),
                amount: response.data.data[0].amount,
                status: response.data.data[0].status,
              },
            };
          }
        }
      })
      .catch((error) => {
        console.log("Error fetching the business list");
      });
    return payoutData;
  };

  const getRefund = async () => {
    let refundData = "";

    await PaymentDetails.getLatestRefund()
      .then((response) => {
        if (response.data.count === 0) {
          refundData = {
            id: 2,
            name: "Refunds : None",
            imageUrl: "https://tailwindui.com/img/logos/48x48/tuple.svg",
            lastInvoice: {
              Name: "N/A",
              dateTime: "-",
              amount: 0,
              status: "none",
            },
          };
        } else if (response.data.data) {
          setLatestRefund(response.data.data[0]);
          if (response.data.data[0]) {
            refundData = {
              id: 2,
              name: "Refunds : " + response.data.data[0].description,
              imageUrl: "https://tailwindui.com/img/logos/48x48/tuple.svg",
              lastInvoice: {
                Name: response.data.data[0].description,
                dateTime: "2023-01-23",
                amount: response.data.data[0].amount,
                status: "none",
              },
            };
          }
        }
      })
      .catch((error) => {
        console.log("Error fetching the business list");
      });

    return refundData;
  };

  const getSubscription = async () => {
    let subscriptionData = "";
    await PaymentDetails.getLatestSubscription()
      .then((response) => {
        if (response.data && response.data.data) {
          if (response.data.has_more === false) {
            subscriptionData = {
              id: 3,
              name: "Recently Subscribed : None",
              imageUrl: "https://tailwindui.com/img/logos/48x48/tuple.svg",
              lastInvoice: {
                Name: "N/A",
                dateTime: "2023-01-23",
                amount: 0,
                status: "none",
              },
            };
          } else if (response.data.data) {
            setLatestSubscription(response.data.data[0]);
            if (response.data.data[0]) {
              subscriptionData = {
                id: 3,
                name: "Recently Subscribed : " + response.data.data[0].customer,
                imageUrl: "https://tailwindui.com/img/logos/48x48/tuple.svg",
                lastInvoice: {
                  Name: response.data.data[0].description,
                  dateTime: timeStampConvertion(
                    response.data.data[0].current_period_start
                  ),
                  amount: response.data.data[0].amount,
                  status: "active",
                },
              };
            }
          }
        }
      })
      .catch((error) => {
        console.log("Error fetching the business list");
      });
    return subscriptionData;
  };

  const getStats = async () => {
    const payOutData = await getPayout();
    const refundData = await getRefund();
    const subscriptionData = await getSubscription();

    if (payOutData && refundData && subscriptionData) {
      setCardData([payOutData, refundData, subscriptionData]);
    }
  };

  const handlePageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({
      ...searchParams,
      page: currentPage,
      pageSize: pageSize,
    });
  };

  const getAllCustomers = (fetchedDataTag) => {
    let customerIdData = "";

    if (fetchedDataTag === "next") {
      customerIdData = "&starting_after=" + lastFetchedCustomerId;
    } else if (fetchedDataTag === "previous") {
      customerIdData = "&ending_before=" + firstFetchedCustomerId;
    }

    const page = query.get("page") || 1;
    const sort = query.get("sort") || "";
    const limit = query.get("limit") || pageLimit;
    const sortData = sort ? `sort=${sort}&` : "";
    const pageData = page ? `page=${page}` : "";
    const queryData = sortData + pageData + customerIdData + `&limit=${limit}`;

    PaymentDetails.getAllCustomers(queryData)
      .then((response) => {
        setCustomersList(response.data.list.data);
        setLastFetchedCustomerId(response.data.last_customer_id);
        setFirstFetchedCustomerId(response.data.first_customer_id);
        setHasMoreData(response.data.list.has_more);
      })
      .catch((error) => {
        console.log("Error fetching the business list");
      });
  };

  const handleNext = () => {
    if (hasMoreData) {
      getAllCustomers("next");
    }
  };

  const handlePrevious = () => {
    if (hasMoreData) {
      getAllCustomers("previous");
    }
  };

  const timeStampConvertion = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const dateTimeString = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;

    return dateTimeString;
  };

  const handleMoreSectionClick = () => {
    setTriggerEditForm(!triggerEditForm);
  };

  const handleMoreButtonClick = async (item) => {
    let customerInfo = "";
    let businessInfo = "";
    let OwnerInfo = "";
    setSlideFormCustomer(item);

    await PaymentDetails.getLocationByCustomerId(item.id)
      .then((response) => {
        console.log(response);
        if (response.data.status_flag === 0) {
          setShowLocationDropDown(true);
          businessInfo = "No Business Linked";
        } else {
          setShowLocationDropDown(false);
          businessInfo = response.data.data.name;
          setBusinessLocationName(businessInfo);
        }

        if (item.name === "") {
          customerInfo = "No Customer Name";
        } else {
          customerInfo = item.description + " : " + item.id;
        }

        const data = [
          {
            name: "HeyHire Business Name",
            description: businessInfo,
            href: "#",
            icon: BuildingOffice2Icon,
          },
          {
            name: "Owner",
            description: "Your customers' data will be safe and secure",
            href: "#",
            icon: UserIcon,
          },
          {
            name: "Stripe Customer Name & Id",
            description: customerInfo,
            href: "#",
            icon: CurrencyDollarIcon,
          },
        ];

        const callsToAction = [
          {
            name: "view in strip.com",
            href: "https://dashboard.stripe.com/test/customers/" + item.id,
            icon: LockOpenIcon,
          },
        ];
        setDetailBusinessData(data);
        setDetailBusinessAction(callsToAction);
        setRefreshPanel(!refreshPanel);
      })
      .catch((error) => {
        console.log("Error fetching the business details");
      });
  };

  const captureSearchInput = (value) => {
    setSearchCustomer(value);
  };

  const getCustomer = () => {
    PaymentDetails.getCustomerById(searchCustomer)
      .then((response) => {
        setCustomersList([response.data.data]);
      })
      .catch((error) => {
        console.log("Error fetching the search business");
      });
  };

  const confirmBlock = async () => {
    const blockAccount = await PaymentDetails.blockAccount(blockCustomerId);
    setOpenBlockConfirmationModal(false);
  };

  return (
    <div className=" px-4 sm:px-6 lg:px-0">
      {openBlockConfirmationModal && (
        <ConfirmationModal
          openTrigger={openBlockConfirmationModal}
          closeTrigger={() => setOpenBlockConfirmationModal(false)}
          type="error"
          title="Confirm Action"
          confirmText="Block"
          selectedId={blockCustomerId}
          description="Are you sure you want to block this location ? Please make sure the customer is attached to a Business Location"
          confirm={confirmBlock}
        />
      )}
      <SlideOverPanel1
        children={
          <SlidePanelForm
            openTrigger={handleMoreSectionClick}
            customer={slideFormCustomer}
            showLocationOption={showLocationDropDown}
            businessName={businessLocationName}
          />
        }
        openTrigger={triggerEditForm}
      />
      <div className="grid grid-flow-col">
        <div className="pt-3">
          {showCards && <LogoCards clients={cardData} />}
        </div>
      </div>
      <div className="pt-7 -py-6">
        <div className="float-right pb-10">
          <div className="min-w-[450px] pt-2">
            <label
              htmlFor="search"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Search By Stripe Customer ID :{" "}
              <span className="text-gray-400 bg-gray-50 ring-gray-500/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset">
                example - cus_Ntg2xON4eiNQui
              </span>
            </label>
            <div className="relative mt-2 flex items-center">
              <input
                type="text"
                name="search"
                id="search"
                className="block w-full rounded-md border-1 py-1.5 pr-14 p-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-warmgray-600 m:text-sm sm:leading-6"
                onChange={(e) => captureSearchInput(e.target.value)}
              />
              <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                <kbd className="inline-flex items-center rounded border cursor-pointer border-gray-200 px-1 font-sans text-xs text-gray-400">
                  <MagnifyingGlassIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                    onClick={getCustomer}
                  />
                </kbd>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-y divide-warmgray-300 pt-20 min-h-[300px]">
          <div className="space-y-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900 pb-2">
              Payment Accounts
            </h3>
          </div>
          <div className="border-10 shadow-sm border-black pl-1 pr-1">
            <div className="mt-1 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-0"
                        >
                          Account
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                        >
                          Default Payment
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                        >
                          Balance
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                        >
                          Created At
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                        >
                          Subscription
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">View More</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {customersList.map((customer, index) => (
                        <tr key={customer.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                            <div className="flex items-center">
                              <div className="ml-0">
                                <div
                                  key={index}
                                  onClick={() => {
                                    setBusinessNameShowPanel(
                                      !businessNameShowPanel
                                    );
                                  }}
                                  className="font-medium text-gray-900"
                                >
                                  Name: {customer.description}
                                </div>
                                <div className="text-gray-500">
                                  email: {customer.email}
                                </div>
                                <div>
                                  <NarrowFlyoutMenu
                                    refresh={refreshPanel}
                                    key={customer.id}
                                    item={customer}
                                    handleButtonClick={handleMoreButtonClick}
                                    handleSectionClick={handleMoreSectionClick}
                                    panelData={detailBusinessData}
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <PlainModal
                              children={
                                <CreditCards source={customer.default_source} />
                              }
                              status={customer.default_source ? true : false}
                              buttonName="card * * *"
                              icon={CreditCardSvg}
                              confirmButtonName="Edit"
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {customer.balance}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {timeStampConvertion(customer.created)}
                            {/* <StackedAvatar /> */}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {customer.subscriptions.total_count !== 0 && (
                              <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                Active
                              </span>
                            )}
                            {customer.subscriptions.total_count === 0 && (
                              <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                                No Subscription
                              </span>
                            )}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-1 pr-4 text-right text-sm font-medium sm:pr-0">
                            <a
                              href="#asd"
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              <FlyoutMenu
                                data={solutions}
                                handleClick={handleActionCardOnClick}
                                item={customer}
                                // open={businessNameShowPanel}
                                // close={!businessNameShowPanel}
                              />
                              <span className="sr-only">, vimal</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 mt-5 justify-between sm:justify-end">
          <a
            href="#cv"
            onClick={handlePrevious}
            className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Previous
          </a>
          <a
            href="#asd"
            onClick={handleNext}
            className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Next
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsPanel;
