import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import RBAC from "../../api/RBAC";
import Input from "../form/Input";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setRefreshRole } from "../../store/authSlice";

export default function AddRoleModal({
  open,
  setOpen,
  featuresList,
}) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [roleNameError, setRoleNameError] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [permissionsArray, setPermissionsArray] = useState([]);
  const [selectedAccess, setSelectedAccess] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initialExpandedRows = {};
    const initialPermissions = [];

    Object.entries(featuresList).forEach(([key, records], index) => {
        initialExpandedRows[index] = true;
        
        records.forEach((record) => {
            initialPermissions.push({ feature_id: record.id, allow: 0 });
        });
    });

    setExpandedRows(initialExpandedRows);
    setPermissionsArray(initialPermissions);
  }, [featuresList]);

  const handleRowClick = (index) => {
    setExpandedRows((prevExpandedRows) => ({
       ...prevExpandedRows,
       [index]: !prevExpandedRows[index],
     }));
  };

  const handleCheckClick = (id, checked, records) => {
    if (records) {
      const updatedPermissionsArray = permissionsArray?.map((permission) => {
        const recordExists = records.some((record) => record.id === permission.feature_id);
        return {
          ...permission,
          allow: checked ? (recordExists ? 0 : permission.allow) : (recordExists ? 1 : permission.allow)
        };
      });
      setSelectedAccess(updatedPermissionsArray);
      setPermissionsArray(updatedPermissionsArray);
    } else {
      const updatedPermission = permissionsArray.find(permission => permission.feature_id === id);
      if (updatedPermission) {
        const updatedPermissionsArray = permissionsArray.map((permission) => {
          if (permission.feature_id === id) {
            return {
              ...permission,
              allow: checked ? 0 : 1
            };
          }
          return permission;
        });
        setSelectedAccess(updatedPermissionsArray);
        setPermissionsArray(updatedPermissionsArray);
      }
    } 
  };

  const handleAddRole = async () => {
    if(!name){
      setNameError(true);
    } else {
      setNameError(false);
      setLoading(true);
      try {
        const payload = {
          roles: name,
          permissions: selectedAccess,
        }
        const response = await RBAC.addRole(payload);  
        if(response){
          if(response?.data?.error){
            setRoleNameError(true);
          } else {
            toast.success("New Role Added");
            dispatch(setRefreshRole(true));
            setOpen(false);
          }
        }
      } catch (error) {
        console.error("Error adding new role:", error);
      }
      setLoading(false);
    }
  }
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg p-8 text-center shadow-xl transition-all sm:my-8 sm:p-6 w-fit md:w-[600px] bg-white">
                <div className="mt-2">
                  <h4 className="font-bold text-primary-500 mt-4 mx-6 text-2xl">
                    Add New Role
                  </h4>
                  <div className="text-left">
                    <Input
                      className="my-3"
                      name="name"
                      label="Role Name"
                      type="text"
                      value={name}
                      // error={(nameError && "Role Name is required") || (roleNameError && "Role name already exists.")}
                      placeholder="Role Name"
                      darmo={true}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {nameError ? <div className="text-red-400 text-xs font-bold">Role Name is required.</div> :
                    (roleNameError && <div className="text-red-400 text-xs font-bold">Role name already exists.</div>)}
                  </div>
                  <div className="max-h-96 overflow-auto my-2">
                  <table className="min-w-full divide-y divide-gray-500 my-2 whitespace-nowrap">
                    <thead>
                        <tr>
                            <th className="w-1/3 py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">Features</th>
                            <th className="w-1/3 py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">Permission</th>
                            <th className="w-1/4 py-2.5 px-3 text-center text-lg font-bold text-gray-900 whitespace-nowrap">Access</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Object.entries(featuresList).map(([item, records], index) => (
                    <React.Fragment key={index}>
                        <tr className="border-b border-gray-300">
                            <td className="text-left whitespace-nowrap pt-3 pb-2 pr-3 text-[16px] font-extrabold text-gray-900">
                                <i
                                    className={`fas ${
                                    expandedRows[index] ? "fa-chevron-up" : "fa-chevron-down"
                                    } cursor-pointer mr-2`}
                                    onClick={() => handleRowClick(index)}
                                ></i>
                                <i className="fas fa-bars text-primary-500 mr-1"></i>
                                {item}
                            </td>
                            <td className="text-left">{item}</td>
                            <td className="text-center">
                                <input
                                    className="cursor-pointer"
                                    type="checkbox"
                                    checked={records.every(record => permissionsArray.some(permission => permission.feature_id === record.id && permission.allow === 1))}
                                    onChange={() => handleCheckClick(index, records.every(record => permissionsArray.some(permission => permission?.feature_id === record.id && permission.allow === 1)), records)}
                                />
                            </td>
                        </tr>
                        {expandedRows[index] &&
                            records &&
                            records.length > 0 && (
                            records.map((permission, permissionIndex) => (
                            <tr key={`${index}_expanded_${permissionIndex}`}>
                                <td className="pt-1 pl-4 text-left">
                                    <i className="fas fa-circle text-[8px] mr-1 mb-2 text-primary-500"></i>
                                    {permission.operations}
                                </td>
                                <td className="text-left">{item + '.' + permission.operations}</td>
                                <td className="text-center"><input className="cursor-pointer" type="checkbox"
                                    checked={permissionsArray?.find(item => item.feature_id === permission.id)?.allow === 1}
                                    onChange={() => handleCheckClick(permission?.id, permissionsArray?.find(item => item.feature_id === permission.id)?.allow === 1)}
                                /></td>
                            </tr>
                        ))
                        )}
                    </React.Fragment>
                    ))}
                    </tbody>
                  </table>
                  </div>
                </div>
                <div
                  className={`mt-5 md:mt-12 flex items-center sm:px-12 lg:px-6 mb-6 gap-2`}
                >
                  <button
                    type="button"
                    className={`flex w-full md:w-1/2 justify-center rounded-xl bg-primary-500 hover:bg-primary-400 px-6 py-2 text-sm font-bold text-white shadow-sm  transition-all hover:opacity-50 disabled:opacity-50`}
                    onClick={() => handleAddRole()}
                    disabled={loading || (selectedAccess && selectedAccess.some(item => item.allow === 1)) ? false : true}
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    className={`flex w-full md:w-1/2 justify-center rounded-xl bg-primary-500 hover:bg-primary-400
                     px-6 py-2 text-sm font-bold text-white shadow-sm transition-all hover:opacity-50`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(false);
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}