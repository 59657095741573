import { Form, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../../../../components/organisms/Layout/Authlayout";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Auth from "../../../../api/auth";
import FormInput from "../../../../components/atoms/FormInput";
import {
  RegisterStep0Schema,
  RegisterStep0InitialValues,
} from "../registerSchema";
import { darkmode } from "../../../../store/authSlice";

const Step0 = ({ onSubmit }) => {
  const [apiError, setApiError] = useState(null);
  const selectedPlan = JSON.parse(localStorage.getItem("selectedPlan")) || null;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: RegisterStep0InitialValues,
    validationSchema: RegisterStep0Schema,
    onSubmit: async (formData) => {
      setSubmitting(true);
      setLoading(true);
      const valid = await validateEmail(formData.email);
      if (valid) {
        localStorage.setItem("registerStep0", JSON.stringify(formData));
        return onSubmit(formData, "2");
      }
      setLoading(false);
    },
  });

  const mode = useSelector(darkmode);
  const { t } = useTranslation();

  const validateEmail = async (email) => {
    try {
      await Auth.VerifyUnique({ email });
      setApiError({});
      return true;
    } catch (error) {
      const err = error.response.data.errors;
      const erAr = {};
      if (err.hasOwnProperty("email")) erAr.email = err.email[0];
      setApiError(erAr);
      return false;
    }
  };

  const initializeFormValues = () => {
    const storedData = localStorage.getItem("registerStep0");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setValues(parsedData);
      form.setFieldsValue(parsedData);
    }
  };

  useEffect(() => {
    if (selectedPlan === null) {
      navigate("/plans");
    }
    initializeFormValues();
  }, []);

  return (
    <AuthLayout>
      <div className="flex min-h-full flex-1 flex-col justify-center sm:px-6 lg:px-8">
        <div className="h-full sm:mx-auto sm:w-full rounded">
          <div className="w-full h-full">
            <div className="sm:mx-auto sm:w-full flex flex-col justify-center h-full">
              <div className="sm:mx-auto sm:w-full sm:max-w-[480px] flex flex-col justify-center h-full">
                <div className="text-center">
                  <div
                    className={`title font-bold w-fit lg:mt-3 mx-auto text-base ${
                      mode ? "text-black" : "text-white"
                    }`}
                  >
                    Welcome to HeyHire - the easiest way to hire local talent!
                  </div>
                  <div
                    className={`title font-bold w-fit mt-4 mx-auto text-base ${
                      mode ? "text-black" : "text-white"
                    }`}
                  >
                    Start hiring now!
                  </div>
                </div>

                <div className="px-6 sm:rounded-lg sm:px-12">
                  <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <div className="w-full">
                      <Space
                        size={20}
                        direction="vertical"
                        className="w-full my-12"
                      >
                        <FormInput
                          name="businessName"
                          label={t("common.business-name")}
                          placeholder="XYZ Holdings LLC"
                          value={values.businessName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          darmo={mode}
                        />
                        {touched.businessName && errors.businessName && (
                          <p className="text-red-400 text-xs font-bold mt-[-14px]">
                            {errors.businessName}
                          </p>
                        )}

                        <FormInput
                          name="email"
                          label={t("common.email")}
                          placeholder="Enter email address"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          darmo={mode}
                        />
                        {touched.email && errors.email && (
                          <p className="text-red-400 text-xs font-bold mt-[-14px]">
                            {errors.email}
                          </p>
                        )}
                        {touched.email && apiError?.email && (
                          <p className="text-red-400 text-xs font-bold mt-[-14px]">
                            {apiError?.email}
                          </p>
                        )}
                      </Space>

                      <div
                        className={`title mt-4 text-sm ${
                          mode ? "text-black" : "text-primary-100"
                        }`}
                      >
                        By registering, you are agreeing to the
                        <Link
                          to="https://www.heyhire.com/terms-conditions"
                          target="_blank"
                          className={`${
                            mode
                              ? "text-indigo-600 hover:text-indigo-500 "
                              : "text-secondary hover:text-white"
                          } font-semibold ml-1`}
                        >
                          Terms and Conditions
                        </Link>{" "}
                        and
                        <Link
                          to="https://www.heyhire.com/privacy"
                          target="_blank"
                          className={`ml-1 ${
                            mode
                              ? "text-indigo-600 hover:text-indigo-500 "
                              : "text-secondary hover:text-white"
                          } font-semibold`}
                        >
                          Privacy Policy
                        </Link>
                      </div>

                      <div
                        className={`text-sm text-center mt-8 ${
                          mode ? "text-black" : "text-white"
                        }`}
                      >
                        Already registered?
                        <Link
                          to="/login"
                          className={`${
                            mode
                              ? "text-indigo-600 hover:text-indigo-500 "
                              : "text-secondary hover:text-white"
                          } font-semibold ml-1`}
                        >
                          Click here
                        </Link>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="flex border-t-2 py-4 border-primary-500 dark:border-darkGray justify-center">
                <div className="flex w-full lg:w-1/2 xl:w-1/3 justify-between">
                  <button
                    className="font-bold text-sm ml-2 px-6 py-2 w-32 rounded-full bg-primary-500 dark:bg-darkGray text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                    type="button"
                    onClick={() => {
                      navigate("/plans", { state: { isPrevious: true } });
                    }}
                    disabled={loading}
                  >
                    Previous
                  </button>
                  <button
                    className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-full bg-primary-500 dark:bg-darkGray text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                    type="submit"
                    onClick={() => handleSubmit()}
                    disabled={loading}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Step0;
