import React from "react";

const FormSuccessWithButton = ({
  status = true,
  title,
  button = false,
  content,
  closeButton = false,
}) => {
  return (
    <div>
      {status && (
        <div className="bg-green-50 border border-green-400 rounded text-green-800 text-sm p-4 flex items-start transition-all animate-fade-in-from-top-.5s">
          <div className="relative bottom-1 pt-[0px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
            </svg>
          </div>
          <div className="w-full">
            <p>
              <span className="font-bold pr-1 text-[15px]">{title}</span>
              <span className="text-[13px] font-sans font-semibold">
                {content}
              </span>
            </p>
            {button && (
              <button className="border-green-400 bg-white hover:bg-gray-50 px-4 py-2 mt-4 border rounded font-bold">
                Understood
              </button>
            )}
          </div>
          {closeButton && (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FormSuccessWithButton;
