import { Fragment, useState } from "react";
import { CheckCircleIcon,ChartBarSquareIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Feeds = ({data = []}) => {

  return (
    <div>
      <ul className="space-y-2">
        {data.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === data.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            <>
              <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                {activityItem.type ===
                  "success" && (
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  )}
                {activityItem.type === "warning" && (
                  <div>
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-primary-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
                {activityItem.type === "error" && (
                  <div>
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                <span className="font-medium text-gray-900">
                  {activityItem.person.name}
                </span>
                <br></br>
                <span className="font-medium text-gray-900">
                  {activityItem.person.comment}
                </span>
              </p>
              <time
                dateTime={activityItem.dateTime}
                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
              >
                {activityItem.date}
              </time>
            </>
          </li>
        ))}
      </ul>
    </div>
  );
}


export default Feeds;