import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Input from "../form/Input";
import { toast } from "react-toastify";
import TextArea from "../form/TextArea";
import Notification from "../../api/Notification";
import ConfirmationModal from "./ConfirmationModal";

export default function EventModal({
  open,
  setOpen,
  editEvent,
  setEditEvent,
  setRefreshEvent,
}) {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [lineOfBusiness, setLineOfBusiness] = useState("");
  const [nameError, setNameError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [lineOfBusinessError, setLineOfBusinessError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);

  const deleteAttachedEvent = async (item) => {
    setOpenDeleteConfirmationModal(true);
  };

  const handleRemoveAttachedEvent = async () => {
    try {
      await Notification.deleteEvent(editEvent?.id).then((response) => {
        toast.success("Deleted Successfully");
        setName("");
        setType("");
        setDescription("");
        setLineOfBusiness("");
        setRefreshEvent(true);
        setEditEvent(false);
        setOpenDeleteConfirmationModal(false);
        setOpen(false);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditAlert = async () => {
    setNameError(false);
    setTypeError(false);
    setDescriptionError(false);
    setLineOfBusinessError(false);

    let hasError = false;
    if (name.trim() === "") {
      setNameError(true);
      hasError = true;
    }

    if (type.trim() === "") {
      setTypeError(true);
      hasError = true;
    }

    if (description.trim() === "") {
      setDescriptionError(true);
      hasError = true;
    }

    if (lineOfBusiness.trim() === "") {
      setLineOfBusinessError(true);
      hasError = true;
    }

    if (hasError) return;

    setLoading(true);
    try {
      const payload = {
        name,
        type,
        description,
        line_of_business: lineOfBusiness,
      };
      const response = editEvent
        ? await Notification.updateEvent(editEvent?.id, payload)
        : await Notification.addEvent(payload);
      if (response) {
        editEvent
          ? toast.success("Updated Successfully")
          : toast.success("Added Successfully");
        setEditEvent(null);
        setRefreshEvent(true);
        setOpen(false);
      }
    } catch (error) {
      console.error("Error editing event:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (editEvent) {
      setName(editEvent?.name);
      setType(editEvent?.type);
      setDescription(editEvent?.description);
      setLineOfBusiness(editEvent?.line_of_business);
    }
  }, [editEvent]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg p-8 text-center shadow-xl transition-all sm:my-8 sm:p-6 w-fit md:w-[600px] bg-white">
                <ConfirmationModal
                  openTrigger={openDeleteConfirmationModal}
                  closeTrigger={() => setOpenDeleteConfirmationModal(false)}
                  type="error"
                  title="Confirm Action"
                  confirmText="Delete"
                  description="Are you sure you want to delete this alert?"
                  confirm={handleRemoveAttachedEvent}
                />
                <div className="mt-2">
                  <div className="flex justify-between items-center sm:px-12 lg:px-6">
                    <h4 className="font-bold text-primary-500 text-2xl mx-auto">
                      {editEvent ? "Edit Event" : "Add New Event"}
                    </h4>
                    {editEvent && (
                      <button
                        className="btn btn-link"
                        aria-label="Delete"
                        onClick={() => deleteAttachedEvent()}
                      >
                        <i className="fa fa-trash text-lg text-primary-500"></i>
                      </button>
                    )}
                  </div>
                  <div className="text-left snap-start sm:rounded-lg sm:px-12 lg:px-6 my-4 flex flex-col gap-2">
                    <Input
                      className="my-3"
                      name="name"
                      label="Event Name"
                      type="text"
                      value={name}
                      error={nameError && "Name is required"}
                      placeholder="Event Name"
                      darmo={true}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <Input
                      className="my-3"
                      name="type"
                      label="Event Type"
                      type="text"
                      value={type}
                      error={typeError && "Type is required"}
                      placeholder="Event Type"
                      darmo={true}
                      onChange={(e) => setType(e.target.value)}
                    />

                    <TextArea
                      name="eventDetails"
                      label="Event description"
                      type="text"
                      value={description}
                      error={descriptionError && "Description is required"}
                      placeholder="description"
                      darmo={true}
                      onChange={(e) => setDescription(e.target.value)}
                    />

                    <Input
                      className="my-3"
                      name="lineOfBusiness"
                      label="Event Line of Business"
                      type="text"
                      value={lineOfBusiness}
                      error={
                        lineOfBusinessError && "Line of business is required"
                      }
                      placeholder="Event Line of Business"
                      darmo={true}
                      onChange={(e) => setLineOfBusiness(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className={`mt-5 md:mt-12 flex items-center sm:px-12 lg:px-6 mb-6 gap-2`}
                >
                  <button
                    type="button"
                    className={`flex w-full md:w-1/2 justify-center rounded-xl bg-primary-500 hover:bg-primary-400 px-6 py-2 text-sm font-bold text-white shadow-sm  transition-all hover:opacity-50`}
                    onClick={() => handleEditAlert()}
                    disabled={loading}
                  >
                    {editEvent ? "Edit" : "Add"}
                    {loading && (
                      <i className="fad fa-circle-notch fa-spin my-auto ml-2" />
                    )}
                  </button>
                  <button
                    type="button"
                    className={`flex w-full md:w-1/2 justify-center rounded-xl bg-primary-500 hover:bg-primary-400
                     px-6 py-2 text-sm font-bold text-white shadow-sm transition-all hover:opacity-50`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(false);
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
