import axios from "./api";

const base = "/business/qr-code";

const QRCodes = {
  getAll() {
    return axios.get(base);
  },
  getById(id) {
    return axios.get(`${base}/${id}`);
  },
  add(body) {
    return axios.post(base, body);
  },
  update(id, body) {
    return axios.put(base + `/${id}`, body);
  },
  delete(id) {
    return axios.delete(base + "/" + id);
  },
  uploadLogo(image) {
    const formData = new FormData();
    formData.append("photo", image);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return axios.post("/business/photo/poster_logo", formData, config);
  },

  //No Auth endpoint
  getByEntityId(id) {
    return axios.get("/business/qr-code-previous/" + id);
  },
};

export default QRCodes;
