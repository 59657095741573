import axios from "./api";

const base = "/admin/group";

const Group = {
  getAll() {
    return axios.get(base);
  },
  getById(id) {
    return axios.get(`${base}/${id}`);
  },
  add(data) {
    return axios.post(base, data);
  },
  update(id, data) {
    return axios.put(base + `/${id}`, data);
  },
  uploadGroupImage(id, image) {
    const formData = new FormData();
    formData.append("group_id", id);
    formData.append("group", image);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
  

    return axios.post("business/photo/group_logo", formData, config);
  },
  delete(id) {
    return axios.delete(base + "/" + id);
  },
//   update(id, jobPosition) {
//     return axios.put(`${base}/${id}`, jobPosition);
//   },
};

export default Group;