import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../components/organisms/Layout";
import { multipleWhiteIcon, profileWhiteIcon } from "../../assets/images";
import { useNavigate } from "react-router-dom";

const MainShiftsPage = () => {
  const navigate = useNavigate();
  
  return (
    <DashboardLayout darmo={true}>
      <div className="mt-8 w-full max-w-7xl">
        <div className="flex flex-col gap-1 xl:gap-3">
          <h2 className={`text-2xl font-bold text-black`}>
            {/* {editMode ? "Edit" : "Post"} a Shift */}
            Let's get started...
          </h2>
          <span className="mt-1">Post a shift to find local job seekers that are open for last minute shifts on HeyHire now Network!</span>
        </div>
       
        <div className="flex flex-col justify-center items-center">
            <h2 className="text-xl font-bold text-black my-4 xl:my-12">Please select</h2>
            <div className="flex flex-col md:flex-row gap-4">
                <div className="bg-primary-500 rounded-md p-2 w-72 flex flex-col justify-center cursor-pointer" onClick={()=>navigate("/shifts/add")}>
                    <img src={profileWhiteIcon} className="mb-2 w-24 h-36 mx-auto"/>
                    <p className="text-lg font-bold text-white text-center mb-2">Just posting one Shift</p>
                    <p className="text-white text-center">Select this option if you're just looking to post one shift!</p>
                </div>
                <div className="bg-primary-500 rounded-md p-2 w-72 flex flex-col justify-center cursor-pointer" onClick={()=>navigate("/shifts/event/add")}>
                    <img src={multipleWhiteIcon} className="mb-2 w-28 h-36 mx-auto"/>
                    <p className="text-lg font-bold text-white text-center mb-2">Multiple Shifts</p>
                    <p className="text-white text-center">Need multiple shifts for an event? Select this option</p>
                </div>
            </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MainShiftsPage;
