import React from "react";

const PrimaryButton = ({ label,style,customIcon,loading, children, onClick }) => {
    
  if (label) {
    return (
      <button className={`${style}`} onClick={onClick} disabled={loading}>
        <div className="flex flex-row pl-2">
          <div>{customIcon}</div>
          <div className="pl-2"> {label}</div>
        </div>
        {loading ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
      </button>
    );
  }

  return (
    <button
      onClick={onClick}
      className={`w-full rounded-full px-1.5 pt-[10px] pb-[9px] text-white text-lg font-bold bg-gradient-to-b from-primary-500 to-primary-400 hover:bg-primary-500 disabled:opacity-70 shadow-md`}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
