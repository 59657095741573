import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Application from "../../api/Application";
import JobPosition from "../../api/JobPosition";
import User from "../../api/User";
import { DashboardLayout } from "../../components/organisms/Layout";
import SliderWrapper from "../../components/molecules/SliderWrapper";
import ApplicationLogs from "../../api/ApplicationLogs";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { DateFormat } from "../../utils";
import Loading from "../../components/Loading";
import CardLoading from "../../components/Loading/CardLoading";
import { DropActionButtonDropdown } from "../../components/TableActionDropdown";
import ArchivedJob from "../../api/ArchivedJob";
import { decodeIds, encodeIds } from "../../utils";
import {
  applicantsIcon,
  declineIcon,
  hiredIcon,
  pendingIcon,
  positionIcon,
} from "../../assets/images";
import "../../pages/Shifts/ViewShift.scss";
import NewConfirmationModal from "../../components/modals/NewConfirmationModal";
import { useDispatch } from "react-redux";
import { setJobPositionTitle } from "../../store/authSlice";
import ShiftApplicantCard from "../../components/ShiftApplicantCard";

const CANCELED = "canceled";
const REJECTED = "rejected";
const ACTIVE = "applied";
const employees = [];

const ViewShift = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isApplicantsLoading, setApplicantsLoading] = useState(true);
  const [active, setActive] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [jobPosition, setJobPosition] = useState({});
  const [jobPositions, setJobPositions] = useState([]);
  const [currentEmployees, setCurrentEmployees] = useState([]);
  const [decodedId, setDecodedId] = useState(decodeIds(params?.id)[0]);
  const [showMoreGeneral, setShowMoreGeneral] = useState(false); //Benji wanted me to make the buttons control both of the paragraphs and not control each on a general level. The other functions become obsolete as a result. Didnt remove them because dont know if you guys wanna handle this some other way.
  const [filteredApplicants, setFilteredApplicants] = useState("all");
  const [isScrollable, setIsScrollable] = useState(false);
  const scrollContainerRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;

      if (isMobile) {
        setFilteredApplicants("pending");
      } else {
        setFilteredApplicants("all");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScroll = (direction) => {
    const scrollContainer = scrollContainerRef.current;
    const scrollStep = 100;

    if (direction === "left") {
      scrollContainer.scrollLeft -= scrollStep;
    } else if (direction === "right") {
      scrollContainer.scrollLeft += scrollStep;
    }
  };

  const loadJobPositions = async () => {
    try {
      setLoading(true);
      const response = await JobPosition.getAll();
      const sortedJobs = response.data.data.filter(
        (j) => j?.archived_at == null && j?.deleted_at == null
      );
      setJobPositions(sortedJobs);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error(t("common.something-went-wrong-please-try-again"));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    loadJobPositions();
    //     loadApplications();
    //     loadEmployees();
    //     uploadApplicationEvent();
  }, [decodedId]);

  useEffect(() => {
    setDecodedId(decodeIds(params?.id)[0]);
  }, [location]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", handleScrollButtonVisibility);
    handleScrollButtonVisibility();

    return () => {
      scrollContainer.removeEventListener(
        "scroll",
        handleScrollButtonVisibility
      );
    };
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const totalTagWidth = Array.from(scrollContainer.children).reduce(
      (acc, child) => acc + child.offsetWidth,
      0
    );
    setIsScrollable(totalTagWidth > scrollContainer.offsetWidth);
  }, []);

  const handleScrollButtonVisibility = () => {
    const scrollContainer = scrollContainerRef.current;
    const maxScrollPosition =
      scrollContainer.scrollWidth - scrollContainer.clientWidth;
    const isLeftScrollDisabled = scrollContainer.scrollLeft === 0;
    const isRightScrollDisabled =
      scrollContainer.scrollLeft >= maxScrollPosition;

    const leftButton = document.querySelector(".scroll-button.left");
    const rightButton = document.querySelector(".scroll-button.right");

    if (leftButton) {
      leftButton.style.display = isLeftScrollDisabled ? "none" : "block";
    }

    if (rightButton) {
      rightButton.style.display = isRightScrollDisabled ? "none" : "block";
    }
  };

  return (
    <DashboardLayout darmo={true} bgColor="bg-white">
      <div className="m-auto">
        <div className="flex overflow-x-auto mt-4 mb-0 md:mb-6">
          {isScrollable && (
            <button
              className="px-2 py-0 my-6 scroll-button left"
              onClick={() => handleScroll("left")}
            >
              <i className="far fa-less-than" />
            </button>
          )}
          <div
            ref={scrollContainerRef}
            className="flex flex-row overflow-x-auto mb-4 md:mb-2"
          >
            {jobPositions?.map((jp, index) => (
              <Link
                key={index}
                to={`/business/view-job/${encodeIds(jp?.id)}`}
                className={`py-2 w-auto cursor-pointer rounded-md px-6 flex items-center justify-center text-white text-sm
                font-bold shadow-md ${
                  jp?.title === "Bartender"
                    ? "bg-midnight hover:text-white"
                    : "bg-orange-900 hover:bg-orange-400 hover:text-white transition-all hover:opacity-50"
                } mr-2 whitespace-nowrap`}
              >
                {jp?.title === jobPosition?.title && (
                  <img src={positionIcon} alt="logo" className="mr-2" />
                )}
                <span className="my-auto">{jp?.title}</span>
              </Link>
            ))}
          </div>
          {isScrollable && (
            <button
              className="px-2 py-0 text-black my-6 scroll-button right"
              onClick={() => handleScroll("right")}
            >
              <i className="far fa-greater-than" />
            </button>
          )}
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center -mt-2">
          <div className="my-auto mt-1 hidden md:block">
            <h4 className="font-bold text-2xl">
              <span>Open Shift: </span>
              <span className="text-primary-500">
                {/* {jobPosition?.title}{" "}
                {jobPosition?.archived_at != null ? "[ARCHIVED]" : ""} */}
                Bartender
              </span>
            </h4>
          </div>

          <div className="my-4 block md:hidden w-full text-center">
            <div className="flex items-center justify-center">
              <hr className="border-primary-500 flex-grow mr-2" />
              <h4 className="font-bold text-2xl max-w-[70%]">
                <span className="text-primary-500">
                  {/* {jobPosition?.title}{" "}
                  {jobPosition?.archived_at != null ? "[ARCHIVED]" : ""} */}
                  Bartenders
                </span>
              </h4>
              <hr className="border-primary-500 flex-grow ml-2" />
            </div>
          </div>

          <div className="h-min flex items-center w-auto">
            <DropActionButtonDropdown
              type="shift"
              menus={[
                {
                  label: "Edit Position",
                  action: () => navigate("/job-positions/edit/" + params?.id),
                },
                {
                  label: `${
                    jobPosition?.archived_at != null
                      ? "Restore the Position"
                      : "Archive Position"
                  }`,
                  //   action: () => handleArcheive(),
                },
                // {
                //   label: "Delete Position",
                //   action: () => handleDelete(),
                //   disabled: jobPosition.deleted_at != null,
                // },
              ]}
            />
          </div>
        </div>

        <div className="flex flex-col xl:flex-row w-full gap-3 mt-6">
          <div className="flex gap-1 justify-between px-4 py-2 border-2 border-gray-300 overflow-auto rounded-md bg-white xl:w-1/2">
            <div>
              <h6 className="font-bold mb-1">
                Spots Needed <span className="font-normal">(3)</span>
              </h6>
            </div>

            <div>
              <h6 className="font-bold mb-1">
                Shift Date:{" "}
                <span className="font-normal">Friday February 12th</span>
              </h6>
            </div>

            <div>
              <h6 className="font-bold mb-1">
                Shift Time: <span className="font-normal">Night Shift</span>
              </h6>
            </div>
          </div>

          <div className="flex gap-1 justify-between px-4 py-2 border-2 border-gray-300 overflow-auto rounded-md bg-white xl:w-1/2">
            <div>
              <h6 className="font-bold mb-1 text-orange-900">
                Spots Filled <span className="font-normal">(3)</span>
              </h6>
            </div>

            <div>
              <h6 className="font-bold mb-1 text-orange-900">
                Reached out: <span className="font-normal">(20)</span>
              </h6>
            </div>

            <div>
              <h6 className="font-bold mb-1 text-orange-900">
                Potential Candidates: <span className="font-normal">(277)</span>
              </h6>
            </div>
          </div>
        </div>

        <hr className="border-primary-500 w-full mt-6 block md:hidden" />
        <hr className="border-primary-500 w-full mt-2 block md:hidden" />

        {/* <div className="flex overflow-x-auto md:mt-6 mt-2">
            <div className="hidden md:block">
              <div 
                onClick={() => setFilteredApplicants("all")}
                className={`py-2 w-auto cursor-pointer rounded-md px-6 flex items-center justify-center text-white text-sm
                font-bold shadow-md ${filteredApplicants === "all" ? "bg-midnight" : "bg-primary-500 hover:bg-primary-400 transition-all hover:opacity-50"} mr-2 whitespace-nowrap`}>
                <img src={applicantsIcon} alt="logo" className="mr-2"/>
                <span className="my-auto">All Applicants ({currentEmployees?.length + active?.length + rejected?.length + canceled?.length})</span>
              </div>
            </div>

            <div 
              onClick={() => setFilteredApplicants("pending")}
              className={`py-2 w-auto cursor-pointer rounded-md px-6 flex items-center justify-center text-white
                text-sm font-bold shadow-md ${filteredApplicants === "pending" ? "bg-midnight" : "bg-primary-500 hover:bg-primary-400 transition-all hover:opacity-50"} mr-2 whitespace-nowrap`}>
                <img src={pendingIcon} alt="pending-logo" className="mr-1"/>
                <span className="my-auto">Pending ({active?.length})</span>
            </div>

            <div 
              onClick={() => setFilteredApplicants("hired")}
              className={`py-2 w-auto cursor-pointer rounded-md px-6 flex items-center justify-center text-white text-sm
                font-bold shadow-md ${filteredApplicants === "hired" ? "bg-midnight" : "bg-primary-500 hover:bg-primary-400 transition-all hover:opacity-50"} mr-2 whitespace-nowrap`}>
                <img src={hiredIcon} alt="hired-logo" className="mr-1.5"/>
                <span className="my-auto">Hired ({currentEmployees?.length})</span>
            </div>

            <div 
              onClick={() => setFilteredApplicants("canceled")}
              className={`py-2 w-auto cursor-pointer rounded-md px-6 flex items-center justify-center text-white text-sm
                font-bold shadow-md ${filteredApplicants === "canceled" ? "bg-midnight" : "bg-primary-500 hover:bg-primary-400 transition-all hover:opacity-50"} whitespace-nowrap`}>
                <img src={declineIcon} alt="decline-logo" className="mr-1.5"/>
                <span className="my-auto">Cancelled ({canceled?.length + rejected?.length})</span>
            </div>
        </div> */}

        {/* {(filteredApplicants === "all" || filteredApplicants === "pending") && (*/}
        <div className="mt-6">
          <p className="font-extrabold mb-2 text-xl text-center md:text-left mt-12 md:mt-0">
            Available Seekers
          </p>
          {/* {isApplicantsLoading && (
            <div class="grid custom-screen-size md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-6 mt-4">
              {Array.from({ length: 6 }).map((_, index) => (
                <CardLoading key={index} />
              ))}
            </div>
            )}  */}
          <div className="custom-size grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-6 mt-4">
            {/* {active.map((application) => ( */}
            <ShiftApplicantCard
              // key={application.id}
              // applicant={application.seeker}
              // application={application}
              type={"applicant"}
              // positionId={decodedId}
            />
            <ShiftApplicantCard
              // key={application.id}
              // applicant={application.seeker}
              // application={application}
              type={"applicant"}
              // positionId={decodedId}
            />
            <ShiftApplicantCard
              // key={application.id}
              // applicant={application.seeker}
              // application={application}
              type={"applicant"}
              // positionId={decodedId}
            />
            {/* ))} */}
            {/* {!isApplicantsLoading && active.length === 0 && (
              <>
                <div
                  className="w-full p-4 whitespace-nowrap text-start hidden md:block"
                  colSpan={12}
                >
                  <p>No pending applicants yet</p>
                </div>

                <div
                  className="w-full p-4 whitespace-nowrap text-start block md:hidden"
                  colSpan={12}
                >
                  <div className="border border-purple-600 px-4 py-10 rounded-md mx-8">
                    <p className="text-center">No pending applicants yet</p>
                  </div>  
                </div>
              </>
            )} */}
          </div>
        </div>
        {/* )} */}

        {/* {(filteredApplicants === "all" || filteredApplicants === "hired") && ( */}
        <div className="pt-6">
          <p className="font-extrabold mb-2 text-xl text-center md:text-left mt-12 md:mt-0">
            Confirmed Seekers
          </p>
          {/* {isApplicantsLoading && (
            <div class="grid custom-screen-size md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-6 mt-4">
              {Array.from({ length: 6 }).map((_, index) => (
                <CardLoading key={index} />
              ))}
            </div>
          )} */}
          <div className="grid custom-size md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-6 mt-4">
            {/* {currentEmployees.map((employee) => ( */}
            <ShiftApplicantCard
              // key={employee.id}
              // applicant={employee.seeker}
              // application={employee}
              type={"employee"}
              // positionId={decodedId}
            />
            <ShiftApplicantCard
              // key={employee.id}
              // applicant={employee.seeker}
              // application={employee}
              type={"employee"}
              // positionId={decodedId}
            />
            {/* ))} */}
          </div>
          {/* {currentEmployees.length === 0 && (
          <>
            <div
              className="w-full p-4 whitespace-nowrap text-start hidden md:block"
              colSpan={12}
            >
              <p>No hires yet</p>
            </div>
            <div
              className="w-full whitespace-nowrap text-start block md:hidden"
              colSpan={12}
            >
              <div className="border border-purple-600 px-4 py-10 rounded-md mx-8">
                <p className="text-center">No hires yet</p>
              </div>
            </div>
          </>
          )} */}
        </div>
        {/* )} */}

        {/* {(filteredApplicants === "all" || filteredApplicants === "canceled") && ( */}
        <div className="pt-6">
          <p className="font-extrabold mb-2 text-xl text-center md:text-left mt-12 md:mt-0">
            Rejected/Cancelled
          </p>
          <div className="grid custom-size md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-6 mt-4">
            {/* {[...canceled, ...rejected].map((application) => ( */}
            <ShiftApplicantCard
              // key={application.id}
              // applicant={application.seeker}
              // application={application}
              type={"cancelled"}
              // positionId={decodedId}
            />
            {/* ))} */}
          </div>
          {/* {(canceled.length === 0 && rejected.length === 0) && (
          <>  
            <div
              className="w-full p-4 whitespace-nowrap hidden md:block"
              colSpan={12}
            >
              <p>Rejected / Cancelled Applicants
              will appear here</p>
            </div>
            <div
              className="w-full text-start block md:hidden"
              colSpan={12}
            >
              <div className="border border-purple-600 px-4 py-10 rounded-md mx-8">
                <p className="text-center">Rejected / Cancelled Applicants
                will appear here</p>
              </div>
            </div>
          </>  
          )} */}
        </div>
        {/* )} */}
      </div>
      {/* {loading && <Loading />} */}

      {isModalVisible && (
        <NewConfirmationModal
          open={isModalVisible}
          setOpen={setIsModalVisible}
          type={type}
          // onClick={type === "delete" ? DeletePosition : (jobPosition?.archived_at != null ? UnarchivePosition : ArchivePosition)}
          jobPosition={jobPosition}
        />
      )}
    </DashboardLayout>
  );
};

export default ViewShift;
