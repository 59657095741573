import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DateFormat, encodeIds } from "../utils";
import { defaultUserImage, defaultUserImage2 } from "../assets/images";
import ApplicationLogs from "../api/ApplicationLogs";
import Application from "../api/Application";
import { darkmode, setChatApplicant } from "../store/authSlice";
import { useDispatch, useSelector } from "react-redux";

const CANCELEDSTATUS = ["canceled"];
const REJECTEDSTATUS = ["rejected"];

const ApplicantCard = ({
  applicant,
  application,
  type,
  positionId,
  className,
  isView,
  isCancel,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mode = useSelector(darkmode);

  const uploadApplicationEvent = () => {
    const body = {
      event_type: "view",
      entity_type: "application",
      entity_id: application?.id,
    };
    ApplicationLogs.addEvent(body)
      .then((response) => {})
      .catch((error) => console.log("uploadApplicationEvent -> error", error));
  };

  const recordApplicationView = async () => {
    try {
      const body = {
        application_id: application?.id,
      };
      await Application.postApplicationView(body);
    } catch (error) {
      console.log(
        "Failed to record application view. Please try again.",
        error?.response
      );
    }
  };

  const { t } = useTranslation();

  if (!applicant || !application) return <></>;
  return (
    <li
      className={
        `col-span-1 flex flex-col divide-y divide-warmgray-300 dark:divide-warmgray-500 rounded-lg text-center shadow ${
          type === "employee"
            ? "bg-primary-500 dark:bg-[#3A3B3C]"
            : "bg-white dark:bg-[#2C2D30]"
        }
        ${
          CANCELEDSTATUS.includes(application?.status) ||
          REJECTEDSTATUS.includes(application?.status)
            ? "opacity-25"
            : ""
        } ` + className
      }
    >
      <div className="flex flex-1 flex-col p-8">
        <img
          src={
            applicant?.photo?.thumb_url ||
            (type === "employee" || !mode
              ? defaultUserImage2
              : defaultUserImage)
          }
          alt="Profile"
          className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
        />
        <h3
          className={`mt-6 text-base font-bold text-primary-500 dark:text-white ${
            type === "employee" && "text-white"
          }`}
        >
          {application?.seeker?.first_name +
            " " +
            application?.seeker?.last_name}
        </h3>
        <span
          className={`block text-xs text-gray-500 mt-1 ${
            type === "employee" && "text-white dark:text-warmgray-300"
          }`}
        >
          {type === "applicant" ? "Applied on:" : "Hired on:"}{" "}
          {dayjs(application?.created_at).format(DateFormat)}
        </span>
        <div className="mt-3">
          {applicant?.city && (
            <span
              className={`block text-xs text-gray-500 mt-1 ${
                type === "employee" && "text-white dark:text-warmgray-300"
              }`}
            >
              <i
                className={`fas fa-map-marker-alt mr-0.5 ${
                  type !== "employee" && "text-purple-600 dark:text-gray-400"
                }`}
              />{" "}
              {applicant?.city}, {applicant?.state}
            </span>
          )}
        </div>
      </div>
      <div className="-mt-px flex divide-x divide-warmgray-300 dark:divide-warmgray-500">
        <div className="flex w-0 flex-1">
          {!CANCELEDSTATUS.includes(application?.status) &&
            !REJECTEDSTATUS.includes(application?.status) && (
              <button
                onClick={() => {
                  dispatch(setChatApplicant(applicant?.id));
                }}
                className={`relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold ${
                  type === "employee"
                    ? "text-white dark:text-warmgray-300"
                    : "text-gray-900 dark:text-warmgray-300"
                }`}
              >
                {type === "applicant" ? "Start Chat" : "Open Chat"}
              </button>
            )}
        </div>
        <div className="-ml-px flex w-0 flex-1">
          {!CANCELEDSTATUS.includes(application?.status) &&
            !REJECTEDSTATUS.includes(application?.status) && (
              <button
                onClick={() => {
                  recordApplicationView();
                  uploadApplicationEvent();
                  {
                    type === "applicant"
                      ? navigate(
                          `/business/applicant/${encodeIds(
                            positionId
                          )}/${encodeIds(applicant?.id)}`
                        )
                      : navigate(
                          `/business/hired-applicant/${encodeIds(
                            positionId
                          )}/${encodeIds(applicant?.id)}`
                        );
                  }
                }}
                className={`relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold ${
                  type === "employee"
                    ? "text-white dark:text-warmgray-300"
                    : "text-gray-900 dark:text-warmgray-300"
                }`}
              >
                View Profile
              </button>
            )}

          {CANCELEDSTATUS.includes(application?.status) && isCancel && (
            <button className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 dark:text-warmgray-300">
              Cancelled
            </button>
          )}

          {REJECTEDSTATUS.includes(application?.status) && isCancel && (
            <button className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 dark:text-warmgray-300">
              Rejected
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export default ApplicantCard;
