import React from "react";
import { useTranslation } from "react-i18next";
import FormInput from "../../atoms/FormInput";
import { Col, Row } from "antd";

const FormJobSeeker = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[28, 20]}>
      <Col span={12}>
        <FormInput
          name="firstName"
          label={t("common.first-name")}
          placeholder={t("common.first-name")}
        />
      </Col>
      <Col span={12}>
        <FormInput
          name="lastName"
          label={t("common.last-name")}
          placeholder={t("common.last-name")}
        />
      </Col>
      <Col span={12}>
        <FormInput
          name="jobPosition"
          label={t("common.job-position")}
          placeholder={t("common.job-position")}
        />
      </Col>
      <Col span={12}>
        <FormInput
          name="company"
          label={t("common.company")}
          placeholder={t("common.company")}
        />
      </Col>
      <Col span={12}>
        <FormInput
          name="phone"
          label={t("common.phone")}
          placeholder={t("common.phone")}
        />
      </Col>
      <Col span={12}>
        <FormInput
          name="email"
          label={t("common.email")}
          placeholder={t("common.example-email")}
        />
      </Col>
    </Row>
  );
};

export default FormJobSeeker;
