import React from 'react'

const DisplayCard = ({ label,labelValue, content }) => {
  return (
    <div className="flex-grow pl-4 w-full">
      <h2 className="title-font mb-1 text-base font-bold tracking-wider text-gray-900">
        {label}:{labelValue}
      </h2>
      <p className="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">
        {content}
      </p>
    </div>
  );
};

export default DisplayCard;