import axios from "./api";

const base = "/business/payment";

const Payment = {
  addAccount(payment) {
    return axios.post(base + "/stripe-account", payment);
  },
  getRedirectURL(request) {
    return axios.post(base + "/checkout-url", request);
  },
  getSubscribePlan() {
    return axios.get(`business/plan-list`);
  },
  getLatestSubscriptionInfo() {
    return axios.get("business/subscription");
  },
  getStripeCustomerID(companyId, locationId) {
    return axios.get(`business/stripe-customer/${companyId}/${locationId}`);
  },
  getSubscriptionDetailsFromCustomerId(stripe_customer_id) {
    return axios.get(`business/business-subscription/${stripe_customer_id}`);
  },
  getPayout(companyId, locationId) {
    return axios.get(`business/invoices/${companyId}/${locationId}`);
  },
};

export default Payment;
