import React from 'react'
import "../atoms/FormActivationCode/FormActivationCode.scss";
   
const PinCodeField = ({ codeLength = 6, values, placeHolders, onChange }) => {
  return (
    <div>
      <div className="container mx-auto pl-5 pr-5">
        <div className="max-w-sm">
          <div className="w-full">
            <div className="bg-white h-fit rounded text-center">
              <div
                id="otp"
                className="flex flex-row justify-center text-center px-2 mt-5 animate-fade-in-from-top-1s"
              >
                {[...Array(codeLength)].map((e, i) =>
                  values ? (
                    <input
                      key={i}
                      className="pin-box m-2 border-2 border-gray-500 h-14 w-14 font-bold text-black text-lg text-center form-control rounded"
                      type="text"
                      pattern="[^\s]+"
                      id={i}
                      value={values ? values.charAt(i) : ""}
                      maxLength="1"
                      onChange={(e) => onChange(e)}
                    />
                  ) : (
                    <input
                      key={i}
                      className="pin-box m-2 border-2 border-gray-500 h-14 w-14 font-bold text-black text-lg text-center form-control rounded"
                      type="text"
                      pattern="[^\s]+"
                      id={i}
                      maxLength="1"
                      onChange={(e) => onChange(e)}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PinCodeField