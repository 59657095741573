import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CompanyApi from "../api/Company";
import Button from "../components/form/Button";
import { toast } from "react-toastify";
import Modal from "../components/Modal";
import AddCompanyModal from "../components/modals/AddCompanyModal";
import ChangeBrandModal from "../components/modals/ChangeBrandModal";
import SearchBar from "../components/atoms/SearchBar";
import { useCustomEventListener } from "react-custom-events";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Loading from "../components/Loading";
import { DashboardLayout } from "../components/organisms/Layout";
import PaginationView from "../components/molecules/Pagination";
import useQuery from "../hooks/useQuery";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { selectUser } from "../store/authSlice";
import Table from "../components/atoms/Tables/Table";
import { addIcon } from "../assets/images";

const Companies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const [companies, setCompanies] = useState([]);
  const [sorted, setSorted] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editCompany, setEditCompany] = useState(null);
  const [showChangeBrandModal, setShowChangeBrandModal] = useState(false);
  const [sortByColumn, setSortByColumn] = useState(null);
  const [sortByType, setSortByType] = useState(0); // 0: off, 1: asc, 2: dsc
  const params = useParams();
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 50);
  const [searchCompany, setSearchCompany] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const user = useSelector(selectUser);

  useEffect(() => {
    document.title = "HeyHire Portal-Companies";
  }, []);

  useEffect(() => {
    loadCompanies();
  }, [query]);

  useCustomEventListener("companyAdded", () => {
    setShowModal(false);
    loadCompanies();
  });

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    loadCompanies();
  });

  useCustomEventListener("closeChangeBrandModal", () => {
    setShowChangeBrandModal(false);
    loadCompanies();
  });

  const searchCompanyName = (txt) => {
    setSearchCompany(txt);
    let text = txt.toLowerCase();
    if (text == "") {
      setFilteredCompanies(sorted);
    } else {
      let _companies = sorted.filter((j) =>
        j.name.toLowerCase().includes(text)
      );
      setFilteredCompanies(_companies);
    }
  };

  const loadCompanies = () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const sort = query.get("sort") || "";
    const limit = query.get("limit") || pageLimit;
    const sortData = sort ? `sort=${sort}&` : "";
    const pageData = page ? `page=${page}` : "";
    const queryData = sortData + pageData + `&limit=${limit}`;
    CompanyApi.getAll(queryData)
      .then((response) => {
        setCompanies(response.data.data);
        setFilteredCompanies(response.data.data);
        setSorted(response.data.data);
        setMetaData(response.data.meta);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(false));
  };

  const toggleSortBy = (column) => {
    setSortByColumn(column);
    setSortByType(sortByType === 2 ? 0 : sortByType + 1);

    // Update data
    let sortedData = companies.sort((a, b) => {
      if (sortByType === 0) return a.id - b.id;

      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return sortByType === 1 ? -1 : 1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return sortByType === 1 ? 1 : -1;
      }
      return 0;
    });
    setFilteredCompanies(sortedData);
    setSorted(sortedData);
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };
  const handleSortChange = (row) =>
    setSearchParams({ ...searchParams, sort: row });

  return (
    <DashboardLayout darmo={true}>
      {showModal && (
        <AddCompanyModal
          company={editCompany}
          title={
            editCompany
              ? t("companies.edit-company")
              : t("companies.add-a-company")
          }
          onClose={() => setShowModal(!showModal)}
          visible={showModal}
        />
      )}

      {showChangeBrandModal && (
        <ChangeBrandModal
          company={editCompany}
          title="Change Brand"
          onClose={() => setShowChangeBrandModal(!showChangeBrandModal)}
          visible={showChangeBrandModal}
        />
      )}

      <div className="flex flex-col md:flex-row justify-between mt-6">
        <div className="w-full lg:w-3/5 xl:w-1/3">
          <SearchBar
            searchValue={searchCompany}
            placeholder="Search Company By Name"
            searchFunction={(value) => searchCompanyName(value)}
          />
        </div>
      </div>

      <div className="h-full overflow-x-auto mt-3 pb-12">
        {loading ? (
          <Loading count={8} />
        ) : (
          <>
            <Table
              mainHeader="Companies"
              headers={
                !loading && filteredCompanies?.length > 0
                  ? [
                      t("common.name"),
                      // <FaArrowAltCircleUp
                      //   onClick={() => handleSortChange("name")}
                      //   className="cursor-pointer"
                      // />,
                      t("common.owner"),
                      t("common.number-of-locations"),
                      "Edit",
                      "Action",
                    ]
                  : ["No data available"]
              }
              data={
                !loading &&
                filteredCompanies?.map((company, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      setEditCompany(company);
                    }}
                  >
                    <td className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1">
                      {company?.name}
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {company.owners &&
                          company.owners.length > 0 &&
                          `${company.owners[0].first_name} ${company.owners[0].last_name}`}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {company.locations.length}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      <div
                        className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                        onClick={() => {
                          setEditCompany(company);
                          setShowModal(true);
                        }}
                      >
                        Edit
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      {user?.roles[0]?.name === "super-user" &&
                        company?.is_enterprise === 1 && (
                          <div
                            className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                            onClick={() => {
                              setEditCompany(company);
                              setShowChangeBrandModal(true);
                            }}
                          >
                            Change Brand
                          </div>
                        )}
                    </td>
                  </tr>
                ))
              }
              buttonData={
                <button
                  onClick={() => navigate("/companies/add")}
                  className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                >
                  <div className="flex">
                    <img
                      src={addIcon}
                      alt="logo"
                      className="mr-2 my-auto w-4 h-4"
                    />
                    Add
                  </div>
                </button>
              }
            />
            <div className="flex w-full h-20 mt-2 justify-end items-center">
              {!loading && (
                <PaginationView
                  current={metaData?.current_page}
                  next={metaData?.to}
                  pageSize={metaData?.per_page}
                  total={metaData?.total}
                  onChange={handlecPageChange}
                />
              )}
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Companies;
