import React, { useEffect, useState } from "react";
import { Space } from "antd";
import Company from "../../../api/Company";
import { useTranslation } from "react-i18next";
import FormInput from "../../atoms/FormInput";
import FormSelect from "../../atoms/FormSelect";

const FormRegion = ({ region = null }) => {
  const { t } = useTranslation();

  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState(region.name);

  useEffect(() => {
    loadCompanies();
  }, []);

  const loadCompanies = () => {
    Company.getAll().then((response) => {
      setCompanies(response.data.data);
    });
  };

  return (
    <Space size={20} direction="vertical" className="w-full">
      <FormInput
        darmo={true}
        name="name"
        defaultValue={name}
        value={name}
        label={t("common.name")}
        placeholder={t("regions.add-a-region-name")}
      />
      <FormSelect
        darmo={true}
        name="company_id"
        label={t("common.company")}
        placeholder=""
        defaultValue={region.company_id}
        value={region.company_id}
        options={companies.map((_company) => ({
          value: _company.id,
          label: _company.name,
        }))}
      />
    </Space>
  );
};

export default FormRegion;
