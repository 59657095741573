import React from 'react'

const StepperControl = ({ handleClick, currentStep, steps }) => {
  
  return (
    <div className="container mt-4 mb-8 flex justify-around">
      <button
        onClick={() => handleClick()}
        className={` w-40 rounded-full  px-1.5 pt-[10px] pb-[9px] cursor-pointer bg-primary-700 py-2 font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-primary-500 disabled:opacity-70 shadow-md hover:text-white ${
          currentStep === 1 ? " cursor-not-allowed opacity-50 " : ""
        }`}
      >
        Back
      </button>

      <button
        className="w-40 rounded-full  px-1.5 pt-[10px] pb-[9px] cursor-pointer bg-primary-700 py-2 font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-primary-500 disabled:opacity-70 shadow-md hover:text-white"
        onClick={() => handleClick("next")}
        disabled=""
      >
        {currentStep === steps.length - 1 ? "Confirm" : "Next"}
      </button>
    </div>
  );
};

export default StepperControl