import axios from "./api";

const base = "/business/job-seeker";

const JobSeeker = {
  getAll(query=null) {
    return axios.get(`${base}?${query ? query : ""}`);
  },
  getById(id){
    return axios.get(base + "/" +id);
  },
  editSeeker(id, body) {
    return axios.put(`/job-seeker/profile/${id}`, body);
  }
};

export default JobSeeker;
