export const formatDate = (timestamp, format) => {
  const date = new Date(timestamp);
  let formattedDate = "";

  // Example of format: "YYYY-MM-DD HH:mm:ss"
  // You can modify this logic to support different formats

  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  formattedDate = format
    .replace("YYYY", year)
    .replace("MM", month)
    .replace("DD", day)
    .replace("HH", hours)
    .replace("mm", minutes)
    .replace("ss", seconds);

  return formattedDate;
};

const padZero = (value) => {
  return value.toString().padStart(2, "0");
};
