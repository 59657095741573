import { PhotoIcon } from "@heroicons/react/24/outline";
import React, { useRef } from "react";

const DropFileSection = ({
  label = "Label",
  onClickAction = [],
  icon = (
    <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
  ),
  handleFileUpload = [],
  status,
  type,
  logoIcon,
  disabled = false,
}) => {
  const fileInputRef = useRef(null);

  const handleClick = (event) => {
    event.target.value = null;
    fileInputRef.current.click();
  };

  return (
    <div
      className={`file-upload-container cursor-pointer ${
        status === "no-file"
          ? "bg-white"
          : status === "upload"
          ? "bg-warmgray-100"
          : "bg-white"
      }`}
      onClick={handleClick}
    >
      <div className="col-span-full">
        <div
          className={`mt-2 flex justify-center rounded-lg border ${
            type === "poster" ? "" : "border-dashed"
          }  ${
            status === "no-file"
              ? "border-gray-900/25"
              : status === "success"
              ? "border-green-700 bg-green-100"
              : "border-primary-500"
          } px-6 py-10`}
        >
          <div className="text-center flex flex-col items-center justify-center">
            {icon}
            <p
              className={`${
                type === "poster" && status !== "no-file" ? "mt-2" : "mt-4"
              } text-xs font-semibold ${
                status !== "no-file" ? "text-success-text" : "text-primary-400"
              } focus-within:outline-none focus-within:ring-2
                 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-primary-500 hover:font-semibold`}
            >
              {type === "poster" ? logoIcon : label}
            </p>
            <div className="flex text-sm ml-3 leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md font-semibold "
              >
                {status === "no-file" && type !== "poster" && (
                  <span className="text-warmgray-400">
                    File should be less than 10MB
                  </span>
                )}
                <input
                  type="file"
                  id="myFileInput"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  disabled={disabled}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropFileSection;
