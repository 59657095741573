import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { DashboardLayout } from "../../components/organisms/Layout";
import AddAddressContent from "../../components/AddAddressContent";

const AddAddress = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const editMode = location.pathname.search("edit") !== -1;

  useEffect(() => {
    if (editMode) {
      document.title = "HeyHire Portal-Edit Address";
    } else {
      document.title = "HeyHire Portal-Add New Address";
    }
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full">
        <h2 className={"text-2xl font-bold"}>
          <Link to={"/admin/address-book"}>
            <i className="far fa-chevron-left text-xl mr-2" />
          </Link>{" "}
          {editMode
            ? t("address-book.edit-address")
            : t("address-book.add-address")}
        </h2>
        <hr className="my-2 border-gray-300" />
        <AddAddressContent />
      </div>
    </DashboardLayout>
  );
};

export default AddAddress;
