import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { heyhireIcon } from "../../../assets/images";
import ThemeSwitcher from "../../atoms/Selector/ThemeSwitcher";

const AuthLayout = ({ children, restrictSwitch = false }) => {
  const location = useLocation();
  const [theme, setTheme] = useState(
    () => localStorage.getItem("theme") || "device"
  );

  return (
    <div className="relative w-full min-h-full overflow-x-auto z-auto bg-white dark:bg-[#18191A]">
      <div className="h-screen flex flex-col">
        {location?.pathname !== "/login" && (
          <div
            id="navbar"
            className="navbar top-0 w-full bg-primary-500 dark:bg-[#242526] dark:border dark:border-darkGray flex justify-between align-middle px-2 md:px-4 lg:px-6 z-[1] rounded-b-xl"
          >
            <div className="mx-4 flex w-full">
              <div className="flex justify-start items-center ml-4 cursor-pointer">
                <img width={100} src={heyhireIcon} alt="heyhire" />
              </div>

              {!restrictSwitch && (
                <div className="flex justify-end items-center w-full">
                  <ThemeSwitcher theme={theme} setTheme={setTheme} />
                </div>
              )}
            </div>
          </div>
        )}
        <div className="flex h-full overflow-y-auto flex-col">{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
