import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import User from "../../../api/User";
import { toast } from "react-toastify";
import { DashboardLayout } from "../../../components/organisms/Layout";
import useFormatPhoneNumber from "../../../hooks/FormatPhoneNumber";
import PaginationView from "../../../components/molecules/Pagination";
import useQuery from "../../../hooks/useQuery";
import { useSelector } from "react-redux";
import {
  selectUser,
  selectRoles,
  selectSelectedLocation,
} from "../../../store/authSlice";
import Table from "../../../components/atoms/Tables/Table";
import TableLoading from "../../../components/Loading/TableLoading";
import { addIcon } from "../../../assets/images";

const ManageUsers = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const formatPhoneNumber = useFormatPhoneNumber;
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useQuery();
  const currentUser = useSelector(selectUser);
  const roles = useSelector(selectRoles);
  const isBusinessOwner = currentUser?.company?.is_enterprise === 0;
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 10);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState({});

  useEffect(() => {
    document.title = "HeyHire Portal-Users & Permissions";
  }, [selectedLocation]);

  useEffect(() => {
    loadUsers();
  }, [type, query]);

  const loadUsers = async () => {
    setLoading(false);
    const page = query.get("page") || 1;
    const sort = query.get("sort") || "";
    const sortData = sort ? `sort=${sort}&` : "";
    const limit = query.get("limit") || pageLimit;
    const queryData = sortData + page + `&limit=${limit}`;
    try {
      const response = await User.getAll(queryData);
      const _users = response.data.data?.filter(
        (i) => i?.id !== currentUser?.id
      );
      let userData = _users;
      if (isBusinessOwner) {
        // filter
        userData = _users.filter(
          (e) =>
            e.roles[0]?.name !== "owner" && e?.roles[0]?.name !== "super-user"
        );
      } else {
        userData = filterUsers(userData);
      }
      setUsers(userData);
      setMetaData(response.data.meta);
      setLoading(true);
    } catch (error) {
      toast.error("Error trying to load users. Please try again.");
      setLoading(true);
    }
  };

  const filterUsers = (userList) => {
    let tempList = [];
    switch (roles[0]) {
      case "regional_manager":
        tempList = userList.filter(
          (e) =>
            e.roles[0]?.name !== "owner" && e?.roles[0]?.name !== "super-user"
        );
        break;
      case "location_manager":
        tempList = userList.filter(
          (e) =>
            e.roles[0]?.name === "employee" &&
            e?.roles[0]?.name !== "super-user"
        );
        break;
      case "owner":
        if (type == "Sub Users") {
          tempList = userList.filter(
            (e) =>
              e.roles[0]?.name !== "regional_manager" &&
              e.roles[0]?.name !== "owner" &&
              e?.roles[0]?.name !== "super-user"
          );
        } else {
          tempList = userList.filter((e) => e?.roles[0]?.name !== "super-user");
        }
        break;
      default:
        tempList = userList;
        break;
    }
    return tempList;
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  const deleteUser = (userId) => {
    setLoading(true);
    User.deleteUser(userId)
      .then(() => {
        const dataCopy = users.slice();
        dataCopy.splice(
          users.findIndex((item) => item?.id === userId),
          1
        );
        setUsers(dataCopy);
        setLoading(false);
        toast.success("User Deleted.");
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status !== 403) {
          toast.error("Error trying to delete users. Please try again.");
        }
      })
      .then(() => setLoading(false));
  };

  const suspendUser = (userId) => {
    setLoading(true);
    User.suspendUser(userId)
      .then(() => {
        const dataCopy = [...users];
        dataCopy.map((item) => {
          if (item?.id == userId) {
            item.suspended_at = new Date();
          }
        });
        setUsers(dataCopy);
        toast.success("User Suspended.");
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Error trying to suspend users. Please try again.");
        }
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  const unSuspendUser = (userId) => {
    setLoading(true);
    User.unSuspendUser(userId)
      .then(() => {
        const dataCopy = [...users];
        dataCopy.map((item) => {
          if (item?.id == userId) {
            item.suspended_at = null;
          }
        });
        setUsers(dataCopy);
        toast.success("User UnSuspended.");
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Error trying to unsuspend users. Please try again.");
        }
        setLoading(false);
      })
      .then(() => setLoading(false));
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6 pb-12">
        <div className="overflow-x-auto overflow-hidden">
          {!loading ? (
            <TableLoading Columns={6} />
          ) : (
            <>
              <Table
                mainHeader={
                  isBusinessOwner
                    ? "Manage Sub Users"
                    : type || "Manage " + t("business-users.business-users")
                }
                headers={
                  users?.length > 0
                    ? [
                        t("common.full-name"),
                        t("common.user-type"),
                        t("common.email"),
                        t("common.phone"),
                        t("common.location"),
                        t("common.last-login"),
                        t("common.status"),
                      ]
                    : ["No User Yet"]
                }
                data={users?.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td className="pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-gray-300 whitespace-nowrap">
                        {user?.first_name} {user?.last_name}
                      </td>
                      <td className="pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-gray-300 whitespace-nowrap capitalize">
                        {user.roles &&
                          user?.roles?.length > 0 &&
                          user?.roles[0].name.replace("_", " ")}
                      </td>
                      <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-gray-300">
                        {user?.email}
                      </td>
                      <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-gray-300">
                        {formatPhoneNumber(user.phone)}
                      </td>
                      <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-gray-300">
                        {user?.company?.name}
                      </td>
                      <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-gray-300">
                        {user.last_login || "-"}
                      </td>
                      <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base">
                        {user?.suspended_at ? (
                          <span className="inline-flex items-center rounded-md bg-red-50 dark:bg-[#2C2D30] px-2 py-1 text-sm font-medium text-red-700 dark:text-red-400 ring-1 ring-inset ring-red-600/20 dark:ring-red-500/30">
                            Suspended
                          </span>
                        ) : (
                          <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-[#2C2D30] px-2 py-1 text-sm font-medium text-green-700 dark:text-green-400 ring-1 ring-inset ring-green-600/20 dark:ring-green-500/30">
                            Active
                          </span>
                        )}
                      </td>
                      {currentUser?.id == user?.id ? (
                        <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                          <div
                            className="text-indigo-600 dark:text-indigo-300 hover:text-indigo-900 hover:underline cursor-pointer"
                            onClick={() => navigate(`${path}/edit/${user?.id}`)}
                          >
                            Edit
                          </div>
                        </td>
                      ) : (
                        <>
                          {currentUser?.roles[0]?.name === "owner" &&
                          user?.roles[0]?.name === "owner" ? (
                            <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                              <div
                                className="text-indigo-600 dark:text-indigo-300 hover:text-indigo-900 hover:underline cursor-pointer"
                                onClick={() =>
                                  navigate(`${path}/edit/${user?.id}`)
                                }
                              >
                                Edit
                              </div>
                            </td>
                          ) : (
                            <>
                              <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                                <div
                                  className="text-indigo-600 dark:text-indigo-300 hover:text-indigo-900 hover:underline cursor-pointer"
                                  onClick={() =>
                                    navigate(`${path}/edit/${user?.id}`)
                                  }
                                >
                                  Edit
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                                <div
                                  className="text-indigo-600 dark:text-indigo-300 hover:text-indigo-900 hover:underline cursor-pointer"
                                  onClick={() => deleteUser(user?.id)}
                                >
                                  Delete
                                </div>
                              </td>
                              {!user?.suspended_at ? (
                                <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                                  <div
                                    className="text-indigo-600 dark:text-indigo-300 hover:text-indigo-900 hover:underline cursor-pointer"
                                    onClick={() => suspendUser(user?.id)}
                                  >
                                    Suspend
                                  </div>
                                </td>
                              ) : (
                                <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                                  <div
                                    className="text-indigo-600 dark:text-indigo-300 hover:text-indigo-900 hover:underline cursor-pointer"
                                    onClick={() => unSuspendUser(user?.id)}
                                  >
                                    Unsuspend
                                  </div>
                                </td>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </tr>
                  );
                })}
                buttonData={
                  <button
                    onClick={() => navigate("add")}
                    className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                  >
                    <div className="flex">
                      <img
                        src={addIcon}
                        alt="logo"
                        className="mr-2 my-auto w-4 h-4"
                      />
                      Add user
                    </div>
                  </button>
                }
              />
              <div className="flex w-full h-20 mt-2 justify-end items-center">
                <PaginationView
                  current={metaData?.current_page}
                  next={metaData?.to}
                  pageSize={metaData?.per_page}
                  total={users?.length}
                  onChange={handlecPageChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ManageUsers;
