import axios from "./api";

const base = "/business/dashboard";

const Dashboard = {
  getRecentLogins() {
    return axios.get(`${base}/admin/recent-logins`);
  },
  getRecentLocations() {
    return axios.get(`${base}/admin/recent-locations`);
  },
  getRecentSeekers() {
    return axios.get(`${base}/admin/recent-seekers`);
  },
  getRecentApplications() {
    return axios.get(`${base}/admin/recent-applications`);
  },
};

export default Dashboard;
