import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Collapse } from "antd";
import "./PublicProfile.scss";
import ImgAvatarArrow from "../../../assets/images/profile-round.svg";
import HeyHireLogo from "../../../assets/images/atx-local-jobs-pic.svg";
import DefaultBrandLogo from "../../../assets/images/two-hands.png";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { getPlatform } from "../../../utils";
import Brand from "../../../api/Brand";
import Banner from "../../../components/atoms/Banner/Banner";

const { Panel } = Collapse;

const BusinessShowCase = () => {
  const navigate = useNavigate();
  const { city } = useParams();
  const passedProp = useLocation();
  const [brands, setBrands] = useState(passedProp?.state?.brand);
  const [error, setError] = useState(null);
  const [platform, setPlatform] = useState("ios");
  const [cityParam, setCityParam] = useState("Austin");
  const [pageTitle, setPageTitle] = useState("My App");

  useEffect(() => {
    document.title = "Find local jobs in  " + cityParam + ", Texas - SXSW2023";
    const _platform = getPlatform();
    setPlatform(_platform);
    if (!brands) {
      loadBrands();
    }
  }, []);

  useEffect(() => {
    document.title = "Find local jobs in  " + cityParam + ", Texas - SXSW2023"; // Set the document title whenever pageTitle changes
  }, [cityParam]);

  const loadBrands = async () => {
    let getCity = city;

    if (getCity === undefined) {
      getCity = "Austin";
    }

    setCityParam(getCity);

    await Brand.getAllBrandForPublicPage(getCity)
      .then((response) => {
        const tempObj = response.data.data;
        setBrands(tempObj);
        setError(null);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          setError(error?.response?.data?.message);
        }
      });
  };

  const renderBusiness = (brand) => {
    return (
      <div>
        <a
          href={`https://heyhire.app/${brand.url_slug}`}
          target="_blank"
          rel="noreferrer"
        >
          <li className="py-3 sm:py-10 border-solid border-5 shadow-lg hover:bg-primary-100">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0 pl-5">
                <img
                  className="w-8 h-8 rounded-full"
                  src={
                    brand.photo.photo
                      ? brand.photo.photo.tiny_url
                      : DefaultBrandLogo
                  }
                  alt="Neil"
                />
              </div>
              <div className="business-container flex-1 min-w-0 pl-10">
                <p className="text-base font-bold text-gray-900 truncate dark:text-black">
                  {brand.locationName}
                </p>
                <p className="text-sm font-bold text-gray-500 truncate dark:text-gray-400">
                  Open Positions:{" "}
                  <span className="text-indigo-700">
                    ({brand.positionOpen})
                  </span>
                </p>
                <p className="text-sm font-bold text-gray-500 truncate dark:text-gray-400">
                  Location:{" "}
                  <span className="text-indigo-700">{brand.address}</span>
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-black pr-5">
                <button className="button-mobile bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded-full">
                  More Info
                </button>
              </div>
            </div>
          </li>
        </a>
      </div>
    );
  };

  return (
    <div className="page-public-profile h-auto bg-gray-300">
      <div className="public-profile flex flex-col h-full">
        <Banner />
        <div className="min-w-full w-full mx-auto flex-1">
          <div className="flex h-full flex-col lg:flex-row lg:pl-10">
            <div className="w-full lg:h-fit lg:w-[383px] bg-primary-700 text-white flex flex-col">
              <div className="relative hidden top-[-70px] lg:flex flex-col px-6 h-100 pt-10 ">
                <div className="relative flex justify-center bg-white rounded-full p-3 w-[145px] h-[145px] mx-auto">
                  <img
                    className={"rounded-full"}
                    height="125"
                    width="125"
                    src={HeyHireLogo}
                    alt="logo"
                  />
                  <img
                    src={ImgAvatarArrow}
                    alt="avatar"
                    className="absolute right-1 bottom-0"
                  />
                </div>

                <span className="block font-bold text-white text-xl mt-7 mb-4">
                  Local jobs in {cityParam}, TX
                </span>

                <div className="flex items-center mb-6">
                  <div className="text-center min-w-[28px]">
                    <i className="far fa-home col-span-1 text-xl text-white-400 text-center self-center" />
                  </div>
                  <button
                    className="ml-1 col-span-7 self-center underline italic"
                    onClick={() => window.open(`https://app.heyhire.com`)}
                  >
                    app.heyhire.com
                  </button>
                </div>

                <span className="flex flex-col space-y-4 text-white-900">
                  <div>
                    Find a local job without the hassle in 3 simple steps!
                  </div>
                  <div>
                    {" "}
                    Create a creative one-page profile that will allow you to
                    present yourself to employers around you. Experience or
                    education is not required!
                  </div>
                  <div>
                    Once downloading the HeyHire App you can Open your map to
                    find the hiring businesses around you and apply in 2 taps!
                    Also, find our QR banners around town!
                  </div>
                  <div>
                    Easily manage your applications and communicate with
                    employers via text for a smooth interview scheduling
                    experience!
                  </div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </span>
              </div>

              <div className={"hidden lg:block mt-auto"}>
                <div className="flex justify-center mb-4">
                  <button
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                      )
                    }
                  >
                    <img
                      width={100}
                      src="/images/play_store_badge.png"
                      alt="Play Store"
                    />
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/heyhire/id1628579684"
                      )
                    }
                  >
                    <img
                      width={100}
                      className="ml-1"
                      src="/images/app_store_badge.png"
                      alt="App Store"
                    />
                  </button>
                </div>
                <div className="shadow-2xl text-center bg-white text-primary-500 py-4 flex justify-center">
                  <span className="font-semibold self-center mr-2">
                    Powered by{" "}
                  </span>
                  <img
                    height={10}
                    width={120}
                    alt="logo"
                    src="/logo/powered-by.svg"
                  />
                </div>
              </div>

              <Collapse
                className="public-profile-collapse lg:hidden"
                ghost
                expandIconPosition="right"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <FiChevronUp size={24} color="white" />
                  ) : (
                    <FiChevronDown size={24} color="white" />
                  )
                }
              >
                <Panel
                  header={
                    <div className={"flex text-white"}>
                      <div
                        className={"rounded-full border-4 border-white h-min"}
                      >
                        <img
                          className={"rounded"}
                          src={HeyHireLogo}
                          alt="logo"
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className={"ml-2"}>
                        <span className={"block text-lg font-bold "}>
                          Local Jobs in {cityParam}, TX
                        </span>
                        <button
                          className={
                            "scale-90 block underline cursor-pointer italic"
                          }
                          onClick={() => window.open(`https://app.heyhire.com`)}
                        >
                          app.heyhire.com
                        </button>
                      </div>
                    </div>
                  }
                  key="1"
                >
                  <div>
                    <p className={"text-white ml-14"}>{brands?.location}</p>
                    <p className={"flex flex-col space-y-4 mt-4 text-white"}>
                      <div>
                        Find a local job without the hassle in 3 simple steps!
                      </div>
                      <div>
                        {" "}
                        Create a creative one-page profile that will allow you
                        to present yourself to employers around you. Experience
                        or education is not required!
                      </div>
                      <div>
                        Once downloading the HeyHire App you can Open your map
                        to find the hiring businesses around you and apply in 2
                        taps! Also, find our QR banners around town!
                      </div>
                      <div>
                        Easily manage your applications and communicate with
                        employers via text for a smooth interview scheduling
                        experience!
                      </div>
                    </p>
                  </div>
                </Panel>
              </Collapse>
            </div>
            <div className="overflow-hidden h-full md:w-full flex-1 lg:ml-16 lg:pt-8 p-4 pb-44 lg:pb-6">
              {!error ? (
                <div className="overflow-x-auto lg:max-h-[730px] scroll-smooth	">
                  <div className="w-full lg:h-fit max-w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 ">
                    <div className="flex items-center justify-between mb-4">
                      <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-black">
                        List Of Businesses
                      </h5>
                      <div className="">
                        <h6 className="relative right-5 pb-2">Scroll Down</h6>
                        <div className="animate-bounce">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M12 5v13M5 12l7 7 7-7" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="flow-root">
                      <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        {brands &&
                          brands.map((brand) => {
                            return renderBusiness(brand);
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex w-full h-full justify-center items-center">
                  <span className="text-slate-500 text-xl lg:text-6xl font-black tracking-widest decoration-slate-500 antialiased">
                    {error}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={"relative lg:hidden w-full bottom-0"}>
        <div
          className={
            "px-4 py-5 bg-primary-500 flex items-center justify-center shadow-[3px_0_0_2px_rgba(0,0,0,0.1)]"
          }
        >
          <span className={"text-white font-thin"}>
            Want to <strong className="font-bold">apply</strong> for this{" "}
            <strong className="font-bold">job?</strong>
          </span>
          <button
            onClick={() => {
              if (platform == "ios") {
                window.open(
                  "https://apps.apple.com/us/app/heyhire/id1628579684"
                );
              } else {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                );
              }
            }}
          >
            <img
              width={150}
              className="ml-4"
              src={
                platform === "ios"
                  ? "/images/app_store_badge.png"
                  : "/images/play_store_badge.png"
              }
              alt="App Store"
            />
          </button>
        </div>
        <div className="text-center bg-white-200 text-primary-500 py-4 flex justify-center">
          <span className="font-semibold self-center mr-2">Powered by </span>
          <img height={10} width={120} alt="logo" src="/logo/powered-by.svg" />
        </div>
      </div>
    </div>
  );
};

export default BusinessShowCase;
