import React from "react";
import { emitCustomEvent } from "react-custom-events";

const Modal = ({
  children,
  title,
  subTitle,
  closeOnBackgroundClick = true,
  headerBg = "bg-violet-600",
}) => {
  const close = () => {
    emitCustomEvent("closeModal");
  };

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={() => (closeOnBackgroundClick ? close() : null)}
          aria-hidden="true"
        />

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="ease-in-out inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
          <button
            className="absolute right-0 m-3 p-3 text-white text-opacity-80 hover:text-opacity-100 text-xl"
            onClick={close}
          >
            <i className="far fa-times" />
          </button>

          <div className={"w-full text-center py-10 " + headerBg}>
            <span className="block text-xl text-white font-normal">
              {title}
            </span>
            <span className="block text-white text-sm text-opacity-80 mt-3 px-10">
              {subTitle}
            </span>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
