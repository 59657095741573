import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Region from "../api/Region";
import { toast } from "react-toastify";
import { emitCustomEvent } from "react-custom-events";
import Company from "../api/Company";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import { Link, useNavigate } from "react-router-dom";
import { DashboardLayout } from "../components/organisms/Layout";
import FormSelect from "../components/atoms/FormSelect";

const AddRegion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("-1");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);

  useEffect(() => {
    document.title = "HeyHire Portal-Add New Region";
    loadCompanies();
  }, []);

  const addEditRegion = () => {
    setLoading(true);
    let body = {
      company_id: selectedCompany,
      name: name,
    };
    Region.create(body)
      .then(() => {
        toast.success(t("regions.region-added"));
        emitCustomEvent("regionChanged");
        navigate("/companies/regions");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  const loadCompanies = () => {
    Company.getAll().then((response) => {
      setCompanies(response.data.data);
      if (response.data.data.length > 0) {
        setSelectedCompany(response.data.data[0].id);
      }
    });
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full mt-6">
        <h2 className={"text-2xl font-bold"}>{t("regions.add-a-region")}</h2>
        <hr className="my-2 border-gray-300" />

        <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
          <div className={"grid grid-cols-1 md:grid-cols-3 gap-2"}>
            <Input
              name="name"
              darmo={true}
              label={t("common.name")}
              type="text"
              value={name}
              placeholder={t("regions.add-a-region-name")}
              error={
                validationErrors && validationErrors.name
                  ? validationErrors.name[0]
                  : null
              }
              onChange={(e) => setName(e.target.value)}
            />

            <FormSelect
              label="Select Company"
              placeholder="Select Company"
              value={selectedCompany?.label}
              options={companies?.map((info) => ({
                value: info?.id,
                label: info?.name,
              }))}
              error={
                validationErrors && validationErrors.company_id
                  ? validationErrors.company_id[0]
                  : null
              }
              onChange={(e) => setSelectedCompany(e?.value)}
              darmo={true}
            />
          </div>

          <hr className="my-4" />

          <div className="flex flex-col justify-center items-center w-full md:w-1/5">
            <button
              className="w-full flex justify-center items-center rounded-full bg-primary-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                addEditRegion();
              }}
              disabled={loading}
            >
              {t("common.save")}
              {loading && <i className="fad fa-circle-notch fa-spin ml-2" />}
            </button>
            <div className="mt-4">
              <button>{t("click-here-to-save-as-draft")}</button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddRegion;
