import axios from "./api";

const PublicProfileAPI = {
  brandLookUp(slug){
    return axios.get(`public-profile/brand/${slug}`);
  },
  locationLookUp(brandSlug, slug){
    return axios.get(`public-profile/brand/${brandSlug}/${slug}`);
  }
};

export default PublicProfileAPI;
