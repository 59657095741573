import React, { useState } from "react";
import { emitCustomEvent } from "react-custom-events";
import DraftApi from "../api/Draft";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { DateFormat } from "../utils";

const Draft = ({ draft, className }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);

  const deleteDraft = () => {
    setDeleteLoading(true);

    DraftApi.deleteDraft(draft.id)
      .then(() => {
        emitCustomEvent("draftDeleted", draft);
        toast.success("Draft Deleted");
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something went wrong. Please try again.");
        }
      })
      .then(() => {
        setDeleteLoading(false);
      });
  };

  return (
    <div className={className}>
      <div
        className={
          "px-4 py-2 flex justify-between rounded text-sm bg-primary-100 text-primary-500"
        }
      >
        <span className={"self-center"}>
          You have a pending draft:
          <span className={"font-bold ml-1"}>{draft.title}</span>
          <span className={"ml-1"}>
            [{dayjs(draft.created_at).format(DateFormat)}]
          </span>
        </span>
        <div className={"flex"}>
          <button
            className={
              "text-sm p-1 rounded bg-primary-200 hover:bg-primary-300 hover:font-bold"
            }
            onClick={() => emitCustomEvent("loadDraft", draft)}
          >
            LOAD
          </button>
          <button
            className={
              "ml-2 text-sm p-1 rounded bg-primary-200 hover:bg-primary-300 hover:font-bold"
            }
            onClick={deleteDraft}
          >
            DELETE{" "}
            {deleteLoading && (
              <i className="fad fa-circle-notch fa-spin ml-1" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Draft;
