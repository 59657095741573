import axios from "./api";

const base = "/business/payment";

const Cards = {
  getToken() {
    return axios.get(base + '/get-token');
  },
  add(payment) {
    return axios.post(base, payment);
  },
  getCards() {
    return axios.get(base);
  },
  getAllInvoices() {
    return axios.get("business/invoices");
  },
  getCardByCustomerID(id){
    return axios.get(`/business/payment-methods/${id}`);
  }
};

export default Cards;
