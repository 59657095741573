import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DraftApi from "../api/Draft";
import { toast } from "react-toastify";
import DraftPositionsCard from "../components/molecules/DraftPositionsCard";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { DashboardLayout } from "../components/organisms/Layout";
import RBAC from "../api/RBAC";
import { useSelector } from "react-redux";
import { selectSelectedLocation } from "../store/authSlice";

const DraftPositions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isPositionCreated, setPositionCreated] = useState(false);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [loading, setLoading] = useState(false);
  const [drafts, setDrafts] = useState([]);
  const [isPositionUpdated, setPositionUpdated] = useState(false);
  const [isPositionDeleted, setPositionDeleted] = useState(false);

  useEffect(() => {
    document.title = "HeyHire Portal-Position Drafts";
    loadDraft();
    // checkPositionCreate();
    // checkPositionEdit();
    // checkPositionDelete();
  }, [selectedLocation]);

  const checkPositionCreate = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "position",
        operations: "create",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPositionCreated(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkPositionEdit = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "position",
        operations: "update",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPositionUpdated(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkPositionDelete = async () => {
    try {
      const payload = {
        location_id: selectedLocation?.id,
        feature: "position",
        operations: "delete",
      };
      const response = await RBAC.checkPermissionRBAC(payload);
      if (response?.data?.result) {
        setPositionDeleted(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadDraft = async () => {
    setLoading(true);
    await DraftApi.getAllByType("add-job-position")
      .then((response) => {
        setDrafts(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something wrong, please try again.");
          console.error(error);
          setLoading(false);
        }
      });
  };

  return (
    <DashboardLayout darmo={true}>
      <div>
        <div className="flex justify-between">
          <h2 className="font-bold text-2xl">
            <Link to={"/job-positions"}>
              <i className="far fa-chevron-left text-xl mr-2" />
            </Link>{" "}
            Saved Drafts
          </h2>

          {/* {isPositionCreated && */}
          <button
            className="font-bold text-sm mr-2 px-6 py-2 w-fit rounded-xl bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
            onClick={() => {
              navigate("/job-positions/add");
            }}
          >
            <i className="far fa-plus mr-1" /> Add New Job
          </button>
          {/* } */}
        </div>
        <div>
          <span className="font-semibold block px-3 mb-2 ml-3 text-gray-100">
            {drafts?.length} Total Drafts
          </span>
        </div>

        <div className="mt-6">
          {loading ? (
            <Loading size={"5xl"} count={6} />
          ) : (
            drafts.map((jb, i) => (
              <DraftPositionsCard
                key={i}
                className={"my-1"}
                draftPositions={jb}
                loadDrafts={loadDraft}
                // isPositionUpdated={isPositionUpdated}
                // isPositionDeleted={isPositionDeleted}
                // isChanged={true}
              />
            ))
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DraftPositions;
