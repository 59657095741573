import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Company from "../../api/Company";
import User from "../../api/User";
import useFormatPhoneNumber from "../../hooks/FormatPhoneNumber";
import { toast } from "react-toastify";
import { emitCustomEvent } from "react-custom-events";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import ReactSelect from "react-select";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Location from "../../api/Location";
import { DashboardLayout } from "../../components/organisms/Layout";
import Region from "../../api/Region";
import { useSelector } from "react-redux";
import { selectRoles, selectUser } from "../../store/authSlice";
import { userTypesNotEnterprise, userTypesSuper } from "../../utils/permission";
import { generatePassword } from "../../utils";

const AllowedUsers = ["super-user"];

const AddUserPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const editMode = location.pathname.search("edit") !== -1;
  const user = useSelector(selectUser);
  const roles = useSelector(selectRoles);
  const [selectedUserType, setSelectedUserType] = useState("owner");
  const [selectedCompany, setSelectedCompany] = useState(-1);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userPosition, setUserPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const allowedCompanySelection =
    roles.length > 0 ? AllowedUsers.includes(roles[0]) : false;
  const userTypes = allowedCompanySelection
    ? user.company.is_enterprise || roles[0] == "super-user"
      ? userTypesSuper
      : userTypesNotEnterprise
    : userTypesNotEnterprise;

  useEffect(() => {
    if (editMode) {
      document.title = "HeyHire Portal-Edit User";
    } else {
      document.title = "HeyHire Portal-Add New User";
    }
    loadCompanies();
    loadLocations();
    loadRegions();
    if (editMode) {
      loadUser();
    }
  }, []);

  useEffect(() => {
    setSelectedLocation([]);
    setSelectedRegions([]);
  }, [selectedCompany]);

  const loadUser = () => {
    setLoading(true);

    User.getById(params.userId)
      .then((response) => {
        const tempUser = response.data.data;
        setSelectedCompany(tempUser?.company_id);
        setSelectedUserType(tempUser?.roles[0]?.name);
        setSelectedLocation([
          { value: tempUser?.location_id, label: tempUser?.location?.name },
        ]);
        setFirstName(tempUser?.first_name);
        setLastName(tempUser?.last_name);
        setEmail(tempUser?.email);
        setPhone(tempUser?.phone);
        setUserPosition(tempUser?.position_name);
        /*
      if (tempUser?.roles[0]?.name == "regional_manager") {
        const _tempRegions = filteredRegions();
        console.log('_tempRegions', _tempRegions);
        let _selectedRegion = [];
        _selectedRegion.push({value: _tempRegions[0]?.id, label: _tempRegions[0]?.name});
        setSelectedRegions(_selectedRegion);
      }
      */
        setLoading(false);
      })
      .catch((error) => {
        console.log("loadUser -> error", error);
      });
  };

  const loadCompanies = () => {
    Company.getAll().then((response) => {
      setCompanies(response.data.data);

      // Set the default selected company
      if (response.data.data.length > 0 && !editMode) {
        setSelectedCompany(response.data.data[0].id);
      }
    });
  };

  const loadLocations = () => {
    Location.getAll().then((response) => {
      const locationsResponse = response.data.data;
      setLocations(locationsResponse);

      if (locationsResponse.length > 0 && !editMode) {
        /*
        const _tempLocations = filteredLocations();
        let _selectedLocation = [];
        _selectedLocation.push({value: _tempLocations[0]?.id, label: _tempLocations[0]?.name});
        */
        setSelectedLocation(locationsResponse[0].id);
      }
    });
  };

  const loadRegions = () => {
    Region.getAll().then((response) => {
      setRegions(response.data.data);
    });
  };

  const updateUser = async () => {
    setLoading(true);
    setValidationErrors(null);
    const requestBody = {
      role: selectedUserType,
      email: email,
      phone: phone,
      first_name: firstName,
      last_name: lastName,
      company_id: selectedCompany,
    };
    if (selectedUserType === "regional_manager") {
      requestBody.region_ids = selectedRegions?.map((r) => r?.value);
    } else {
      requestBody.location_ids = selectedLocation?.map((sl) => sl?.value);
    }

    User.update(requestBody, params.userId)
      .then(() => {
        toast.success(t("business-users.user-updated"));
        emitCustomEvent("closeModal");
        navigate(-1);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  const addUser = async () => {
    const confirmPass = password ? await checkConfirmPassword() : true;
    if (!confirmPass) {
      return;
    } else {
      setLoading(true);
      setValidationErrors(null);
      const _password = password ? password : await generatePassword();
      const requestBody = {
        role: selectedUserType,
        email: email,
        phone: phone,
        first_name: firstName,
        last_name: lastName,
        company_id: selectedCompany,
        password: _password,
      };

      if (selectedUserType === "regional_manager") {
        requestBody.region_ids = selectedRegions.map((r) => r.value);
      } else {
        requestBody.location_ids = selectedLocation?.map((sl) => sl?.value);
      }

      if (selectedUserType === "employee") {
        requestBody.position_name = userPosition;
      }

      User.add(requestBody)
        .then(() => {
          toast.success(t("business-users.user-added"));
          emitCustomEvent("closeModal");
          navigate("/admin/manage-business-users");
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setValidationErrors(error.response.data.errors);
          } else {
            if (error?.response?.status !== 403) {
              toast.error(t("common.something-went-wrong-please-try-again"));
            }
          }
        })
        .then(() => setLoading(false));
    }
  };

  const filteredLocations = () => {
    return locations?.filter(
      (location) => location?.company?.id === selectedCompany
    );
  };

  const checkConfirmPassword = () => {
    if (confirmPassword == password) {
      return true;
    } else {
      setValidationErrors({
        ...validationErrors,
        confirmPassword: "password do not match!",
      });
      return false;
    }
  };

  const filteredRegions = () => {
    return regions.filter((region) => region.company_id === selectedCompany);
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full">
        <h2 className={"text-2xl font-bold"}>
          <Link to={"/admin/manage-business-users"}>
            <i className="far fa-chevron-left text-xl mr-2" />
          </Link>{" "}
          {editMode ? "Edit User" : "Create a User"}
        </h2>
        <hr className="my-2 border-gray-300" />

        <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            {allowedCompanySelection && (
              <Select
                label={t("common.company")}
                value={selectedCompany}
                name="company"
                onChange={(e) => {
                  setSelectedCompany(companies[e.target.selectedIndex].id);
                }}
                disabled={!allowedCompanySelection}
              >
                {companies.map((company) => {
                  return (
                    <option className="p-5" key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  );
                })}
              </Select>
            )}
            <Select
              name="name"
              label={t("business-users.select-user-type")}
              value={selectedUserType}
              error={
                validationErrors && validationErrors.name
                  ? validationErrors.name[0]
                  : null
              }
              onChange={(e) => setSelectedUserType(e.target.value)}
            >
              {userTypes.map((u, i) => (
                <option key={i} value={u.value}>
                  {u.label}
                </option>
              ))}

              {/* <option value="regional_manager">Regional Manager</option>
              <option value="employee">Employee</option> */}
            </Select>
            {selectedUserType === "regional_manager" ? (
              <div>
                <span className={"font-bold block mt--1"}>Regions</span>
                <ReactSelect
                  value={selectedRegions}
                  isMulti
                  theme={(theme) => {
                    return {
                      ...theme,
                      borderRadius: 4,
                      spacing: {
                        ...theme.spacing,
                        controlHeight: 47,
                      },
                      colors: {
                        ...theme.colors,
                        primary25: "#A899E2",
                        primary: "#7D66D3",
                      },
                    };
                  }}
                  onChange={(selected) => setSelectedRegions(selected)}
                  options={filteredRegions().map((region) => {
                    return {
                      value: region.id,
                      label: region.name,
                    };
                  })}
                  isDisabled={selectedUserType === "owner"}
                />
              </div>
            ) : (
              <>
                {/* {selectedUserType !== "owner" && ( */}
                <div>
                  <span className={"font-bold block mt--1"}>Locations</span>
                  <ReactSelect
                    value={selectedLocation}
                    isMulti
                    theme={(theme) => {
                      return {
                        ...theme,
                        borderRadius: 4,
                        spacing: {
                          ...theme.spacing,
                          controlHeight: 47,
                        },
                        colors: {
                          ...theme.colors,
                          primary25: "#A899E2",
                          primary: "#7D66D3",
                        },
                      };
                    }}
                    onChange={(selected) => setSelectedLocation(selected)}
                    options={filteredLocations().map((location) => {
                      return {
                        value: location.id,
                        label: location.name,
                      };
                    })}
                    // isDisabled={selectedUserType === "owner"}
                  />
                </div>
                {/* )} */}
              </>
            )}
            <Input
              className="my-1"
              name="name"
              label={t("business-users.first-name")}
              type="text"
              value={firstName}
              error={
                validationErrors && validationErrors.first_name
                  ? validationErrors.first_name[0]
                  : null
              }
              onChange={(e) => setFirstName(e.target.value)}
              darmo={true}
            />
            <Input
              className="my-1"
              name="name"
              label={t("business-users.last-name")}
              type="text"
              value={lastName}
              error={
                validationErrors && validationErrors.last_name
                  ? validationErrors.last_name[0]
                  : null
              }
              onChange={(e) => setLastName(e.target.value)}
              darmo={true}
            />
            {selectedUserType === "employee" ? (
              <Input
                className="my-1"
                name="position"
                label={t("business-users.position")}
                type="text"
                value={userPosition}
                error={
                  validationErrors && validationErrors.position_name
                    ? validationErrors.position_name[0]
                    : null
                }
                onChange={(e) => setUserPosition(e.target.value)}
                darmo={true}
              />
            ) : null}
            <Input
              className="my-1"
              name="email"
              label={t("common.email")}
              type="text"
              value={email}
              error={
                validationErrors && validationErrors.email
                  ? validationErrors.email[0]
                  : null
              }
              onChange={(e) => setEmail(e.target.value)}
              darmo={true}
            />
            <Input
              className="my-1"
              name="phone"
              label={t("common.phone")}
              type="text"
              value={useFormatPhoneNumber(phone)}
              error={
                validationErrors && validationErrors.phone
                  ? validationErrors.phone[0]
                  : null
              }
              onChange={(e) => {
                if (e.target.value.length < 15) {
                  setPhone(e.target.value);
                }
              }}
              disabled={false}
              darmo={true}
            />

            {!editMode && (
              <Input
                className="my-1"
                name="password"
                label={t("common.password")}
                type="password"
                value={password}
                error={
                  validationErrors && validationErrors.password
                    ? validationErrors.password
                    : null
                }
                onChange={(e) => {
                  setValidationErrors({ ...validationErrors, password: null });
                  setPassword(e.target.value);
                }}
                darmo={true}
              />
            )}
            {!editMode && (
              <Input
                className="my-1"
                name="confirmPassword"
                label={"Confirm " + t("common.password")}
                type="password"
                value={confirmPassword}
                icon="far fa-lock"
                error={
                  validationErrors && validationErrors.confirmPassword
                    ? validationErrors.confirmPassword
                    : null
                }
                onChange={(e) => {
                  setValidationErrors({
                    ...validationErrors,
                    confirmPassword: null,
                  });
                  setConfirmPassword(e.target.value);
                }}
                darmo={true}
              />
            )}
          </div>

          <hr className="my-5" />

          <div className="flex flex-col justify-center items-center w-full md:w-1/5">
            <button
              className="w-full flex justify-center items-center rounded-full bg-primary-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                if (editMode) {
                  updateUser();
                } else {
                  addUser();
                }
              }}
              disabled={loading}
            >
              {editMode ? t("business-users.update-user") : t("common.add")}
              {loading && <i className="fad fa-circle-notch fa-spin ml-2" />}
            </button>

            <div className="mt-4">
              <button>{t("click-here-to-save-as-draft")}</button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddUserPage;
