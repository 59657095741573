import React, { useEffect, useState } from "react";
import MultiSelector from "../../../../components/atoms/Selector/MultiSelector";
import Input from "../../../../components/form/Input";
import ReactImageUploading from "react-images-uploading";
import Company from "../../../../api/Company";
import Brand from "../../../../api/Brand";
import TextArea from "../../../../components/form/TextArea";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { leftArrowImage } from "../../../../assets/images";
import Group from "../../../../api/Group";
import { toast } from "react-toastify";
import Location from "../../../../api/Location";

const Groups = ({ callback = false }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [groupUrl, setGroupUrl] = useState("");
    const [images, setImages] = useState([]);
    const apiUrl = process.env.REACT_APP_API_BASE_URL.includes("stagingapi")
    ? "https://staging.heyhire.app"
    : "https://heyhire.app/";
    const [companies, setCompanies] = useState([]);
    const [brands, setBrands] = useState([]);
    const [filteredBrands, setFilteredBrands] = useState([]);
    const [locations, setLocations] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [companySelected, setCompanySelected] = useState([]);
    const [brandSelected, setBrandSelected] = useState([]);
    const [locationSelected, setLocationSelected] = useState([]);
    const [refreshCompany, setRefreshCompany] = useState(false);
    const [refreshBrand, setRefreshBrand] = useState(false);
    const [refreshLocation, setRefreshLocation] = useState(false);
    const [show, setShow] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [file, setFile] = useState("/images/register_logo.svg");
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState(null); 
    const [reloadList, setReloadList] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [existImage, setExistImage] = useState(null);
    const [groupUrlError, setGroupUrlError] = useState(false);
    
    useEffect(() => {
        loadCompanies();
        loadBrands();
        loadLocations();
        loadGroupList();
      }, []);

    useEffect(()=>{
      if(reloadList) loadGroupList();
    },[reloadList]);

    const loadGroupList = () => {
      setLoading(true);
      Group.getAll()
        .then((response) => {
          setDataList(response?.data?.data);
          setReloadList(false);
        })
        .catch((error) => {
          console.error('Error fetching group list:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    const loadCompanies = () => {
        Company.getAll().then((response) => {
            let fetchdata = response.data.data;
            let companyData = [];
    
            for (let i = 0; i < fetchdata.length; i++) {
              const el = fetchdata[i];
              companyData.push({
                label: el.name,
                value: el.id,
              });
            }
            setCompanies(companyData);
            setRefreshCompany(true);
        });
    };

    const loadBrands = () => {
        Brand.getAll().then((response) => {
          setBrands(response.data.data);
        });
      };

    const loadLocations = () => {
        Location.getAll().then((response) => {
          setLocations(response.data.data);
        });
    };

    useEffect(()=>{
      if(refreshBrand){
        setRefreshBrand(false);
      }
    },[refreshBrand])

    useEffect(()=>{
      if(refreshLocation){
        setRefreshLocation(false);
      }
    },[refreshLocation])
    
    useEffect(()=>{
      if(brands && locations && companySelected){
        const tempBrandData = brands.filter((brand) =>
          companySelected.some((selected) => selected.value === brand.company_id)
        );

        if(tempBrandData.length > 0){
          let brandData = [];
    
          for (let i = 0; i < tempBrandData.length; i++) {
            const el = tempBrandData[i];
              brandData.push({
                label: el.name,
                value: el.id,
              });
          }
          setFilteredBrands(brandData);
          setRefreshBrand(true);
        }
      }
    },[companySelected, brands, locations])

    useEffect(()=>{
      if(brands && locations && brandSelected && companySelected){
        const tempLocationData = locations.filter((location) =>
          brandSelected.some((selected) => selected.value === location.brand?.id)
        );

        if(tempLocationData.length > 0){
          let brandData = [];
    
          for (let i = 0; i < tempLocationData.length; i++) {
            const el = tempLocationData[i];
              brandData.push({
                label: el.name,
                value: el.id,
              });
          }
          setFilteredLocations(brandData);
          setRefreshLocation(true);
        }
      }
    },[brandSelected, brands, locations])

    const clickCompanyHandler = (value) => {
      if(value.length === 0){
        setFilteredBrands([]);
        setFilteredLocations([]);
        setRefreshBrand(true);
        setRefreshLocation(true);
        setBrandSelected([]);
        setLocationSelected([]);
      } else {
            // Filter brands and locations based on the newly selected companies
            const filteredBrands = brands.filter((brand) =>
              value.some((selected) => selected.value === brand.company_id)
            );
    
            const filteredLocations = locations.filter((location) =>
              value.some((selected) => selected.value === location?.id)
            );
        
            // Filter selected brands to remove those that don't belong to the newly selected companies
            const newBrandSelected = brandSelected.filter((selectedBrand) =>
              filteredBrands.some((brand) => brand.id === selectedBrand.value)
            );
        
            // Filter selected locations to remove those that don't belong to the newly selected companies
            const newLocationSelected = locationSelected.filter((selectedLocation) =>
              filteredLocations.some((location) => location.id === selectedLocation.value)
            );
        
            // Update selected brands and locations
            setBrandSelected(newBrandSelected);
            setLocationSelected(newLocationSelected);
          }
      setCompanySelected(value);
    };

    const clickBrandHandler = (value) => {
      if(value.length === 0) {
        setFilteredLocations([]);
        setRefreshLocation(true);
        setLocationSelected([]);
      } else {
        const filteredLocations = locations.filter((location) =>
          value.some((selected) => selected.value === location?.brand?.id)
        );

        const newLocationSelected = locationSelected.filter((selectedLocation) =>
          filteredLocations.some((location) => location.id === selectedLocation.value)
        );

        setLocationSelected(newLocationSelected);
      }
      setBrandSelected(value);
    };
    
    const clickLocationHandler = (value) => {
      setLocationSelected(value);
    };

    const handleCreateGroup = () => {
      const requestBody = {
        group_name: title,
        description: description,
        group_url: groupUrl,
        website_url: websiteUrl,
        companies: companySelected,
        brands: brandSelected,
        locations: locationSelected,
      }

    if (file === "/images/register_logo.svg" && existImage === null) {
      setImageError(true);
      return;
    } else {
      setButtonLoading(true);
      const request = editMode
      ? Group.update(editId, requestBody)
      : Group.add(requestBody);
      
      request
        .then((res) => {
          if(existImage === null) Group.uploadGroupImage(res?.data?.data?.id, file);
          setShow(false);
          setEditMode(false);
          setReloadList(true);
          setButtonLoading(false);
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.error === "Group URL already exists") {
            setGroupUrlError(true);
          } else if(error.response && error.response.data && error.response.data.message === "Invalid Input") {
            toast.error("Please fill all the fields.")
          }
          setButtonLoading(false);
        })
      }
    }

    const handleShowGroup = () => {
      setShow(!show);
      setEditMode(false);
      setTitle("");
      setGroupUrl("");
      setWebsiteUrl("");
      setBrandSelected([]);
      setCompanySelected([]);
      setLocationSelected([]);
      setFile("/images/register_logo.svg");
      setGroupUrlError(false);
    }

    const getDetailById = async (id) => {
      try {
        const response = await Group.getById(id);
        const data = response?.data?.data?.group_details;
        setExistImage(response?.data?.data?.group_photo?.original_url);
    
        setTitle(data?.group_name);
        setDescription(data?.description);
        setGroupUrl(data?.group_url);
        setWebsiteUrl(data?.website_url);
        setBrandSelected(JSON.parse(data?.brands));
        setCompanySelected(JSON.parse(data?.companies));
        setLocationSelected(JSON.parse(data?.locations));
        setEditId(data?.id);
      } catch (error) {
        console.error('Error fetching group details:', error);
      }
    }

    const handleEditGroup = (id) => {
      setEditMode(true);
      setShow(true);
      getDetailById(id);
      setGroupUrlError(false);
    }

    const deleteGroup = (groupId) => {
      Group.delete(groupId)
      .then((res) => {
        if(res?.data?.message === "success") toast.success("Group Deleted.");
        setReloadList(true);
      })
      .catch(((error) => {
        console.log(error);
      }))
    }

  return (
    <div>
      {show && (
      <div className="flex flex-col w-full py-8 items-center justify-center">
          <div className="flex cursor-pointer mb-8 md:w-4/5 xl:w-3/5" onClick={() => setShow(false)}>
            <img src={leftArrowImage} className="w-4 h-4 my-auto mr-2"/>
            <span className="text-[15px] font-bold">Back</span>
          </div>
          <div className="md:w-4/5 xl:w-3/5 items-center">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 lg:gap-6 mb-2 md:mb-6">
            <Input
              name="title"
              label="Group Name"
              type="text"
              value={title}
              placeholder="Enter Group Name"
              onChange={(e) => setTitle(e.target.value)}
              darmo={true}
            />

            <div>
                <p className="block font-medium title text-warmgray-900">Upload Logo</p>
                <ReactImageUploading
                    value={images}
                    onChange={(imageList, index) => {
                      setImageError(false);
                      setFile(imageList[0]?.file);
                      setImages(imageList);
                      setExistImage(null);
                    }}
                >
                      {({imageList, onImageUpload}) => (
                        <div className="flex mt-2">
                          <div className="h-28 w-28 bg-warmgray-200 selfcenter flex justify-center">
                            {existImage ? (
                              <img
                                className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                                src={existImage}
                              />
                              ) : imageList && imageList.length > 0 ? (
                              <img
                                className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                                src={imageList[0]?.dataURL}
                              />
                              ) : (
                              <i
                                className="text-white text-3xl self-center fa fa-plus cursor-pointer"
                                onClick={onImageUpload}
                              />
                            )}
                          </div>
                          
                          <div className="flex flex-col ml-4 justify-center">
                          <button
                            className="self-center font-bold text-sm mr-2 px-6 py-2 rounded-xl bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                            onClick={(e) => {
                                e.preventDefault(); // Prevent default action
                                onImageUpload(e); // Call onImageUpload
                            }}
                            // disabled={!edit}
                          >
                            Upload Group Logo
                          </button>
                          <div
                              className={`font-bold text-[12px] mt-4 ${
                                imageError
                                    ? "text-red-400"
                                    : "text-gray-900"
                                }`}
                            >
                                Upload your group profile logo
                          </div>
                          </div>
                        </div>
                      )}
                </ReactImageUploading>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 lg:gap-6 mb-2 md:mb-6">
            <Input
              name="website_url"
              label="Group Website URL"
              type="text"
              value={websiteUrl}
              placeholder="Enter Group Website URL"
              onChange={(e) => setWebsiteUrl(e.target.value)}
              darmo={true}
            />

            <div>
            <Input
              name="groupUrl"
              label="Group URL"
              type="text"
              value={groupUrl}
              slug={apiUrl}
              placeholder="groupName"
              onChange={(e) => setGroupUrl(e.target.value)}
              darmo={true}
            />
            {groupUrlError && (
              <div className="text-red-400 text-xs font-bold mt-2">Group URL already exists</div>
            )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 lg:gap-6 mb-2 md:mb-6">
            <div>
            <TextArea
              className="my-3"
              name="groupDescription"
              label="Description"
              value={description}
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
              darmo={"true"}
            />
            </div>
            
            <div className="flex flex-col">
            <p className="block font-medium title text-warmgray-900 mb-2">Select Company</p>
              <MultiSelector
                isSearchable = {true}
                isMultiSelect={true}
                selectedValues={companySelected.length > 0 ? companySelected : false}
                dropDownData={companies}
                refresh={refreshCompany}
                callBackHandler={clickCompanyHandler}
                placeholder="Select Company"
                disabled={""}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 lg:gap-6 mb-2 md:mb-6">
            <div className="flex flex-col">
            <p className="block font-medium title text-warmgray-900 mb-2">Select Brands</p>
              <MultiSelector
                isSearchable = {true}
                isMultiSelect={true}
                selectedValues={brandSelected}
                dropDownData={filteredBrands}
                refresh={refreshBrand}
                callBackHandler={clickBrandHandler}
                placeholder="Select Brands"
                disabled={""}
              />
            </div>

            <div>
            <p className="block font-medium title text-warmgray-900 mb-2">Select Locations</p>
              <MultiSelector
               isSearchable = {true}
               isMultiSelect={true}
               selectedValues={locationSelected}
               dropDownData={filteredLocations}
               refresh={refreshLocation}
               callBackHandler={clickLocationHandler}
               placeholder="Select Locations"
               disabled={""}
             />
            </div>
          </div>

          <div className="flex justify-center">
            <button
                className="font-bold text-sm mr-2 px-6 py-2 rounded-xl bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                type="button"
                onClick={() => handleCreateGroup()}
                disabled={buttonLoading}
                >
                {editMode ? "Edit Group" : "Create Group"}
                {buttonLoading ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
            </button>
          </div>  
        </div>
      </div>
      )}

      {!show && ( 
      <div className="flex flex-col w-full overflow-auto lg:mx-4 mt-6">
        <div className="flex justify-end mb-4">
          <button
            className="font-bold text-sm mr-2 px-6 py-2 rounded-xl bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
            type="button"
            onClick={() => handleShowGroup(false)}
          >
              Create New Group
            </button>
        </div>
        <table className="min-w-full divide-y divide-gray-500 mb-12">
          <thead>
            <tr>
              <th className="py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">Group Name</th>
              <th className="py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">Group URL</th>
              <th className="py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">Website URL</th>
              <th className="py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">Companies</th>
              <th className="py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">Brands</th>
              <th className="py-2.5 pr-3 text-left text-lg font-bold text-gray-900 whitespace-nowrap">Locations</th>
              <th className="py-2.5 px-3 text-center text-lg font-bold text-gray-900 whitespace-nowrap"></th>
            </tr>
          </thead> 
          <tbody>
          {loading ? (
            <tr>
              <td colSpan="7" className="text-center py-4">
                Loading...
              </td>
            </tr>
            ) : (
            <>
            {dataList.map((item, index) => (
              <React.Fragment key={item.id}>
              <tr className="border-b border-gray-300">
                <td className="whitespace-nowrap py-4 pr-3 text-[16px] font-extrabold text-gray-900">{item.group_name}</td>
                <td>
                  <a
                    href={`https://heyhire.app/group/${item.group_url}`}
                    // href={`http://localhost:3000/${item.group_url}/groups/${encodeIds(item?.id)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.group_url}
                  </a>
                </td>
                <td>
                  <a
                    href={item.website_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.website_url}
                  </a>
                </td>
                <td>{JSON.parse(item.companies).map(company => company.label).join(', ')}</td>
                <td>{JSON.parse(item.brands).map(company => company.label).join(', ')}</td>
                <td>{JSON.parse(item.locations).map(company => company.label).join(', ')}</td>
                <td className="text-center">
                  <Menu
                    as="div"
                    className="relative inline-block flex-shrink-0 text-left"
                  >
                    <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open options menu</span>
                      <span className="flex h-full w-full items-center justify-center rounded-full">
                        <EllipsisVerticalIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </span>
                    </Menu.Button>
                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-9 top-0 z-10 w-40 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div>
                        <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {handleEditGroup(item?.id)}}
                            className={`${
                            active ? "bg-warmgray-200 text-black" : "text-black"
                            } block px-4 py-2 text-sm cursor-pointer`}
                          >
                            Edit
                          </div>
                        )}
                        </Menu.Item>
                        <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              deleteGroup(item?.id);
                            }}
                            className={`${
                            active ? "bg-warmgray-200 text-black" : "text-black"
                            } block px-4 py-2 text-sm cursor-pointer`}
                          >
                            Delete
                          </div>
                        )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                </td>
              </tr>
              </React.Fragment>
            ))}
            </>
          )}
          </tbody>
        </table>
      </div>
      )}
    </div>
  );
};

export default Groups;