import axios from "./api";

const base = "/business/draft";

const Draft = {
  getAllByType(type) {
    return axios.get(`${base}?type=${type}`);
  },
  add(draft) {
    return axios.post(base, draft);
  },
  deleteDraft(id) {
    return axios.delete(`${base}/${id}`);
  },
};

export default Draft;
