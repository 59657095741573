import axios from "./api";

const base = "/business/location";

const Location = {
  getAll(query=null) {
    return axios.get(`${base}?${query ? query : ""}`);
  },
  getById(id) {
    return axios.get(`${base}/${id}`);
  },
  add(location) {
    return axios.post(base, location);
  },
  update(id, location) {
    return axios.put(base + `/${id}`, location);
  },
  delete(id) {
    return axios.delete(base + "/" + id);
  },
  locationBlockStatus() {
    return axios.get(`business/location-status`);
  },
  getLocationStats(id) {
    return axios.get(base + `/stats/${id}`);
  },
};

export default Location;