import { object, string, ref } from "yup";

export const RegisterStep1InitialValues = {
  // businessName: "",
  firstName: "",
  lastName: "",
  // email: "",
  phone: "",
  password: "",
  cpassword: "",
  hearAboutUs: "",
  others: "",
};

export const RegisterStep0InitialValues = {
  businessName: "",
  email: "",
};

export const RegisterStep0Schema = object().shape({
  businessName: string().required("Business Name is required").test("is-not-empty", "Business Name cannot be empty", (value) => value?.trim()?.length > 0),
  email: string().email("Invalid email address").required("E-mail is required"),
});

export const RegisterStep1Schema = object().shape({
  // businessName: string().required("Business Name is required."),
  firstName: string().required("First Name is required.").test("is-not-empty", "First Name cannot be empty", (value) => value?.trim()?.length > 0),
  lastName: string().required("Last Name is required.").test("is-not-empty", "Last Name cannot be empty", (value) => value?.trim()?.length > 0),
  // email: string()
  //   .email("Invalid email address")
  //   .required("E-mail is required."),
  phone: string().required("Phone is required."),
  password: string()
  .test("min-length", "Minimum 8 characters required", (value) => value && value?.length >= 8)
  .required("Password is required."),
  cpassword: string()
    .required("Please retype your password.")
    .oneOf([ref("password"), null], "Your passwords do not match."),
  hearAboutUs: string().required("This field is required."),

  others: string().when("hearAboutUs", {
    is: "Others",
    then: string().required("This field is required."),
    otherwise: string().notRequired(),
  }),
});

export const RegisterStep2InitialValues = {
  businessAddress: "",
  locationName: "",
  customUrl: "",
  businessCategory: "",
  businessDescription: "",
};

export const RegisterGoStep2InitialValues = {
  businessAddress: "",
  locationName: "",
  customUrl: "",
  websiteUrl: "",
  businessCategory: "",
  businessDescription: "",

  jobTitle: "",
  jobAvailability: "",
  jobDescription: "",
  jobExperience: "",
  jobCertificate: "",
  jobWage:"",
};

export const RegisterGoStep2Schema = object().shape({
  businessAddress: string().required("Business Address is required."),
  locationName: string(),
  customUrl: string().required("Custom url is required.").test("is-not-empty", "Custom url cannot be empty", (value) => value?.trim()?.length > 0),
  websiteUrl: string().required("Website url is required.").test("is-not-empty", "Website url cannot be empty", (value) => value?.trim()?.length > 0),
  businessCategory: string().required("Business Category is required."),
  businessDescription: string().required("Business Description is required."),

  jobTitle: string().required("Job title is required").test("is-not-empty", "Job title cannot be empty", (value) => value?.trim()?.length > 0),
  jobAvailability: string().required("Availablity is required"),
  jobDescription: string().required("Description is required").test("is-not-empty", "Description cannot be empty", (value) => value?.trim()?.length > 0),
  jobExperience: string().required("Experience is required").test("is-not-empty", "Experience cannot be empty", (value) => value?.trim()?.length > 0),
  jobCertificate: string().required("Certificate is required"),
  jobWage: string(),
});

export const RegisterStep2Schema = object().shape({
  businessAddress: string().required("Business Address is required."),
  locationName: string(),
  customUrl: string().required("Custom url is required.").test("is-not-empty", "Custom url cannot be empty", (value) => value?.trim()?.length > 0),
  businessCategory: string().required("Business Category is required."),
  businessDescription: string().required("Business Description is required.").test("is-not-empty", "Business Description cannot be empty", (value) => value?.trim()?.length > 0),
});

export const RegisterStep4InitialValues = {
  jobTitle: "",
  start_date: "",
  availability: "",
  description: "",
  experience: "",
  // certificate: "",
};

export const RegisterStep4Schema = object().shape({
  title: string().required("Job title is required").test("is-not-empty", "Job title cannot be empty", (value) => value?.trim()?.length > 0),
  start_date: string().required("Start Date is required"),
  availability: string().required("Availablity is required"),
  description: string().required("Description is required").test("is-not-empty", "Description cannot be empty", (value) => value?.trim()?.length > 0),
  experience: string().required("Experience is required").test("is-not-empty", "Experience cannot be empty", (value) => value?.trim()?.length > 0),
  // certificate: string().required("Certificate is required"),
});

const RegistrationPayload = {
  business_name: "",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  password: "",
  hearAboutUs: "",
  others: "",
};

const BusinessPayload = {
  business_address: "",
  custom_url: "",
  business_category: "",
  business_description: "",
  design_poster_skip: false,
};

const FirstJobOpening = {
  job_title: "",
  start_date: "",
  position_type: "",
  description: "",
  required_experience: "",
  required_certificate: "",
};
