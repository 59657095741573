import React, { useState } from "react";
import { Collapse } from "antd";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import "./OpenPositionCard.scss";
import dayjs from "dayjs";
import { DateFormat } from "../../../utils";

const MIN_LENGTH = 130;
const MAX_LENGTH = 250;
const { Panel } = Collapse;

const OpenPositionCard = ({ position }) => {
  const [openKeys, setOpenKeys] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const renderToggler = () => (
    <div className={`flex flex-row justify-between`}>
      <div
        className={
          "font-bold text-xl " +
          (openKeys && openKeys.includes("1") && "text-white lg:text-black")
        }
      >
        {position?.title || position?.position}
      </div>
      <div className="font-light lg:block">
        <span
          className={
            " " +
            (openKeys &&
              openKeys.includes("1") &&
              " font-semibold text-white lg:text-gray-400")
          }
        >
          <i className="far fa-calendar mr-2" />{" "}
          {dayjs(position?.start_date).format(DateFormat) || "08/05/2021"}
        </span>
      </div>
    </div>
  );
  const renderContent = () => (
    <div className={"flex flex-col lg:flex-row items-center justify-between"}>
      <span className="lg:block text-sm mb-1">
        {position.description.length < (showMore ? MAX_LENGTH : MIN_LENGTH)
          ? position.description
          : position.description.substring(
              0,
              showMore ? MAX_LENGTH : MIN_LENGTH
            ) + "..."}
      </span>
      <div className="font-light lg:hidden w-full ml-56">
        <div className="w-full">
          <i className="far fa-clock mr-2 text-gray-400" />{" "}
          {position?.availability || "Part Time, Full Time"}
        </div>
        <div className="w-full">
          <i className="far fa-money-bill-wave mr-2 text-gray-400" /> $10 p/hr
        </div>
        {/* <div className="w-full mt-3">
          <i className="far fa-calendar mr-2 text-gray-400" /> 08/05/2021
        </div> */}
      </div>
      <div className="flex flex-col min-w-fit mt-4 lg:mt-0">
        <div className="flex-row lg:flex-row hidden lg:block">
          <button
            className={
              "font-blacklg:flex lg:ml-4 py-1.5 px-3 rounded border-2 box-border border-orange-400 hover:bg-orange-400 hover:text-primary h-min " +
              (showMore
                ? "bg-orange-400 text-primary-500"
                : "text-primary-400 bg-white")
            }
            onClick={() => setShowMore(!showMore)}
          >
            View Details
          </button>
          <button className="font-black ml-4 py-2 px-3 rounded bg-primary-500 text-white hover:bg-primary-400 h-min">
            Apply Now
          </button>
        </div>
        <button className="font-black lg:hidden ml-4 py-2 px-3 rounded bg-primary-500 text-white hover:bg-primary-400 h-min">
          Apply Now
        </button>
        {renderTimer()}
      </div>
    </div>
  );
  const renderTimer = () => (
    <div className="font-light hidden lg:block lg:flex-row mt-1">
      <span className={"ml-14"}>
        <i className="far fa-clock mr-2 text-gray-400" />{" "}
        {position?.availability || "Part Time, Full Time"}
      </span>
      <br />
      <span className={"ml-14"}>
        <i className="far fa-money-bill-wave mr-2 text-gray-400" /> $10 p/hr
      </span>
    </div>
  );
  return (
    <div className="bg-white shadow-lg rounded-xl">
      <div className="hidden lg:block py-4 px-6">
        {renderToggler()}
        {renderContent()}
        {/* {renderTimer()} */}
      </div>
      <Collapse
        className="open-position-card-accordion lg:hidden bg-white"
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) =>
          isActive ? (
            <FiChevronDown size={24} color="#FFFFFF" />
          ) : (
            <FiChevronRight size={24} color="#594A9E" />
          )
        }
        onChange={setOpenKeys}
      >
        <Panel header={renderToggler()} key="1">
          {renderContent()}
        </Panel>
      </Collapse>
    </div>
  );
};

export default OpenPositionCard;
