import React from "react";

import "./Toggle.css";

const Toggle = ({ disabled = false, value, onChange }) => {
  return (
    <label className="switch">
      <input
        defaultChecked={value}
        checked={value}
        type="checkbox"
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      <span
        className={
          (value ? "bg-primary-200 " : " ") +
          "slider round border-2 rounded-full " +
          (disabled
            ? " border-primary-200 before:bg-primary-200"
            : "border-primary-500 before:bg-primary-500")
        }
      />
    </label>
  );
};

export default Toggle;
