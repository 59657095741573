import React from "react";
import { DashboardLayout } from "./components/organisms/Layout";

const Unauthorized = () => {
    return(
    <DashboardLayout darmo={true}>
     <div className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          {/* <p className="text-2xl font-semibold text-indigo-600">403</p> */}
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Access Denied</h1>
          <p className="mt-3 text-base leading-7 text-gray-600">Sorry, you don't have access to view this page.</p>
          {/* <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/login"
              className="rounded-md bg-primary-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-300 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Login again
            </a>
          </div> */}
        </div>
     </div>
     </DashboardLayout>
    )
}

export default Unauthorized;