import axios from "./api";

const Alert = {
  getById(id) {
    return axios.get(`/business/show-alert/${id}`);
  },
  add(data) {
    return axios.post(`/business/create-alert`, data);
  },
  addBroadcast(data) {
    return axios.post(`/business/broadcast-alert`, data);
  },
  update(id, data) {
    return axios.put(`/business/update-alert/${id}`, data);
  },
  delete(id) {
    return axios.delete(`/business/delete-alert/${id}`);
  },
};

export default Alert;
