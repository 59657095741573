import axios from "./api";

const base = "/admin/rbac/feature";
const permissionBase = "/admin/feature-permissions";
const locationBase = "/admin/feature-company-location";
const roleslocationBase = "/business/attach-location-role";
const rolesUserBase = "/business/attach-user-role";
const roleBase = "/admin/rbac/roles";

const RBAC = {
  getAll() {
    return axios.get(base);
  },
  getLocationPlan() {
    return axios.get(`/business/location-plan`);
  },
  getAttachedRolesForLocationBusiness(id) {
    return axios.get(`/business/show-location-role/${id}`);
  },
  getAttachedRolesForUser(id) {
    return axios.get(`/business/show-user-role/${id}`);
  },
  deleteRoleForLocation(location_id, role_id) {
    return axios.delete(
      `/admin/delete-location-role/${location_id}?role_id=${role_id}`
    );
  },
  deleteRoleForUser(user_id, role_id) {
    return axios.delete(
      `/admin/delete-user-role/${user_id}?role_id=${role_id}`
    );
  },
  getByCompanyId(id) {
    return axios.get(`/admin/feature-permissions-company/${id}`);
  },
  getByLocationId(id) {
    return axios.get(`/admin/feature-permissions-location/${id}`);
  },
  add(body) {
    return axios.post(base, body);
  },
  update(id, body) {
    return axios.put(`${base}/${id}`, body);
  },
  addPermissions(body) {
    return axios.post(permissionBase, body);
  },
  addPermissionsRolesLocation(body) {
    return axios.post(roleslocationBase, body);
  },
  addPermissionsRolesUser(body) {
    return axios.post(rolesUserBase, body);
  },
  addPermissionsForLocation(body) {
    return axios.post(locationBase, body);
  },
  addRole(body) {
    return axios.post(roleBase, body);
  },
  getRolesList() {
    return axios.get(roleBase);
  },
  getRoleById(id, body) {
    return axios.put(`${roleBase}/${id}`, body);
  },
  checkPermissionRBAC(body) {
    if (!body.location_id) {
      return false;
    }
    return axios.post(`/business/permissions`, body);
  },
  getAllUsers() {
    return axios.get(`/admin/user-all`);
  },
};

export default RBAC;
