import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBackgroundcolor } from "../../../store/authSlice";

const ThemeSwitcher = ({ theme, setTheme }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const root = window.document.documentElement;

    if (theme === "device") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "dark"
        : "light";
      root.classList.toggle("dark", systemTheme === "dark");
      dispatch(setBackgroundcolor(systemTheme === "light"));
    } else {
      root.classList.toggle("dark", theme === "dark");
      dispatch(setBackgroundcolor(theme === "light"));
    }

    localStorage.setItem("theme", theme);
  }, [theme, dispatch]);

  const getCurrentIcon = () => {
    switch (theme) {
      case "light":
        return <LightIcon />;
      case "dark":
        return <DarkIcon />;
      case "device":
        return <DeviceIcon />;
      default:
        return null;
    }
  };

  const handleClickOutside = (event) => {
    if (isOpen && !event.target.closest(".theme-dropdown")) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  return (
    <div className="relative theme-dropdown">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-32 px-4 py-2 text-sm font-medium text-gray-900 bg-gray-300 border border-darkGray rounded-md shadow-sm hover:bg-warmgray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-300 focus:ring-blue-500 dark:bg-[#1c1e21] dark:text-gray-200 dark:border-[#3a3b3c] dark:hover:bg-[#2c2d2f]"
      >
        <span className="flex items-center">
          {getCurrentIcon()}
          <span className="ml-2">
            {theme === "device"
              ? "System"
              : theme.charAt(0).toUpperCase() + theme.slice(1)}
          </span>
        </span>
        <svg
          className="w-5 h-5 ml-2 -mr-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 w-40 mt-2 origin-top-right bg-gray-200 dark:bg-[#1c1e21] border border-gray-700 dark:border-[#3a3b3c] rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {["light", "dark", "device"].map((option) => (
              <button
                key={option}
                onClick={() => {
                  setTheme(option);
                  setIsOpen(false);
                }}
                className={`${
                  theme === option ? "bg-warmgray-200 dark:bg-[#2c2d2f]" : ""
                } flex items-center w-full px-4 py-2 text-sm text-gray-600 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-[#2c2d2f]`}
              >
                {option === "light" && (
                  <div className="mr-1">
                    <LightIcon />
                  </div>
                )}
                {option === "dark" && (
                  <div className="mr-1">
                    <DarkIcon />
                  </div>
                )}
                {option === "device" && (
                  <div className="mr-1">
                    <DeviceIcon />
                  </div>
                )}
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const LightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
  >
    <circle cx="12" cy="12" r="5" />
    <line x1="12" y1="1" x2="12" y2="3" />
    <line x1="12" y1="21" x2="12" y2="23" />
    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
    <line x1="1" y1="12" x2="3" y2="12" />
    <line x1="21" y1="12" x2="23" y2="12" />
    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
  </svg>
);

const DarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
  </svg>
);

const DeviceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
  >
    <rect x="2" y="3" width="20" height="14" rx="2" ry="2" />
    <line x1="8" y1="21" x2="16" y2="21" />
    <line x1="12" y1="17" x2="12" y2="21" />
  </svg>
);

export default ThemeSwitcher;
