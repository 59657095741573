import React from "react";
import { useTranslation } from "react-i18next";
import FormSelect from "../atoms/FormSelect";
import FormInput from "../atoms/FormInput";
import { Col, Row } from "antd";
import FormTextarea from "../atoms/FormTextarea";

const AddEditPredefinedJob = ({ job, type = "predefined-job" }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[28, 20]}>
      <Col xs={24} md={12}>
        <FormInput
          name="title"
          label={t("pre-defined-jobs.job-title")}
          placeholder={t("pre-defined-jobs.job-title")}
        />
      </Col>
      <Col xs={24} md={12}>
        <FormInput
          name="start_date"
          label={t("pre-defined-jobs.start-date")}
          placeholder={t("pre-defined-jobs.start-date")}
        />
      </Col>
      <Col span={24}>
        <FormSelect
          name="position"
          label={t("pre-defined-jobs.type-of-position")}
          placeholder=""
          options={[
            { value: "full-time", label: "Full Time" },
            { value: "part-time", label: "Part Time" },
          ]}
        />
      </Col>
      <Col span={24}>
        <FormTextarea
          name="description"
          label={t("pre-defined-jobs.description")}
          placeholder={t("pre-defined-jobs.description")}
        />
      </Col>
      <Col span={24}>
        <FormTextarea
          name="experience"
          label={t("pre-defined-jobs.required-experience")}
          placeholder={t("pre-defined-jobs.required-experience")}
        />
      </Col>
      <Col span={24}>
        <FormInput
          name="certifications"
          label={t("pre-defined-jobs.required-certifications")}
          placeholder={t("pre-defined-jobs.required-certifications")}
        />
      </Col>
    </Row>
  );
};

export default AddEditPredefinedJob;
