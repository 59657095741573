import React, { useEffect, useRef } from "react";
import "./ColorSelect.scss";
import { SketchPicker } from "react-color";
import "rc-color-picker/assets/index.css";

const ColorSelect = ({
  label,
  defaultValue,
  value,
  onClickDisplay,
  display,
  onChange,
  inputRef,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickDisplay && onClickDisplay();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickDisplay]);

  const calculatePosition = () => {
    if (!inputRef || !inputRef.current || !ref.current) return;

    const inputRect = inputRef.current.getBoundingClientRect();
    ref.current.style.top = `${inputRect.bottom}px`;
    ref.current.style.left = `${inputRect.left}px`;
  };

  useEffect(() => {
    calculatePosition();
  }, [display, inputRef]);

  return (
    <div className="flex flex-col relative">
      <span className="block mb-2 font-bold title text-warmgray-900 dark:text-gray-300">
        {label}
      </span>
      <input
        type="button"
        className="color-select color-select-body w-full cursor-pointer"
        style={{ backgroundColor: value }}
        onClick={onClickDisplay}
        ref={inputRef}
      />
      {display && (
        <div ref={ref} className="color-picker-overlay">
          <SketchPicker color={defaultValue || "#36c"} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

export default ColorSelect;
