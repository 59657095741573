import axios from "./api";

const base = "/business/category";

const Category = {
  getAll() {
    return axios.get(base);
  },
};

export default Category;
