import React from "react";

const Select = ({
  label,
  name,
  children,
  options,
  onChange,
  className,
  innerClassName,
  value,
  error,
  disabled = false,
  slug = false,
}) => {
  return (
    <div className={className}>
      <label className="block font-bold title text-warmgray-900">{label}</label>
      <div
        className={
          (slug
            ? "flex flex-row border border-l-0 rounded-sm w-full focus:outline-none mt-3"
            : "flex flex-row border w-full focus:outline-none focus-within:border-violet-400") +
          (error ? "border-red-500" : "") +
          (disabled ? " bg-gray-50" : "") +
          (" " + innerClassName)
        }
      >
        {slug ? (
          <div className="bg-warmgray-200 p-3">
            <span className="self-center">{slug}</span>
          </div>
        ) : (
          ""
        )}
        <select
          name={name}
          value={value}
          className={
            "w-full focus:outline-none flex border border-warmgray-300 rounded-md " +
            (disabled ? " bg-gray-50" : "")
          }
          disabled={disabled}
          onChange={onChange}
        >
          {children}
        </select>
      </div>
      {error ? (
        <span className="block text-red-500">
          <small>{error}</small>
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Select;
