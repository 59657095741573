import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// import Input from "../form/Input";
import Payment from "../../api/Payment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import HeyHireLogo from "../../assets/images/powered-by.svg";
import Cards from "../../api/Cards";

const stripePromise = loadStripe("pk_live_rWHf1KRxe5AUUwJCPsoHJEuN");

const AddCardModal = ({ onSubmit, data, onChange }) => {
  const [options, setOptions] = useState({});

  useEffect(() => {
    getSecret();
  }, []);

  function getSecret() {
    Cards.getToken()
      .then((response) => {
        setOptions({
          clientSecret: response.data.data,
        });
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something Went Wrong");
        }
      });
  }

  return (
    <div className="w-full">
      {data && (
        <>
          <div className="flex justify-center py-8 mt-auto">
            <img
              className="mx-auto md:w-[160px] lg:w-[290px] lg:mt-[35.67px]"
              src={HeyHireLogo}
              alt="heyhire"
            />
          </div>
          <h2 className="text-primary-500 border-b-primary-500 border-b-2 w-fit mb-10">
            ADD YOUR FIRST PAYMENT METHOD!
          </h2>
        </>
      )}
      <div className="text-left snap-start px-8 pt-6 pb-8 mb-4 w-full">
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            data={data}
            onChange={onChange}
            isCancel={true}
            isNote={false}
            isSkip={false}
            isRegister={false}
          />
        </Elements>
      </div>
    </div>
  );
};

export default AddCardModal;
