
export const CheckPermission = (permission, permissions) => permissions.includes(permission);

export const userTypesSuper = [
    { label: "Owner", value: "owner"},
    { label: "Regional Manager", value: "regional_manager"},
    // { label: "Location Manager", value: "location_manager"},
    { label: "Employee", value: "employee"},
];

export const userTypesEnterprise = [
    { label: "Regional Manager", value: "regional_manager"},
    // { label: "Location Manager", value: "location_manager"},
    { label: "Employee", value: "employee"},
];

export const userTypesNotEnterprise = [
    { label: "Owner", value: "owner"},
    { label: "Employee", value: "employee"},
];

export const regionalMangerUserAddTypes = [
    // { label: "Location Manager", value: "location_manager"},
    { label: "Employee", value: "employee"},
]
export const sAddUserTypes = [
    { label: "Sub User / Employee", value: "employee"},
]

export const AddUserTypes = {
    "super-user": userTypesSuper,
    "owner": sAddUserTypes,
    "regional_manager": regionalMangerUserAddTypes,
    "location_manager": sAddUserTypes,
    "employee": sAddUserTypes,
}