import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddressBook from "../../api/AddressBook";
import { toast } from "react-toastify";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import Input from "../form/Input";
import PlacesAutocomplete from "../form/PlacesAutocomplete";

const AddAddressModal = ({ addressProp }) => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [loading, setLoading] = useState("");
  const [validationErrors, setValidationErrors] = useState(null);

  useEffect(() => {
    if (addressProp) {
      setName(addressProp.name);
      setAddress(addressProp.address);
      setCity(addressProp.city);
      setState(addressProp.state);
      setZip(addressProp.zip);
    }
  }, [addressProp]);

  useCustomEventListener("placeSelected", (data) => {
    setAddress(data.addressLine);
    setState(data.terms[data.terms.length - 2].value);
    setCity(data.terms[data.terms.length - 3].value);
    setZip(data.zipCode);
  });

  const addAddress = () => {
    setLoading(true);
    setValidationErrors(null);

    const param = {
      name: name,
      address: address,
      city: city,
      state: state,
      zip: zip,
    };

    const request = addressProp
      ? AddressBook.update(addressProp.id, param)
      : AddressBook.add(param);

    request
      .then(() => {
        toast.success(
          addressProp
            ? t("address-book.address-updated")
            : t("address-book.address-added")
        );
        emitCustomEvent("dataChanged");
        emitCustomEvent("closeModal");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  return (
    <div className="w-full">
      <div className="text-left snap-start px-8 pt-6 pb-8 mb-4 w-full">
        <Input
          className="my-3"
          name="name"
          label={t("common.name")}
          type="text"
          value={name}
          placeholder={t("common.name")}
          error={
            validationErrors && validationErrors.name
              ? validationErrors.name[0]
              : null
          }
          onChange={(e) => setName(e.target.value)}
        />

        <PlacesAutocomplete
          label={t("common.address")}
          placeholder={t("common.address-example")}
          pValue={address}
          error={
            validationErrors && validationErrors.address
              ? validationErrors.address[0]
              : null
          }
        />

        <Input
          className="my-3"
          name="city"
          label={t("common.city")}
          type="text"
          value={city}
          placeholder={t("address-book.city-example")}
          error={
            validationErrors && validationErrors.city
              ? validationErrors.city[0]
              : null
          }
          onChange={(e) => setCity(e.target.value)}
          disabled={true}
        />

        <Input
          className="my-3"
          name="name"
          label={t("common.state")}
          type="text"
          value={state}
          placeholder={t("address-book.state-example")}
          error={
            validationErrors && validationErrors.state
              ? validationErrors.state[0]
              : null
          }
          onChange={(e) => setState(e.target.value)}
          disabled={true}
        />

        <Input
          className="my-3"
          name="zip"
          label={t("common.zip")}
          type="text"
          value={zip}
          placeholder={"90210"}
          error={
            validationErrors && validationErrors.zip
              ? validationErrors.zip[0]
              : null
          }
          onChange={(e) => setZip(e.target.value)}
          disabled={true}
        />

        <hr className="mt-8" />

        <div className="mt-8 flex">
          <button
            className="w-full rounded-xl p-4 m-1 text-lg border-2 hover:text-white hover:bg-violet-500 border-violet-500 text-violet-500"
            onClick={(e) => {
              e.preventDefault();
              emitCustomEvent("closeModal");
            }}
          >
            {t("common.cancel")}
          </button>
          <button
            className="w-full rounded-xl p-4 m-1 text-white text-lg bg-violet-500 hover:bg-violet-700 disabled:bg-violet-200"
            onClick={(e) => {
              e.preventDefault();
              addAddress();
            }}
            disabled={loading}
          >
            {t("common.save")}
            {loading ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
          </button>
        </div>

        <div className="text-center mt-5">
          <button>{t("click-here-to-save-as-draft")}</button>
        </div>
      </div>
    </div>
  );
};

export default AddAddressModal;
