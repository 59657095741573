import React from "react";
import "./EmbedCode.scss";
import { Space } from "antd";
import OpenPositionCard from "../../components/molecules/OpenPositionCard/OpenPositionCard";

const openPositions = [
  {
    position: "Busser/Porter",
    description:
      "We are seeking team players with high volume experience, great personnalities and a smile to carry on the hospitality of Juliet. ",
  },
];
const OpenPositions = () => {
  return (
    <div className="h-full flex flex-col">
      <div className="bg-warmgray-100 w-full min-h-[138px]">
        <div className="px-4 text-center flex flex-col justify-center h-full items-center py-5">
          <h4 className="mb-4">We’d love to have you join our team! </h4>
          <div className="title">
            <span className="font-bold">
              Please use the form below to apply
            </span>
            <br />
            Feel free to send in your resume even if you don't see the position
            you're looking for listed.
          </div>
        </div>
      </div>
      <div className="px-4">
        <div className="max-w-[831px] w-full mx-auto mt-8">
          <Space direction="vertical" size={8} className="w-full">
            <OpenPositionCard position={openPositions[0]} />
            <OpenPositionCard position={openPositions[0]} />
            <OpenPositionCard position={openPositions[0]} />
            <OpenPositionCard position={openPositions[0]} />
          </Space>
        </div>
      </div>
      <div className="bg-primary-500 w-full min-h-[80px] mt-auto px-4 py-4">
        <div className="max-w-[831px] mx-auto h-full flex flex-col md:flex-row items-center justify-between">
          <div className="text-center bg-primary-500 text-white py-4 flex justify-center">
            <span className="self-center mr-2">Powered by </span>
            <img height={10} width={120} alt="logo" src="/logo/white.svg" />
          </div>
          <div className="flex justify-center">
            <img
              width={100}
              src="/images/play_store_badge.png"
              alt="Play Store"
            />
            <img
              width={100}
              className="ml-1"
              src="/images/app_store_badge.png"
              alt="App Store"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenPositions;
