import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  selectRoles,
  selectUser,
  selectPermissions,
} from "../../../../store/authSlice";
import { Fragment, useState } from "react";
import {
  Popover,
  Dialog,
  Switch,
  Transition,
  Listbox,
} from "@headlessui/react";
import {
  MagnifyingGlassCircleIcon,
  ArrowLeftOnRectangleIcon,
  Bars3BottomLeftIcon,
  BellIcon,
  BriefcaseIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CogIcon,
  DocumentMagnifyingGlassIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  UsersIcon,
  XMarkIcon,
  ArrowDownCircleIcon,
} from "@heroicons/react/24/outline";
import StackedAvatar from "../../../../components/atoms/StackedAvatar/StackedAvatar";
import FlyoutMenu from "../../../../components/atoms/FlyoutMenu/FlyoutMenu";
import Company from "../../../../api/Company";
import useQuery from "../../../../hooks/useQuery";
import { DatePicker, Pagination, Tooltip } from "antd";
import { formatDate } from "../../../../utils/dateConversion";
import DropDown from "../../../../components/atoms/Selector/DropDown";
import TurnKeyBusinesses from "../../../../api/TurnKeyBusinesses";
import ControlPanel from "../../../../api/ControlPanel";
import FileSaver, { saveAs } from "file-saver";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import { toast } from "react-toastify";
import PopupNotification from "../../../../components/atoms/Notification/PopupNotification";
import Table from "../../../../components/atoms/Tables/Table";

const Turnkey = ({ callback = false }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);
  const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] =
    useState(false);
  const { t } = useTranslation();
  const roles = useSelector(selectRoles);
  const [businessNameShowPanel, setBusinessNameShowPanel] = useState(false);
  const query = useQuery();
  const [turnkeyAccountList, setTurnkeyAccountList] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortCriteria, setSortCriteria] = useState("");
  const [pageSize, setPageSize] = useState(50);
  const [searchInput, setSearchInput] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openExportQRModal, setOpenExportQRModal] = useState(false);
  const [exportOption, setExportOption] = useState(1);
  const [cityOption, setCityOption] = useState(false);
  const [exportQROption, setExportQROption] = useState(1);
  const [allTurnkeyAccount, setAllTurnkeyAccount] = useState(false);
  const [activatedTurnkeyAccounts, setActivatedTurnkeyAccounts] =
    useState(false);
  const [notActivatedTurnkeyAccounts, setNotActivatedTurnkeyAccounts] =
    useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [turnkeyAccountId, setTurnkeyAccountId] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(false);
  const [loadingQRdownload, setLoadingQRdownload] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [zipUrl, setZipUrl] = useState("");
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [dateSelected, setDateSelected] = useState(false);
  const navigate = useNavigate();

  const sortOptions = [
    { id: 1, name: "No Sort" },
    { id: 2, name: "Most Recently Created" },
    { id: 3, name: "Most Recently Updated" },
    { id: 4, name: "Status" },
    { id: 5, name: "City" },
  ];

  const exportOptions = [
    { id: 1, name: "Export All" },
    { id: 2, name: "Export Only Activated Accounts" },
    { id: 3, name: "Export Only Not Activated Accounts" },
    { id: 4, name: "Export By City" },
    { id: 5, name: "Export By Date" },
  ];

  const exportQROptions = [
    { id: 1, name: "Export All" },
    { id: 2, name: "Austin" },
    { id: 3, name: "Houston" },
  ];

  const searchOptions = [
    { id: 1, name: "Select Criteria" },
    { id: 2, name: "Business Name" },
    { id: 3, name: "City" },
  ];

  const columns = React.useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Activation Code", accessor: "activation_code" },
      { Header: "Turnkey Company Name", accessor: "turnKeyCompanyName" },
      { Header: "Status", accessor: "status" },
      { Header: "Activation Link", accessor: "link" },
    ],
    []
  );

  const QRURL =
    "https://api.qrserver.com/v1/create-qr-code/?size=600x600&&color=000000&data=" +
    process.env.REACT_APP_URL +
    "/easy-login/";

  let link = process.env.REACT_APP_URL + "/easy-login/";

  useEffect(() => {
    getAllTurnkeyBusiness();
    callback("Turnkey");
  }, []);

  useEffect(() => {
    if (isDownloaded) {
      flushFile();
    }
  }, [isDownloaded, zipUrl]);

  useEffect(() => {
    if (allTurnkeyAccount) {
      exportAll(allTurnkeyAccount);
      setAllTurnkeyAccount(false);
      setCities([]);
      setCityOption(false);
    } else if (activatedTurnkeyAccounts) {
      exportAll(activatedTurnkeyAccounts);
      setActivatedTurnkeyAccounts(false);
    } else if (notActivatedTurnkeyAccounts) {
      exportAll(notActivatedTurnkeyAccounts);
      setNotActivatedTurnkeyAccounts(false);
    }
  }, [
    allTurnkeyAccount,
    activatedTurnkeyAccounts,
    notActivatedTurnkeyAccounts,
  ]);

  const flushFile = () => {
    const formData = new FormData();
    formData.append("file_path", zipUrl);
    ControlPanel.deleteStorage(formData);
  };
  const getAllTurnkeyBusiness = (
    pageNumber = 1,
    pageSize = 50,
    filter = "",
    sortCriterion = "",
    isExport = false
  ) => {
    const page = pageNumber;
    const limit = pageSize;
    let queryData = "";

    if (isExport === 1) {
      queryData = "";
    } else if (isExport === 2) {
      queryData = `filter[payload->status]=2`;
    } else if (isExport === 3) {
      queryData = `filter[payload->status]=not:2&filter[payload->status]=null`;
    } else if (isExport === 4) {
      queryData = `filter[payload->turnKeyCity]=` + cityOption + ``;
    } else if (isExport === 5) {
      queryData = `filter[created_at]=` + startDate + ``;
    } else {
      if (searchCriteria === 3) {
        queryData = `filter[payload->turnKeyCity]=${filter}&sort=${sortCriterion}&page=${page}&limit=${limit}`;
      } else {
        queryData = `filter[payload->turnKeyCompanyName]=${filter}&sort=${sortCriterion}&page=${page}&limit=${limit}`;
      }
    }

    ControlPanel.getAllTurnkeyAccount(queryData)
      .then((response) => {
        if (isExport === 1 || isExport === 4 || isExport === 5) {
          setAllTurnkeyAccount(response.data.data);
        } else if (isExport === 2) {
          setActivatedTurnkeyAccounts(response.data.data);
        } else if (isExport === 3) {
          setNotActivatedTurnkeyAccounts(response.data.data);
        } else {
          setTurnkeyAccountList(response.data.data);
          setTotalRecords(response.data.total);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          console.log("error");
        } else {
        }
      });
  };

  const exportQR = (filter = "", id = "") => {
    let queryData = "";

    if (id === 4) {
      queryData = `filter[created_at]=${filter}`;
    } else {
      queryData = `filter[payload->turnKeyCity]=${filter}`;
    }

    ControlPanel.exportQR(queryData)
      .then((response) => {
        const zipUrl = response.data.zipUrl;
        setZipUrl(zipUrl);
        const link = document.createElement("a");
        link.href = zipUrl;
        link.download = "qr-images.zip";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoadingQRdownload(false);

        const deleteRemaining = setTimeout(() => {
          setIsDownloaded(true);
        }, 5000);
      })
      .catch(() => {});
  };

  const getCities = () => {
    ControlPanel.getAllCities()
      .then((response) => {
        let citiesData = [];
        const responseData = response.data.data;

        citiesData = [...citiesData, { id: 0, name: "Choose" }];

        responseData.map((cityObject, index) => {
          citiesData = [
            ...citiesData,
            { id: index + 1, name: cityObject.city },
          ];
        });
        setCities(citiesData);
      })
      .catch(() => {});
  };

  const capturePageClick = (pageNumber, pageSize) => {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    getAllTurnkeyBusiness(pageNumber, pageSize, searchInput, sortCriteria);
  };

  const captureSearchInput = (searchInput) => {
    setSearchInput(searchInput);
    getAllTurnkeyBusiness(pageNumber, pageSize, searchInput, sortCriteria);
  };

  const payloadExtract = (payload, key) => {
    const payloadJSON = JSON.parse(payload);
    return payloadJSON[key];
  };

  const captureSearchCriteria = (id) => {
    if (id === 1) {
      setSearchCriteria(false);
    } else {
      setSearchCriteria(id);
    }
  };

  const captureSort = (id) => {
    let criteria = "";
    if (id === 2) {
      criteria = "-created_at";
    } else if (id === 3) {
      criteria = "-updated_at";
    } else if (id === 4) {
      criteria = "-payload->status";
    } else if (id === 5) {
      criteria = "-payload->turnKeyCity";
    }
    setSortCriteria(criteria);
    getAllTurnkeyBusiness(pageNumber, pageSize, searchInput, criteria);
  };

  const captureExportOptions = (id) => {
    if (id === 4) {
      getCities();
    } else {
      setCities([]);
    }
    setExportOption(id);
  };

  const captureCityOptions = (id) => {
    if (id === 0) {
      setCityOption(false);
    } else {
      setCityOption(cities[id].name);
    }
  };

  const captureExportQROptions = (id) => {
    setExportQROption(id);
  };

  const confirmExport = (id) => {
    setLoading(true);
    if (id === 1) {
      getAllTurnkeyBusiness(pageNumber, pageSize, searchInput, sortCriteria, 1);
    } else if (id === 2) {
      getAllTurnkeyBusiness(pageNumber, pageSize, searchInput, sortCriteria, 2);
    } else if (id === 3) {
      getAllTurnkeyBusiness(pageNumber, pageSize, searchInput, sortCriteria, 3);
    } else if (id === 4) {
      if (cityOption === false) {
        toast.error("Select City");
      } else {
        getAllTurnkeyBusiness(
          pageNumber,
          pageSize,
          searchInput,
          sortCriteria,
          4
        );
      }
    } else if (id === 5) {
      if (dateSelected === false) {
        toast.error("Select Date");
      } else {
        getAllTurnkeyBusiness(
          pageNumber,
          pageSize,
          searchInput,
          sortCriteria,
          5
        );
      }
    }
  };

  const confirmQRExport = (id) => {
    if (!loadingQRdownload) {
      setLoadingQRdownload(true);

      if (id === 1) {
        exportQR();
      } else if (id === 2) {
        exportQR("Austin");
      } else if (id === 3) {
        exportQR("Houston");
      } else if (id === 4) {
        exportQR(new Date().toUTCString(), 4);
      }
    }
  };

  const exportToCSV = () => {
    setExportOption(1);
    setOpenExportModal(true);
  };

  const exportToPNG = () => {
    setExportQROption(1);
    setOpenExportQRModal(true);
  };

  const exportAll = (data) => {
    const processedData = data.map((item) => {
      const payload = JSON.parse(item.payload);
      let name = payload.turnKeyCompanyName
        .replace(/[^\w\s]/gi, "")
        .replace(/[\n\r]+/g, "");
      return {
        id: item.id,
        activation_code: item.activation_code,
        turnKeyCompanyName: name || "",
        status: payload.status === 2 ? "Activated" : "Not Activated",
        link: link + item.activation_code,
      };
    });

    const csvContent = [
      columns.map((column) => column.Header).join(","),
      ...processedData.map((item) =>
        columns.map((column) => item[column.accessor]).join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "turnkey-accounts.csv");
    setLoading(false);
  };

  const confirmDelete = (id) => {
    TurnKeyBusinesses.deleteAccount(id)
      .then((response) => {
        if (response.status === 200) {
          setOpenDeleteConfirmModal(false);
          setShowNotification(true);
          getAllTurnkeyBusiness(
            pageNumber,
            pageSize,
            searchInput,
            sortCriteria
          );
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const handleQRDownload = (e, companyName) => {
    const url = e.target.src;
    let name = companyName.replace(/[^\w\s]/gi, "").replace(/[\n\r]+/g, "");
    FileSaver(url, name + ".jpg");
  };

  return (
    <div>
      {showNotification && (
        <PopupNotification
          type="success"
          title="Deleted Successfully!"
          description="All the records are wiped from database"
          openTrigger={showNotification}
          closeTrigger={() => setShowNotification(false)}
        />
      )}
      {openDeleteConfirmModal && (
        <ConfirmationModal
          title="Delete Account"
          description="Are you sure you want to delete this turnkey account? This action is irreversible."
          confirmText="Delete"
          openTrigger={true}
          closeTrigger={() => setOpenDeleteConfirmModal(false)}
          selectedId={turnkeyAccountId}
          confirm={confirmDelete}
        />
      )}
      {openExportModal && (
        <ConfirmationModal
          openTrigger={openExportModal}
          closeTrigger={() => setOpenExportModal(false)}
          type="success"
          title="Confirm CSV Export"
          confirmText="Export To CSV"
          height="min-h-[450px]"
          selectedId={exportOption}
          disableConfirmButton={loading}
          content={
            <div className="min-w-[300px] pt-2">
              <DropDown
                data={exportOptions}
                title="Select an option"
                captureSelection={captureExportOptions}
              />
              {cities && cities.length !== 0 && (
                <div className="pt-3">
                  <DropDown
                    data={cities}
                    title="Select City"
                    captureSelection={captureCityOptions}
                  />
                </div>
              )}

              {exportOption === 5 && (
                <div className="pt-3">
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={(date) => {
                      setDateSelected(true);
                      setStartDate(date);
                    }}
                    className="border-primary-500 shadow-none hover:border-primary-100"
                  />
                </div>
              )}

              <p className="pt-5">A .csv file will be exported</p>
            </div>
          }
          confirm={confirmExport}
        />
      )}
      {openExportQRModal && (
        <ConfirmationModal
          openTrigger={openExportQRModal}
          closeTrigger={() => setOpenExportQRModal(false)}
          type="success"
          title="Confirm QR Export"
          confirmText="Export QR Codes"
          height="h-[250px]"
          selectedId={exportQROption}
          content={
            !loadingQRdownload ? (
              <div className=" min-w-[300px] pt-2">
                <DropDown
                  data={exportQROptions}
                  title="Select an option"
                  captureSelection={captureExportQROptions}
                />
                <p className="pt-5">A .zip file will be exported</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center pl-20 pt-20">
                <div className="pb-5 text-sm font-bold">
                  Preparing Zip. Please wait.....
                </div>
                <div className="animate-spin rounded-full h-5 w-5 border-t-1 border-b-2 border-primary-700"></div>
              </div>
            )
          }
          confirm={confirmQRExport}
        />
      )}
      <div className="divide-y divide-warmgray-300">
        <div className="flex flex-col">
          <div className="flex md:flex-row flex-col float-right divide-x-8 divide-white mt-4">
            <div className="flex md:flex-row flex-col gap-3">
              <div className="w-full lg:w-80 pt-2 z-50">
                <DropDown
                  data={sortOptions}
                  title="Sort By"
                  captureSelection={captureSort}
                />
              </div>
              <div className="w-full lg:w-80 pt-2">
                <div className="z-50">
                  <DropDown
                    data={searchOptions}
                    title="Search By"
                    captureSelection={captureSearchCriteria}
                  />
                </div>
                {searchCriteria && (
                  <div className="relative z-0 mt-2 flex items-center">
                    <input
                      type="text"
                      name="search"
                      id="search"
                      className="block w-full rounded-md border-1 py-1.5 pr-14 p-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-warmgray-600 m:text-sm sm:leading-6"
                      onChange={(e) => captureSearchInput(e.target.value)}
                    />
                    <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                      <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                        <MagnifyingGlassCircleIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </kbd>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex ml-auto">
              <div className="min-w-[50px] pt-2 pl-10 float-right cursor-pointer">
                <label
                  htmlFor="search"
                  className="block text-sm font-medium leading-6 text-gray-900 pb-2"
                >
                  Export QR
                </label>
                <i
                  className="fas fa-file-image fa-2x ml-6"
                  aria-hidden="true"
                  onClick={exportToPNG}
                ></i>
              </div>
              <div className="min-w-[50px] pt-2 pl-10 float-right cursor-pointer">
                <label
                  htmlFor="search"
                  className="block text-sm font-medium leading-6 text-gray-900 pb-2"
                >
                  Export CSV
                </label>
                <i
                  className="fas fa-file-excel fa-2x ml-6"
                  aria-hidden="true"
                  onClick={exportToCSV}
                ></i>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Table
              mainHeader={"Turnkey Accounts"}
              headers={
                turnkeyAccountList && turnkeyAccountList?.length > 0
                  ? [
                      "Turnkey Name",
                      "QR",
                      "Code",
                      "Status",
                      "Action",
                      "Edit",
                      "Delete",
                    ]
                  : ["No data available"]
              }
              data={
                turnkeyAccountList &&
                turnkeyAccountList?.map((account, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1">
                      <div className="flex items-center">
                        <div className="flex flex-row ml-0">
                          <div className="pt-3">
                            <img
                              className="h-7 w-7 mr-2 rounded-full bg-gray-50"
                              src={payloadExtract(
                                account.payload,
                                "brandLogoUrl"
                              )}
                              alt=""
                            />
                          </div>
                          <div>
                            <div
                              key={index}
                              className="font-medium text-gray-900"
                            >
                              Name:{" "}
                              {payloadExtract(
                                account.payload,
                                "turnKeyCompanyName"
                              )}
                            </div>
                            <div className="text-primary-500">
                              City:{" "}
                              {payloadExtract(account.payload, "turnKeyCity")}
                            </div>
                            <div className="text-primary-500">
                              website:{" "}
                              {!payloadExtract(
                                account.payload,
                                "turnKeyWebsite"
                              )
                                ? "Not Specified"
                                : payloadExtract(
                                    account.payload,
                                    "turnKeyWebsite"
                                  )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <img
                        className="h-8 w-8 bg-gray-50"
                        src={QRURL + account.activation_code}
                        alt=""
                        onClick={(e) =>
                          handleQRDownload(
                            e,
                            payloadExtract(
                              account.payload,
                              "turnKeyCompanyName"
                            )
                          )
                        }
                      />
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {account.activation_code}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {payloadExtract(account.payload, "status") === 2 ? (
                          <span className="rounded-full px-2 text-xs font-semibold leading-5 bg-green-100 text-green-800">
                            Activated
                          </span>
                        ) : (
                          <span className="rounded-full px-2 text-xs font-semibold leading-5 bg-orange-100 text-orange-800">
                            Not Activated
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      <div
                        className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          navigator.clipboard.writeText(
                            link + account.activation_code
                          );
                          toast.success("Url copied");
                        }}
                      >
                        Activation-Link
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      <div
                        className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                        onClick={() => {
                          navigate("/settings");
                        }}
                      >
                        Edit
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      <div
                        className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setTurnkeyAccountId(account.id);
                          setOpenDeleteConfirmModal(true);
                        }}
                      >
                        Delete
                      </div>
                    </td>
                  </tr>
                ))
              }
            />
          </div>
        </div>
      </div>
      <div className="pt-5">
        <Pagination
          pageSize={pageSize}
          total={totalRecords}
          onChange={capturePageClick}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default Turnkey;
