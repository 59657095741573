import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  selectRoles,
  selectUser,
  selectPermissions,
} from "../../../../store/authSlice";
import { Fragment, useState } from "react";
import {
  Popover,
  Dialog,
  Switch,
  Transition,
  Listbox,
} from "@headlessui/react";
import {
  MagnifyingGlassCircleIcon,
  ArrowLeftOnRectangleIcon,
  Bars3BottomLeftIcon,
  BellIcon,
  BriefcaseIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CogIcon,
  DocumentMagnifyingGlassIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import StackedAvatar from "../../../../components/atoms/StackedAvatar/StackedAvatar";
import FlyoutMenu from "../../../../components/atoms/FlyoutMenu/FlyoutMenu";
import Company from "../../../../api/Company";
import useQuery from "../../../../hooks/useQuery";
import { Divider, Pagination } from "antd";
import { formatDate } from "../../../../utils/dateConversion";
import DropDown from "../../../../components/atoms/Selector/DropDown";
import ControlPanel from "../../../../api/ControlPanel";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal";
import QRCodes from "../../../../api/Qrcode";
import { Stats } from "../../../../components/atoms/Stats";
import { RiAndroidFill, RiAppleFill, RiTabletFill } from "react-icons/ri";
import { FaDesktop } from "react-icons/fa";
import Table from "../../../../components/atoms/Tables/Table";

const AnalyticsPanel = ({ callback = false }) => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL.includes("stagingapi")
    ? "staging.heyhire.app"
    : "heyhire.app";
  const [businessList, setBusinessList] = useState([]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);
  const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] =
    useState(false);
  const { t } = useTranslation();
  const roles = useSelector(selectRoles);
  const [businessNameShowPanel, setBusinessNameShowPanel] = useState(false);
  const query = useQuery();
  const [businessListPrepared, setBusinessListPrepared] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [searchInput, setSearchInput] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortCriteria, setSortCriteria] = useState("");
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [businessId, setBusinessId] = useState(false);
  const [statCards, setStatCards] = useState([]);
  const [checkboxState, setCheckboxState] = useState([]);
  const [checkboxGrayState, setCheckboxGrayState] = useState([]);

  const sortOptions = [
    { id: 1, name: "No Sort" },
    { id: 3, name: "Most Recently Created" },
  ];

  useEffect(() => {
    getAllLocations();
    stats();
    callback("Analytics");
  }, []);

  const stats = async () => {
    const fetch = await ControlPanel.analyticStats();
    const stat = fetch.data;
    let total = parseInt(stat.sum_total_scans, 10);
    let iphone = parseInt(stat.sum_iphone, 10);
    let ipad = parseInt(stat.sum_ipad, 10);
    let android = parseInt(stat.sum_android, 10);
    let others = parseInt(stat.sum_others, 10);
    let iphonePercent = Math.floor((iphone / total) * 100);
    let androidPercent = Math.floor((android / total) * 100);
    let ipadPercent = Math.floor((ipad / total) * 100);
    let othersPercent = Math.floor((others / total) * 100);

    const data = [
      {
        name: "Total Scans",
        color: "text-[#28B463]",
        count: total,
        subtext: "",
        icon: "",
      },
      {
        name: "Iphone",
        color: "text-[#F1C40F]",
        count: iphone,
        subtext: iphonePercent + "% of total scans",
        icon: (
          <RiAppleFill
            className="h-5 w-5 flex-shrink-0 self-center text-[#F1C40F]"
            aria-hidden="true"
          />
        ),
      },
      {
        name: "Android",
        color: "text-[#2E86C1]",
        count: android,
        subtext: androidPercent + "% of total scans",
        icon: (
          <RiAndroidFill
            className="h-5 w-5 flex-shrink-0 self-center text-[#2E86C1]"
            aria-hidden="true"
          />
        ),
      },
      {
        name: "Ipad",
        color: "text-[#8E44AD]",
        count: ipad,
        subtext: ipadPercent + "% of total scans",
        icon: (
          <RiTabletFill
            className="h-5 w-5 flex-shrink-0 self-center text-[#8E44AD]"
            aria-hidden="true"
          />
        ),
      },
      {
        name: "Other (Laptop/Desktops)",
        color: "text-[#000]",
        count: others,
        subtext: othersPercent + "% of total scans",
        icon: (
          <FaDesktop
            className="h-5 w-5 flex-shrink-0 self-center text-[#000]"
            aria-hidden="true"
          />
        ),
      },
    ];

    setStatCards(data);
  };

  const getAllLocations = (
    pageNumber = 1,
    pageSize = 50,
    filter = "",
    sortCriterion = "",
    isExport = false
  ) => {
    const page = pageNumber;
    const limit = pageSize;

    const queryData = `filter[name]=${filter}&sort=${sortCriterion}&page=${page}&limit=${limit}`;

    ControlPanel.getAllLocationsWithQuery(queryData)
      .then((response) => {
        setBusinessList(response.data.data);
        checkboxStatus(response.data.data);
        setTotalRecords(response.data.meta.total);
        setBusinessListPrepared(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          console.log("error");
        }
      });
  };

  const checkboxStatus = async (businesses) => {
    businesses.map((business) => {
      setCheckboxState((prevState) => [
        ...prevState,
        { key: business.id, value: business.include_analytics },
      ]);
    });
    businesses.map((business) => {
      setCheckboxGrayState((prevState) => [
        ...prevState,
        { key: business.id, value: business.disabled },
      ]);
    });
  };

  const capturePageClick = (pageNumber, pageSize) => {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    getAllLocations(pageNumber, pageSize, searchInput);
  };

  const captureSearchInput = (searchInput) => {
    setSearchInput(searchInput);
    getAllLocations(pageNumber, pageSize, searchInput);
  };

  const captureSort = (id) => {
    let criteria = "";
    if (id === 2) {
      criteria = "-is_enterprise";
    } else if (id === 3) {
      criteria = "-created_at";
    } else if (id === 4) {
      criteria = "name";
    }
    setSortCriteria(criteria);
    getAllLocations(pageNumber, pageSize, searchInput, criteria);
  };

  const confirmDelete = (id) => {
    const xx = ControlPanel.deleteBusiness(id);
    setOpenDeleteConfirmationModal(false);
    getAllLocations();
  };

  const deleteBusiness = async (id) => {
    setBusinessId(id);
    setOpenDeleteConfirmationModal(true);
  };

  const downloadQR = (uuid) => {
    fetch(
      `https://api.qrserver.com/v1/create-qr-code/?size=600x600&&color=000000&data=https://${apiUrl}/qr/${uuid}`,
      {
        method: "GET",
        headers: {},
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "qr-code.jpg");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckboxChange = async (id, e) => {
    let status = 0;

    if (e.target.checked === true) {
      status = 1;
    } else {
      status = 0;
    }

    const data = {
      location_id: id,
      status: status,
    };

    const indexToUpdate = checkboxState.findIndex((item) => item.key === id);

    const updatedCheckboxState = [...checkboxState];
    updatedCheckboxState[indexToUpdate].value = status;
    setCheckboxState(updatedCheckboxState);

    await ControlPanel.analyticsStatus(data);

    const element = document.getElementById("checkbox-alert-" + id);
    if (element) {
      element.style.opacity = "0";
      element.style.display = "block";
      element.style.opacity = "1";

      setTimeout(() => {
        element.style.display = "none";
      }, 1000);
    }
  };

  const handleGrayoutCheckboxChange = async (id, e) => {
    let status = 0;

    if (e.target.checked === true) {
      status = 1;
    } else {
      status = 0;
    }

    const data = {
      location_id: id,
      disabled: status,
    };

    const indexToUpdate = checkboxGrayState.findIndex(
      (item) => item.key === id
    );

    const updatedCheckboxState = [...checkboxGrayState];
    updatedCheckboxState[indexToUpdate].value = status;
    setCheckboxGrayState(updatedCheckboxState);

    await ControlPanel.analyticsGrayOutStatus(data);

    const element = document.getElementById("gray-checkbox-alert-" + id);
    if (element) {
      element.style.opacity = "0";
      element.style.display = "block";
      element.style.opacity = "1";

      setTimeout(() => {
        element.style.display = "none";
      }, 1000);
    }
  };

  const getCheckboxStatus = (business) => {
    const indexToUpdate = checkboxState.findIndex(
      (item) => item.key === business.id
    );
    const updatedCheckboxState = [...checkboxState];

    if (updatedCheckboxState[indexToUpdate] === undefined) {
      return business.include_analytics;
    }
    return updatedCheckboxState[indexToUpdate].value;
  };

  const getCheckboxGrayStatus = (business) => {
    const indexToUpdate = checkboxGrayState.findIndex(
      (item) => item.key === business.id
    );
    const updatedCheckboxState = [...checkboxGrayState];

    if (updatedCheckboxState[indexToUpdate] === undefined) {
      return business.disabled;
    }
    return updatedCheckboxState[indexToUpdate].value;
  };

  return (
    <div>
      {openDeleteConfirmationModal && (
        <ConfirmationModal
          openTrigger={openDeleteConfirmationModal}
          closeTrigger={() => setOpenDeleteConfirmationModal(false)}
          type="error"
          title="Confirm Action"
          confirmText="Delete"
          selectedId={businessId}
          description="Are you sure you want to delete this company. This is a permanent action."
          confirm={confirmDelete}
        />
      )}
      <div className="divide-y divide-warmgray-300">
        <div className="flex flex-col">
          <div>
            <div className="space-y-5 pt-8">
              <h3 className="text-xl font-medium leading-6 text-gray-900 pb-2">
                Analytics
              </h3>
            </div>
            <div className="pt-2">
              {statCards && <Stats header={""} data={statCards} />}
            </div>
            <Divider name="Stats" />
            <div className="flex flex-col">
              <div className="flex md:flex-row flex-col float-right gap-3 divide-white">
                <div className="w-full md:w-80 pt-2">
                  <DropDown
                    data={sortOptions}
                    title="Sort By"
                    captureSelection={captureSort}
                  />
                </div>
                <div className="w-full md:w-80 pt-2">
                  <label
                    htmlFor="search"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Quick search
                  </label>
                  <div className="relative mt-2 flex items-center">
                    <input
                      type="text"
                      name="search"
                      id="search"
                      className="block w-full rounded-md border-1 py-1.5 pr-14 p-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-warmgray-600 m:text-sm sm:leading-6"
                      onChange={(e) => captureSearchInput(e.target.value)}
                    />
                    <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                      <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                        <MagnifyingGlassCircleIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </kbd>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <Table
                  headers={
                    businessListPrepared && businessList?.length > 0
                      ? [
                          "Add Statistics",
                          "Grayout",
                          "Location",
                          "QR Code",
                          "Scans",
                          "Iphone",
                          "Ipad",
                          "Android",
                          "Laptop/Desktop",
                        ]
                      : ["No data available"]
                  }
                  data={
                    businessListPrepared &&
                    businessList?.map((business, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1">
                          <div className="flex h-6 items-left flex-row">
                            <div className="pr-1 pt-1">
                              <input
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                onChange={(e) =>
                                  handleCheckboxChange(business.id, e)
                                }
                                checked={getCheckboxStatus(business)}
                                className="h-4 w-4 rounded border-gray-400 cursor-pointer text-primary-500
                                       focus:ring-primary-500"
                              />
                            </div>
                            <div
                              className="left-10 z-100 absolute"
                              id={`checkbox-alert-${business.id}`}
                              style={{ display: "none" }}
                            >
                              <span className="inline-flex items-center gap-x-0.5 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                {getCheckboxStatus(business)
                                  ? "Added!"
                                  : "Removed!"}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                          <div className="flex h-6 items-left flex-row">
                            <div className="pr-1 pt-1">
                              <input
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                onChange={(e) =>
                                  handleGrayoutCheckboxChange(business.id, e)
                                }
                                checked={getCheckboxGrayStatus(business)}
                                className="h-4 w-4 rounded border-gray-400 cursor-pointer text-primary-500
                                       focus:ring-primary-500"
                              />
                            </div>
                            <div
                              className="left-10 z-100 absolute"
                              id={`gray-checkbox-alert-${business.id}`}
                              style={{ display: "none" }}
                            >
                              <span className="inline-flex items-center gap-x-0.5 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                {getCheckboxGrayStatus(business)
                                  ? "Added!"
                                  : "Removed!"}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                          <div className="flex items-center">
                            <div className="ml-0">
                              <div
                                key={index}
                                onClick={() => {
                                  setBusinessNameShowPanel(
                                    !businessNameShowPanel
                                  );
                                }}
                                className="font-medium text-gray-900"
                              >
                                {business.name}
                              </div>
                              <div className="text-gray-500">
                                Created At :{" "}
                                {formatDate(
                                  business.created_at,
                                  "MM-DD-YYYY HH:mm:ss"
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                          <div className="text-gray-900 cursor-pointer">
                            <img
                              width={50}
                              src={`https://api.qrserver.com/v1/create-qr-code/?size=600x600&&color=000000&data=https://${apiUrl}/qr/${business.qr?.uuid}`}
                              alt="alt"
                              onClick={() => downloadQR(business.qr?.uuid)}
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                          <div className="text-gray-900">
                            {business.analytics
                              ? business.analytics?.total_scans
                              : "_"}
                          </div>
                        </td>
                        <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                          <div className="text-gray-900">
                            {business.analytics
                              ? business.analytics?.iphone
                              : "_"}
                          </div>
                        </td>
                        <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                          <div className="text-gray-900">
                            {business.analytics
                              ? business.analytics?.ipad
                              : "_"}
                          </div>
                        </td>
                        <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                          <div className="text-gray-900">
                            {business.analytics
                              ? business.analytics?.android
                              : "_"}
                          </div>
                        </td>
                        <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                          <div className="text-gray-900">
                            {business.analytics
                              ? business.analytics?.others
                              : "_"}
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <Pagination
          pageSize={50}
          total={totalRecords}
          onChange={capturePageClick}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default AnalyticsPanel;
