import React from "react";
import "./FormInputWithSuffix.scss";
import { Form } from "antd";

const FormInputWithSuffix = ({
  name,
  label,
  type = "text",
  placeholder,
  addonBefore,
  value,
  darmo,
  setFieldValue,
  ...rest
}) => {
  const handleChange = (event) => {
    const newValue = event.target.value;
    setFieldValue(name, newValue);
  };

  return (
    <>
      <label
        className={`title font-bold text-md ${
          darmo ? "text-black" : "text-white"
        }`}
      >
        {label}
      </label>

      <div className={`flex rounded-md shadow-sm`}>
        <span
          className={`inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm ${
            darmo ? "bg-gray-200 text-gray-600" : "bg-slate-800 text-gray-300"
          }`}
        >
          {addonBefore}
        </span>
        <Form.Item
          name={name}
          rules={[{ required: true, message: "Required!" }]}
          noStyle
        >
          <input
            onChange={handleChange}
            type="text"
            name="company-website"
            id="company-website"
            className={`block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-2 ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
              darmo
                ? "bg-white text-gray-600 placeholder:text-gray-400"
                : "bg-slate-800 text-gray-300 placeholder:text-gray-300"
            }`}
            placeholder="mybusinessname"
          />
        </Form.Item>
      </div>
    </>
  );
};

export default FormInputWithSuffix;
