import React from 'react'

const  StickyBanner = ({style="info",bottom = true,content = "Content",linkText = "link"}) => {

  const bgStyle = {
    info: " from-primary-500 via-pink-500 to-[#bc74a9] text-white ",
    warning: "from-orange-100 via-orange-300 to-orange-500",
    success: "bg-green-200 text-green-800 ",
  };
  return (
    <div
      id="sticky-banner"
      tabindex="-1"
      class={`fixed ${
        bottom ? "bottom-0" : "top-0"
      } left-0 z-50 py-2 flex rounded-sm justify-between w-full 
      bg-gradient-to-r
      ${bgStyle[style]} font-bold shadow-lg`}
    >
      <div class="flex items-center mx-auto">
        <p class="flex items-center text-[13px] font-bold">
          <span class="inline-flex p-1 mr-3 rounded-full 0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
              />
            </svg>

            <span class="sr-only">Credit Card</span>
          </span>
          <span>
            {content}
            <a
              href="/payments"
              class="inline font-medium text-blue-600 underline underline-offset-2 pl-2 decoration-600  decoration-solid hover:no-underline"
            >
              {linkText}
            </a>
          </span>
        </p>
      </div>
      <div class="flex items-center">
        <button
          data-dismiss-target="#sticky-banner"
          type="button"
          class="flex-shrink-0 inline-flex justify-center items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 "
        >
          <svg
            aria-hidden="true"
            class="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Close banner</span>
        </button>
      </div>
    </div>
  );
}

export default StickyBanner;