import React from 'react'

const FormWarning = ({status = false,title,content,note}) => {
  return (
    <div>
      {status && (
        <div
          class="bg-orange-100 border-l-4 border-orange-500 text-black p-4"
          role="alert"
        >
          <p class="font-bold">{title}</p>
          <p>{content}</p>
          <p class="italic font-bold text-sm">{note}</p>
        </div>
      )}
    </div>
  );
}

export default FormWarning