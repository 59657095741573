import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { i18nextPlugin } from "translation-check";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./lang/en.json";
import de from "./lang/de.json";
import es from "./lang/es.json";

i18n
  .use(i18nextPlugin)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: en,
      de: de,
      es: es,
    },
  });

export default i18n;
