import axios from "./api";

const CannedMessage = {
  create(data) {
    return axios.post("business/canned-messages", data);
  },
  getAll() {
    return axios.get("business/canned-messages");
  },
  getById(id) {
    return axios.get(`business/canned-messages/${id}`);
  },
  delete(id) {
    return axios.delete(`business/canned-messages/${id}`);
  },
  update(id, message) {
    return axios.put(`business/canned-messages/${id}`, message);
  },
};

export default CannedMessage;
