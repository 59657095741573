import axios from "./api";

const base = "/business";

const BusinessAnalyticsDashboard = {
  getBusinessAnalytics(id) {
    return axios.get(`${base}/analytics/${id}`);
  }
};

export default BusinessAnalyticsDashboard;
