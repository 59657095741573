import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMinimizedPersons } from "../../../store/authSlice";

const ProfileRing = ({ children, isActive, person }) => {
  const [isRing, setRing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isActive) {
      setRing(true);
    }
  }, [isActive]);

  return (
    <div
      className={`h-12 w-12 rounded-full relative ${
        isRing ? "ring-4 ring-red-500" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default ProfileRing;
