import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import HButton from "../../atoms/HButton";
import "./ModalDeleteWrapper.scss";

function ModalDeleteWrapper({
  children,
  visible,
  onClose,
  color = "red",
  title,
}) {
  const { t } = useTranslation();
  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={onClose}
      closable={false}
      footer={null}
    >
      <div
        className={`align-bottom bg-white text-left overflow-hidden shadow-xl`}
      >
        <button
          className="absolute right-0 m-3 p-3 text-white text-opacity-80 hover:text-opacity-100 text-xl"
          onClick={onClose}
        >
          <i className="far fa-times" />
        </button>

        <div className={`w-full text-center py-10 bg-${color}-500`}>
          <span className="block text-xl text-white font-normal">{title}</span>
          <span className="block text-white text-[10px] mt-3">
            <p>{t("common.are-you-sure-you-want-to-delete-this-item")}</p>
            <p className="font-normal">
              {t("common.this-action-cant-be-undone")}
            </p>
          </span>
        </div>

        {children}
        <div className="flex px-6 pb-8 pt-6">
          <HButton
            variant="outlined"
            color={color}
            className="h-[40px] flex-1 text-[12px]"
          >
            {t("common.cancel")}
          </HButton>
          <HButton
            color={color}
            className="h-[40px] flex-1 ml-3 text-[12px]"
            type="submit"
          >
            {t("common.delete")}
          </HButton>
        </div>
      </div>
    </Modal>
  );
}

export default ModalDeleteWrapper;
