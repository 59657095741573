import React, { useEffect } from "react";
import { GoTerminal, GoIssueOpened } from "react-icons/go";
import { FaTools } from "react-icons/fa";
import "./EmbedCode.scss";
import { Space, Tabs } from "antd";
import { DashboardLayout } from "../../components/organisms/Layout";
import InstallCodeManually from "../../components/embed/InstallCode";
import NeedaHand from "../../components/embed/NeedAHand";

const { TabPane } = Tabs;

const tabItems = [
  {
    icon: <GoTerminal />,
    title: "Install code manually",
    subtitle: "Basic JavaScript",
    content: <InstallCodeManually />,
  },
  {
    icon: <FaTools />,
    title: "Install on your platform",
    subtitle: "Wordpress, Shopify, Wix & more",
    content: <InstallCodeManually />,
  },
  {
    icon: <GoIssueOpened />,
    title: "Need a hand?",
    subtitle: "Ask a teammate to install the code",
    content: <NeedaHand />,
  },
];
const EmbedCode = () => {
  useEffect(() => {
    document.title = "HeyHire Portal-Embedded Code";
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="page-embed-code mt-4">
        <h4 className="mb-6 text-base text-gray-900 dark:text-gray-300 font-bold">
          Embed Code to your site
        </h4>
        <Tabs
          defaultActiveKey={0}
          type="card"
          size={"large"}
          tabBarGutter={10}
          className=" dark:bg-[#242526]"
        >
          {tabItems.map((_item, _i) => (
            <TabPane
              tab={
                <TabNav
                  icon={_item.icon}
                  title={_item.title}
                  subtitle={_item.subtitle}
                />
              }
              key={_i}
            >
              <div className="dark:bg-[#242526]">{_item.content}</div>
            </TabPane>
          ))}
        </Tabs>
      </div>
    </DashboardLayout>
  );
};

export default EmbedCode;

const TabNav = ({ icon, title, subtitle }) => {
  return (
    <div className="h-16 py-4 px-6 flex justify-center">
      <Space size={17} align="center">
        <div>{icon}</div>
        <div>
          <div className="title">{title}</div>
          <div className="small text-gray-400">{subtitle}</div>
        </div>
      </Space>
    </div>
  );
};
