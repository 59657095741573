import axios from "./api";

const base = "/business/address";

const AddressBook = {
  getAll() {
    return axios.get(base);
  },
  getById(id) {
    return axios.get(`${base}/${id}`);
  },
  add(address) {
    return axios.post(base, address);
  },
  update(id, address) {
    return axios.put(base + `/${id}`, address);
  },
  delete(id) {
    return axios.delete(base + "/" + id);
  },
};

export default AddressBook;
