import axios from "./api";

const base = "/business/predefined-job";

const PredefinedJob = {
  getAll() {
    return axios.get(base);
  },
  getAllTitle() {
    return axios.get(`${base}?title_only=1`);
  },
  getByCategory(id) {
    return axios.get(`${base}?category_id=${id}`);
  },
  getById(id) {
    return axios.get(base + `/${id}`);
  },
  add(predefinedJob) {
    return axios.post(base, predefinedJob);
  },
  update(id, predefinedJob) {
    return axios.put(base + `/${id}`, predefinedJob);
  },
  delete(id) {
    return axios.delete(base + `/${id}`);
  },
};

export default PredefinedJob;
