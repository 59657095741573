import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Loading = ({
  count = 5,
  size = "4xl",
  type = "default",
  header = "Loading...",
}) => {
  return (
    <>
      {type === "round" ? (
        <i className="block text-center py-48 text-5xl text-violet-600 fad fa-circle-notch fa-spin" />
      ) : (
        <div className={`text-${size}`}>
          <p className="text-sm text-center text-gray-900 dark:text-gray-300">
            {" "}
            {header}
          </p>
          <div className="animate-pulse">
            {/* <Skeleton
              className="my-2 bg-gray-200 dark:bg-[#363739] text-gray-900 dark:text-gray-300"
              count={count}
            /> */}
            {Array.from({ length: count }).map((_, index) => (
              <div
                key={index}
                className="h-4 my-2 bg-gray-300 dark:bg-[#363739] rounded animate-pulse"
              ></div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
