import React from "react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/20/solid";

const AlertModal = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed bottom-0 rounded-md bg-yellow-50 dark:bg-[#3A3A3A] p-4 shadow-lg z-50 w-full">
      <div className="relative flex justify-center items-center">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              aria-hidden="true"
              className="h-5 w-5 text-yellow-400 dark:text-yellow-300"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800 dark:text-yellow-300">
              {data?.message}
            </p>
          </div>
        </div>
        <button
          type="button"
          onClick={onClose}
          className="absolute top-0 right-0 mx-2 inline-flex rounded-md bg-green-50 dark:bg-[#444444] px-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon
            aria-hidden="true"
            className="h-5 w-5 text-yellow-600 dark:text-yellow-400"
          />
        </button>
      </div>
    </div>
  );
};

export default AlertModal;
