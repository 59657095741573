import React from "react";
import { useTranslation } from "react-i18next";
import { DashboardLayout } from "../../components/organisms/Layout";
import HButton from "../../components/atoms/HButton";
import { Col, Form, Row } from "antd";
import FormInput from "../../components/atoms/FormInput";
import FormSelect from "../../components/atoms/FormSelect";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/authSlice";
import { defaultUserImage } from "../../assets/images";

const Profile = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const roles = ["Admin", "Super Admin", "Business", "Job Seeker"];

  return (
    <DashboardLayout darmo={true}>
      <div className="flex justify-between">
        <h2 className="font-bold text-2xl">{t("profile.user-settings")}</h2>
      </div>

      <div className="mt-8">
        <div className={"flex"}>
          <img
            width="104"
            height="104"
            src={user?.photo?.thumb_url || defaultUserImage}
            alt="avatar"
          />

          <div className="flex flex-col justify-center pl-10">
            <button
              className="h-8 border border-blue-900 hover:bg-blue-900 rounded-xl px-4 text-blue-900 hover:text-white"
              onClick={() => {}}
            >
              Change Photo
            </button>
            <button
              className="block lg:hidden mt-2 lg:mt-0 h-8 border border-red-500 hover:bg-red-500 rounded-xl px-4 text-red-500 hover:text-white"
              onClick={() => {}}
            >
              Delete Profile
            </button>
          </div>

          <div className="flex-col justify-center pl-20 hidden lg:flex">
            <p className="font-bold mb-3">Delete Account</p>

            <p className="mb-4">Once deleted, it will be gone forever.</p>

            <div>
              <button
                className="h-8 border border-red-500 hover:bg-red-500 rounded-xl px-4 text-red-500 hover:text-white"
                onClick={() => {}}
              >
                Delete Profile
              </button>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <Form
            //   onFinish={onSubmit}
            layout="vertical"
          >
            <Row gutter={[24, { xs: 16, md: 28 }]}>
              <Col span={24}>
                <h5 className="font-bold">Main Information</h5>
                <hr className="mt-1" />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <FormInput
                  name="first_name"
                  label={t("common.first-name")}
                  placeholder={t("common.first-name")}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <FormInput
                  name="last_name"
                  label={t("common.last-name")}
                  placeholder={t("common.last-name")}
                />
              </Col>
              <Col xs={24} md={12} lg={8} className="hidden lg:block"></Col>
              <Col xs={24} md={12} lg={8}>
                <FormInput
                  name="email"
                  label={t("common.email")}
                  placeholder={t("common.email")}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <FormSelect
                  name="role"
                  label={t("profile.user-role")}
                  placeholder=""
                  options={roles.map((_role) => ({
                    value: _role,
                    label: _role,
                  }))}
                />
              </Col>
              <Col xs={24} md={12} lg={8} className="hidden lg:block"></Col>
              <Col span={24}>
                <h5 className="font-bold mt-8 lg:mt-0">Address</h5>
                <hr className="mt-1" />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <FormInput
                  name="country"
                  label={t("common.country")}
                  placeholder={t("common.country")}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <FormInput
                  name="address"
                  label={t("common.address")}
                  placeholder={t("common.address-example")}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <FormInput
                  name="city"
                  label={t("common.city")}
                  placeholder={t("common.city")}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <FormInput
                  name="state"
                  label={t("common.state")}
                  placeholder={t("common.state")}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <FormInput
                  name="zip"
                  label={t("common.zip")}
                  placeholder={t("common.zip")}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div className="mt-8 flex">
        <HButton
          variant="outlined"
          className="h-[48px] text-[18px] border-blue-900 text-blue-900"
        >
          {t("common.clear")}
        </HButton>
        <HButton className="h-[48px] ml-3 text-[18px]" type="submit">
          {t("common.save")}
        </HButton>
      </div>
    </DashboardLayout>
  );
};

export default Profile;
