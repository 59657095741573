import React from "react";
import { useState } from "react";
import GoogleLocations from "../../components/google/GoogleLocations/GoogleLocations";
import AddressBook from "../../api/AddressBook";
import FloatingInputText from "../../components/form/FloatingInputText";
import FormErrorWithButton from "../../components/atoms/Notification/FormErrorWithButton";

function AddNewAddressForm({ company, triggerClose }) {
  const [name, setName] = useState([]);
  const [addressName, setAddressName] = useState([]);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [zip, setZip] = useState([]);
  const [lat, setLat] = useState([]);
  const [lng, setLng] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const addAddress = () => {
    setLoading(true);

    const param = {
      name: name,
      address: addressName,
      city: city,
      state: state,
      zip: zip,
      lat: lat,
      lng: lng,
      company_id: company,
    };

    AddressBook.add(param)
      .then((response) => {
        triggerClose(true);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          setShowError(true);
        }
      })
      .then(() => setLoading(false));
  };

  const handleLocationInput = (addressLine, location) => {
    let addressComponents = location[0].address_components;
    const locationSpecifics = location[0].geometry.location;

    setLat(locationSpecifics.lat());
    setLng(locationSpecifics.lng());

    const collectData = addressComponents.map((locationComponent) => {
      const types = locationComponent.types;

      if (types[0] === "locality") {
        setCity(locationComponent.long_name);
      }

      if (types[0] === "administrative_area_level_1") {
        setState(locationComponent.long_name);
      }

      if (types[0] === "postal_code") {
        setZip(locationComponent.long_name);
      }
    });

    const splitAddress = addressLine.split(",");
    setAddressName(splitAddress[0]);
  };

  return (
    <div className="pt-10 h-[400px]">
      <label
        htmlFor="search"
        className="block text-sm font-medium leading-6 text-gray-900 float-left"
      >
        Name
      </label>{" "}
      <FloatingInputText
        className="my-3"
        id="companyName"
        name="name"
        value={name}
        type="text"
        onChange={(e) => setName(e.target.value)}
      />
      <br></br>
      <label
        htmlFor="search"
        className="block text-sm font-medium leading-6 text-gray-900 float-left"
      >
        Address
      </label>{" "}
      <GoogleLocations
        placeholder="Search address . . ."
        getCompleteLocation={handleLocationInput}
        pValue={addressName}
        isNewAddress={false}
      />
      <div className="pt-2">
        <FormErrorWithButton
          status={showError}
          title={"Error"}
          content="Something when wrong! Please check if the name and address is not empty"
        />
      </div>
      <button
        className="inline-flex w-1/4  mt-20 justify-center rounded-xl bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700 sm:col-start-2"
        onClick={addAddress}
      >
        Add
        {loading ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
      </button>
    </div>
  );
}

export default AddNewAddressForm;
