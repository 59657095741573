import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";

import FormRegion from "../../components/molecules/FormRegion";
import Button from "../../components/form/Button";
import Region from "../../api/Region";
import { useTranslation } from "react-i18next";
import DeleteModal from "../../components/modals/DeleteModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { DashboardLayout } from "../../components/organisms/Layout";
import { FormModal } from "../../components/organisms/FormModal";
import PaginationView from "../../components/molecules/Pagination";
import SearchBar from "../../components/atoms/SearchBar";
import useQuery from "../../hooks/useQuery";
import Table from "../../components/atoms/Tables/Table";
import { addIcon } from "../../assets/images";
import IconSelector from "../../components/atoms/Selector/IconSelector";

const LocationsTable = ({ locations }) => {
  const navigate = useNavigate();

  return (
    <div className="min-w-full mt-1 mb-3">
      <p className="font-bold">
        <div className="tbl-cell">Locations</div>
      </p>
      {locations &&
        locations.map((location) => {
          return (
            <p
              key={location.id}
              className="cursor-pointer hover:bg-gray-500 hover:text-white"
              onClick={() => {
                navigate(`/companies/locations/${location.id}/edit`);
              }}
            >
              <div className="tbl-cell">{location.name}</div>
            </p>
          );
        })}
    </div>
  );
};

const ShowSingleRow = ({
  region,
  setEditRegion,
  setShowModal,
  setRegionToBeDeleted,
}) => {
  const [showLocations, setShowLocations] = useState(false);
  return (
    <>
      <tr
        key={region.id}
        className="cursor-pointer hover:bg-gray-50"
        onClick={() => setShowLocations(!showLocations)}
      >
        <td>
          <div className="tbl-cell p-2">{region.name}</div>
        </td>
        <td>
          <div className="tbl-cell mr-2">{region.company.name}</div>
        </td>
        <td>
          <div className="tbl-cell mr-2">
            <span className="flex">
              {region.owners &&
                region.owners.length > 0 &&
                `${region.owners[0].first_name} ${region.owners[0].last_name}`}
            </span>
          </div>
        </td>
        <td>
          <div className="tbl-cell mr-2">{region.locations.length}</div>
        </td>
        <td>
          <div className="tbl-cell text-center mr-2">
            <IconSelector
              menus={[
                {
                  label: "Edit",
                  action: () => {
                    setEditRegion(region);
                    setShowModal(true);
                  },
                },
                {
                  label: "Delete",
                  action: () => {
                    setRegionToBeDeleted(region);
                  },
                },
              ]}
            />
          </div>
        </td>
      </tr>
      {showLocations && (
        <div className="p-4">
          <LocationsTable locations={region?.locations} />
        </div>
      )}
    </>
  );
};

const Regions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();

  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editRegion, setEditRegion] = useState(null);
  const [metaData, setMetaData] = useState({});
  const [regionToBeDeleted, setRegionToBeDeleted] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchRegion, setSearchRegion] = useState("");
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 50);

  useEffect(() => {
    document.title = "HeyHire Portal-Regions";
    loadRegions();
  }, []);

  useCustomEventListener("regionChanged", () => {
    setShowModal(false);
    setRegionToBeDeleted(null);
    loadRegions();
  });

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    setRegionToBeDeleted(null);
  });

  const searchRegionName = (txt) => {
    setSearchRegion(txt);
    let text = txt.toLowerCase();
    if (text == "") {
      setFilteredRegions(regions);
    } else {
      let _regions = regions.filter((j) => j.name.toLowerCase().includes(text));
      setFilteredRegions(_regions);
    }
  };

  const loadRegions = () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const limit = query.get("limit") || pageLimit;
    const pageData = page ? `page=${page}` : "";
    const queryData = pageData + `&limit=${limit}`;

    Region.getAll(queryData)
      .then((response) => {
        setRegions(response.data.data);
        setFilteredRegions(response.data.data);
        setMetaData(response.data.meta);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast("Something went wrong.");
        }
      })
      .then(() => setLoading(false));
  };

  const addEditRegion = (values) => {
    const request = editRegion
      ? Region.update(editRegion.id, values)
      : Region.create(values);

    request
      .then(() => {
        toast.success(
          editRegion ? t("regions.region-updated") : t("regions.region-added")
        );
        emitCustomEvent("regionChanged");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      });
  };

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  return (
    <DashboardLayout darmo={true}>
      <FormModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        title={
          editRegion ? t("regions.edit-region") : t("regions.add-a-region")
        }
        onSubmit={addEditRegion}
      >
        <FormRegion region={editRegion} />
      </FormModal>
      {regionToBeDeleted && (
        <DeleteModal
          title="Delete Region"
          name="Region"
          deleteRequest={() => Region.deleteById(regionToBeDeleted.id)}
          onSuccess={() => emitCustomEvent("regionChanged")}
        />
      )}

      <div className="flex flex-col md:flex-row justify-between mt-6">
        <div className="w-full lg:w-3/5 xl:w-1/3">
          <SearchBar
            searchValue={searchRegion}
            placeholder="Search Region By Name"
            searchFunction={(value) => searchRegionName(value)}
          />
        </div>
      </div>

      <div className="overflow-x-auto h-full mt-3 pb-12">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Table
              mainHeader="Regions"
              headers={
                !loading && filteredRegions?.length > 0
                  ? [
                      t("regions.region-name"),
                      t("common.company-name"),
                      t("regions.owner"),
                      t("regions.no-of-locations"),
                      t("common.action"),
                    ]
                  : ["No data available"]
              }
              data={
                <>
                  {!loading &&
                    filteredRegions.map((region) => {
                      return (
                        <ShowSingleRow
                          key={region.id}
                          region={region}
                          setEditRegion={setEditRegion}
                          setShowModal={setShowModal}
                          setRegionToBeDeleted={setRegionToBeDeleted}
                        />
                      );
                    })}
                </>
              }
              buttonData={
                <button
                  onClick={() => navigate("add")}
                  className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                >
                  <div className="flex">
                    <img
                      src={addIcon}
                      alt="logo"
                      className="mr-2 my-auto w-4 h-4"
                    />
                    Add
                  </div>
                </button>
              }
            />
            <div className="flex w-full h-20 mt-2 justify-end items-center">
              {!loading && (
                <PaginationView
                  current={metaData?.current_page}
                  next={metaData?.to}
                  pageSize={metaData?.per_page}
                  total={metaData?.total}
                  onChange={handlecPageChange}
                />
              )}
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Regions;
