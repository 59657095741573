import React from "react";

const Button = ({ label, loading, children, onClick }) => {
  if (label) {
    return (
      <button
        className={`w-full rounded-full px-1.5 pt-[10px] pb-[9px] text-white text-lg font-bold bg-gradient-to-b from-primary-500 to-primary-400 hover:bg-primary-500 disabled:opacity-70 shadow-md`}
        onClick={onClick}
        disabled={loading}
      >
        {label}

        {loading ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
      </button>
    );
  }

  return (
    <button
      onClick={onClick}
      className="px-5 bg-blue-400 hover:bg-blue-500 text-white rounded-[5px]"
    >
      {children}
    </button>
  );
};

export default Button;
