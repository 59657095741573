import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  CheckBadgeIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/24/outline";

const ConfirmationModal = ({
  title = "Title",
  description = "",
  confirmText = "Ok",
  cancelText = "Cancel",
  selectedId = null,
  openTrigger = false,
  closeTrigger = false,
  confirm = false,
  showCancelButton = true,
  showConfirmButton = true,
  disableConfirmButton = false,
  type = "error",
  width = "",
  height = "",
  content = "",
}) => {
  const [open, setOpen] = useState(false);
  // const cancelButtonRef = useRef(null);

  const background = {
    success: "bg-green-600 dark:bg-green-800",
    error: "bg-red-600",
    warning: "bg-orange-600",
  };
  const hoverColor = {
    success: "bg-green-500",
    error: "bg-red-500",
    warning: "bg-orange-500",
  };

  useEffect(() => {
    setOpen(openTrigger);
  }, [openTrigger, closeTrigger]);

  const handleConfirm = () => {
    confirm(selectedId);
  };

  const handleClose = () => {
    setOpen(false);
    closeTrigger();
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-100"
        // initialFocus={closeTrigger}
        onClose={() => handleClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-60 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-[#1F1F1F] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div
                  className={`px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ${height} ${width}`}
                >
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      {type === "error" && (
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      )}
                      {type === "success" && (
                        <CheckBadgeIcon
                          className="h-6 w-6 text-green-400 mb-3"
                          aria-hidden="true"
                        />
                      )}
                      {type === "warning" && (
                        <ShieldExclamationIcon
                          className="h-6 w-6 text-orange-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>

                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                        <p>{description}</p>
                        {content}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-gray-600 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {showConfirmButton && (
                    <button
                      type="button"
                      className={`inline-flex w-full ${
                        disableConfirmButton
                          ? "pointer-events-none bg-gray-400"
                          : ""
                      } justify-center rounded-xl ${
                        background[type]
                      } px-3 py-2 text-sm font-semibold text-white shadow-sm hover:${
                        hoverColor[type]
                      } sm:ml-3 sm:w-auto`}
                      onClick={handleConfirm}
                    >
                      {confirmText}
                    </button>
                  )}
                  {showCancelButton && (
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-xl bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={closeTrigger}
                      // ref={cancelButtonRef}
                    >
                      {cancelText}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationModal;
