import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Input from "../form/Input";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setRefreshFeature } from "../../store/authSlice";
import Alert from "../../api/Alert";

export default function EditAlertModal({
  open,
  setOpen,
  editAlert,
  setEditAlert,
  setNewAlert,
}) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEditAlert = async () => {
    if (name === "" && message === "") {
      setNameError(true);
      setMessageError(true);
    } else if (name === "") {
      setNameError(true);
    } else if (message === "") {
      setMessageError(true);
    } else {
      setLoading(true);
      try {
        const payload = {
          Name: name,
          Message: message,
        };
        const response = await Alert.update(editAlert?.location_id, payload);
        if (response) {
          toast.success("Edited Successfully");
          dispatch(setRefreshFeature(true));
          setNewAlert(true);
          setEditAlert(null);
          setOpen(false);
        }
      } catch (error) {
        console.error("Error editing feature:", error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editAlert) {
      setName(editAlert?.name);
      setMessage(editAlert?.message);
    }
  }, [editAlert]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg p-8 text-center shadow-xl transition-all sm:my-8 sm:p-6 w-fit md:w-[600px] bg-white">
                <div className="mt-2">
                  <h4 className="font-bold text-primary-500 mt-4 mx-6 text-2xl">
                    Edit Alert
                  </h4>
                  <div className="text-left snap-start sm:rounded-lg sm:px-12 lg:px-6 my-4 flex flex-col gap-2">
                    <Input
                      className="my-3"
                      name="name"
                      label="Alert Name"
                      type="text"
                      value={name}
                      error={nameError && "Name is required"}
                      placeholder="Feature Name"
                      darmo={true}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <Input
                      name="message"
                      label="Alert Message"
                      type="text"
                      value={message}
                      error={messageError && "message is required"}
                      placeholder="message"
                      darmo={true}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className={`mt-5 md:mt-12 flex items-center sm:px-12 lg:px-6 mb-6 gap-2`}
                >
                  <button
                    type="button"
                    className={`flex w-full md:w-1/2 justify-center rounded-xl bg-primary-500 hover:bg-primary-400 px-6 py-2 text-sm font-bold text-white shadow-sm  transition-all hover:opacity-50`}
                    onClick={() => handleEditAlert()}
                    disabled={loading}
                  >
                    Edit
                    {loading && (
                      <i className="fad fa-circle-notch fa-spin ml-2 my-auto" />
                    )}
                  </button>
                  <button
                    type="button"
                    className={`flex w-full md:w-1/2 justify-center rounded-xl bg-primary-500 hover:bg-primary-400
                     px-6 py-2 text-sm font-bold text-white shadow-sm transition-all hover:opacity-50`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(false);
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
