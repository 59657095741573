const SearchBar = ({ searchValue, placeholder, searchFunction }) => {
  return (
    <div>
      <div className="relative mt-3 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
          <i className="fad fa-search mx-2 text-2xl text-gray-500 my-auto" />
        </div>
        <input
          className="block w-full rounded-md border-0 py-1.5 pl-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(text) => searchFunction(text.target.value)}
          placeholder={placeholder}
          value={searchValue}
          placeholderTextColor="#fff"
        />
      </div>
    </div>
  );
};

export default SearchBar;

const styles = {
  searchBarContainer: {
    backgroundColor: "#fff",
    borderWidth: 2,
    borderRadius: 10,
    borderColor: "grey",
    marginBottom: 5,
    marginTop: 10,
  },
  inputContainer: {
    width: "100%",
    paddingLeft: 10,
  },
};
