import { useState } from "react";
import ReactSelect from "react-select";

const MultiSelect = (props) => {
  const [selectInput, setSelectInput] = useState("");

  const filterOptions = (options, input) =>
    options?.filter(({ label }) =>
      label?.toLowerCase().includes(input?.toLowerCase())
    );

  const customFilterOption = ({ label }, input) =>
    label?.toLowerCase().includes(input?.toLowerCase());

  const onInputChange = (inputValue) => {
    setSelectInput(inputValue);
  };

  const handleChange = (selected) => {
    props.onChange(selected);
  };

  const customStyles = {
    option: (styles, { isSelected, isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "#DEEBFF" : null,
      color: isSelected ? "#000" : null,
    }),
  };

  return (
    <ReactSelect
      {...props}
      inputValue={selectInput}
      onInputChange={onInputChange}
      filterOption={customFilterOption}
      components={{ IndicatorSeparator: () => null }} // Remove the indicator separator
      styles={customStyles}
      isSearchable={true} // Enable search
      isClearable={true} // Allow clearing the selection
      menuPlacement={props.menuPlacement ?? "auto"}
      tabSelectsValue={false}
      hideSelectedOptions={true}
      blurInputOnSelect={true}
      isLoading={props?.isLoading}
      isDisabled={props?.disabledSelect || props?.isLoadingAttachedRoles}
    />
  );
};

export default MultiSelect;