
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import {useEffect} from "react";
import SlidePanelForm from "../../../pages/admin/AdminControlPanel/Payments/Forms/SlidePanelForm";

export default function SlideOverPanel1({
    children,
    openTrigger = false,
    closeTrigger = false,
}) {

  useEffect(() => {
    setOpen(openTrigger);
  }, openTrigger);  
  
  const closetab = () =>  {
    setOpen(false);
  } 

  const [open, setOpen] = useState(false);

  return (
    <Transition.Root show={openTrigger} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closetab}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
