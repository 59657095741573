import axios from "./api";
const baseUrl = "/business/turnkey-business";

const TurnKeyBusinesses = {
  async getAllAccounts() {
    return axios.get(`/business/turnkey-business/account/all`);
  },
  addTurnKeyAccount(data) {
    return axios.post("/business/turnkey-business", data);
  },
  updateEmail(data) {
    return axios.post("/business/turnkey-business/update-email", data);
  },
  updateStatus(activation_code) {
    return axios.get(`/business/turnkey-business/status/${activation_code}`);
  },
  updatePhoto(data) {
    return axios.post(`${baseUrl}/update-photo`, data);
  },
  addPhotoDetails(data) {
    return axios.post("/business/turnkey-business/add-photo", data);
  },
  addPositions(location_id, activation_code) {
    return axios.get(
      `/business/turnkey-business/add-positions/${location_id}?activation_code=${activation_code}`
    );
  },
  updateTurnKeyPayload(data) {
    return axios.post("/business/turnkey-business/update-payload", data);
  },
  turnkeyAccountStatus(activation_code) {
    return axios.get(
      `/business/turnkey-business/turnkey-status/${activation_code}`
    );
  },
  addCompanyId(data) {
    return axios.post("/business/turnkey-business/add-company", data);
  },
  deleteAccount(id) {
      return axios.delete(`/business/turnkey-business/${id}`);
  }
};

export default TurnKeyBusinesses;
