import React from "react";

const Table = ({ title, headers, data, buttonData, mainHeader }) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 border border-warmgray-300 dark:border-none rounded-xl shadow-sm bg-white dark:bg-[#242526]">
      {(mainHeader || title || buttonData) && (
        <div className="sm:flex sm:items-center py-4">
          <div className="sm:flex-auto">
            <div className="sm:hidden mb-4 flex justify-end">{buttonData}</div>
            <h1
              className={`${
                mainHeader ? "text-xl xl:text-2xl" : "text-lg"
              } font-bold leading-6 text-gray-900 dark:text-gray-300`}
            >
              {mainHeader ? mainHeader : title}
            </h1>
          </div>
          <div className="hidden sm:block">{buttonData}</div>
        </div>
      )}
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-warmgray-400">
              <thead>
                <tr>
                  {headers
                    ?.filter(
                      (header) =>
                        header !== "Edit" &&
                        header !== "Delete" &&
                        header !== "Action"
                    )
                    .map((header, index) => (
                      <th
                        key={index}
                        scope="col"
                        className={`pr-3 py-3.5 ${
                          header === "Action" ? "text-right" : "text-left"
                        } text-sm md:text-base font-bold text-gray-900 dark:text-gray-300 text-nowrap`}
                      >
                        {header}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-warmgray-300">{data}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
