import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import FormSelect from "../atoms/FormSelect";
import TextArea from "../form/TextArea";

export default function NewConfirmationModal({
  open,
  setOpen,
  type,
  jobPosition,
  onClick,
  applicant,
  positionTitle,
  defaultUserImage,
}) {
  const declineReasonList = [
    { value: "1", label: "Applicant doesn't fit" },
    { value: "2", label: "Decided to go with someone else." },
    { value: "3", label: "Applicant didn't respond." },
    { value: "4", label: "Other" },
  ];
  const [declineStatus, setDeclineStatus] = useState("");
  const [reason, setReason] = useState("");

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg p-8 text-center shadow-xl transition-all sm:my-8 sm:p-6 w-fit md:w-[600px] bg-white">
                <div className="mt-2">
                  <h4 className="font-bold text-primary-500 mt-4 mx-6 text-2xl">
                    {type === "delete" && `Delete ${jobPosition?.title} ?`}
                    {type === "archeive" && `Archeive ${jobPosition?.title} ?`}
                    {type === "Unarchive" &&
                      `UnArcheive ${jobPosition?.title} ?`}
                    {type === "hire" &&
                      `You’re About to Hire ${applicant?.first_name} ${applicant?.last_name} For ${positionTitle}`}
                    {type === "decline" &&
                      `You’re About to Decline ${applicant?.first_name} ${applicant?.last_name}`}
                  </h4>
                  <div className="sm:rounded-lg sm:px-12 lg:px-6 mt-2">
                    {type === "hire" && (
                      <div className="flex justify-center">
                        <img
                          src={applicant?.photo?.thumb_url || defaultUserImage}
                          alt="Profile"
                          className="object-contain rounded-full"
                          style={{ maxHeight: "120px", maxWidth: "120px" }}
                          height={120}
                          width={120}
                        />
                      </div>
                    )}
                    {type === "archeive" && (
                      <p className="text-primary-500 font-bold">
                        {jobPosition?.title} Will Move to Archived Positions.
                      </p>
                    )}
                    {type === "Unarchive" && (
                      <p className="text-primary-500 font-bold">
                        {jobPosition?.title} Will be Restored from Archived
                        Positions.
                      </p>
                    )}
                    {type === "decline" && (
                      <>
                        <FormSelect
                          declineClass="text-left my-4"
                          label={"Rejection Reason"}
                          name="decline_status"
                          onChange={(value) => {
                            setDeclineStatus(value.value);
                          }}
                          // error={
                          //   validationErrors && validationErrors.hiring_status
                          //     ? validationErrors.hiring_status[0]
                          //     : null
                          // }
                          options={declineReasonList}
                          darmo={true}
                          defaultValue={declineStatus}
                          placeholder={
                            declineReasonList[parseInt(declineStatus) - 1]
                              ?.label
                          }
                        />

                        {declineStatus === "4" && (
                          <div className="my-4">
                            <TextArea
                              className="mt-2"
                              name="reason"
                              value={reason}
                              placeholder={"Enter your reason here..."}
                              // error={
                              //   validationErrors && validationErrors.experience
                              //     ? validationErrors.experience[0]
                              //     : null
                              // }
                              onChange={(e) => setReason(e.target.value)}
                              darmo={true}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={`mt-5 ${
                    type === "hire" ? "md:mt-8" : "md:mt-12"
                  } flex flex-col items-center sm:px-12 lg:px-6 mb-6`}
                >
                  <button
                    type="button"
                    className={`flex w-full md:w-1/2 justify-center rounded-xl ${
                      type === "delete" ||
                      type === "archeive" ||
                      type === "Unarchive" ||
                      type === "decline"
                        ? "bg-red-500 hover:bg-red-400"
                        : "bg-primary-500 hover:bg-primary-400"
                    } px-6 py-2 text-sm font-bold text-white shadow-sm  transition-all hover:opacity-50`}
                    onClick={onClick}
                  >
                    {type === "delete" && "Delete"}
                    {type === "archeive" && "Archeive"}
                    {type === "Unarchive" && "Restore"}
                    {type === "hire" &&
                      `Hire ${applicant?.first_name} ${applicant?.last_name}`}
                    {type === "decline" &&
                      `Decline ${applicant?.first_name} ${applicant?.last_name}`}
                  </button>
                  <button
                    type="button"
                    className={`mt-4 flex w-full md:w-1/2 justify-center rounded-xl ${
                      type === "hire"
                        ? "bg-red-500 hover:bg-red-400"
                        : "bg-primary-500 hover:bg-primary-400"
                    } px-6 py-2 text-sm font-bold text-white shadow-sm transition-all hover:opacity-50`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
