import React, { useState, useEffect } from "react";

const TextInputWithDropdown = ({
  title = "",
  description = "",
  requirement = "Required",
  onCreate,
  isEditMode = false,
}) => {
  const [currentDescription, setCurrentDescription] = useState(description);
  const [currentRequirement, setCurrentRequirement] = useState(requirement);

  useEffect(() => {
    setCurrentDescription(description);
    setCurrentRequirement(requirement);
  }, [description, requirement]);

  const handleSave = () => {
    if (currentDescription.trim()) {
      const newQuestion = {
        title: title || `Question`,
        description: currentDescription,
        requirement: currentRequirement,
      };
      onCreate(newQuestion);
      if (!isEditMode) {
        setCurrentDescription("");
        setCurrentRequirement("Required");
      }
    }
  };

  return (
    <div className="relative">
      <div className="overflow-hidden rounded-lg border border-gray-300 dark:border-darkGray shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 dark:focus-within:border-gray-400 dark:focus-within:ring-gray-400 mt-2">
        <input
          type="text"
          name="title"
          id="title"
          className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 dark:text-gray-300 dark:bg-slate-800 focus:ring-0"
          placeholder="Title"
          value={title || `Question`}
          disabled
        />

        <textarea
          rows="2"
          name="description"
          id="description"
          className="block w-full resize-none border-0 py-0 text-gray-900 dark:text-gray-300 dark:bg-slate-800 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Type your question here..."
          value={currentDescription}
          onChange={(e) => setCurrentDescription(e.target.value)}
        ></textarea>

        <div className="p-2 flex justify-end dark:text-gray-300 dark:bg-slate-800">
          <label
            htmlFor="requirement"
            className="block text-sm leading-6 text-gray-600 dark:text-gray-300 my-auto mr-2"
          >
            Is Required?
          </label>
          <select
            id="requirement"
            name="requirement"
            className=" block rounded-md dark:bg-darkGray dark:border-none border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={currentRequirement}
            onChange={(e) => setCurrentRequirement(e.target.value)}
          >
            <option value="Required">Required</option>
            <option value="Optional">Optional</option>
          </select>
        </div>

        <div className="flex items-center justify-end space-x-3 border-t border-gray-200 dark:border-darkGray dark:bg-[#3E4042] px-2 py-2 sm:px-3">
          <div className="flex-shrink-0">
            <button
              type="button"
              className="mr-2 inline-flex items-center rounded-md bg-primary-500 dark:bg-[#2E2F30] dark:hover:bg-opacity-80 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSave}
            >
              {isEditMode ? "Save" : "Create"}
            </button>
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-gray-500 dark:bg-[#5A5A5A] dark:hover:bg-[#4A4A4A] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              onClick={() => onCreate(null)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextInputWithDropdown;
