import React from "react";
import Toggle from "./form/Toggle";

const NotificationSettingItem = ({
  title,
  description,
  value,
  onChange,
  disabled,
}) => {
  return (
    <div>
      <div className="flex justify-between">
        <div>
          <span className="block font-normal">{title}</span>
          <span className="block text-sm">{description}</span>
        </div>
        <div className="flex flex-col justify-center">
          <Toggle value={value} onChange={onChange} disabled={disabled} />
        </div>
      </div>

      <hr className="my-2" />
    </div>
  );
};

export default NotificationSettingItem;
