import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArchivedJob from "../../api/ArchivedJob";
import { toast } from "react-toastify";
import AddEditPredefinedJob from "../../components/modals/AddEditPredefinedJob";
import { useCustomEventListener } from "react-custom-events";
import DeleteModal from "../../components/modals/DeleteModal";
import { DashboardLayout } from "../../components/organisms/Layout";
import { ModalDeleteWrapper } from "../../components/organisms/ModalDeleteWrapper";
import { FormModal } from "../../components/organisms/FormModal";
import JobOpeningCard from "../../components/molecules/JobOpeningCard";
import JobOpeningCardLoading from "../../components/Loading/JobOpeningCardLoading";
import { useSelector } from "react-redux";
import { darkmode } from "../../store/authSlice";

const ArchivedJobs = () => {
  const { t } = useTranslation();
  const mode = useSelector(darkmode);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [jobToEdit, setJobToEdit] = useState(null);
  const [jobToDelete, setJobToDelete] = useState(null);

  useEffect(() => {
    document.title = "HeyHire Portal-Archived Position";
    loadArchivedJobs();
  }, []);

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    setJobToEdit(null);
    setJobToDelete(null);
  });

  useCustomEventListener("dataChanged", () => {
    loadArchivedJobs();
  });

  const unarchiveJob = async (id) => {
    setLoading(false);
    try {
      await ArchivedJob.unachiveJob(id);
      toast.success(t("pre-defined-jobs.pre-defined-job-added"));
      setLoading(true);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Validation error
      } else {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong-please-try-again"));
        }
      }
      setLoading(true);
    }
  };

  const loadArchivedJobs = () => {
    setLoading(false);
    ArchivedJob.getAll()
      .then((response) => {
        setJobs(response.data.data);
        console.log(response);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .finally(() => setLoading(true));
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6 pb-12">
        <div className="flex justify-between">
          <h2 className="font-bold text-lg lg:text-2xl text-gray-900 dark:text-gray-300">
            {t("archived-jobs.archived-jobs")}
          </h2>
        </div>

        <div className="w-full">
          {!loading ? (
            <div className="my-4 bg-[#F6F6F6] dark:bg-[#242526] py-3 px-3 rounded-2xl flex flex-col gap-3">
              <ul
                role="list"
                className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3"
              >
                {Array.from({ length: 6 }).map((_, index) => (
                  <JobOpeningCardLoading />
                ))}
              </ul>
            </div>
          ) : (
            <>
              {jobs?.length > 0 ? (
                <div className="my-4 bg-[#F6F6F6] dark:bg-[#242526] py-3 px-3 rounded-2xl flex flex-col gap-3">
                  <ul
                    role="list"
                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3"
                  >
                    {jobs?.map((jb, i) => (
                      <JobOpeningCard
                        key={i}
                        jobPosition={jb}
                        loadJobs={loadArchivedJobs}
                        darmo={mode}
                      />
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="flex flex-1 flex-col justify-center items-center mt-20">
                  <td
                    className="px-4 py-4 whitespace-nowrap text-center"
                    colSpan={12}
                  >
                    <p>No Data Available</p>
                  </td>
                </div>
              )}
            </>
          )}

          <FormModal
            visible={showModal}
            onClose={() => setShowModal(false)}
            title={
              jobToEdit
                ? t("pre-defined-jobs.edit-predefined-job")
                : t("pre-defined-jobs.add-a-predefined-job")
            }
            onSubmit={unarchiveJob}
          >
            <AddEditPredefinedJob />
          </FormModal>
          <ModalDeleteWrapper
            visible={showModalDelete}
            onClose={() => setShowModalDelete(false)}
            title={t("common.delete") + " " + t("common.address")}
          ></ModalDeleteWrapper>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ArchivedJobs;
