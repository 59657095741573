import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// import Input from "../form/Input";
import Payment from "../../../../api/Payment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../../../components/modals/CheckoutForm";
import AuthLayout from "../../../../components/organisms/Layout/Authlayout";
import Cards from "../../../../api/Cards";

const stripePromise = loadStripe("pk_live_rWHf1KRxe5AUUwJCPsoHJEuN");

const Step6 = ({ onSubmit, data }) => {
  const [options, setOptions] = useState({});
  const [cards, setCards] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [paymentTableHeader, setPaymentTableHeader] = useState([
    {
      name: "Location",
    },
    {
      name: "Brand",
    },
    {
      name: "Expiration",
    },
    {
      name: "Last 4 Digit",
    },
    {
      name: "Type",
    },
  ]);

  useEffect(() => {
    getSecret();
  }, []);

  function getSecret() {
    Cards.getToken()
      .then((response) => {
        setOptions({
          clientSecret: response.data.data,
        });
      })
      .catch((error) => {
        toast.error("Something Went Wrong");
      });
  }

  const LoadCards = async () => {
    const cards = await Cards.getCards();
    let rowLength = paymentTableHeader.length;
    const fetchData = cards.data.data;
    setCards(fetchData);
  };

  const updateCardsList = () => {
    LoadCards();
  };

  return (
    <AuthLayout restrictSwitch={true}>
      <div className="flex min-h-full flex-1 flex-col justify-center  sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full lg:max-w-lg rounded p-2 mx-2 my-8">
          <h4 className="font-normal font-newake text-[28px] text-indigo-600 border-b-primary-500 border-b w-fit mb-4">
            ADD YOUR FIRST PAYMENT METHOD!
          </h4>
          <div className="text-left snap-start pt-6 pb-8 mb-4 w-full my-4">
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                data={data}
                onChange={updateCardsList}
                isCancel={false}
                isNote={true}
                isSkip={true}
                isRegister={true}
              />
            </Elements>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Step6;
