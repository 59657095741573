import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../../components/organisms/Layout/Authlayout";
import { CheckIcon } from "@heroicons/react/20/solid";
import ProgressBar from "../../../../components/atoms/ProgressBar/ProgressBar";
import { Switch } from "@headlessui/react";
import { useSelector } from "react-redux";
import { stripeCustomerId } from "../../../../store/authSlice";
import Payment from "../../../../api/Payment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Step7 = ({ onSubmit, data }) => {
  const navigate = useNavigate();
  const heyhireLightList = [
    "3 Positions",
    "Unlimited applicants",
    "Unlimited hires",
    "Custom landing page",
    "Banner Maker",
    "App map placement",
    "Unlimited Templates",
    "2 Users",
    "Integrations (from library)",
    "Calender integration",
    "Customer success manager",
    "Complimentary banner design (annnual only)",
  ];

  const heyhireUnlimitedList = [
    "Unlimited Positions",
    "Unlimited applicants",
    "Unlimited hires",
    "Custom landing page",
    "Banner Maker",
    "App map placement",
    "Unlimited Templates",
    "Unlimited Users",
    "Integrations (custom)",
    "Calender integration",
    "Customer success manager",
    "Complimentary banner design (annnual only)",
    "Complimentary banner print + fulfilment (annual only)",
    "White glove onboarding",
  ];
  const [enabled, setEnabled] = useState(false);
  const stripeCustID = useSelector(stripeCustomerId);
  const selectedPlan = JSON.parse(localStorage.getItem("selectedPlan")) || null;

  useEffect(() => {
    if (!stripeCustID) {
      console.log(stripeCustID);
      navigate("/register");
    }
  }, [stripeCustID]);

  const handleCheckout = () => {
    const request = {
      plan_id:
        selectedPlan === "plus"
          ? enabled
            ? 3
            : 2
          : selectedPlan === "infinity" && (enabled ? 5 : 4),
      customer_stripe_id: stripeCustID,
    };
    Payment.getRedirectURL(request).then((result) => {
      window.open(result?.data?.url?.url);
    });
  };

  return (
    <AuthLayout restrictSwitch={true}>
      <ProgressBar currentStep={6} />
      <div className="flex flex-1 flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full rounded mx-4 h-full">
          <div className="w-full h-full">
            <div className="sm:mx-auto sm:w-full flex flex-col justify-center h-full">
              <div className="mb-8 sm:mx-auto sm:w-full lg:max-w-lg sm:max-w-[600px] h-full">
                <div className="flex flex-col h-full items-center justify-center sm:rounded-lg sm:px-12 lg:px-6">
                  <div className="flex">
                    <p
                      className={`text-[14px] font-bold mt-1 ${
                        enabled ? "text-gray-500" : "text-primary-500"
                      }`}
                    >
                      Billed Monthly
                    </p>
                    <Switch
                      checked={enabled}
                      onChange={setEnabled}
                      className="mx-3 bg-primary-500 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <p
                      className={`text-[14px] font-bold mt-1 ${
                        enabled ? "text-primary-500" : "text-gray-500"
                      }`}
                    >
                      Billed Yearly
                      {/* (save{" "}
                      <span className="tracking-tighter">33</span>%) */}
                    </p>
                  </div>
                  <div className="mt-4 border-2 rounded-md shadow-md shadow-primary-300">
                    <div className="m-6">
                      <div className="flex flex-col gap-2">
                        <p className="font-bold text-2xl text-primary-500">
                          {enabled ? "Annual" : "Monthly"}{" "}
                          <span className="text-black">Subscription</span>
                        </p>
                        <p className="font-bold text-2xl">
                          <span className="tracking-tighter">
                            {enabled
                              ? `${selectedPlan === "plus" ? "$99" : "$149"}`
                              : `${selectedPlan === "plus" ? "$129" : "$199"}`}
                          </span>
                          <span className="text-sm font-normal">/month</span>
                        </p>
                        <p className="font-normal text-primary-500">
                          {enabled ? (
                            <span>
                              Yearly subscription{" "}
                              {/* <span className="text-green-500 font-bold">
                                Save 33%
                              </span> */}
                            </span>
                          ) : (
                            "Month to month. Cancel anytime!"
                          )}
                        </p>
                      </div>
                      <hr className="border-gray-300 my-6" />
                      <div className="flex flex-col gap-1">
                        <p className="font-normal mb-2 mr-0 md:mr-6">
                          Enjoy all these features and hire at ease!
                        </p>

                        {selectedPlan === "plus"
                          ? heyhireLightList?.map((item) => (
                              <div className="flex items-center gap-0.5">
                                <div className="relative flex h-5 w-5 items-center justify-center rounded-full bg-primary-500 mr-2">
                                  <CheckIcon
                                    className="h-3 w-3 text-white"
                                    aria-hidden="true"
                                  />
                                </div>
                                <span className="font-bold text-[15px] mt-1">
                                  {item}
                                </span>
                              </div>
                            ))
                          : heyhireUnlimitedList?.map((item) => (
                              <div className="flex items-center gap-0.5">
                                <div className="relative flex h-5 w-5 items-center justify-center rounded-full bg-primary-500 mr-2">
                                  <CheckIcon
                                    className="h-3 w-3 text-white"
                                    aria-hidden="true"
                                  />
                                </div>
                                <span className="font-bold text-[15px] mt-1">
                                  {item}
                                </span>
                              </div>
                            ))}
                      </div>

                      <div className="flex justify-center mt-6">
                        <button
                          className="cursor-pointer font-bold text-sm mr-2 px-6 py-2 w-32 rounded-full bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                          type="button"
                          onClick={() => handleCheckout()}
                        >
                          Checkout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="flex border-t-2 py-4 border-primary-500 justify-center">
                <div className="flex w-full lg:w-1/2 xl:w-1/3 justify-between">
                  <button
                    className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-full bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                    type="button"
                    onClick={() => navigate("/register?step=5")}
                  >
                    Previous
                  </button> */}
              {/* <button
                    className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-md bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                    type="submit"
                    onClick={() => {
                      dispatch(
                        login({
                          init: true,
                          loggedIn: true,
                          user: data?.user,
                          token: data?.token,
                          permissions: data?.permissions,
                          roles: data?.roles,
                          accessible_entities: data?.accessible_entities,
                          enterprise: data?.enterprise,
                        })
                      );
                      navigate("/dashboard");
                    }}
                  >
                    Next
                  </button> */}
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Step7;
