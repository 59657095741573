import React from "react";
import {
  ProfileImgArrowIcon,
  ProfileImgArrowWhiteIcon,
} from "../../../assets/images";
import { useSelector } from "react-redux";
import { darkmode } from "../../../store/authSlice";

const ProfileCard = ({
  imageUrl = "",
  content = "Proprietor: username",
  modifiedheader,
  sidebar,
  selectedLocation,
  role,
}) => {
  const mode = useSelector(darkmode);
  return (
    <>
      {/* {tooltipText && <Tooltip id="tooltip-1" style={tooltipStyle} />} */}
      <div className="flex flex-col w-full justify-center">
        <div className="relative flex w-full mx-auto bg-clip-border shadow-3xl rounded-xl">
          <div className="flex items-center w-full">
            {(role === "regional_manager" || role === "owner") &&
              selectedLocation &&
              selectedLocation?.name !== "Select A Location" && (
                <div
                  className={`relative flex items-center justify-center rounded-full border-white bg-white dark:bg-transparent mx-auto ${
                    sidebar ? "w-[100px]" : "w-[50px] mt-2"
                  }`}
                >
                  <img className="rounded-full p-1" src={imageUrl} alt="" />
                  <img
                    src={mode ? ProfileImgArrowIcon : ProfileImgArrowWhiteIcon}
                    alt=""
                    className={`absolute ${sidebar ? "h-[90px]" : ""}`}
                  />
                </div>
              )}

            {/* Text Container */}
            <div
              className={`${sidebar ? "flex flex-col ml-2 w-full" : "hidden"}`}
            >
              <h4 className="uppercase text-sm text-primary-500 dark:text-white font-bold ml-1 mb-0.5">
                {modifiedheader}
              </h4>
              <p className="text-sm font-bold text-gray-600 dark:text-gray-500 ml-1">
                {content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
