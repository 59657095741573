import axios from "./api";
const baseUrl = "/admin";

const ControlPanel = {
  getAllBusiness(query = null) {
    return axios.get(`${baseUrl}/business-list?${query ? query : ""}`);
  },
  getAllLocationsWithQuery(query = null) {
    return axios.get(`${baseUrl}/location-paginate?${query ? query : ""}`);
  },
  getAllLocation() {
    return axios.get(`${baseUrl}/location-list`);
  },
  getAllTurnkeyAccount(query = null) {
    return axios.get(`${baseUrl}/turnkey-list?${query ? query : ""}`);
  },
  getSubscription(customerId) {
    return axios.get(`${baseUrl}/payment/subscription/` + customerId);
  },
  attachBusiness(data) {
    return axios.post(`${baseUrl}/payment/attach-business`, data);
  },
  validateUpload(file) {
    return axios.post(`${baseUrl}/turnkey/validate-upload`, file);
  },
  processAccountCreation(file) {
    return axios.post(`${baseUrl}/turnkey/create-accounts`, file);
  },
  getLogs(fileKey, index) {
    return axios.get(
      `${baseUrl}/turnkey/import-logs?file_key=` + fileKey + `&index=` + index
    );
  },
  exportQR(query = null) {
    return axios.get(`${baseUrl}/turnkey/export-qr?${query ? query : ""}`);
  },
  getAllCities() {
    return axios.get(`${baseUrl}/turnkey/unique-cities?`);
  },
  deleteStorage(data) {
    return axios.post(`${baseUrl}/turnkey/delete-storage`, data);
  },
  deleteBusiness(id) {
    return axios.delete(`${baseUrl}/business/${id}`);
  },


  /* 
   * Analytics
  */
  analyticsStatus(data) {
    return axios.post(`${baseUrl}/analytics-status`, data);
  },
  analyticsGrayOutStatus(data) {
    return axios.post(`${baseUrl}/location-disable`, data);
  },
  analyticStats() {
    return axios.get(`${baseUrl}/stats`);
  },
};

export default ControlPanel;