import { useState, useEffect } from "react";
import { DashboardLayout } from "../../components/organisms/Layout";
import Input from "../../components/form/Input";
import TextArea from "../../components/form/TextArea";
import { toast } from "react-toastify";
import ReactImageUploading from "react-images-uploading";
import Location from "../../api/Location";
import BrandApi from "../../api/Brand";
import Company from "../../api/Company";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  setSelectedLocation,
  selectSelectedLocation,
  darkmode,
} from "../../store/authSlice";
import User from "../../api/User";
import { copyIcon, exitIcon } from "../../assets/images";
import { Tooltip } from "react-tooltip";

const EditPublicProfile = () => {
  const user = useSelector(selectUser);
  const isEnterprise = user.company.is_enterprise !== 0;
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectSelectedLocation);
  const mode = useSelector(darkmode);
  const [brandId, setBrandId] = useState("");
  const [externalName, setExternalName] = useState("");
  const [description, setDescription] = useState("");
  const [urlSlug, setUrlSlug] = useState("");
  const [brand, setBrand] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [facebook, setFacebook] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [loading, setLoading] = useState(false);
  const [brandLoading, setBrandLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [images, setImages] = useState([]);
  const [coverImages, setCoverImages] = useState([]);
  const domainFLag = process.env.REACT_APP_API_BASE_URL.search("stagingapi");
  const [latestBrandId, setLatestBrandId] = useState(null);
  // const [uploadStatus, setUploadStatus] = useState("no-file");
  // const [fileName, setFileName] = useState(null);

  useEffect(() => {
    document.title = "HeyHire Portal-Edit Public Profile";
    getUser(user.id);
  }, []);

  useEffect(() => {
    if (latestBrandId !== null) {
      loadCompanies();
    }
  }, [latestBrandId]);

  const getUser = (userId) => {
    User.getById(userId)
      .then((response) => {
        const brandId = response.data.data.location.brand_id;
        setLatestBrandId(brandId);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const loadBrand = async () => {
    try {
      setBrandLoading(true);
      const br = await BrandApi.getAll();
      if (br.data.data.length > 0) {
        const brandId = latestBrandId;
        setBrandId(brandId);
        const response = await BrandApi.getById(brandId);
        const brandFromResponse = response.data.data;
        setBrand(brandFromResponse);
        setName(brandFromResponse.name);
        setEmail(brandFromResponse.email);
        setWebsite(brandFromResponse.website);
        setCompany(brandFromResponse.company_id);
        setDescription(brandFromResponse.description);
        setExternalName(brandFromResponse.external_name);
        setFacebook(brandFromResponse.facebook);
        setInstagram(brandFromResponse.instagram);
        setLinkedin(brandFromResponse.linkedin);
        setTwitter(brandFromResponse.twitter);
        setUrlSlug(brandFromResponse.url_slug);
        setYoutube(brandFromResponse.youtube);
        setBrandLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  const loadCompanies = () => {
    setLoading(true);
    Company.getAll()
      .then((response) => {
        setCompanies(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .then(() => loadBrand());
  };

  const updateBrand = () => {
    setUpdateLoading(true);
    setValidationErrors(null);

    let body = {
      id: brandId,
      name: name,
      company_id: company,
      website: website,
      facebook: facebook,
      twitter: twitter,
      url_slug: urlSlug,
      description: description,
      external_name: externalName,
      instagram: instagram,
      linkedin: linkedin,
      youtube: youtube,
    };

    // if (website !== brand.url_slug) body.url_slug = website;

    BrandApi.update(brandId, body)
      .then(() => {
        toast.success("Changes Saved");
        if (coverImages.length > 0) {
          uploadBrandCoverPhoto();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          // Validation error
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error("Something Went Wrong. Please Try Again.");
          }
        }
      })
      .then(() => setUpdateLoading(false));
  };

  const uploadBrandPhoto = (_images) => {
    setUpdateLoading(true);
    BrandApi.uploadImage(brand.id, _images[0].file).finally(() => {
      Location.getById(selectedLocation?.id)
        .then((response) => {
          dispatch(setSelectedLocation(response.data.data));
          localStorage.setItem(
            "selectedLocation",
            JSON.stringify(response.data.data)
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
      setUpdateLoading(false);
    });
  };

  const uploadBrandCoverPhoto = () => {
    setUpdateLoading(true);
    BrandApi.uploadCoverImage(brand.id, coverImages[0].file).finally(() =>
      setUpdateLoading(false)
    );
  };

  const openUrlInNewTab = () => {
    const baseUrl = `https://${
      domainFLag !== -1 ? "staging.heyhire" : "heyhire"
    }.app/`;
    const fullUrl = `${baseUrl}${urlSlug}`;

    window.open(fullUrl, "_blank");
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="mt-4 w-full max-w-7xl pb-12">
        <div className="flex justify-between flex-col">
          <h2 className="font-bold text-2xl text-gray-900 dark:text-gray-300">
            Edit Business Profile
          </h2>
          <hr className="w-full border border-primary-100 dark:border-gray-400 mt-1" />
          {/* <button
            onClick={() => setEdit(!edit)}
            className="p-2 px-16 rounded-md border-2 border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white disabled:border-primary-200 disabled:text-primary-200"
            disabled={loading || updateLoading}
          >
            {t("common.edit")}{" "}
            {edit ? (
              <i className="fad fa-lock-open ml-1" />
            ) : (
              <i className="fad fa-lock ml-1" />
            )}
          </button> */}
        </div>

        {(loading || brandLoading) && (
          <i className="block fad fa-circle-notch fa-spin text-3xl text-primary-500 mt-8 text-center" />
        )}
        {!loading && !brandLoading && (
          <>
            {brand && (
              <div className="mt-8">
                {/* <Input
                        name="twitter"
                        label="Company name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={
                          validationErrors && validationErrors.twitter
                            ? validationErrors.twitter[0]
                            : null
                        }
                        disabled={!edit}
                        darmo={true}
                      />

                      <Input
                        name="external_name"
                        label="External Business Name"
                        value={externalName}
                        onChange={(e) => setExternalName(e.target.value)}
                        error={
                          validationErrors && validationErrors.external_name
                            ? validationErrors.external_name[0]
                            : null
                        }
                        disabled={!edit}
                        darmo={true}
                      />

                      <div className="self-center">
                        {isEnterprise && (
                          <>
                            <input type="checkbox" /> Apply External Business
                            Name to children{" "}
                          </>
                        )}
                      </div> */}

                <div className="mb-6">
                  <p className="block mb-2 font-bold title text-warmgray-900 dark:text-gray-300">
                    Business Logo
                  </p>
                  <ReactImageUploading
                    value={images}
                    onChange={(imageList, index) => {
                      setImages(imageList);
                      uploadBrandPhoto(imageList);
                    }}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div className="flex">
                        <div className="h-28 w-28 bg-warmgray-200 dark:bg-gray-600 self-center flex justify-center">
                          {imageList && imageList.length > 0 ? (
                            <img
                              className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                              src={imageList[0].dataURL}
                            />
                          ) : brand.photo ? (
                            <img
                              className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                              src={brand.photo.thumb_url}
                            />
                          ) : (
                            <i
                              className="text-white dark:text-gray-300 text-3xl self-center fa fa-plus cursor-pointer"
                              onClick={onImageUpload}
                            />
                          )}
                        </div>
                        <button
                          className="ml-4 my-auto flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                          onClick={onImageUpload}
                        >
                          Upload Logo
                        </button>
                      </div>
                    )}
                  </ReactImageUploading>
                </div>

                <div className="mb-6 md:w-3/4 lg:w-1/2">
                  <TextArea
                    label="Business Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    error={
                      validationErrors && validationErrors.description
                        ? validationErrors.description[0]
                        : null
                    }
                    darmo={mode}
                  />
                </div>

                <div className="mb-6 md:w-3/4 lg:w-1/2 md:flex">
                  <div className="w-full mr-2">
                    <Input
                      name="url_slug"
                      label="HeyHire Business URL"
                      slug={`https://${
                        domainFLag !== -1 ? "staging.heyhire" : "heyhire"
                      }.app/`}
                      placeholder="business-name"
                      value={urlSlug}
                      onChange={(e) => setUrlSlug(e.target.value)}
                      error={
                        validationErrors && validationErrors.url_slug
                          ? validationErrors.url_slug[0]
                          : null
                      }
                      darmo={mode}
                    />
                  </div>
                  <div className="flex items-center justify-end md:justify-center mt-1 md:mt-5">
                    <img
                      src={exitIcon}
                      alt="exit"
                      className="cursor-pointer mr-2 h-4 w-4"
                      onClick={openUrlInNewTab}
                      data-tooltip-id="exit-tooltip"
                      data-tooltip-content="Open URL in New Tab"
                    />
                    <img
                      src={copyIcon}
                      alt="copy"
                      className="cursor-pointer h-4 w-5"
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(
                          `https://${
                            domainFLag !== -1 ? "staging.heyhire" : "heyhire"
                          }.app/${selectedLocation?.brand?.url_slug}`
                        );
                        toast.success("Url copied");
                      }}
                      data-tooltip-id="copy-tooltip"
                      data-tooltip-content="Copy URL"
                    />
                  </div>
                </div>

                {/* <div>
                      <ReactImageUploading
                        value={coverImages}
                        onChange={(imageList, index) => {
                          setCoverImages(imageList);
                        }}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          <div className="flex">
                            <div className="h-28 w-28 bg-warmgray-200 selfcenter flex justify-center">
                              {imageList && imageList.length > 0 ? (
                                <img
                                  className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                                  src={imageList[0].dataURL}
                                />
                              ) : brand.cover ? (
                                <img
                                  className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                                  src={brand.photo.thumb_url}
                                />
                              ) : (
                                <i
                                  className="text-white text-3xl self-center fa fa-plus cursor-pointer"
                                  onClick={onImageUpload}
                                />
                              )}
                            </div>
                            <button
                              className="ml-4 rounded px-4 py-2 bg-primary-500 hover:bg-primary-300 text-white cursor-pointer self-center disabled:opacity-50"
                              onClick={onImageUpload}
                              disabled={!edit}
                            >
                              Upload Cover Banner
                            </button>
                          </div>
                        )}
                      </ReactImageUploading>
                    </div> */}

                {/* {isEnterprise && (
                      <>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                          <Input
                            name="name"
                            label="Brand Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={
                              validationErrors && validationErrors.name
                                ? validationErrors.name[0]
                                : null
                            }
                            disabled={!edit}
                            darmo={true}
                          />

                          <Select
                            label="Company"
                            value={company}
                            name="company"
                            onChange={(e) => {
                              setCompany(companies[e.target.selectedIndex].id);
                            }}
                            disabled={!edit}
                          >
                            {companies.map((company) => {
                              return (
                                <option
                                  className="p-5"
                                  key={company.id}
                                  value={company.id}
                                >
                                  {company.name}
                                </option>
                              );
                            })}
                          </Select>

                          <div />

                          <Input
                            className="mt-0"
                            name="website"
                            label="Website"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                            error={
                              validationErrors && validationErrors.url_slug
                                ? validationErrors.url_slug[0]
                                : null
                            }
                            disabled={!edit}
                            darmo={true}
                          />

                          <Input
                            className="mt-0"
                            name="email"
                            label="Email"
                            value={email}
                            disabled={true}
                            darmo={true}
                          />
                        </div>
                      </>
                    )} */}

                {/* <div className={"mt-8"}>
                      <span className={"block font-bold mb-1"}>
                        Social Media
                      </span>
                      <hr /> */}

                {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-4"> */}
                <div className="mb-6 md:w-3/4 lg:w-1/2">
                  <Input
                    name="website"
                    label="Business URL"
                    slug="http://"
                    placeholder="www.example.com"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    error={
                      validationErrors && validationErrors.website
                        ? validationErrors.website[0]
                        : null
                    }
                    darmo={mode}
                  />
                </div>
                {/* <Input
                          name="facebook"
                          label="Facebook Page"
                          slug="facebook.com/"
                          value={facebook}
                          onChange={(e) => setFacebook(e.target.value)}
                          error={
                            validationErrors && validationErrors.facebook
                              ? validationErrors.facebook[0]
                              : null
                          }
                          disabled={!edit}
                          darmo={true}
                        />
                        <div />

                        <Input
                          name="linkedin"
                          label="Linkedn Page"
                          slug="linkedin.com/"
                          value={linkedin}
                          onChange={(e) => setLinkedin(e.target.value)}
                          error={
                            validationErrors && validationErrors.linkedin
                              ? validationErrors.linkedin[0]
                              : null
                          }
                          disabled={!edit}
                          darmo={true}
                        />

                        <Input
                          name="instagram"
                          label="Instagram Page"
                          slug="instagram.com/"
                          value={instagram}
                          onChange={(e) => setInstagram(e.target.value)}
                          error={
                            validationErrors && validationErrors.instagram
                              ? validationErrors.instagram[0]
                              : null
                          }
                          disabled={!edit}
                          darmo={true}
                        />
                        <div />

                        <Input
                          name="twitter"
                          label="Twitter Page"
                          slug="twitter.com/"
                          value={twitter}
                          onChange={(e) => setTwitter(e.target.value)}
                          error={
                            validationErrors && validationErrors.twitter
                              ? validationErrors.twitter[0]
                              : null
                          }
                          disabled={!edit}
                          darmo={true}
                        />

                        <Input
                          name="youtube"
                          label="Youtube Page"
                          slug="youtube.com/"
                          value={youtube}
                          onChange={(e) => setYoutube(e.target.value)}
                          error={
                            validationErrors && validationErrors.youtube
                              ? validationErrors.youtube[0]
                              : null
                          }
                          disabled={!edit}
                          darmo={true}
                        />
                      </div>
                    </div> */}

                <div className="flex w-full md:w-1/6 mt-8 justify-center md:justify-start">
                  <button
                    className="my-auto flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                    onClick={(e) => {
                      e.preventDefault();
                      updateBrand();
                    }}
                    disabled={updateLoading || loading}
                  >
                    {"Save Changes"}
                    {loading || updateLoading ? (
                      <i className="fad fa-circle-notch fa-spin ml-2" />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            )}

            {/* <div className="flex w-full md:w-2/6 mt-12">
                <button
                  className="w-full rounded-md p-2 m-1 text-white text-lg bg-violet-500 hover:bg-violet-700 disabled:bg-violet-200"
                  onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(
                      `https://${
                        domainFLag !== -1 ? "staging.heyhire" : "heyhire"
                      }.app/${selectedLocation?.brand?.url_slug}/${
                        selectedLocation?.url_slug
                      }`
                    );
                    toast.success("Url copied");
                  }}
                  disabled={updateLoading || loading}
                >
                  Copy Business Profile Url
                </button>
              </div> */}
          </>
        )}
        <Tooltip id="exit-tooltip" />
        <Tooltip id="copy-tooltip" />
      </div>
    </DashboardLayout>
  );
};

export default EditPublicProfile;
