import React from "react";
import { DashboardLayout } from "../../components/organisms/Layout";
import ShiftAddOrEdit from "../../components/molecules/ShiftAddOrEdit/ShiftAddOrEdit";

const AddNewShifts = () => {
  return (
    <DashboardLayout darmo={true}>
      <div className="mt-8 w-full max-w-7xl">
        <div className="flex flex-col xl:flex-row gap-1 xl:gap-3">
          <h2 className={`text-2xl font-bold text-black`}>
            {/* {editMode ? "Edit" : "Post"} a Shift */}
            Post a Shift
          </h2>
          <span className="mt-2">Post a shift to find local job seekers that are open for last minute shifts on HeyHire now Network!</span>
        </div>
        <hr className={`w-full border border-primary-100 mt-2`}/>
        <ShiftAddOrEdit/>
      </div>
    </DashboardLayout>
  );
};

export default AddNewShifts;