import {Dialog} from '@headlessui/react';
import {LinkIcon, PlusIcon, QuestionMarkCircleIcon, XMarkIcon} from '@heroicons/react/20/solid';
import React from 'react'
import {useState} from 'react';
import FloatingInputText from '../../../../../components/form/FloatingInputText';
import MultiSelector from '../../../../../components/atoms/Selector/MultiSelector';
import ControlPanel from '../../../../../api/ControlPanel';
import {useEffect} from 'react';


const team = [
  {
    name: "Tom Cook",
    email: "tom.cook@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Whitney Francis",
    email: "whitney.francis@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Leonard Krasner",
    email: "leonard.krasner@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Floyd Miles",
    email: "floyd.miles@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Emily Selman",
    email: "emily.selman@example.com",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];


const SlidePanelForm = ({
  customer = [],
  openTrigger = false,
  showLocationOption = true,
  businessName = "",
  handleLocationSelection,
}) => {
  const [open, setOpen] = useState(true);
  const [locationList, setLocationList] = useState(false);
  const [locationSelected, setLocationSelected] = useState(false);
  const [subscription, setSubscription] = useState(false);

  useEffect(() => {
    getAllLocation();
    getSubscription();
  }, [openTrigger]);

  const getAllLocation = () => {
    ControlPanel.getAllLocation()
      .then((response) => {
        let fetchdata = response.data.data;
        let locationData = [];

        for (let i = 0; i < fetchdata.length; i++) {
          const el = fetchdata[i];

          locationData.push({
            label: el.name,
            value: el.id,
          });
        }

        setLocationList(locationData);
      })
      .catch((error) => {
        console.log("Error fetching the location list");
      });
  };

  const getSubscription = () => {
    ControlPanel.getSubscription(customer.id)
      .then((response) => {
        if (response.data.total_count !== 0 && response.data.data) {
          setSubscription(response.data.data[0]);
        } else {
          setSubscription("No Subscription");
        }
      })
      .catch((error) => {
        console.log("Error fetching the subscription for customer");
      });
  };

  const clickHandler = (value, label) => {
    setLocationSelected(value);
  };

  const handleSelection = () => {
    if (showLocationOption && subscription !== "No Subscription") {
      let data = {
        customer: customer,
        subscription: subscription,
        locationId: locationSelected,
      };

      ControlPanel.attachBusiness(data)
        .then((response) => {
          openTrigger(false);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log("Error attaching business");
        });
    }
  }

  return (
    <div className="h-full">
      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
        <div className="flex-1">
          {/* Header */}
          <div className="px-4 py-6 sm:px-6 bg-gradient-to-r from-[#F1B257] to-white">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                  Business Edit Form
                </Dialog.Title>
                <p className="text-sm text-gray-600">Edit and save details.</p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500 border-none"
                  onClick={() => openTrigger(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon
                    className=" border-none h-6 w-6"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>

          {/* Divider container */}
          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
            {/* Project name */}
            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div className="pt-4">
                <label
                  htmlFor="project-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                >
                  Customer Name :
                </label>
              </div>
              <div className="sm:col-span-2 pt-4">
                {/* <FloatingInputText
                  className="my-3"
                  id="brandName"
                  name="name"
                  type="text"
                /> */}
                <label
                  htmlFor="project-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                >
                  {customer.description}
                </label>
              </div>
            </div>
            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div className="pt-4">
                <label
                  htmlFor="project-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                >
                  Customer Id :
                </label>
              </div>
              <div className="sm:col-span-2 pt-4">
                {/* <FloatingInputText
                  className="my-3"
                  id="brandName"
                  name="name"
                  type="text"
                /> */}
                <label
                  htmlFor="project-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                >
                  <div>{customer.id}</div>
                  <div className='pt-2'>
                    <a
                      href="#href"
                      className="group flex items-center space-x-2.5 text-sm font-medium text-indigo-600 hover:text-indigo-900"
                    >
                      <LinkIcon
                        className="h-5 w-5 text-indigo-500 group-hover:text-indigo-900"
                        aria-hidden="true"
                      />
                      <span>Copy Customer Id</span>
                    </a>
                  </div>
                </label>
              </div>
            </div>
            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div className="pt-4">
                <label
                  htmlFor="project-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                >
                  Linked Business
                </label>
              </div>
              {showLocationOption ? (
                <div className="sm:col-span-2">
                  {locationList && (
                    <MultiSelector
                      dropDownData={locationList}
                      callBackHandler={clickHandler}
                      placeholder="Select position title"
                      disabled={""}
                    />
                  )}
                </div>
              ) : (
                <p className="pt-5">{businessName}</p>
              )}
            </div>
            {/* Project description */}
            {/* <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <label
                  htmlFor="project-description"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                >
                  Subscription Log
                </label>
              </div>
              <div className="sm:col-span-2">
                <textarea
                  id="project-description"
                  name="project-description"
                  rows={3}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={""}
                />
              </div>
            </div> */}

            {/* Team members */}
            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <div>
                <h3 className="text-sm font-medium leading-6 text-gray-900">
                  Owners Email
                </h3>
              </div>
              <div className="sm:col-span-2">
                <div className="flex space-x-2">{customer.email}</div>
              </div>
            </div>

            {/* Privacy */}
            <fieldset className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <legend className="sr-only">Privacy</legend>
              <div
                className="text-sm font-medium leading-6 text-gray-900"
                aria-hidden="true"
              >
                Subscription
              </div>
              <div className="space-y-5 sm:col-span-2">
                <div className="space-y-5 sm:mt-0">
                  <div className="relative items-start">
                    {/* <div className="absolute flex h-6 items-center">
                      <input
                        id="public-access"
                        name="privacy"
                        aria-describedby="public-access-description"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        defaultChecked
                      />
                    </div> */}
                    <div className=" text-sm leading-6">
                      <label
                        htmlFor="public-access"
                        className="font-medium text-gray-900"
                      >
                        Plan id: {subscription.id}
                      </label>
                    </div>{" "}
                    <br></br>
                    <div className=" text-sm leading-6">
                      <label
                        htmlFor="public-access"
                        className="font-medium text-gray-900"
                      >
                        Latest Invoice Id: {subscription.latest_invoice}
                      </label>
                    </div>
                    <br></br>
                    <div className=" text-sm leading-6">
                      <label
                        htmlFor="public-access"
                        className="font-medium text-gray-900"
                      >
                        Billing : {subscription.billing}
                      </label>
                    </div>
                    <br></br>
                    <div className=" text-sm leading-6">
                      <label
                        htmlFor="public-access"
                        className="font-medium text-gray-900"
                      >
                        Status : {subscription.status}
                      </label>
                    </div>
                  </div>
                  {/* <div className="relative flex items-start">
                    <div className="absolute flex h-6 items-center">
                      <input
                        id="restricted-access"
                        name="privacy"
                        aria-describedby="restricted-access-description"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="pl-7 text-sm leading-6">
                      <label
                        htmlFor="restricted-access"
                        className="font-medium text-gray-900"
                      >
                        Enterprise plan
                      </label>
                      <p
                        id="restricted-access-description"
                        className="text-gray-500"
                      >
                        Fixed price for 2 location and added charge for every
                        additional locations
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="absolute flex h-6 items-center">
                      <input
                        id="private-access"
                        name="privacy"
                        aria-describedby="private-access-description"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="pl-7 text-sm leading-6">
                      <label
                        htmlFor="private-access"
                        className="font-medium text-gray-900"
                      >
                        One time plan
                      </label>
                      <p
                        id="private-access-description"
                        className="text-gray-500"
                      >
                        charge a custom amount , one time
                      </p>
                    </div>
                  </div> */}
                </div>
                <hr className="border-gray-200" />
                <div className="flex flex-col items-start space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
                  <div>
                    <a
                      href="#"
                      className="group flex items-center space-x-2.5 text-sm text-gray-500 hover:text-gray-900"
                    >
                      <QuestionMarkCircleIcon
                        className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span>Learn more about sharing</span>
                    </a>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        {/* Action buttons */}
        <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => openTrigger(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSelection}
            >
              Confirm Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlidePanelForm;