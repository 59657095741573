import * as React from "react";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./SliderWrapper.scss";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  // adaptiveHeight: true,
  arrows: false,
  centerPadding: "40px",
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const SliderWrapper = ({ children }) => {
  const slider = React.useRef(null);

  const onPreviousSlider = () => {
    if (slider.current) {
      slider.current.slickPrev();
    }
  };
  const onNextSlider = () => {
    if (slider.current) {
      slider.current.slickNext();
    }
  };

  return (
    <div className="slider-container">
      <div className="slider-arrow-left" onClick={onPreviousSlider}>
        <FaChevronLeft size={16} color="#323232" />
      </div>
      <div className="slider-arrow-right" onClick={onNextSlider}>
        <FaChevronRight size={16} color="#323232" />
      </div>
      <Slider {...settings} ref={slider}>
        {children}
      </Slider>
    </div>
  );
};

export default SliderWrapper;
