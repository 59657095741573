export default function Illustrator({
  overview = "Business Analytics",
  header = "header",
  data = [], // Add mode as a prop to determine dark or light mode
  mode,
}) {
  return (
    <div className="mt-2 mb-4 md:mb-8 md:mt-4">
      <div className="bg-[#f6f6f6] dark:bg-[#242526] px-1 sm:px-0 border border-primary-200 dark:shadow-none dark:border-darkGray shadow-xl w-full mx-auto rounded-2xl">
        <div className="p-4">
          <h3 className="text-base font-bold text-gray-900 dark:text-gray-300">
            {overview}
          </h3>
          <p className="max-w-2xl font-normal text-[#8C8C8C]">{header}</p>
        </div>
        <hr className="h-px bg-gray-200 dark:bg-transparent border-0" />
        <div className="mx-auto max-w-2xl pb-4 px-4 lg:max-w-3xl">
          <div className="grid grid-cols-2 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8">
            {data.map((item) => (
              <div key={item.name} className="flex flex-col items-center">
                <div className="mb-2">
                  <img
                    src={mode ? item.darkSrc : item.lightSrc}
                    alt="logo"
                    className="w-auto h-6 flex-shrink-0 animate-fade-in-from-top-.1s"
                  />
                </div>
                <h3 className="text-md font-medium text-gray-900 dark:text-warmgray-300">
                  {item.name}
                </h3>
                <p className="text-xl font-bold text-primary-500 dark:text-gray-300 -tracking-widest">
                  {item.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
