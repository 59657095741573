import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Brand from "../../../api/Brand";
import Region from "../../../api/Region";
import AddressBook from "../../../api/AddressBook";
import Company from "../../../api/Company";
import { Col, Row } from "antd";
import FormInput from "../../atoms/FormInput";
import FormSelect from "../../atoms/FormSelect";

const FormLocation = () => {
  const { t } = useTranslation();

  const [brands, setBrands] = useState([]);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState();
  const [addresses, setAddresses] = useState([]);
  const [companies, setCompanies] = useState([]);

  const loadBrands = () => {
    Brand.getAll().then((response) => {
      setBrands(response.data.data);
    });
  };

  const loadRegions = () => {
    Region.getAll().then((response) => {
      setRegions(response.data.data);
      setRegion(response.data.data.length > 0 ? response.data.data[0] : null);
    });
  };

  const loadAddresses = () => {
    AddressBook.getAll().then((response) => {
      setAddresses(response.data.data);
    });
  };

  const loadCompanies = () => {
    Company.getAll().then((response) => {
      setCompanies(response.data.data);
    });
  };

  useEffect(() => {
    loadBrands();
    loadRegions();
    loadAddresses();
    loadCompanies();
  }, []);

  return (
    <Row gutter={[28, 20]}>
      <Col xs={24} md={12}>
        <FormInput
          name="name"
          label={t("common.name")}
          placeholder={t("common.name")}
        />
      </Col>
      <Col xs={24} md={12}>
        <FormSelect
          name="address"
          label={t("locations.assign-address")}
          placeholder={t("locations.assign-address")}
          options={addresses.map((_address) => ({
            value: _address.id,
            label: _address.name,
          }))}
        />
      </Col>
      <Col xs={24} md={12}>
        <FormSelect
          name="company"
          label={t("locations.assign-a-business")}
          placeholder={t("locations.assign-a-business")}
          options={companies.map((_company) => ({
            value: _company.id,
            label: _company.name,
          }))}
        />
      </Col>
      <Col xs={24} md={12}>
        <FormSelect
          name="brand"
          label={t("locations.assign-a-brand")}
          placeholder={t("locations.assign-a-brand")}
          options={brands.map((_brand) => ({
            value: _brand.id,
            label: _brand.name,
          }))}
        />
      </Col>
      <Col xs={24} md={12}>
        <FormInput
          name="website"
          label={t("common.website")}
          placeholder={t("common.website")}
        />
      </Col>
      <Col xs={24} md={12}>
        <FormSelect
          name="brand"
          label={t("locations.assign-a-region")}
          placeholder={t("common.please-select-one")}
          options={regions.map((_region) => ({
            value: _region.id,
            label: _region.name,
          }))}
        />
      </Col>
    </Row>
  );
};

export default FormLocation;
