import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import Company from "../api/Company";
import { toast } from "react-toastify";
import { emitCustomEvent } from "react-custom-events";
import Input from "../components/form/Input";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Draft from "../api/Draft";
import Location from "../api/Location";
import { DashboardLayout } from "../components/organisms/Layout";

const AddCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [draftLoading, setDraftLoading] = useState(false);
  const [draft, setDraft] = useState(null);
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);

  useEffect(() => {
    document.title = "HeyHire Portal-Add New Company";
    // loadDraft();
    loadLocations();
  }, []);

  const addCompany = () => {
    setLoading(true);

    Company.add({ name: name, is_enterprise: isEnterprise ? 1 : 0 })
      .then(() => {
        toast.success(t("company-added"));
        emitCustomEvent("companyAdded");
        navigate("/companies");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setValidationErrors(error.response.data.errors);
        } else {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        }
      })
      .then(() => setLoading(false));
  };

  const loadLocations = () => {
    Location.getAll().then((response) => {
      const locationsResponse = response.data.data;
      setLocations(locationsResponse);
    });
  };

  const saveDraft = (payload) => {
    setDraftLoading(true);
    Draft.add({
      type: "add-company",
      title: "add-company",
      payload: payload,
    })
      .then(() => {
        toast.success("Draft Saved");
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("That didn't work. Please Try Again.");
        }
      })
      .then(() => {
        setDraftLoading(false);
      });
  };

  const loadDraft = () => {
    Draft.getAllByType("add-company").then((response) => {
      setDraft(response.data.data[0]);
    });
  };

  const setFromDraft = () => {
    setName(JSON.parse(draft.payload).name);

    // Clear draft
    setDraft(null);
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full mt-6">
        <h2 className={"text-2xl font-bold"}>{t("companies.add-a-company")}</h2>
        <hr className="my-2 border-gray-300" />

        {/* {false && (
          <div
            className={
              "mt-2 px-4 py-2 flex justify-between rounded text-sm bg-primary-100 text-primary-500"
            }
          >
            <span>You have a pending draft.</span>
            <button
              className={"text-sm hover:font-bold"}
              onClick={setFromDraft}
            >
              LOAD
            </button>
          </div>
        )} */}

        <div className="text-left snap-start pb-8 mb-4 w-full mt-2">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2">
            <Input
              darmo={true}
              className="my-3"
              name="name"
              label={t("common.name")}
              type="text"
              value={name}
              placeholder={t("companies.add-a-company")}
              error={
                validationErrors && validationErrors.name
                  ? validationErrors.name[0]
                  : null
              }
              onChange={(e) => setName(e.target.value)}
            />
            {isEnterprise && (
              <div className="my-3">
                <span className={"font-bold block mt--1"}>Locations</span>
                <ReactSelect
                  value={selectedLocation}
                  isMulti
                  theme={(theme) => {
                    return {
                      ...theme,
                      borderRadius: 4,
                      spacing: {
                        ...theme.spacing,
                        controlHeight: 47,
                      },
                      colors: {
                        ...theme.colors,
                        primary25: "#A899E2",
                        primary: "#7D66D3",
                      },
                    };
                  }}
                  onChange={(selected) => setSelectedLocation(selected)}
                  options={locations.map((location) => {
                    return {
                      value: location.id,
                      label: location.name,
                    };
                  })}
                />
              </div>
            )}
          </div>
          <div className="self-center font-bold">
            <input
              className="mr-2"
              type="checkbox"
              value={isEnterprise}
              onChange={() => setIsEnterprise(!isEnterprise)}
            />
            Is an enterprise Company?
          </div>
          <hr className="my-4" />

          <div className="flex w-full md:w-1/6">
            <button
              className="w-full flex justify-center items-center rounded-full bg-primary-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                addCompany();
              }}
              disabled={loading}
            >
              {t("common.add")}
              {loading && <i className="fad fa-circle-notch fa-spin ml-2" />}
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddCompany;
