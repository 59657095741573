import axios from "./api";

const base = "/business/application";
const baseInstagram = "/business/instagram";
const baseLog = "/business/application-log";

const Application = {
  getByLocationId(locationId, withoutChats = 0) {
    return axios.get(
      `${base}/location/${locationId}?withoutChats=${withoutChats}`
    );
  },
  getByPositionId(positionId, withoutChats = 0) {
    return axios.get(
      `${base}/position/${positionId}?withoutChats=${withoutChats}`
    );
  },
  getById(Id) {
    return axios.get(`${base}/${Id}`);
  },
  hireByApplicationId(id) {
    return axios.post(`${base}/hire/${id}`);
  },
  rejectByApplicationId(id) {
    return axios.post(`${base}/reject/${id}`);
  },
  getInstagramProfile(id) {
    return axios.get(`${baseInstagram}/${id}`);
  },
  getApplicationLogs(id) {
    return axios.get(`${baseLog}/${id}`);
  },
  postApplicationView(body) {
    return axios.post(`${base}/view`, body);
  },
  getByIdForAvailability(Id) {
    return axios.get(`/business/calender-availability/${Id}`);
  },
};

export default Application;
