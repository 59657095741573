import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { emitCustomEvent } from "react-custom-events";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  PaymentTargets,
  SingleBusinessPaymentTargets,
} from "../../utils/datas";
import myAxios from "../../api/api";
import {
  selectUser,
  selectRoles,
  login,
  selectSelectedLocation,
  darkmode,
} from "../../store/authSlice";
import Cards from "../../api/Cards";
import { Tooltip } from "react-tooltip";
import FormSelect from "../atoms/FormSelect";

const CheckoutForm = ({
  data,
  handleShow,
  locationId,
  locationName = "",
  addPayment,
  handleAddPayment,
  onChange,
  isCancel,
  isNote,
  isSkip,
  isRegister,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const [selectedCard, setSelectedCard] = useState("");
  const [entityLoading, setEntityLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState("");
  const [selectedEntityType, setSelectedEntityType] = useState("");
  const user = useSelector(selectUser);
  const selectedLocation = useSelector(selectSelectedLocation);
  const roles = useSelector(selectRoles);
  const allowedEntityTypeSelection = user
    ? roles.length > 0 && roles[0] === "owner" && !user.company.is_enterprise
      ? false
      : true
    : false;
  const [entities, setEntities] = useState([]);
  const paymentMethodTargets = allowedEntityTypeSelection
    ? PaymentTargets
    : SingleBusinessPaymentTargets;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedCard === "") {
      handleShow(false);
    } else {
      handleShow(true);
    }
  }, [selectedCard]);

  useEffect(() => {
    if (addPayment) {
      setSelectedCard("");
      handleAddPayment(false);
    }
  }, [addPayment]);

  useEffect(() => {
    if (!allowedEntityTypeSelection) {
      OnTargetSelect("location");
      setSelectedEntityType("location");
    }
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (selectedEntity === "" || selectedEntityType === "") {
      return toast.error("select an entity type and entity to proceed");
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (error) {
      console.log(error);
    }
    if (paymentMethod) {
      const cardElement = elements.getElement(CardElement);
      let token = await stripe.createToken(cardElement);

      const cardData = {
        nonce: token.token.id,
        entity_type: selectedEntityType,
        entity_id: selectedEntity,
      };

      if (selectedEntityType === "location") {
        cardData.location_id = locationId;
      }

      Cards.add(cardData)
        .then(() => {
          toast.success("Payment added: ");
          setLoading(false);
          // if (data?.user) {
          //   if (isRegister) {
          //     navigate("/register?step=4");
          //   } else {
          //     dispatch(login(data));
          //     navigate("/dashboard");
          //   }
          // } else {
          //   emitCustomEvent("closeModal");
          //   emitCustomEvent("paymentAdded");
          // }
        })
        .catch((error) => {
          setLoading(false);
          if (data?.user) {
            emitCustomEvent("closeModal");
          }
          toast.error(
            "Could not add Card. Please check if the card has sufficient balance and try again."
          );
        });
    }

    // onChange(true);
  };

  const OnTargetSelect = async (value) => {
    setSelectedEntity("");
    setEntityLoading(true);
    // call endpoint
    const resp = await myAxios.get(`/business/${value}`);
    const dataResp = resp.data.data;

    const entities = dataResp.map((d) => {
      return { label: d?.name || d?.title, value: d.id };
    });
    setEntities(entities);
    if (entities.length === 1) {
      setSelectedEntity(entities[0]?.value);
    } else if (value === "location") {
      const _temp = entities.find(
        (i) =>
          i?.value === selectedLocation?.id || i?.value === user?.location?.id
      );
      setSelectedEntity(_temp);
    } else if (value === "company") {
      const _temp = entities.find((i) => i?.id == user?.company_id);
      setSelectedEntity(_temp);
    }
    setEntityLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="border-b border-gray-300 dark:border-warmgray-500 mb-6 xl:mb-8 mx-0 xl:mx-6">
          <div className="mb-6 xl:mb-8 mt-4 lg:w-2/4 xl:w-2/6">
            <FormSelect
              type="add-job"
              label="Select Card"
              placeholder="Select Card"
              classNames="font-bold"
              options={data?.map((card) => ({
                value: card,
                label: `${card.card.brand.toUpperCase()} ending with *${
                  card.card.last4
                } ${locationName && `(Location : ${locationName})`}`,
              }))}
              onChange={(selectedOption) => {
                setSelectedCard(selectedOption?.value);
              }}
              value={
                selectedCard !== "" && {
                  value: selectedCard,
                  label: `${selectedCard.card.brand.toUpperCase()} ending with *${
                    selectedCard.card.last4
                  }`,
                }
              }
              darmo={mode}
            />
          </div>
        </div>
        <div className="my-4 mx-0 xl:mx-6 flex flex-col xl:flex-row gap-8">
          <div className="xl:w-[45%] mr-0 xl:mr-12">
            <p className="text-primary-500 dark:text-gray-500 uppercase font-bold mb-4">
              Payment Details
            </p>
            {allowedEntityTypeSelection && selectedCard === "" && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <FormSelect
                  type="add-job"
                  label={"Select Entity Type"}
                  value={selectedEntityType?.title}
                  placeholder="Select Entity Type"
                  classNames="font-bold"
                  options={paymentMethodTargets?.map((job) => ({
                    value: job?.value,
                    label: job?.label,
                  }))}
                  onChange={(value) => {
                    OnTargetSelect(value.value);
                    setSelectedEntityType(value.value);
                  }}
                  darmo={mode}
                />

                <FormSelect
                  type="entity-type"
                  label={"Select Entity"}
                  value={selectedEntity?.title}
                  placeholder="Select Entity"
                  classNames="font-bold"
                  disabledEntity={entityLoading}
                  options={entities?.map((job) => ({
                    value: job?.value,
                    label: job?.label,
                  }))}
                  onChange={(value) => {
                    setSelectedEntity(value.value);
                  }}
                  darmo={mode}
                />
              </div>
            )}
            <hr className="mt-5" />

            {selectedCard !== "" ? (
              <div className="bg-white p-3.5 border rounded-sm w-full focus:outline-none focus-within:border-violet-400 border-gray-300">
                <div className="flex">
                  <div className="uppercase mr-2">
                    {selectedCard?.card?.brand}
                  </div>
                  <div className="flex">
                    <span className="hidden md:block">
                      XXXX XXXX XXXX&nbsp;
                    </span>
                    <span className="block md:hidden">*&nbsp;</span>
                    {selectedCard?.card?.last4}
                  </div>
                  <div className="ml-auto flex">
                    <span className="hidden md:block mr-1">Expiry:</span>
                    <span className="">
                      {selectedCard?.card?.exp_month?.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                      })}
                      /{selectedCard?.card?.exp_year}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <CardElement className="p-3.5 px-4 border rounded-sm w-full focus:outline-none focus-within:border-violet-400 border-gray-300 py-3.5" />
            )}

            {/* <div className="p-3.5 px-4 border rounded-sm w-full focus:outline-none focus-within:border-violet-400 border-gray-300 py-3.5"></div> */}

            <hr className="mt-3" />
            {isNote && (
              <p className="text-xs text-gray-400 mb-12">
                * All cards are encrypted and stored
              </p>
            )}

            {!selectedCard && (
              <div className="mt-5 flex flex-row space-x-4">
                <button
                  className="flex w-full justify-center rounded-md bg-primary-500 dark:bg-darkGray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                  // disabled={!stripe || loading}
                >
                  {t("common.add")}
                  {!stripe ? (
                    <i className="fad fa-circle-notch fa-spin ml-2" />
                  ) : (
                    ""
                  )}
                </button>
                {isSkip && (
                  <button
                    className="flex w-full justify-center rounded-md bg-primary-500 dark:bg-darkGray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                    onClick={() => navigate("/register?step=4")}
                  >
                    Skip
                  </button>
                )}
                {/* <button
                  className="w-full rounded-md p-4 py-2.5 m-1 text-white text-lg bg-violet-500 hover:bg-violet-700 disabled:bg-violet-200"
                  disabled={!stripe || loading}
                >
                  {t("common.add")}
                  {!stripe ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
                </button> */}
              </div>
            )}
          </div>

          {selectedCard != "" && (
            <>
              <div className="xl:w-[45%] mr-0 xl:mr-12">
                <p className="text-primary-500 uppercase font-bold mb-4">
                  Billing Address
                </p>
                <div className="mb-4">
                  <p className="mb-1.5 tracking-wide text-gray-400 text-[15px] font-bold">
                    Billing Address
                  </p>
                  <input
                    type="text"
                    className="p-3.5 px-4 border rounded-sm w-full focus:outline-none focus-within:border-violet-400 border-gray-300 py-2.5 mb-2"
                    // placeholder="123 Main Street"
                    value={
                      (selectedCard?.billing_details?.address?.line1 || "-") +
                      (selectedCard?.billing_details?.address?.line2
                        ? " " + selectedCard?.billing_details?.address?.line2
                        : "")
                    }
                  />
                </div>

                <div className="grid grid-cols-3 gap-2">
                  <div>
                    <p className="mb-1.5 tracking-wide text-gray-400 text-[15px] font-bold">
                      City
                    </p>
                    <input
                      type="text"
                      className="p-3.5 px-4 border rounded-sm w-full focus:outline-none focus-within:border-violet-400 border-gray-300 py-2.5 mb-2"
                      //  placeholder="City"
                      value={
                        selectedCard?.billing_details?.address?.city || "-"
                      }
                    />
                  </div>

                  <div>
                    <p className="mb-1.5 tracking-wide text-gray-400 text-[15px] font-bold">
                      State
                    </p>
                    <input
                      type="text"
                      className="p-3.5 px-4 border rounded-sm w-full focus:outline-none focus-within:border-violet-400 border-gray-300 py-2.5 mb-2"
                      // placeholder="State"
                      value={
                        selectedCard?.billing_details?.address?.state || "-"
                      }
                    />
                  </div>

                  <div>
                    <p className="mb-1.5 tracking-wide text-gray-400 text-[15px] font-bold">
                      Zip
                    </p>
                    <input
                      type="text"
                      className="p-3.5 px-4 border rounded-sm w-full focus:outline-none focus-within:border-violet-400 border-gray-300 py-2.5 mb-2"
                      // placeholder="ZIP"
                      value={
                        selectedCard?.billing_details?.address?.postal_code ||
                        "-"
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="xl:w-[10%] flex flex-row xl:flex-col gap-2 items-end justify-end xl:justify-start">
                <p className="text-green-500 font-bold mr-2 xl:mr-0">
                  Default Card
                </p>
                <p
                  className="text-red-500 font-bold cursor-pointer"
                  data-tooltip-id="remove_card"
                  data-tooltip-content="You must have at least one active payment method. 
            Please add another card before removing this"
                  // onClick={()=>setSelectedCard("")}
                >
                  Remove Card
                </p>
              </div>
            </>
          )}
        </div>
      </form>
      <Tooltip id="remove_card" />
    </>
  );
};

export default CheckoutForm;
