import { Divider, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { settingsIcon } from "../assets/images";

const TableActionDropdown = ({
  onClickEdit,
  onClickDelete,
  onClickArchive,
  archived_at,
  name,
}) => {
  const { t } = useTranslation();
  const menu = (
    <div className="flex flex-col bg-white shadow-lg rounded-xl border border-warmgray-200">
      {onClickEdit && (
        <button
          className="block w-full px-4 py-2 hover:bg-primary-500 hover:text-white"
          onClick={onClickEdit}
        >
          {t("common.edit")}
        </button>
      )}
      {onClickDelete && (
        <>
          <Divider className="m-0" />
          <button
            className="block w-full px-4 py-2 hover:bg-primary-500 hover:text-white"
            onClick={onClickDelete}
          >
            {t("common.delete")}
          </button>
        </>
      )}
      {onClickArchive && (
        <button
          className="block w-full px-4 py-2 hover:bg-primary-500 hover:text-white rounded-xl"
          onClick={onClickArchive}
        >
          {archived_at !== null ? "Restore the Position" : "Archive Position"}
        </button>
      )}
    </div>
  );
  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger="click">
      <i
        className={`text-gray-600 hover:text-indigo-900 far fa-ellipsis-h ${
          name === "jobOpening" &&
          "border hover:border-gray-400 rounded-full p-1"
        }`}
      />
    </Dropdown>
  );
};

export const DropActionDropdown = ({
  className,
  menus,
  type,
  icon = "far fa-ellipsis-v",
  iconColor,
  iconSize,
  image,
}) => {
  const menu = (
    <div className="flex flex-col bg-white shadow-lg rounded-xl border border-gray-50">
      {menus.map((menu, i) => (
        <button
          key={i}
          className={`block w-full px-4 py-2 ${
            type === "shift"
              ? "hover:bg-orange-900 hover:text-white"
              : "hover:bg-primary-500 hover:text-white"
          } disabled:opacity-50`}
          onClick={menu.action}
          disabled={menu.disabled}
        >
          {menu.label}
        </button>
      ))}
    </div>
  );
  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger="click"
      className={"cursor-pointer " + className}
    >
      {image === "settings" ? (
        <img src={settingsIcon} className="w-4 h-4 md:h-5 md:w-5" />
      ) : (
        <i
          className={
            "hover:text-black " + iconSize + " " + iconColor + " " + icon
          }
        />
      )}
    </Dropdown>
  );
};

export default TableActionDropdown;

export const DropActionButtonDropdown = ({ className, menus, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const updatePosition = () => {
      if (buttonRef.current && dropdownRef.current && isOpen) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        // Check if dropdown would go below viewport
        const dropdownHeight = dropdownRect.height;
        const spaceBelow = viewportHeight - buttonRect.bottom;
        const shouldShowAbove =
          spaceBelow < dropdownHeight && buttonRect.top > dropdownHeight;

        dropdownRef.current.style.position = "absolute";
        // Set the width to match the button width
        dropdownRef.current.style.width = `${buttonRect.width}px`;

        if (shouldShowAbove) {
          dropdownRef.current.style.bottom = "100%";
          dropdownRef.current.style.top = "auto";
          dropdownRef.current.style.marginBottom = "0.5rem";
        } else {
          dropdownRef.current.style.top = "100%";
          dropdownRef.current.style.bottom = "auto";
          dropdownRef.current.style.marginTop = "0.5rem";
        }

        // Center align the dropdown with the button
        dropdownRef.current.style.left = "0";
        dropdownRef.current.style.right = "auto";
      }
    };

    if (isOpen) {
      updatePosition();
      window.addEventListener("scroll", updatePosition);
      window.addEventListener("resize", updatePosition);
    }

    return () => {
      window.removeEventListener("scroll", updatePosition);
      window.removeEventListener("resize", updatePosition);
    };
  }, [isOpen]);

  const handleClick = (action) => {
    action();
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        buttonRef.current &&
        dropdownRef.current &&
        !buttonRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  return (
    <div className="relative" ref={buttonRef}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`py-2 w-full cursor-pointer rounded-xl px-6 flex items-center justify-center text-white text-sm font-bold shadow-md ${
          type === "shift"
            ? "bg-orange-900 hover:bg-orange-400"
            : "bg-primary-500 dark:bg-darkGray hover:bg-primary-400"
        } transition-all hover:opacity-50 ${className}`}
      >
        <span>
          {type === "shift"
            ? "Manage"
            : type === "event"
            ? "Manage"
            : "Manage Position"}
        </span>
        <BsChevronDown className="ml-1.5 text-white text-sm" />
      </div>

      {isOpen && (
        <div ref={dropdownRef} className="z-50 shadow-lg">
          <div className="flex flex-col bg-white dark:text-white dark:bg-[#3A3B3D] py-2 rounded-xl border border-gray-50 dark:border-gray-600">
            {menus.map((menu, i) => (
              <button
                key={i}
                className={`block w-full px-4 py-2 text-left ${
                  type === "shift"
                    ? "hover:bg-orange-900 hover:text-white"
                    : "hover:bg-primary-500 dark:hover:bg-gray-400 hover:text-white"
                } disabled:opacity-50 ${
                  i > 0
                    ? "border-t border-warmgray-300 dark:border-gray-500"
                    : ""
                }`}
                onClick={() => handleClick(menu.action)}
                disabled={menu.disabled}
              >
                {menu.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
