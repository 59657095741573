import axios from "./api";

const base = "/business/job-position-archive";

const ArchivedJob = {
  getAll() {
    return axios.get(`${base}`);
  },
  archiveJob(Id) {
    return axios.patch(`${base}/${Id}`);
  },
  unachiveJob(id){
    return axios.delete(`${base}/${id}`);
  }
};

export default ArchivedJob;
