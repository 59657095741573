import React, { useEffect, useState, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import Company from "../../../api/Company";
import Select from "react-select";
import Region from "../../../api/Region";

const MultiSelector = ({
  refresh,
  dropDownData,
  selectedValues = false,
  isSearchable = false,
  placeholder,
  callBackHandler,
  disabled = false,
  isMultiSelect = false,
}) => {
  const [data, setData] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [open, setOpen] = useState(true);
  const dropDownRef = useRef();

  useEffect(()=>{
    if(selectedValues) {
      setSelectedValue(selectedValues);
    }
  },[selectedValues])

  useEffect(() => {
    
    

    setData(dropDownData);
  }, [refresh]);

  const selectStyles = (open) => ({
    singleValue: (provided) => ({ ...provided, color: "#666" }),
    menu: (provided) => ({
      ...provided,
      marginTop: 3,
      borderRadius: 10,
      fontSize: 13,
      zIndex: 1000,
      color: "#44403c",
      fontWeight: 500,
      height: open ? "" : "0px",
      overflow: "auto",
      opacity: open ? 1 : 0,
      transition: "all .2s ease-in-out",
      visibility: open ? "visible" : "hidden",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: "40px",
      padding: "0 6px",
      boxShadow: "3px",
      border: 0,
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontWeight: 400,
        color: "#666666",
      };
    },
    control: (base, state) => ({
      ...base,
      boxShadow: "0px 1px 0px 0px gray",
    }),
  });

  const handleChange = (e) => {
    // if (selectedValues) {
    //   setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
    // }

    if (isMultiSelect) {
      callBackHandler(e, isMultiSelect);
    } else {
      callBackHandler(e.value, e.label);
    }
  };

  return (
    <div
      className="min-w-full transition-all ease-in-out duration-1000"
      onClick={() => setOpen(open)}
    >
      {selectedValues ? (
        <Select
          className="transition-all ease-in-out duration-1000"
          options={data}
          placeholder={placeholder}
          isSearchable={isSearchable}
          onChange={handleChange}
          isMulti={isMultiSelect}
          onBlur={() => setOpen(true)}
          value={
            !isMultiSelect
              ? data &&
                data.filter(function (option) {
                  return option.value === selectedValue;
                })
              : selectedValue
          }
          isDisabled={disabled}
          styles={selectStyles(open)}
          theme={(theme) => ({
            ...theme,
            borderRadius: 7,
            colors: {
              ...theme.colors,
              primary25: "#A899E2",
              primary: "#7D66D3 ",
            },
          })}
        />
      ) : (
        <Select
          className="transition-all ease-in-out duration-1000"
          options={data}
          placeholder={placeholder}
          isSearchable={isSearchable}
          onChange={handleChange}
          isMulti={isMultiSelect}
          onBlur={() => setOpen(true)}
          isDisabled={disabled}
          styles={selectStyles(open)}
          theme={(theme) => ({
            ...theme,
            borderRadius: 7,
            colors: {
              ...theme.colors,
              primary25: "#A899E2",
              primary: "#7D66D3 ",
            },
          })}
        />
      )}
      {selectedValue && (
        <div style={{ marginTop: 20, lineHeight: "25px" }}>
          <div>
            {/* <b>Selected Value: </b> {JSON.stringify(selectedValue, null, 2)} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelector;
