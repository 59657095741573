export const darkThemeColors = {
  neutral0: "#242526", // Background of the select
  neutral5: "#242526", // Background of disabled select
  neutral10: "#374151", // Selected option background
  neutral20: "#ffffff", // Border color
  neutral30: "#ffffff", // Border color on hover (white)
  neutral40: "#d1d5db", // Text color for placeholder (gray-300)
  neutral50: "#d1d5db", // Text color for 'No options' (gray-300)
  neutral60: "#ffffff", // Arrow color on hover (white)
  neutral70: "#d1d5db", // Arrow color (gray-300)
  neutral80: "#ffffff", // Text color (white)
  neutral90: "#ffffff", // Text color focused (white)
  primary: "#ffffff", // Primary color - focus ring (white)
  primary25: "#374151", // Option background on hover
  primary50: "#4b5563", // Option background when clicked
  primary75: "#6b7280", // Option background when clicked
};

export const lightThemeColors = {
  neutral0: "#ffffff",
  neutral5: "#f9fafb",
  neutral10: "#f3f4f6",
  neutral20: "#e5e7eb",
  neutral30: "#d1d5db",
  neutral40: "#9ca3af",
  neutral50: "#6b7280",
  neutral60: "#4b5563",
  neutral70: "#374151",
  neutral80: "#1f2937",
  neutral90: "#111827",
  primary: "#7D66D3",
  primary25: "#D3D3D3",
  primary50: "#9CA3AF",
  primary75: "#6B7280",
};
