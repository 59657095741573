import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { selectLoggedIn, selectInit, logout, login } from "../store/authSlice";
import api from "../api/api";

const RouteGuard = ({ children, auth, guest }) => {
  const dispatch = useDispatch();
  let loggedIn = useSelector(selectLoggedIn);
  let init = useSelector(selectInit);

  if (!init) {
    // Check if there is a token stored inside localStorage
    if (localStorage.getItem("token")) {
      api
        .get("business/auth/user")
        .then((response) => {
          dispatch(
            login({
              user: response.data.user,
              token: localStorage.getItem("token"),
              permissions: response.data.permissions,
              roles: response.data.roles,
              accessible_entities: response.data.accessible_entities,
              enterprise: response.data.enterprise,
            })
          );
        })
        .catch(() => {
          dispatch(logout());
        });
    } else {
      dispatch(logout());
    }
    return "";
  } else {
    if (auth) {
      return loggedIn ? children : <Navigate to="/login" />;
    } else if (guest) {
      if (!loggedIn) {
        return children;
      } else {
        return <Navigate to="/dashboard" />;
      }
    } else {
      console.log("in routeguardsss");
      return children;
    }
  }
};

export default RouteGuard;
