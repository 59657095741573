import axios from "./api";

const User = {
  Login(body) {
    return axios.post("/business/login", body);
  },
  Register(body) {
    return axios.post("/business/auth/register", body);
  },
  VerifyUnique(body) {
    return axios.post("/business/auth/verify-unique", body);
  },
  ResetPassword(body) {
    return axios.post("/business/auth/change-password", body);
  },
  ResetPasswordRequest(body) {
    return axios.post("/business/password-reset/request", body);
  },
  ResetPasswordChange(body) {
    return axios.post("/business/password-reset/change", body);
  }
};

export default User;

