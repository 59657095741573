import React, { useEffect, useState } from "react";
import {
  selectUser,
  selectSelectedLocation,
  selectRoles,
  darkmode,
} from "../../store/authSlice";
import { useSelector } from "react-redux";
import FormSelect from "../atoms/FormSelect";
import Location from "../../api/Location";
import ReactSelect from "react-select";
import { darkThemeColors, lightThemeColors } from "../../themeColors";

const modes = [
  { value: "light", label: "Light Mode" },
  { value: "dark", label: "Dark Mode" },
];

const InstallCodeManually = () => {
  const user = useSelector(selectUser);
  const roles = useSelector(selectRoles);
  const selectedLocation = useSelector(selectSelectedLocation);
  const domainUrl =
    process.env.REACT_APP_API_BASE_URL ===
    "https://stagingapi.heyhire.net/api/v1/"
      ? "https://staging.heyhire.app"
      : "https://heyhire.app";
  const [sourceUrl, setSourceUrl] = useState("");
  const [iframesText, setIframeText] = useState("");
  const [theme, setTheme] = useState("light");
  const mode = useSelector(darkmode);
  const [locations, setLocations] = useState([]);
  const [localSelectedLocation, setLocalSelectedLocation] = useState([]);

  const loadLocations = async () => {
    Location.getAll().then((response) => {
      if (response.data.data) {
        const resLocations = response.data.data;
        setLocations(resLocations);
        if (selectedLocation?.id) {
          setLocalSelectedLocation({
            value: selectedLocation?.id,
            label: selectedLocation?.name,
          });
        }
      }
    });
  };

  useEffect(() => {
    loadLocations();
  }, []);

  useEffect(() => {
    let _tempurl = "";
    let _selectedLocation = locations?.find(
      (l) => l?.id === localSelectedLocation?.value
    );
    if (_selectedLocation?.brand?.url_slug && _selectedLocation?.url_slug) {
      _tempurl = `${domainUrl}/embed/${_selectedLocation?.brand?.url_slug}/${_selectedLocation?.url_slug}/?theme=${theme}`;
    } else if (
      !_selectedLocation?.url_slug &&
      _selectedLocation?.brand?.url_slug
    ) {
      _tempurl = `$${domainUrl}/embed/${_selectedLocation?.brand?.url_slug}/?theme=${theme}`;
    }
    setSourceUrl(_tempurl);
    //     setIframeText(
    //           `<div style={{padding-bottom:'56.25%'; position:'relative'; display:'block'; height:'60%'; width:'100%'}}>
    //   <iframe width="100%" height="800px"
    //     src="${_tempurl}"
    //     name="heyhire" scrolling="yes" frameborder="0" marginheight="0px" marginwidth="0px" allowfullscreen="" style={{position:'absolute'; top:'0'; left: '0'}}>
    //   </iframe>
    // </div>`
    //         );

    setIframeText(
      `<div style={{ position: 'relative', overflow: 'hidden', paddingTop: '75%', height: '0' }}>
  <iframe
    title="heyhire"
    src="${_tempurl}"
    style={{
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      border: '0',
    }}
    allowFullScreen=""
  ></iframe>
</div>`
    );

    //setIframeText(`<iframe src="${_tempurl}" style={{border:'0px', color: '#ffffff', overflow: 'hidden'}} name="heyhire" scrolling="yes" frameborder="0" marginheight="0px" marginwidth="0px" height="800px" width="900px" allowfullscreen></iframe>`);
  }, [localSelectedLocation, theme]);

  const handleMessageChange = (event) => {
    setIframeText(event.target.value);
  };

  return (
    <div className="px-1 md:px-5 lg:px-5 pt-10 pb-20">
      <div className="flex flex-col md:flex-row w-full xl:w-3/4">
        <div className="md:pr-4 w-full">
          <FormSelect
            name="themeMode"
            label="Select Theme Mode for iframes"
            placeholder="Select Theme Mode for iframes"
            defaultValue={theme}
            onChange={(value) => setTheme(value?.value)}
            options={modes}
            darmo={mode}
          />
        </div>
        {user?.company?.is_enterprise === 1 && (
          <div className="w-full">
            <span className="font-bold block text-gray-900 dark:text-gray-300">
              Locations
            </span>
            <ReactSelect
              value={localSelectedLocation}
              theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                spacing: {
                  ...theme.spacing,
                  controlHeight: 40,
                },
                colors: {
                  ...theme.colors,
                  ...(mode ? lightThemeColors : darkThemeColors),
                },
              })}
              onChange={(selected) => setLocalSelectedLocation(selected)}
              options={locations.map((location) => {
                return {
                  value: location.id,
                  label: location.name,
                };
              })}
              styles={{
                menu: (base) => ({
                  ...base,
                  backgroundColor: mode ? "#ffffff" : "#F5F5F5",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: mode
                    ? state.isFocused
                      ? "#D3D3D3"
                      : "#ffffff"
                    : state.isFocused
                    ? "#D3D3D3"
                    : "#F5F5F5",
                  ":active": {
                    backgroundColor: mode ? "#9CA3AF" : "#A9A9A9",
                  },
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: mode ? "#f3f4f6" : "#374151",
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: mode ? "#1f2937" : "#f3f4f6",
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  color: mode ? "#6b7280" : "#9ca3af",
                  ":hover": {
                    backgroundColor: mode ? "#e5e7eb" : "#4b5563",
                    color: mode ? "#1f2937" : "#f3f4f6",
                  },
                }),
              }}
            />
          </div>
        )}
      </div>
      <p className="my-4 text-gray-900 dark:text-gray-300">
        Copy this code and paste it on your site
      </p>
      <textarea
        className="border-2 border-warmgray-900 rounded-md p-3 w-full h-72 mb-4 dark:bg-darkGray dark:text-gray-300"
        value={iframesText}
        onChange={handleMessageChange}
      />
      <div className="w-full">
        <iframe
          src={sourceUrl}
          style={{ border: "0px", color: "#ffffff", overflow: "hidden" }}
          name="heyhire"
          scrolling="yes"
          frameborder="0"
          marginheight="0px"
          marginwidth="0px"
          height="800px"
          width="100%"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default InstallCodeManually;
