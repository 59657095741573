import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { RiEyeFill } from "react-icons/ri";
import { DropActionDropdown } from "../../TableActionDropdown";
import JobPosition from "../../../api/JobPosition";
import { toast } from "react-toastify";
import ArchivedJob from "../../../api/ArchivedJob";
import { encodeIds } from "../../../utils";
import { applicantIcon, applicantWhiteIcon, hireApplicantIcon, hiredWhiteIcon, startChatWhiteIcon } from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { setShiftsArray, shiftsArray } from "../../../store/authSlice";
import moment from "moment";

export const ShiftJobCard = ({ onClick, index, className, type, jobPosition, loadJobs, darmo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const existingShifts = useSelector(shiftsArray);
  const [hiredEmployees, setHiredEmployees] = useState(0);
  // const activeApp =
  //   jobPosition.applications.filter((f) => f.status === "applied") || [];

  useEffect(() => {
    // loadEmployees();
  }, []);

  const ArchivePosition = async () => {
    try {
      await JobPosition.archive(jobPosition.id);
      loadJobs();
      toast.success("Job Archived Successfully");
    } catch (error) {
      toast.error("Failed to archive job. Please try again.");
    }
  };

  const UnarchivePosition = async () => {
    try {
      await ArchivedJob.unachiveJob(jobPosition.id);
      loadJobs();
      toast.success("Job Restored Successfully");
    } catch (error) {
      toast.error("Failed to archive job. Please try again.");
    }
  };

  const DeletePosition = async () => {
    try {
      await JobPosition.delete(jobPosition.id);
      loadJobs();
      toast.success("Job Deleted Successfully");
    } catch (error) {
      toast.error("Failed to archive job. Please try again.");
    }
  };

  const loadEmployees = async () => {
    try {
      const tempUsers = await JobPosition.getHiredEmployees(jobPosition.id);
      setHiredEmployees(tempUsers?.data?.data.length);
    } catch (error) {
      toast.error("Failed to load. Please try again.");
    }
  };

  return (
    <div className={className} key={index}>
      <div className={`relative rounded-sm border bg-[#F1B257] flex flex-col items-stretch text-center p-4 ` + className}>
        <div className="relative flex items-center">
            <div className="flex justify-center w-full">
                <p className="text-xl xl:text-2xl font-extrabold text-white">{jobPosition?.position}</p>
            </div>
            <div className="absolute right-0">
                <DropActionDropdown
                    image={"shiftSettings"}
                    type="shift"
                    className="flex justify-end"
                    icon="far fa-cog"
                    iconSize="text-lg"
                    iconColor={"text-white"}
                    menus={[
                    {
                    label:
                        type === "event" ? "Delete Shift" :
                        jobPosition?.archived_at != null
                            ? "Restore the Position"
                            : "Archive Position",
                    action: 
                        type === "event" ? () => dispatch(setShiftsArray(existingShifts.filter((_, i) => i !== index))) : 
                        jobPosition?.archived_at != null
                          ? () => UnarchivePosition()
                          : () => ArchivePosition(),
                    },
                    // { label: "Delete Position", action: DeletePosition, disabled: jobPosition.deleted_at != null },
                    ]}
                />
            </div>
        </div>
          
        <div className="mt-4 text-white font-bold text-[16px]">  
            <p>Date: <span className="font-arial">{`${moment(jobPosition?.startDate).format('dddd MMMM Do')}`}</span></p>
            <p className="mt-1">{`Shift Time: ${jobPosition?.time} Shift`}</p>

            {type !== "event" &&
            <>
            <div className="flex flex-col xl:flex-row justify-center gap-2 xl:gap-4 mt-4">
                <div className="flex justify-center">
                    <img
                        src={applicantWhiteIcon}
                        alt="logo"
                        className="mr-1 my-auto w-4 h-4"
                    />
                    <span className="font-extrabold text-sm">Available Shifters (12)</span>
                </div>

                <div className="flex justify-center">
                    <img
                        src={startChatWhiteIcon}
                        alt="logo"
                        className="mr-1 my-auto w-4 h-4 mt-0.5"
                    />
                    <span className="font-extrabold text-sm">Active Reachouts (3)</span>
                </div>
            </div>

            <div className="flex justify-center mt-2">
                <img
                 src={hiredWhiteIcon}
                 alt="logo"
                 className="mr-1 my-auto w-4 h-4"
                />
                <span className="font-extrabold text-sm">Confirmed (1/3)</span>
            </div>
            </>
            }
        </div>

        <div className="pt-4 w-full mt-auto">
            <div className="w-full flex items-center justify-center">
              <button
                // onClick={() => navigate("/business/open-shift/123")}
                onClick={() => type === "event" ? onClick() : navigate("/-now-")}
                className={`${type === "event" ? "mt-6" : ""} py-2 w-auto cursor-pointer rounded-xl px-4 flex items-center justify-center text-sm font-extrabold shadow-md 
                  transition-all hover:opacity-50 whitespace-nowrap bg-white text-[#F1B257]`}
              >
                {type === "event" ? "Edit" : "Manage"}
              </button>
            </div>  
        </div>
      </div>
    </div>
  );
};

export default ShiftJobCard;
