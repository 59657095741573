import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../components/form/Input";
import NotificationSettingItem from "../../../components/NotificationSettingItem";
import { DashboardLayout } from "../../../components/organisms/Layout";

const GeneralSettings = () => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState("abc123abc");
  const [newPassword, setNewPassword] = useState("abc123abc");
  const [confirmNewPassword, setConfirmNewPassword] = useState("abc123abc");

  // Toggles
  const [accountAlerts, setAccountAlerts] = useState(false);
  const [jobAlerts, setJobAlerts] = useState(false);

  useEffect(() => {
    document.title = "HeyHire Portal-General Settings";
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="flex justify-between">
        <h2 className="font-bold text-2xl">
          {t("general-settings.general-settings")}
        </h2>
      </div>

      <div className="mt-5">
        <h3 className="font-bold text-base">
          {t("general-settings.security")}
        </h3>
        <hr className="mt-2 mb-4" />
        <div className={"grid grid-cols-1 md:grid-cols-3 gap-2"}>
          <Input
            name="currentPassword"
            type="password"
            icon="far fa-lock"
            label={t("general-settings.current-password")}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            darmo={true}
          />

          <Input
            name="newPassword"
            type="password"
            icon="far fa-lock"
            label={t("general-settings.new-password")}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            darmo={true}
          />

          <Input
            name="confirmNewPassword"
            type="password"
            icon="far fa-lock"
            label={t("general-settings.confirm-new-password")}
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            darmo={true}
          />
        </div>
      </div>

      <div className="mt-8">
        <h3 className="font-bold text-base">
          {t("general-settings.notifications")}
        </h3>
        <hr className="my-2" />

        <NotificationSettingItem
          title={t("general-settings.account-alerts")}
          description={t("general-settings.account-alerts-description")}
          value={accountAlerts}
          onChange={(e) => setAccountAlerts(e.target.checked)}
        />

        <NotificationSettingItem
          title={t("general-settings.message-alerts")}
          description={t("general-settings.message-alerts-description")}
          value={false}
          disabled={true}
        />

        <NotificationSettingItem
          title={t("general-settings.job-alerts")}
          description={t("general-settings.job-alerts-description")}
          value={jobAlerts}
          onChange={(e) => setJobAlerts(e.target.checked)}
        />

        <NotificationSettingItem
          title={t("general-settings.content-updates")}
          description={t("general-settings.content-updates-description")}
          value={false}
          disabled={true}
        />

        <NotificationSettingItem
          title={
            <span className="text-sm">
              {t("general-settings.operational-email-toggle")}
            </span>
          }
          description={t("general-settings.operational-email-description")}
          value={false}
          disabled={true}
        />
      </div>

      <div className="mt-12 flex gap-3 pb-12">
        <button
          className="w-40 flex justify-center items-center rounded-full bg-white border-2 text-black border-primary-500 py-2 text-sm font-semibold leading-6 hover:text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
          onClick={() => {}}
        >
          {t("common.clear")}
        </button>

        <button
          className="w-40 flex justify-center items-center rounded-full bg-primary-500 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50 cursor-pointer"
          onClick={() => {}}
        >
          {t("common.save")}
        </button>
      </div>
    </DashboardLayout>
  );
};

export default GeneralSettings;
