import axios from "./api";
const paymentBaseUrl = "/admin/payment";

const PaymentDetails = {
  getAllCustomers(query = null) {
    return axios.get(`${paymentBaseUrl}/customers?${query ? query : ""}`);
  },
  getLocationByCustomerId(id) {
    return axios.get(`${paymentBaseUrl}/location?customer_id=` + id);
  },
  getAllSubscriptions(query = null) {
    return axios.get(`${paymentBaseUrl}/subscriptions?${query ? query : ""}`);
  },
  getLatestPayout() {
    return axios.get(`${paymentBaseUrl}/payout`);
  },
  getLatestRefund() {
    return axios.get(`${paymentBaseUrl}/refund`);
  },
  getLatestSubscription() {
    return axios.get(`${paymentBaseUrl}/latest-subscription`);
  },
  getCustomerById(id) {
    return axios.get(`${paymentBaseUrl}/customer?customer_id=` + id);
  },
   blockAccount(id) {
    return axios.patch(`${paymentBaseUrl}/block/` + id);
  }
};

export default PaymentDetails;
