import axios from "./api";
const baseUrl = "/business/application-event-log";

const ApplicationLogs = {
  addEvent(logs) {
    return axios.post(`${baseUrl}`, logs);
  },
};

export default ApplicationLogs;
