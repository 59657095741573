import React from "react";
import "react-loading-skeleton/dist/skeleton.css";

const CardLoading = () => {
  return (
    <li className="col-span-1 flex flex-col divide-y divide-warmgray-300 dark:divide-gray-700 rounded-lg text-center bg-white dark:bg-[#2C2D30]">
      <div className="border border-gray-300 dark:border-darkGray shadow rounded-md p-4 w-full">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="flex flex-1 items-center justify-center">
              <div className="m-4 rounded-full bg-gray-300 dark:bg-[#363739] h-12 w-12 flex-shrink-0"></div>
            </div>
            <div className="h-2  bg-gray-300 dark:bg-[#363739] rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-gray-300 dark:bg-[#363739] rounded col-span-2"></div>
                <div className="h-2 bg-gray-300 dark:bg-[#363739] rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-gray-300 dark:bg-[#363739] rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CardLoading;
