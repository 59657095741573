import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { OpenJobs, LineChart } from "../../../assets/images";
import "../ViewJob/ViewJob.scss";
import ApexCharts from "apexcharts";
import FormSelect from "../../../components/atoms/FormSelect";
import {
  darkmode,
  locations,
  selectRoles,
  selectSelectedLocation,
} from "../../../store/authSlice";
import { useSelector } from "react-redux";

const AnalyticsTable = ({ mode }) => {
  const { t } = useTranslation();
  const roles = useSelector(selectRoles);
  const allLocations = useSelector(locations);
  const showLocationDropdown =
    roles[0] !== "employee" ||
    roles[0] === "super-user" ||
    roles[0] === "regional_manager";
  const [selectedOption, setSelectedOption] = useState(null);
  const chartRef = useRef(null); // Reference to the chart container
  const [isInView, setIsInView] = useState(false); // Track if the chart is in view
  const selectedLocation = useSelector(selectSelectedLocation);

  useEffect(() => {
    // Intersection Observer setup
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsInView(true); // Set to true when the chart enters the viewport
          observer.disconnect(); // Stop observing once it's in view
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    if (chartRef.current) {
      observer.observe(chartRef.current);
    }

    return () => {
      if (chartRef.current) observer.unobserve(chartRef.current);
    };
  }, []);

  useEffect(() => {
    // Render the chart when it is in view
    if (isInView) {
      const getChartOptions = () => {
        return {
          series: [52.8, 26.8, 20.4],
          colors: ["#1C64F2", "#16BDCA", "#9061F9"],
          chart: {
            height: 420,
            width: "100%",
            type: "pie",
          },
          stroke: {
            colors: ["white"],
            lineCap: "",
          },
          plotOptions: {
            pie: {
              labels: {
                show: true,
              },
              size: "100%",
              dataLabels: {
                offset: -25,
              },
            },
          },
          labels: ["Direct", "Organic search", "Referrals"],
          dataLabels: {
            enabled: true,
            style: {
              fontFamily: "Inter, sans-serif",
            },
          },
          legend: {
            position: "bottom",
            fontFamily: "Inter, sans-serif",
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value + "%";
              },
            },
          },
          xaxis: {
            labels: {
              formatter: function (value) {
                return value + "%";
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
        };
      };

      if (typeof ApexCharts !== "undefined") {
        const chart = new ApexCharts(
          document.getElementById("pie-chart"),
          getChartOptions()
        );
        chart.render();

        return () => {
          chart.destroy(); // Clean up the chart when the component is unmounted
        };
      }
    }
  }, [isInView]);

  useEffect(() => {
    if (selectedLocation) {
      if (selectedLocation?.name === "Select A Location") {
        setSelectedOption({
          value: "all-locations",
          label: "All Locations",
        });
      } else {
        setSelectedOption({
          value: selectedLocation,
          label: selectedLocation?.name,
        });
      }
    }
  }, [selectedLocation]);

  return (
    <>
      <div className="w-full flex justify-end items-end">
        {roles[0] !== "turnkey_admin" &&
          showLocationDropdown &&
          allLocations && (
            <div className="flex my-4 md:w-1/2 lg:w-1/3 xl:w-1/4">
              <FormSelect
                type="add-job"
                label="Select Location"
                placeholder="Select Location"
                classNames="font-bold"
                options={[
                  { value: "all-locations", label: "All Locations" },
                  ...allLocations?.map((info) => ({
                    value: info,
                    label: info?.name,
                  })),
                ]}
                onChange={(selectedOption) => {
                  setSelectedOption(selectedOption);
                }}
                value={
                  selectedOption
                    ? {
                        label: selectedOption?.label || "All Locations",
                        value: selectedOption?.value || "all-locations",
                      }
                    : { value: "all-locations", label: "All Locations" }
                }
                darmo={mode}
              />
            </div>
          )}
      </div>
      <div
        className="pb-5 mx-auto z-10 bg-fixed bg-cover bg-center"
        style={{ backgroundImage: mode ? `url(${LineChart})` : "none" }}
      >
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
            <div className="relative overflow-hidden rounded-lg px-4 pb-12 pt-5 dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray shadow-md shadow-gray-500 dark:shadow-none sm:px-6 sm:pt-6 bg-transparent">
              <dt>
                <div className="absolute rounded-md bg-transparent p-3">
                  <img src={OpenJobs} alt="logo" className="h-6 w-6we" />
                </div>
                <p className="ml-16 truncate font-bold text-sm text-[#333333] dark:text-gray-500">
                  Open Jobs
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900 dark:text-gray-300">
                  10
                </p>
                <div className="p-1 rounded bg-emerald-500/10 text-emerald-500 text-[12px] font-semibold leading-none ml-2">
                  +30% this week
                </div>
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 dark:bg-darkGray px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <a
                      href="#1"
                      className="font-medium text-indigo-600 dark:text-gray-300 dark:hover:text-gray-500 hover:text-indigo-500"
                    >
                      View all
                    </a>
                  </div>
                </div>
              </dd>
            </div>
            <div className="relative overflow-hidden rounded-lg bg-transparent dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray px-4 pb-12 pt-5 shadow-md shadow-gray-500 dark:shadow-none sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-lg bg-gray-50 p-3">
                  <img src={OpenJobs} alt="logo" className="h-6 w-6we" />
                </div>
                <p className="ml-16 truncate text-sm font-bold text-[#333333] dark:text-gray-500">
                  Open Jobs
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900 dark:text-gray-300">
                  10
                </p>
                <div className="p-1 rounded bg-emerald-500/10 text-emerald-500 text-[12px] font-semibold leading-none ml-2">
                  +30% this week
                </div>
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 dark:bg-darkGray px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <a
                      href="#1"
                      className="font-medium text-indigo-600 dark:text-gray-300 dark:hover:text-gray-500 hover:text-indigo-500"
                    >
                      View all
                    </a>
                  </div>
                </div>
              </dd>
            </div>
            <div className="relative overflow-hidden rounded-lg bg-transparent dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray px-4 pb-12 pt-5 shadow-md dark:shadow-none shadow-gray-500 sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-gray-50 p-3">
                  <img src={OpenJobs} alt="logo" className="h-6 w-6we" />
                </div>
                <p className="ml-16 truncate text-sm font-bold text-[#333333] dark:text-gray-500">
                  Open Jobs
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900 dark:text-gray-300">
                  10
                </p>
                <div className="p-1 rounded bg-emerald-500/10 text-emerald-500 text-[12px] font-semibold leading-none ml-2">
                  +30% this week
                </div>
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 dark:bg-darkGray px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <a
                      href="#1"
                      className="font-medium text-indigo-600 dark:text-gray-300 dark:hover:text-gray-500 hover:text-indigo-500"
                    >
                      View all
                    </a>
                  </div>
                </div>
              </dd>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6 text-[#333333]">
            <div className="relative overflow-hidden rounded-lg px-4 pt-4 shadow-md dark:shadow-none border border-warmgray-200 dark:border-darkGray shadow-gray-500 sm:px-6 sm:pt-6 bg-white dark:bg-[#242526]">
              <div className="flex justify-between mb-4 items-start">
                <div className="font-bold dark:text-gray-300">
                  Ongoing Conversation
                </div>
                <div className="dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle text-gray-400 hover:text-gray-600"
                  >
                    <i className="ri-more-fill" />
                  </button>
                </div>
              </div>
              <div className="overflow-hidden">
                <table className="w-full min-w-[540px]">
                  <tbody>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <div className="flex items-center">
                          <a
                            href="#"
                            className="text-gray-600 dark:text-gray-300 text-sm font-medium hover:text-blue-500 ml-2 truncate"
                          >
                            kavin
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="text-[13px] font-medium text-gray-400">
                          02-02-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="text-[13px] font-medium text-gray-400">
                          17.45
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="dropdown-toggle text-gray-400 hover:text-gray-600 text-sm w-6 h-6 rounded flex items-center justify-center bg-gray-50"
                          >
                            <i className="ri-more-2-fill" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <div className="flex items-center">
                          <a
                            href="#"
                            className="text-gray-600 dark:text-gray-300 text-sm font-medium hover:text-blue-500 ml-2 truncate"
                          >
                            Rimin
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="text-[13px] font-medium text-gray-400">
                          02-02-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="text-[13px] font-medium text-gray-400">
                          17.45
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="dropdown-toggle text-gray-400 hover:text-gray-600 text-sm w-6 h-6 rounded flex items-center justify-center bg-gray-50"
                          >
                            <i className="ri-more-2-fill" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="relative overflow-hidden rounded-lg px-4 pb-12 pt-5 shadow-md border border-warmgray-200 shadow-gray-500 sm:px-6 sm:pt-6 bg-transparent">
              <div className="rounded-t mb-0 px-0 border-0">
                <div className="flex flex-wrap items-center px-4 py-2">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base text-[#333333] ">
                      Applicants
                    </h3>
                  </div>
                </div>
                <div className="block w-full overflow-x-auto">
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-4 bg-primary-500 text-white align-middle border border-solid border-gray-200  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Name
                        </th>
                        <th className="px-4 bg-primary-500 text-white   align-middle border border-solid border-gray-200  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Position Applied
                        </th>
                        <th className="px-4 bg-primary-500 text-white   align-middle border border-solid border-gray-200  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px">
                          Response Time
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      <tr className=" ">
                        <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                          kavin
                        </th>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          waiter
                        </td>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <div className="flex items-center">
                            <span className="mr-2">3 hours</span>
                            <div className="relative w-full">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-blue-200">
                                <div
                                  style={{ width: "70%" }}
                                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                          Roman
                        </th>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          cook
                        </td>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <div className="flex items-center">
                            <span className="mr-2">6 hours</span>
                            <div className="relative w-full">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-blue-200">
                                <div
                                  style={{ width: "40%" }}
                                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                          Rimin
                        </th>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          cook
                        </td>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <div className="flex items-center">
                            <span className="mr-2">5 hours</span>
                            <div className="relative w-full">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-pink-200">
                                <div
                                  style={{ width: "45%" }}
                                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pink-500"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                          Ashly
                        </th>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          Front Desk
                        </td>
                        <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <div className="flex items-center">
                            <span className="mr-2">4 hours</span>
                            <div className="relative w-full">
                              <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                                <div
                                  style={{ width: "60%" }}
                                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
            <div className=" p-6 lg:col-span-2 relative overflow-hidden rounded-lg px-4 pb-12 pt-5 border border-warmgray-200 shadow-md shadow-gray-500 sm:px-6 sm:pt-6 bg-transparent">
              <div className="flex justify-between mb-4 items-start">
                <div className="font-medium">
                  HeyHire Statistics - Job Title
                </div>
                <div className="dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle text-gray-400 hover:text-gray-600"
                  >
                    <i className="ri-more-fill" />
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                <div className="rounded-md border border-dashed border-gray-200 p-4">
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">10</div>
                    <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">
                      +2 this week
                    </span>
                  </div>
                  <span className="text-gray-400 text-sm">
                    Active Conversation
                  </span>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4">
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">50</div>
                    <span className="p-1 rounded text-[12px] font-semibold bg-emerald-500/10 text-emerald-500 leading-none ml-1">
                      +10 this week
                    </span>
                  </div>
                  <span className="text-gray-400 text-sm">
                    Completed Conversation
                  </span>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4">
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">4</div>
                    <span className="p-1 rounded text-[12px] font-semibold bg-emerald-500/10 text-emerald-500  leading-none ml-1">
                      +1 this week
                    </span>
                  </div>
                  <span className="text-gray-400 text-sm">Hired Status</span>
                </div>
              </div>
              <div>
                {/* <img
                  src={ChartSample}
                  alt=""
                  className="justify-center align-middle p-5 pl-52"
                /> */}
                <div id="pie-chart" ref={chartRef}></div>
              </div>
            </div>
            <div className="bg-white border border-gray-100 shadow-md shadow-black/5 p-6 rounded-md">
              <div className="flex justify-between mb-4 items-start">
                <div className="font-medium">Activities</div>
                <div className="dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle text-gray-400 hover:text-gray-600"
                  >
                    <i className="ri-more-fill" />
                  </button>
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full min-w-[460px]">
                  <thead>
                    <tr>
                      <th className="text-[12px] uppercase tracking-wide font-medium text-gray-400 py-2 px-4 bg-gray-50 text-left rounded-tl-md rounded-bl-md">
                        Applicant
                      </th>
                      <th className="text-[12px] uppercase tracking-wide font-medium text-gray-400 py-2 px-4 bg-gray-50 text-left">
                        Viewed Job
                      </th>
                      <th className="text-[12px] uppercase tracking-wide font-medium text-gray-400 py-2 px-4 bg-gray-50 text-left rounded-tr-md rounded-br-md">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <div className="flex items-center">
                          <img
                            src="https://placehold.co/32x32"
                            alt=""
                            className="w-8 h-8 rounded object-cover block"
                          />
                          <a
                            href="#"
                            className="text-gray-600 text-sm font-medium hover:text-blue-500 ml-2 truncate"
                          >
                            Ashley
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="text-[13px] font-medium text-emerald-500">
                          02-02-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="inline-block p-1 rounded bg-emerald-500/10 text-emerald-500 font-medium text-[12px] leading-none">
                          Pending
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <div className="flex items-center">
                          <img
                            src="https://placehold.co/32x32"
                            alt=""
                            className="w-8 h-8 rounded object-cover block"
                          />
                          <a
                            href="#"
                            className="text-gray-600 text-sm font-medium hover:text-blue-500 ml-2 truncate"
                          >
                            Kimin
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="text-[13px] font-medium text-rose-500">
                          09-02-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="inline-block p-1 rounded bg-rose-500/10 text-rose-500 font-medium text-[12px] leading-none">
                          Withdrawn
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <div className="flex items-center">
                          <img
                            src="https://placehold.co/32x32"
                            alt=""
                            className="w-8 h-8 rounded object-cover block"
                          />
                          <a
                            href="#"
                            className="text-gray-600 text-sm font-medium hover:text-blue-500 ml-2 truncate"
                          >
                            Ron
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="text-[13px] font-medium text-emerald-500">
                          02-02-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="inline-block p-1 rounded bg-emerald-500/10 text-emerald-500 font-medium text-[12px] leading-none">
                          Pending
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <div className="flex items-center">
                          <img
                            src="https://placehold.co/32x32"
                            alt=""
                            className="w-8 h-8 rounded object-cover block"
                          />
                          <a
                            href="#"
                            className="text-gray-600 text-sm font-medium hover:text-blue-500 ml-2 truncate"
                          >
                            Kavin
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="text-[13px] font-medium text-rose-500">
                          02-11-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="inline-block p-1 rounded bg-rose-500/10 text-rose-500 font-medium text-[12px] leading-none">
                          Withdrawn
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <div className="flex items-center">
                          <img
                            src="https://placehold.co/32x32"
                            alt=""
                            className="w-8 h-8 rounded object-cover block"
                          />
                          <a
                            href="#"
                            className="text-gray-600 text-sm font-medium hover:text-blue-500 ml-2 truncate"
                          >
                            Samual
                          </a>
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="text-[13px] font-medium text-emerald-500">
                          09-01-2024
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b border-b-gray-50">
                        <span className="inline-block p-1 rounded bg-emerald-500/10 text-emerald-500 font-medium text-[12px] leading-none">
                          Hired
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BusinessAnalyticDashboard = () => {
  const mode = useSelector(darkmode);
  return (
    <DashboardLayout darmo={true}>
      <div>
        <h1 className="text-xl xl:text-2xl font-bold mt-2 text-gray-900 dark:text-gray-300">
          Analytics Dashboard
        </h1>
      </div>
      <AnalyticsTable mode={mode} />
    </DashboardLayout>
  );
};

export default BusinessAnalyticDashboard;
