import React from "react";
import {useEffect} from "react";
import { useNavigate } from "react-router-dom";


export const LoadingScreen= ({ size = 50 }) => {
      const navigate = useNavigate();

      useEffect(() => {
            setTimeout(() => {
            return navigate("/login");
            }, 2000);
      }, []);
  return (
    <div className=" relative flex left-[10%] top-[40%] justify-center">
        <div>
          <img
            className="mx-auto md:w-[160px] lg:w-[290px] lg:mt-[35.67px] animate-pulse"
            src={"/images/auth-logo.svg"}
            alt="logo"
          />
        </div>
        <div>
          <div
                style={{ width: `${size}px`, height: `${size}px` }}
                className="animate-spin relative top-1 lg:top-[50px] left-10"
              >
                <div
                  className="h-full w-full border-4 border-t-orange-900
               border-b-primary-500 rounded-[50%]"
                ></div>
              </div>
        </div>
        <div className="relative top-[100px] lg:top-[150px]  right-[200px] lg:right-[309px] font-bold text-md lg:text-lg">Welcome to heyhire. Hiring made easy!</div>
    </div>
  );
};
