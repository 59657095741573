import axios from "./api";
const subscriptionBaseUrl = "business/subscription";

const Subscription = {
  // getAllSubscriptions(query = null) {
  //   return axios.get(
  //     `${subscriptionBaseUrl}/subscriptions?${query ? query : ""}`
  //   );
  // },

  subscribe(account) {
    return axios.post(subscriptionBaseUrl + "/subscribe", account);
  },
  getAllLocationSubscription() {
    return axios.get(subscriptionBaseUrl);
  },
  subscribeDetails(account){
    return axios.post("business/subscription-details", account);
  },
};

export default Subscription;
