import axios from "./api";

const base = "/business/notification";

const Notification = {
  getAllNotifications() {
    return axios.get(`${base}/getAll`);
  },
  getUnreadCount() {
    return axios.get(`${base}/unread-count`);
  },
  updateReadAt(data) {
    return axios.post(`${base}/read`, data);
  },

  //events
  getAllEvents() {
    return axios.get(`/admin/getall-events`);
  },
  addEvent(data) {
    return axios.post(`/admin/events`, data);
  },
  updateEvent(id, data) {
    return axios.put(`/admin/events/update-events/${id}`, data);
  },
  deleteEvent(id) {
    return axios.delete(`/admin/events/delete-events/${id}`);
  },

  //admin notifications
  addGlobalNotification(data) {
    return axios.post(`/admin/globalnotifications`, data);
  },
  getEventByID(id) {
    return axios.get(`/admin/globalnotifications/location/${id}`);
  },
  deleteGlobalNotification(id) {
    return axios.delete(`/admin/delete-globalnotifications/${id}`);
  },
};

export default Notification;
