import axios from "./api";

const base = "/business/certification";

const Certification = {
  getAll() {
    return axios.get(base);
  },
  getById(id) {
    return axios.get(`${base}/${id}`);
  },
  add(certification) {
    return axios.post(base, certification);
  },
  deleteById(id) {
    return axios.delete(`${base}/${id}`);
  },
  search(cid, q) {
    return axios.get(`${base}?q=${q}&cid=${cid}`);
  },
};

export default Certification;
