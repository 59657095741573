import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { addBlueIcon, addIcon, certificationsIcon, certificationsWhiteIcon, minusIcon } from "../../../assets/images";
import dayjs from "dayjs";
import FormSelect from "../../atoms/FormSelect";
import FormDateInput from "../../form/FormDateInput";
import Input from "../../form/Input";
import { useDispatch, useSelector } from "react-redux";
import { setEventPage, setNextShiftPage, setShiftsArray, shiftsArray } from "../../../store/authSlice";

const ShiftAddOrEdit = ({type, editMode, editIndex}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [position, setPosition] = useState("");
  const [spots, setSpots] = useState("");
  const [time, setTime] = useState("");
  const [wage, setWage] = useState("");
  const [isInBoundShift, setInBoundShift] = useState(false);
  const newShiftsArray = useSelector(shiftsArray);
  const [startDate, setStartDate] = useState(dayjs().add(1, "day").format("YYYY-MM-DD"));
  const [minDate, setMinDate] = useState(new Date());
  const certificateData = [
    {
      name: "Food Handlers",
      id: 1, 
    },
    {
      name: "Food Safety",
      id: 2,
    },
    {
      name: "TABC",
      id: 3,
    },
  ];
  const [selectedCertifications, setSelectedCertifications] = useState([]);

  const handleAddCertification = (item) => {
    setSelectedCertifications((prevSelected) => [...prevSelected, item]);
  };

  const handleRemoveCertification = (item) => {
    setSelectedCertifications((prevSelected) => prevSelected.filter((selectedItem) => selectedItem !== item));
  };

  const positionList = [
    {value: "Bartender", label: "Bartender"},
    {value: "Server", label: "Server"},
    {value: "Barista", label: "Barista"},
    {value: "Door Staff", label: "Door Staff"},
    {value: "DishWasher", label: "DishWasher"},
    {value: "Busser", label: "Busser"},
  ]

  const timingList = [
    {value: "Morning", label: "Morning"},
    {value: "Afternoon", label: "Afternoon"},
    {value: "Evening", label: "Evening"},
    {value: "Night", label: "Night"},
  ]

  const NumberOfSpotsList = Array.from({ length: 10 }, (_, i) => ({ value: (i + 1).toString(), label: (i + 1).toString() }));

  useEffect(() => {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 2);
    setMinDate(tomorrow);
  }, []);

  const validateFields = () => {
    const newErrors = {};
    if (!position) {newErrors.position = 'Position is required';}
    if (!spots) {newErrors.spots = 'Spots are required';}
    if (!wage) {newErrors.wage = 'Wage is required';}
    if (!time) {newErrors.time = 'Time is required';}
    if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return false;
    }
    setErrors({});
    return true;
};


  const addShift = () => {
    if(validateFields()){
     const newShift = {
        position,
        spots,
        wage,
        time,
        startDate,
        selectedCertifications,
        isInBoundShift
      };
    
     dispatch(setShiftsArray([...newShiftsArray, newShift]));  
     dispatch(setNextShiftPage(true));
    }
  }

  const editShift = () => {
    if(validateFields()){
     const updatedShift = {
        position,
        spots,
        wage,
        time,
        startDate,
        selectedCertifications,
        isInBoundShift
      };
    
     dispatch(setShiftsArray(newShiftsArray.map((shift, i) => i === editIndex ? updatedShift : shift)));  
     dispatch(setNextShiftPage(true));
    }
  }

  useEffect(() => {
    if(editMode){
        setPosition(editMode?.position);
        setWage(editMode?.wage);
        setInBoundShift(editMode?.isInBoundShift);
        setSpots(editMode?.spots);
        setTime(editMode?.time);
        setSelectedCertifications(editMode?.selectedCertifications);
    }
  },[editMode])

    return (
        <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:w-3/4">
            <div className="mb-4 md:mb-0">  
              <FormSelect
                type={"add-job"}
                label="Shift Position"
                name="shift-position"
                onChange={(value) => {
                  setPosition(value.value);
                }}
                darmo={true}
                options={positionList}
                defaultValue={position}
                placeholder={positionList[positionList.findIndex(obj=>obj.value===position)]?.label}
              />
              {errors?.position && <p className="mt-1 text-red-400 text-xs font-bold">{errors.position}</p>}
            </div>

            <div className="mb-4 md:mb-0">  
              <FormSelect
                type={"add-job"}
                label="Number of spots?"
                name="spots"
                onChange={(value) => {
                  setSpots(value.value);
                }}
                darmo={true}
                options={NumberOfSpotsList}
                defaultValue={spots}
                placeholder={NumberOfSpotsList[NumberOfSpotsList.findIndex(obj=>obj.value===spots)]?.label}
              />
              {errors?.spots && <p className="mt-1 text-red-400 text-xs font-bold">{errors.spots}</p>}
            </div>
          </div>

          <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-4 lg:w-3/4">
            <div className="mb-4 md:mb-0">  
              <FormDateInput
                dateType={"add-job"}
                name="shift_date"
                label="Date"
                type="date"
                min={minDate.toISOString().split("T")[0]}
                value={startDate}
                placeholder={t("pre-defined-jobs.start-date")}
                onChange={(e) => setStartDate(e.target.value)}
                darmo={true}
              />
            </div>

            <div className="mb-4 md:mb-0">  
              <FormSelect
                type={"add-job"}
                label="Shift Time"
                name="shift-time"
                onChange={(value) => {
                  setTime(value.value);
                }}
                darmo={true}
                options={timingList}
                defaultValue={time}
                placeholder={timingList[timingList.findIndex(obj=>obj.value===time)]?.label}
              />
              {errors?.time && <p className="mt-1 text-red-400 text-xs font-bold">{errors.time}</p>}
            </div>
          </div>

          <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-4 lg:w-3/4">
            <div>
            <Input
              name="wage"
              label={t("pre-defined-jobs.wage")}
              type="text"
              value={wage}
              slug={"$"}
              placeholder="8.50/HR + Tips & Benefts"
              onChange={(e) => setWage(e.target.value)}
              darmo={true}
            />
            {errors?.wage && <p className="text-red-400 text-xs font-bold">{errors.wage}</p>}
            </div>

            <div className="flex items-center">
              <input
                className="mr-2 cursor-pointer my-auto rounded"
                type="checkbox"
                name="isInBoundShift"
                checked={isInBoundShift}
                onChange={(e) => setInBoundShift(e.target.checked)}
              />
                <span 
                  // className={`${mode ? "text-black" : "text-white"}`}
                  className="text-black"
                >Allow In-abound Shifters to apply?</span>
            </div>
          </div>

          <div className="2xl:w-3/4"> 
            <div className="mb-2 md:mb-0"> 
              <label className="text-black font-medium">
                Required Certificates
              </label>
              <div className="mt-3">
              <div className="w-full flex flex-wrap gap-4">
                {certificateData?.map((item) => {
                  const isCertificationSelected = selectedCertifications?.some((selectedItem) => selectedItem === item?.name);
                  const bgColorClass = isCertificationSelected ? 'bg-primary-500' : 'bg-white';
                  const iconSrc = isCertificationSelected ? certificationsWhiteIcon : certificationsIcon;
                  const textColorClass = isCertificationSelected ? 'text-white' : 'text-black';
                  const clickHandler = isCertificationSelected
                    ? () => handleRemoveCertification(item?.name)
                    : () => handleAddCertification(item?.name);

                  return (
                    <div key={item?.id} className={`border-2 border-primary-500 p-2 rounded-xl w-40 flex flex-col justify-center items-center ${bgColorClass}`}>
                      <img src={iconSrc} alt="logo" className="w-12 h-12" />
                      <p className={`mt-1 font-bold text-center ${textColorClass}`}>{item?.name}</p>
                      <div className="mt-auto">
                        <img
                          src={isCertificationSelected ? minusIcon : addBlueIcon}
                          alt="logo"
                          className="my-2 w-5 h-5 cursor-pointer"
                          onClick={clickHandler}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              </div>
            </div>
          </div>

          <div className="flex w-full md:w-1/5 mt-12 justify-center md:justify-start">
            {type === "event" ? (
            <div className="flex">
            <button
              className={`w-36 px-6 border mr-4 flex items-center justify-center bg-orange-900 text-white hover:bg-orange-400 transition-all hover:opacity-50
              text-center text-sm font-bold whitespace-nowrap rounded-xl py-2`}
              onClick={(e) => {
                e.preventDefault();
                newShiftsArray?.length > 0 ? dispatch(setNextShiftPage(true)) : dispatch(setEventPage(false));;
              }}
            >
             Previous
            </button>

            <button
              className={`w-36 px-6 border mr-4 flex items-center justify-center bg-orange-900 text-white hover:bg-orange-400 transition-all hover:opacity-50
              text-center text-sm font-bold whitespace-nowrap rounded-xl py-2`}
              onClick={(e) => {
                e.preventDefault();
                editMode ? editShift() : addShift();
              }}
            >
              <img
                src={addIcon}
                alt="logo"
                className="mr-2 my-auto w-4 h-4"
              />
              {editMode ? "Edit Shift" : "Add"}
            </button>
            </div>
            ) : (
            <>
            <button
              className={`px-6 border mr-4 flex items-center justify-center bg-orange-900 text-white hover:bg-orange-400 transition-all hover:opacity-50
              text-center text-sm font-bold whitespace-nowrap rounded-xl py-2`}
              onClick={(e) => {
                e.preventDefault();
                addShift();
              }}
              // disabled={loading}
            >
              <img
                src={addIcon}
                alt="logo"
                className="mr-2 my-auto w-4 h-4"
              />
              {editMode
                  ? t("pre-defined-jobs.update-job")
                  : "Open Shift"
              }
              {/* {loading ? (
                <i className="fad fa-circle-notch fa-spin ml-2" />
              ) : (
                ""
              )} */}
            </button>

            <div className="text-center">
            {/* {!editMode && ( */}
              <button
                // onClick={saveDraft}
                className={`px-6 border mr-1 flex items-center justify-center bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50
              text-center text-sm font-bold whitespace-nowrap rounded-xl py-2`}
              >
                Save as draft
              </button>
            {/* )} */}
            </div>
            </>
            )}
          </div>
        </div>
    )
}

export default ShiftAddOrEdit;