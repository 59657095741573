import React, { useState, useEffect } from "react";
import { useCustomEventListener } from "react-custom-events";
import { toast } from "react-toastify";
import CannedMessageApi from "../../api/CannedMessage";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { DashboardLayout } from "../../components/organisms/Layout";
import TableActionDropdown from "../../components/TableActionDropdown";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/modals/DeleteModal";
import { useSelector } from "react-redux";
import { selectSelectedLocation } from "../../store/authSlice";
import TableLoading from "../../components/Loading/TableLoading";
import Table from "../../components/atoms/Tables/Table";
import { addIcon } from "../../assets/images";

const CannedMessages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedLocation = useSelector(selectSelectedLocation);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [messageToDelete, setMessageToDelete] = useState(null);

  useCustomEventListener("closeModal", () => {
    setMessageToDelete(null);
  });

  useCustomEventListener("cannedMessageAdded", () => {
    loadData();
  });

  useEffect(() => {
    document.title = "HeyHire Portal-Message Templates";
    loadData();
  }, [selectedLocation]);

  const loadData = async () => {
    setLoading(false);
    await CannedMessageApi.getAll()
      .then((res) => setData(res.data.data))
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .finally(() => setLoading(true));
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6 pb-12">
        <div className="overflow-x-auto overflow-hidden">
          {!loading ? (
            <TableLoading Columns={4} />
          ) : (
            <Table
              mainHeader={t("pc-messages.pre-canned-messages")}
              headers={
                data?.length > 0
                  ? [
                      t("common.message"),
                      t("common.shortcut"),
                      "Edit",
                      "Delete",
                    ]
                  : ["No Messages Yet"]
              }
              data={data?.map((message, index) => (
                <tr key={index}>
                  <td className="pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-gray-300 whitespace-nowrap md:whitespace-normal">
                    {message?.message}
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-gray-300">
                    {message?.shortcut}
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                    <div
                      className="text-indigo-600 dark:text-indigo-300 hover:text-indigo-900 hover:underline cursor-pointer"
                      onClick={() => navigate(`${message.id}/edit`)}
                    >
                      Edit
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                    <div
                      className="text-indigo-600 dark:text-indigo-300 hover:text-indigo-900 hover:underline cursor-pointer"
                      onClick={() => setMessageToDelete(message)}
                    >
                      Delete
                    </div>
                  </td>
                </tr>
              ))}
              buttonData={
                <button
                  onClick={() => navigate("/messages/canned-messages/add")}
                  className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                >
                  <div className="flex">
                    <img
                      src={addIcon}
                      alt="logo"
                      className="mr-2 my-auto w-4 h-4"
                    />
                    Add message
                  </div>
                </button>
              }
            />
          )}
        </div>

        {messageToDelete && (
          <DeleteModal
            title={t("pc-messages.delete-pre-canned-message")}
            name={t("pc-messages.pre-canned-message")}
            deleteRequest={() => CannedMessageApi.delete(messageToDelete)}
            onSuccess={() => {
              setMessageToDelete(null);
              loadData();
            }}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default CannedMessages;
