import axios from "./api";
const baseUrl = "/business/user";
const User = {
  add(user) {
    return axios.post(`${baseUrl}`, user);
  },
  update(user, id) {
    return axios.put(`${baseUrl}/${id}`, user);
  },
  getAll(query=null) {
    return axios.get(`${baseUrl}?${query ? query : ""}`);
  },
  getById(id) {
    return axios.get(`${baseUrl}/${id}`);
  },
  updateLocation(locationId){
    return axios.post(`${baseUrl}/update-location`, { location_id: locationId })
  },
  deleteUser(id) {
    return axios.delete(`${baseUrl}/${id}`);
  },
  suspendUser(id) {
    return axios.get(`${baseUrl}/suspend/${id}`);
  },
  unSuspendUser(id) {
    return axios.get(`${baseUrl}/unsuspend/${id}`);
  },
  setPreferences(data) {
    return axios.post(`${baseUrl}/preferences`, data);
  },
  getStripeCustomerDetails(customerId, locationId){
    return axios.get(`/business/stripe-customer/${customerId}/${locationId}`);
  }
};

export default User;
