import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Loading from "../../../../components/Loading";
import useQuery from "../../../../hooks/useQuery";
import {
  selectRoles,
  selectUser,
  selectPermissions,
} from "../../../../store/authSlice";
import { DashboardLayout } from "../../../../components/organisms/Layout";
import { Fragment, useState } from "react";
import {
  Popover,
  Dialog,
  Switch,
  Transition,
  Listbox,
} from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  Bars3BottomLeftIcon,
  BellIcon,
  BriefcaseIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CogIcon,
  DocumentMagnifyingGlassIcon,
  HomeIcon,
  AcademicCapIcon,
  BuildingOffice2Icon,
  UserIcon,
  LockOpenIcon,
  CurrencyDollarIcon,
  QuestionMarkCircleIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import SimpleTable from "../../../../components/atoms/Tables/SimpleTable";
import Table from "../../../../components/atoms/Tables/SimpleTable";
import ControlPanel from "../../../../api/ControlPanel";
import { data } from "autoprefixer";
import StackedAvatar from "../../../../components/atoms/StackedAvatar/StackedAvatar";
import FlyoutMenu from "../../../../components/atoms/FlyoutMenu/FlyoutMenu";
import RoundEdgeButton from "../../../../components/atoms/Buttons/RoundEdgeButton";
import PaginationView from "../../../../components/molecules/Pagination";
import LogoCards from "../../../../components/atoms/Cards/LogoCards";
import WalletModal from "../../../../components/molecules/WalletModal/WalletModal";
import PlainModal from "../../../../components/molecules/WalletModal/PlainModal";
import CreditCardSvg from "../../../../components/svg/CreditCardSvg";
import CreditCards from "../../../../components/atoms/Cards/CreditCards";
import NarrowFlyoutMenu from "../../../../components/atoms/FlyoutMenu/NarrowFlyoutMenu";
import SlideOverPanel from "../../../../components/atoms/SildeOverPanel/SlideOverPanel";
import SlideOverPanel1 from "../../../../components/atoms/SildeOverPanel/SlideOverPanel1";
import SlidePanelForm from "./Forms/SlidePanelForm";
import Subscription from "../../../../api/Subscription";

const Subscriptions = () => {
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);
  const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] =
    useState(false);
  const { t } = useTranslation();
  const roles = useSelector(selectRoles);
  const [businessNameShowPanel, setBusinessNameShowPanel] = useState(false);
  const [lastFetchedSubscriptionId, setLastFetchedSubscriptionId] =
    useState("");
  const [firstFetchedSubscriptionId, setFirstFetchedSubscriptionId] =
    useState("");

  const query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasMoreData, setHasMoreData] = useState(false);
  const [moreDetailsCustomerName, setMoreDetailsCustomerName] = useState([]);
  const [moreDetailsBusinessName, setMoreDetailsBusinessName] = useState([]);
  const [moreDetailsOwnerName, setMoreDetailsOwnerName] = useState([]);
  const [detailBusinessData, setDetailBusinessData] = useState([]);
  const [detailBusinessAction, setDetailBusinessAction] = useState([]);
  const [refreshPanel, setRefreshPanel] = useState(false);
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 10);

  useEffect(() => {
    document.title = "HeyHire Admin-Control-Panel";
    getAllSubscriptions("");
  }, []);

  const handlePageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({
      ...searchParams,
      page: currentPage,
      pageSize: pageSize,
    });
  };

  const getAllSubscriptions = (fetchedDataTag) => {
    let subscriptionIdData = "";

    if (fetchedDataTag === "next") {
      subscriptionIdData = "&starting_after=" + lastFetchedSubscriptionId;
    } else if (fetchedDataTag === "previous") {
      subscriptionIdData = "&ending_before=" + firstFetchedSubscriptionId;
    }

    const page = query.get("page") || 1;
    const sort = query.get("sort") || "";
    const limit = query.get("limit") || pageLimit;
    const sortData = sort ? `sort=${sort}&` : "";
    const pageData = page ? `page=${page}` : "";
    const queryData =
      sortData + pageData + subscriptionIdData + `&limit=${limit}`;

    Subscription.getAllSubscriptions(queryData)
      .then((response) => {
        console.log("DATA SUBS : " + response.data.data.list.data);
        setSubscriptionsList(response.data.data.list.data);
        setLastFetchedSubscriptionId(response.data.last_subscription_id);
        setFirstFetchedSubscriptionId(response.data.first_subscription_id);
        setHasMoreData(response.data.list.has_more);
      })
      .catch((error) => {
        console.log("Error fetching the subscriptions list");
      });
  };

  const handleNext = () => {
    if (hasMoreData) {
      getAllSubscriptions("next");
    }
  };

  const handlePrevious = () => {
    if (hasMoreData) {
      getAllSubscriptions("previous");
    }
  };

  // const handleMoreButtonClick = (item) => {
  //   let customerInfo = "";
  //   let businessInfo = "";
  //   let OwnerInfo = "";

  //   PaymentDetails.getBusinessByCustomerId(item.id)
  //     .then((response) => {
  //       if (response.data.status_flag === 0) {
  //         console.log("reached here");
  //         businessInfo = "No Business Linked";
  //       } else {
  //         businessInfo = response.data.name;
  //       }

  //       if (item.name === "") {
  //         customerInfo = "No Customer Name";
  //       } else {
  //         customerInfo = item.name;
  //       }

  //       const data = [
  //         {
  //           name: "Stripe Customer Name",
  //           description: customerInfo,
  //           href: "#",
  //           icon: CurrencyDollarIcon,
  //         },
  //         {
  //           name: "HeyHire Business Name",
  //           description: businessInfo,
  //           href: "#",
  //           icon: BuildingOffice2Icon,
  //         },
  //         {
  //           name: "Owner Info",
  //           description: "Your customers' data will be safe and secure",
  //           href: "#",
  //           icon: UserIcon,
  //         },
  //       ];

  //       const callsToAction = [
  //         {
  //           name: "view in strip.com",
  //           href: "https://dashboard.stripe.com/customers/",
  //           icon: LockOpenIcon,
  //         },
  //       ];
  //       setDetailBusinessData(data);
  //       setDetailBusinessAction(callsToAction);
  //       setRefreshPanel(!refreshPanel);
  //     })
  //     .catch((error) => {
  //       console.log("Error fetching the business details");
  //     });
  // };

  return (
    <div className=" px-4 sm:px-6 lg:px-0">
      {/* <SlideOverPanel1 children={<SlidePanelForm />} /> */}
      <div className="pt-7 -py-6">
        {/* Description list with inline editing */}
        <div className="divide-y divide-warmgray-300">
          <div className="space-y-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900 pb-2">
              Subscriptions Logs
            </h3>
          </div>
          <div className="w-full border-10 shadow-sm border-black pl-1 pr-1">
            {/* <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                    {"sdfsdf"}
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                    {"sdfsdf"}
                    </p>
                </div>
              <RoundEdgeButton buttonName="button"/>
            </div> */}
            <div className="mt-1 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-0"
                        >
                          Business
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                        >
                          Subscription Type
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                        >
                          Start date
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                        >
                          View in stripe
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">View More</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {subscriptionsList.map((subscription, index) => (
                        <tr key={subscription.customer}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-md sm:pl-0">
                            <div className="flex items-center">
                              <div className="ml-0">
                                <div
                                  key={index}
                                  onClick={() => {
                                    setBusinessNameShowPanel(
                                      !businessNameShowPanel
                                    );
                                  }}
                                  className="font-medium text-gray-900"
                                >
                                  {subscription.customer}
                                </div>
                                <div className="text-gray-500">
                                  {subscription.currency}
                                </div>
                                {/* <div>
                                  <NarrowFlyoutMenu
                                    refresh={refreshPanel}
                                    key={customer.id}
                                    item={customer}
                                    handleButtonClick={handleMoreButtonClick}
                                    panelData={detailBusinessData}
                                    callsToAction={detailBusinessAction}
                                  />
                                </div> */}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {subscription.default_source}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {subscription.current_period_end}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              Active
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              Link
                            </span>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <a
                              href="#asd"
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              <FlyoutMenu
                              // open={businessNameShowPanel}
                              // close={!businessNameShowPanel}
                              />
                              Action
                              <span className="sr-only">, vimal</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 mt-5 justify-between sm:justify-end">
          <a
            href="#cv"
            onClick={handlePrevious}
            className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Previous
          </a>
          <a
            href="#asd"
            onClick={handleNext}
            className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Next
          </a>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
