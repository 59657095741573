import React, { createContext, useContext, useState } from "react";

const TurnKeyContext = createContext("");

export function TurnKeyContextProvider({ children }) {
  const scrollUpRef = React.createRef();

  const companyObject = [
    {
      companyId: null,
      companyName: "",
    },
  ];

  const businessObject = [
    {
      businessId: "",
      businessName: "",
    },
  ];

  const turnKeyJSONObject = [{}];

  const [company, setCompanyName] = useState(companyObject);
  const [businessType, setBusinessType] = useState(businessObject);
  const [turnKeyObject, setTurnKeyObject] = useState(turnKeyJSONObject);
  const [imageUploadFile, setImageUploadFile] = useState([]);
  const [activationCode,setActivationCode] = useState([]);

  return (
    <TurnKeyContext.Provider
      value={{
        company,
        setCompanyName,
        businessType,
        setBusinessType,
        turnKeyObject,
        setTurnKeyObject,
        scrollUpRef,
        imageUploadFile,
        setImageUploadFile,
        activationCode,
        setActivationCode
      }}
    >
      {children}
    </TurnKeyContext.Provider>
  );
}

export function useSettingsContext() {
  return useContext(TurnKeyContext);
}
