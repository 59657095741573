import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ChatApi from "../../../api/Chat";
import CannedMessage from "../../../api/CannedMessage";
import {
  selectUser,
  setChats,
  selectLoggedIn,
  selectRoles,
  selectSelectedLocation,
  addNewChat,
  setPreCannedMessages,
  setConversationLoading,
  initialChatFetch,
  setInitialChatFetch,
  setChatToken,
  refreshToken,
  selectChats,
  setAddNewChat,
  deleteChats,
  setDeleteChat,
} from "../../../store/authSlice";
import { useLocation } from "react-router-dom";

export const useChatEffects = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  // const location = useLocation();
  // const locationId = new URLSearchParams(location.search).get("location");
  const loggedIn = useSelector(selectLoggedIn);
  const selectedLocation = useSelector(selectSelectedLocation);
  const roles = useSelector(selectRoles);
  const role = roles.length > 0 ? roles[0] : "";
  const selectAddNewChat = useSelector(addNewChat);
  const selectDeleteChat = useSelector(deleteChats);
  const initialFetch = useSelector(initialChatFetch);
  const refresh_token = useSelector(refreshToken);
  const chats = useSelector(selectChats);

  useEffect(() => {
    if (refresh_token) {
      getToken();
    }
  }, [refresh_token]);

  useEffect(() => {
    getToken();
  }, [chats]);

  useEffect(() => {
    const loadPrecannedMessages = async () => {
      CannedMessage.getAll()
        .then((response) => dispatch(setPreCannedMessages(response.data.data)))
        .catch((error) => {
          if (error?.response?.status !== 403) {
            toast.error("Couldn't load pre-canned messages.");
          }
        });
    };

    if (
      loggedIn &&
      selectedLocation &&
      !initialFetch &&
      role !== "super-user"
    ) {
      dispatch(setInitialChatFetch(true));
      loadChatInfo();
      loadPrecannedMessages();
    }
  }, [loggedIn, selectedLocation, initialFetch]);

  useEffect(() => {
    if (selectAddNewChat) {
      loadChatInfo();
      dispatch(setAddNewChat(false));
    }
  }, [selectAddNewChat]);

  useEffect(() => {
    if (selectDeleteChat) {
      loadChatInfo();
      dispatch(setDeleteChat(false));
    }
  }, [selectDeleteChat]);

  const loadChatInfo = async () => {
    try {
      if (user?.company?.is_enterprise === 0) {
        const response = await ChatApi.chatByLocation(user?.location?.id);
        dispatch(setChats(response.data.data));
      } else if (selectedLocation?.id) {
        const response = await ChatApi.chatByLocation(selectedLocation?.id);
        dispatch(setChats(response.data.data));
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status !== 403) {
        toast.error("That didn't work. Try refreshing the page.");
      }
    }
  };

  const getToken = async () => {
    try {
      if (user?.company?.is_enterprise === 0) {
        const response = await ChatApi.createToken({
          location_id: user?.location?.id,
        });
        dispatch(setChatToken(response.data.token));
      } else {
        if (selectedLocation?.id) {
          const response = await ChatApi.createToken({
            location_id: selectedLocation?.id,
          });
          dispatch(setChatToken(response.data.token));
        }
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        if (chats.length > 0) toast.error("Couldn't load chat.");
      }
      dispatch(setConversationLoading(false));
    }
  };
};
