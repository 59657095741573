import React from "react";
import { Link, useLocation } from "react-router-dom";
import Input from "../../components/form/Input";
import { DashboardLayout } from "../../components/organisms/Layout";
import { useTranslation } from "react-i18next";

const JobSeeker = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full">
        <h2 className={"text-2xl font-bold"}>
          <Link to={"/job-seekers"}>
            <i className="far fa-chevron-left text-xl mr-2" />
          </Link>{" "}
          {t("job-seekers.job-seeker-information")}
        </h2>

        <div className={"mt-8 ml-6 font-bold"}>
          {t("job-seekers.basic-information")}
        </div>
        <hr className="mt-1 border-b-black" />

        <div className="ml-6 text-left snap-start pt-6 pb-8 mb-4 w-full">
          <div className={"grid grid-cols-1 md:grid-cols-3 gap-2"}>
            <Input
              name="first-name"
              label={t("common.first-name")}
              type="text"
              value={location.state.first_name}
              disabled
            />

            <Input
              name="last-name"
              label={t("common.last-name")}
              type="text"
              value={location.state.last_name}
              disabled
            />

            <div className={"h-20"} />

            <Input
              name="city"
              label={t("common.city")}
              type="text"
              value={location.state.city}
              disabled
            />

            <Input
              name="state"
              label={t("common.state")}
              type="text"
              value={location.state.state}
              disabled
            />

            <div className={"h-20"} />

            <Input
              name="zip-code"
              label={t("common.zip")}
              type="text"
              value={location.state.zip_code}
              disabled
            />

            <Input
              name="phone"
              label={t("common.phone")}
              type="text"
              value={location.state.phone}
              disabled
            />

            <div />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default JobSeeker;
