import React, { useState } from "react";
import { Collapse } from "antd";
import { FiChevronDown, FiChevronRight, FiChevronUp } from "react-icons/fi";
import "./OpenPositionCardEmbed.scss";

const { Panel } = Collapse;

const DarkMode = {
  backgroundColor: "bg-warmgray-1100",
  footerBgColor: "bg-gray-600",
  positonColor: "bg-wartmgray-1100",
  textColor: "text-white",
  imageColor: "text-white",
  viewButton: "border-white text-white hover:bg-white hover:text-primary",
  applyButton:
    "bg-white text-primary-500 hover:bg-primary-400 hover:text-white",
};

const LightMode = {
  backgroundColor: "bg-gray-50",
  headerBgColor: "bg-gray-50",
  footerBgColor: "bg-primary-500",
  positonColor: "bg-wartmgray-1100",
  imageColor: "text-gray-400",
  viewButton:
    "border-orange-400 text-primary-400 hover:bg-orange-400 hover:text-primary",
  applyButton: "bg-primary-500 text-white hover:bg-primary-400",
};
const OpenPositionCardEmbed = ({ position, mode }) => {
  const [openKeys, setOpenKeys] = useState(null);
  const selectedMode = mode !== "dark" ? LightMode : DarkMode;

  const renderToggler = () => (
    <div className={`flex justify-between`}>
      <div
        className={
          "font-bold text-xl " +
          (openKeys && openKeys.includes("1") && "text-white lg:text-black")
        }
      >
        {position.position}
      </div>
      <div className="hidden font-light lg:block">
        <span className="ml-20">
          <i className={"far fa-clock mr-2 " + selectedMode.imageColor} /> Part
          Time, Full Time
        </span>
      </div>
      <div className="hidden font-light lg:block">
        <span className="ml-20">
          <i className={"far fa-calendar mr-2 " + selectedMode.imageColor} />{" "}
          08/05/2021
        </span>
      </div>
    </div>
  );
  const renderContent = () => (
    <div className={"flex flex-col lg:flex-row items-center justify-between"}>
      <div className={`md:hidden flex justify-between my-4`}>
        <div className={"font-bold text-xl " + selectedMode.textColor}>
          {position.position}
        </div>
        <div className={"font-light lg:block " + selectedMode.textColor}>
          <span className="ml-20">
            <i className={"far fa-calendar mr-2 " + selectedMode.imageColor} />{" "}
            08/05/2021
          </span>
        </div>
      </div>
      <span
        className={
          "lg:block md:text-left text-sm mb-1 " + selectedMode.textColor
        }
      >
        {position.description.length < 161
          ? position.description
          : position.description.substring(0, 160) + "..."}
      </span>
      <div
        className={
          "font-light text-left lg:hidden mx-auto " + selectedMode.textColor
        }
      >
        <div className="w-full">
          <i className={"far fa-clock mr-2 " + selectedMode.imageColor} /> Part
          Time, Full Time
        </div>
        <div className="w-full">
          <i
            className={"far fa-money-bill-wave mr-2 " + selectedMode.imageColor}
          />{" "}
          $10 p/hr
        </div>
      </div>
      <div className="flex flex-col min-w-fit mt-4 lg:mt-0">
        <div className="flex-row lg:flex-row lg:block">
          <button
            className={
              "font-black lg:ml-4 py-1.5 px-3 rounded border-2 box-border h-min " +
              selectedMode.viewButton
            }
          >
            View Details
          </button>
          <button
            className={
              "font-black ml-4 py-2 px-3 rounded h-min " +
              selectedMode.applyButton
            }
          >
            Apply Now
          </button>
        </div>
        {/* {renderTimer()} */}
      </div>
    </div>
  );
  const renderTimer = () => (
    <div className="font-light hidden lg:block lg:flex-row mt-1">
      <span className={"ml-14"}>
        <i className="far fa-clock mr-2 text-gray-400" /> Part Time, Full Time
      </span>
      <br />
      <span className={"ml-14"}>
        <i className="far fa-money-bill-wave mr-2 text-gray-400" /> $10 p/hr
      </span>
    </div>
  );
  return (
    <div
      className={
        selectedMode.backgroundColor +
        " shadow-sm rounded-xl my-4 " +
        selectedMode.textColor
      }
    >
      <div className="hidden lg:block py-4 px-6">
        {renderToggler()}
        {renderContent()}
        {/* {renderTimer()} */}
      </div>
      <Collapse
        className={"open-position-card-accordion lg:hidden " + mode}
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) =>
          isActive ? (
            <FiChevronDown size={24} color="#FFFF" />
          ) : (
            <FiChevronUp size={24} color="#594A9E" />
          )
        }
        onChange={setOpenKeys}
      >
        <Panel header={renderToggler()} key="1">
          {renderContent()}
        </Panel>
      </Collapse>
    </div>
  );
};

export default OpenPositionCardEmbed;
