import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import { DateFormat, encodeIds } from "../utils";
import { certificationsIcon, certificationsWhiteIcon, defaultUserImage, defaultUserImage2 } from '../assets/images';
import ApplicationLogs from "../api/ApplicationLogs";
import Application from "../api/Application";

const CANCELEDSTATUS = ["canceled"];
const REJECTEDSTATUS = ["rejected"];

const ShiftApplicantCard = ({ applicant, application, type, positionId, className }) => {
  const certificateData = [
    {
      name: "Food Handlers",
      id: 1, 
    },
    {
      name: "Food Safety",
      id: 2,
    },
    {
      name: "TABC",
      id: 3,
    },
  ];

  const uploadApplicationEvent = () => {
    const body = {
      event_type: 'view',
      entity_type: 'application',
      entity_id: application?.id
    };
    ApplicationLogs.addEvent(body)
    .then((response) => {})
    .catch((error) => console.log('uploadApplicationEvent -> error', error));
  };

  const recordApplicationView = async () => {
    try {
      const body = {
        application_id: application?.id
      };  
      await Application.postApplicationView(body);
    } catch (error) {
      console.log("Failed to record application view. Please try again.", error?.response);
    }
  };

  const { t } = useTranslation();

//   if (!applicant || !application) return <></>;
  return (
    <div
      className={`relative rounded-xl border border-orange-900 flex flex-col items-stretch text-center py-2 ${type === "employee" && "bg-orange-900"}
        ${CANCELEDSTATUS.includes(application?.status) || REJECTEDSTATUS.includes(application?.status) || type === "cancelled" ? "opacity-25" :""} ` + className
      }
    >
      <div className="border-b border-primary-500 px-2 pb-2">
        <div className="flex justify-center">
          <img
            src={applicant?.photo?.thumb_url || (type === "employee" ? defaultUserImage2 : defaultUserImage)}
            alt="Profile"
            className="rounded-full"
            height={100}
            width={100}
          />
        </div>

        <span className={`text-lg text-primary-500 mt-2 font-bold ${type === "employee" && "text-white"}`}>
          {/* { application?.seeker?.first_name + " " + application?.seeker?.last_name } */}
          Ernesto Martinez
        </span>
        <span className={`block text-[14px] mt-1 ${type === "employee" && "text-white"}`}>
          Available for Shift
        </span>
        <span className={`flex justify-center items-center text-[14px] ${type === "employee" && "text-white"}`}>
          <i className={`fas fa-check-circle text-orange-900 text-lg mr-1.5`} />
          <span className="">Located within (25) mi</span>
        </span>
      </div>

      <div className="my-2 px-2 mx-auto">
        <div className="flex flex-col justify-center items-center">
          <p className={`text-[16px] mb-2 font-bold ${type === "employee" && "text-white"}`}>
            Certifications:
          </p>
          <div className="flex justify-center items-center flex-wrap gap-4">
                {certificateData?.map((item) => (
                    <div key={item?.id} className={`rounded-xl flex flex-col justify-center items-center min-w-20 ${type === "employee" && "text-white"}`}>
                      <img src={type === "employee" ? certificationsWhiteIcon : certificationsIcon} alt="logo" className="w-9 h-9" />
                      <p className={`text-[14px] mt-1 font-bold text-center`}>{item?.name}</p>
                    </div>
                ))}
          </div>
        </div>
      </div>

      <div className="my-2 px-2 mx-auto text-center">
        <div className="flex flex-col justify-center items-center">
          <p className={`text-[16px] mb-2 font-bold ${type === "employee" && "text-white"}`}>
            Experience:
          </p>
          <div className={`flex justify-start items-start flex-col text-left text-[14px] ${type === "employee" && "text-white"}`}>
            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              <li style={{ lineHeight: "1.75" }}><span className="font-bold">Mike's Place - Bartender:</span> 09/09/2022 - Present</li>
              <li style={{ lineHeight: "1.75" }}><span className="font-bold">Chilli's Barback:</span> 09/09/2023 - 02/14/2021</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="pt-3 w-full mt-auto">
        {!CANCELEDSTATUS.includes(application?.status) && !REJECTEDSTATUS.includes(application?.status) && (
          <NavLink
            to={
              type === "applicant"
                ? `/business/applicant/${encodeIds(positionId)}/${encodeIds(applicant?.id)}`
                : `/business/hired-applicant/${encodeIds(positionId)}/${encodeIds(applicant?.id)}`
            }
          >
            <div className="w-full flex items-center justify-center">
              <button
                onClick={() => {
                  recordApplicationView();
                  uploadApplicationEvent();
                }}
                className={`py-2 w-auto cursor-pointer rounded-xl px-4 flex items-center justify-center text-sm font-bold shadow-md 
                  transition-all hover:opacity-50 whitespace-nowrap ${type === "employee" ? "text-black bg-white" : "text-white bg-orange-900 hover:bg-orange-400"}`}
              >
                {type === "applicant"
                  ? t("applicant.view-applicant")
                  : "Invite Ernesto"}
              </button>
            </div>
          </NavLink>
        )}

        {CANCELEDSTATUS.includes(application?.status) && (
          <div className="w-full flex items-center justify-center">
            <button className="py-2 w-auto cursor-pointer rounded-xl px-6 flex items-center justify-center text-sm font-bold shadow-md 
            transition-all hover:opacity-50 whitespace-nowrap bg-rose-500 text-white">
              Cancelled
            </button>
          </div>
        )}

        {REJECTEDSTATUS.includes(application?.status) && (
          <div className="w-full flex items-center justify-center">
            <button className="py-2 w-auto cursor-pointer rounded-xl px-6 flex items-center justify-center text-sm font-bold shadow-md 
            transition-all hover:opacity-50 whitespace-nowrap bg-rose-500 text-white">
              Rejected
            </button>
          </div>
        )}
        </div>
    </div>
  );
};

export default ShiftApplicantCard;
