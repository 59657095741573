import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Certification from "../../../api/Certification";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { useNavigate } from "react-router-dom";
import { useCustomEventListener } from "react-custom-events";
import DeleteModal from "../../../components/modals/DeleteModal";
import { addIcon } from "../../../assets/images";
import Table from "../../../components/atoms/Tables/Table";

const Certifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [certifications, setCertifications] = useState([]);
  const [certificationToDelete, setCertificationToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadCertifications = () => {
    setLoading(true);

    Certification.getAll()
      .then((response) => {
        setCertifications(response.data.data);
      })
      .catch(() => {
        toast.error("Something went wrong.");
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Certifications";
    loadCertifications();
  }, []);

  useCustomEventListener("closeModal", () => {
    setCertificationToDelete(null);
  });

  return (
    <DashboardLayout darmo={true}>
      <div className="h-full overflow-x-auto mt-3 pb-12">
        {loading ? (
          <Loading count={8} />
        ) : (
          <Table
            mainHeader={t("certifications.certifications")}
            headers={
              !loading && certifications?.length > 0
                ? [t("common.name"), t("common.category"), "Delete"]
                : ["No data available"]
            }
            data={
              !loading &&
              certifications?.map((certification, index) => (
                <tr key={index}>
                  <td className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1">
                    {certification?.name}
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                    <div className="text-gray-900">
                      {certification?.category?.name}
                    </div>
                  </td>

                  <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                    <div
                      className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                      onClick={() => setCertificationToDelete(certification.id)}
                    >
                      Delete
                    </div>
                  </td>
                </tr>
              ))
            }
            buttonData={
              <button
                onClick={() => navigate("add")}
                className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-6 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
              >
                <div className="flex">
                  <img
                    src={addIcon}
                    alt="logo"
                    className="mr-2 my-auto w-4 h-4"
                  />
                  Add
                </div>
              </button>
            }
          />
        )}
      </div>

      {certificationToDelete && (
        <DeleteModal
          title="Delete Certification"
          name="Certification"
          deleteRequest={() => Certification.deleteById(certificationToDelete)}
          onSuccess={() => {
            setCertificationToDelete(null);
            loadCertifications();
          }}
        />
      )}
    </DashboardLayout>
  );
};

export default Certifications;
