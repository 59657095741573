import {Button} from 'antd';
import {saveAs} from 'file-saver';
import React from 'react'
import PrimaryButton from '../../atoms/Buttons/PrimaryButton';

const QRCodeDisplayModal = ({ header,buttonDisplay = true, title, QRLink, content }) => {

  
  const downloadQR = (imageUrl) => {
    saveAs(QRLink + "qr_code.jpg");
  };

  return (
    <div class="mx-auto pb-10">
      <img
        class="mx-auto mt-12 h-52 w-52 rounded-lg border p-2 md:mt-0"
        src={QRLink}
        alt="step"
      />
      <div className="pb-5">
        <h1 class="font-laonoto mt-4 text-center text-xl font-bold">
          {header}
        </h1>
        <p class="mt-2 text-center font-semibold text-gray-600">{title}</p>
        <p class="mt-1 text-center font-medium text-red-500">{content}</p>
      </div>

      <div className={`ml-0 pl-6 ${buttonDisplay ? "" : "hidden"}`}>
        <PrimaryButton
          onClick={downloadQR}
          customIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
            </svg>
          }
          label="Download QR"
          style=" w-[150px] rounded-full px-1.5 pt-[10px] pb-[9px] text-white font-bold bg-gradient-to-b from-primary-500 to-primary-400 hover:bg-primary-700 shadow-md"
        />
      </div>
    </div>
  );
};

export default QRCodeDisplayModal