import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedLocation,
  selectUser,
  selectRoles,
  setSelectedLocation,
} from "../../../store/authSlice";
import { defaultUserImage } from "../../../assets/images";
import { Menu, Transition } from "@headlessui/react";
import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import DropdownMenuItem from "../../DropdownMenuItem";
import User from "../../../api/User";
import { toast } from "react-toastify";
import { darkmode } from "../../../store/authSlice";
import Location from "../../../api/Location";
import CustomNotification from "../../../utils/CustomNotification";

export default function AdminSwitchNavBar({ isEnterprise = true }) {
  const selectedLocation = useSelector(selectSelectedLocation);
  const user = useSelector(selectUser);
  const { pathname } = useLocation();
  const [displayAddress, setDisplayAddress] = useState(true);
  const [locations, setLocations] = useState([]);
  const roles = useSelector(selectRoles);
  const dispatch = useDispatch();
  const mode = useSelector(darkmode);
  const showLocationDropdown =
    (isEnterprise && roles[0] !== "employee") ||
    roles[0] === "super-user" ||
    roles[0] === "regional_manager";
  const navigate = useNavigate();
  const showAddress = pathname === "/business/dashboard";
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: "",
  });

  useEffect(() => {
    loadLocations();
  }, []);

  const loadLocations = async () => {
    const _selectedLocation = await JSON.parse(
      localStorage.getItem("selectedLocation")
    );
    Location.getAll().then((response) => {
      if (response.data.data) {
        const resLocations = response.data.data;
        setLocations(resLocations);
        if (resLocations.length > 0 && !selectedLocation) {
          if (showLocationDropdown) {
            if (_selectedLocation) {
              dispatch(setSelectedLocation(_selectedLocation));
            } else {
              if (roles[0] === "turnkey_admin") {
              } else {
                dispatch(setSelectedLocation({ name: "Select a location" }));
              }
            }
          } else {
            dispatch(setSelectedLocation(resLocations[0]));
          }
        }
      }
    });
  };

  const UserInfo = () => {
    return (
      <>
        <div className={"flex justify-center items-center"}>
          <div className={"flex flex-col justify-between text-black"}>
            <span className={"block font-semibold mb-0 text-center"}>
              Switch Business
            </span>
          </div>
        </div>
        {showAddress &&
          displayAddress &&
          roles[0] === "super-user" &&
          selectedLocation?.id && (
            <div className="my-4  rounded-md p-4">
              <p className="w-48 font-semibold text-black text-sm">
                {selectedLocation?.address?.address +
                  ", " +
                  selectedLocation?.address?.city +
                  ", " +
                  selectedLocation?.address?.state || " "}
              </p>
            </div>
          )}
      </>
    );
  };

  const BusinessLocation = ({ className }) => {
    if (roles[0] !== "super-user" && selectedLocation?.id) return <></>;
    return (
      <div className={className}>
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement="topRight"
          overlayClassName={""}
        >
          <a
            href="#href"
            className="ant-dropdown-link hover:text-primary-500"
            onClick={(e) => e.preventDefault()}
          >
            <div className="flex cursor-pointer hover:ring2 hover:ring-violet-200">
              <div
                className={
                  "p-3 w-full rounded flex justify-center text-white cursor-pointer"
                }
              >
                <button className="rounded-md bg-primary-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  {locations && locations.length > 0 && selectedLocation && (
                    <span>{selectedLocation?.name}</span>
                  )}
                </button>
              </div>
            </div>
          </a>
        </Dropdown>
      </div>
    );
  };

  const menu = (
    <div
      className="z-10 origin-top-right w-[260px] h-auto md:max-h-[600px] lg:max-h-[600px] max-h-[400px] 
              rounded-lg shadow-lg bg-white focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div
        className="py-1 h-auto md:max-h-[600px] lg:max-h-[600px] max-h-[400px] overflow-auto"
        role="none"
      >
        <p className="text-sm font-bold pl-5">Location:</p>
        {roles[0] !== "turnkey_admin" &&
          locations.map((location, index) => (
            <div className="font-bold text-sm">
              <DropdownMenuItem
                key={location?.id}
                onClick={() => {
                  dispatch(setSelectedLocation(location));
                  localStorage.setItem(
                    "selectedLocation",
                    JSON.stringify(location)
                  );
                  changeLocation(location?.id);
                  if (roles[0] !== "super-user") {
                    setDisplayAddress(false);
                  }
                  navigate(`/business/dashboard?location=${location?.id}`);
                }}
                label={location?.name}
                icon="far fa-map-marker-alt"
                isMobile="true"
              />
            </div>
          ))}
      </div>
    </div>
  );

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      setShowNotification({ show: true, type: "success" });
    } catch (error) {
      setShowNotification({ show: true, type: "error" });
    }
  };

  return (
    <div
      className={`${
        mode ? "bg-warmgray-100" : "bg-slate-800"
      } p-2 w-full  justify-center items-center admin-switch-nav`}
    >
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="flex ">
            {selectedLocation?.brand?.photo?.thumb_url ? (
              <div
                className="relative rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500
                           focus:ring-offset-2  justify-center p-[5px] min-w-[30px] min-h-[30px]"
              >
                <img
                  width={30}
                  height={30}
                  src={
                    selectedLocation?.brand?.photo?.thumb_url ||
                    defaultUserImage
                  }
                  alt={user?.company?.name}
                  className="object-fill rounded-full"
                />

                <ArrowsUpDownIcon
                  className="absolute h-4 w-4 mr-1 bottom-7 left-7 text-green-500"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <div
                className={`flex flex-row text-[12px] font-bold ${
                  mode ? "text-black" : "text-white"
                }`}
              >
                <div>
                  <ArrowsUpDownIcon
                    className="block h-4 w-4 mr-1"
                    aria-hidden="true"
                  />
                </div>
                <div>Switch Business</div>
              </div>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute left-1/2 transform -translate-x-1/2 z-10 mt-2 w-70 origin-top rounded-md ${
              mode ? "bg-white" : "bg-white opacity-100"
            } py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            <Menu.Item>
              <div className="flex flex-col mb-5">
                <div className="flex flex-col flex-1">
                  <div className="flex-1 flex flex-col pt-12 pb-4 px-6 scroll-custom min-w-[260px] max-w-[260px]">
                    <UserInfo />
                  </div>
                  {showLocationDropdown && (
                    <BusinessLocation className="relative w-full" />
                  )}
                </div>
              </div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      {showNotification?.show && (
        <CustomNotification
          show={showNotification?.show}
          setShow={() =>
            setShowNotification({ show: false, type: showNotification?.type })
          }
          title={
            showNotification?.type === "success"
              ? "Location Changed!"
              : "Error Occurred during Location switching. Try Again!"
          }
          type={showNotification?.type}
        />
      )}
    </div>
  );
}
