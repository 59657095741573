import React, { useEffect, useState } from "react";
import { emitCustomEvent } from "react-custom-events";
import { Modal } from "antd";
import ReactSelect from "react-select";
import Brand from "../../api/Brand";
// import Location from "../../api/Location";
import SuperUser from "../../api/SuperUser";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ChangeBrandModal = ({ company = null, visible, title, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isEnterprise, setIsEnterprise] = useState(company?.is_enterprise === 1 ? true: false);
  //const [locations, setLocations] = useState([]);
  //const [selectedLocation, setSelectedLocation] = useState([]);

  useEffect(() => {
    if (company) {
      loadBrands();
      //loadLocations();  
    }
  }, []);
/*
  const loadLocations = () => {
    Location.getAll().then((response) => {
      const locationsResponse = response.data.data;
      setLocations(locationsResponse);
      if (locationsResponse.length > 0) {
        const _selectedLocation = filterSelectedLocations(locationsResponse);
        setSelectedLocation(_selectedLocation);
      }
    });
  };

  const filterSelectedLocations = (locations) => {
    const _selectedLocations = company?.locations.map(loc => {
      const _selectedLocation = locations.find(l => l?.id === loc?.id);
      if (_selectedLocation) {
        return {label: _selectedLocation?.name, value: _selectedLocation?.id};
      } else {
        return;
      }
    });
    const _tempSelectedLoc = _selectedLocations.filter(i => i?.value);
    return _tempSelectedLoc;
  };
*/
  const loadBrands = () => {
    Brand.getAll().then((response) => {
      setBrands(response.data.data);
      if (response.data.data.length > 0) {
        const _selectedBrands = filterSelectedBrands(response.data.data);
        setSelectedBrands(_selectedBrands);
      }
    });
  };

  const filterSelectedBrands = (_brands) => {
    const _selectedbrands = company?.locations.map(loc => {
      const _selectedbrand = _brands.find(b => b?.id === loc?.brand_id);
      if (_selectedbrand) {
        return {label: _selectedbrand?.name, value: _selectedbrand?.id};
      } else {
        return;
      }
    });
    const _tempSelectedBrand = _selectedbrands.filter(i => i?.value);
    return _tempSelectedBrand;
  };

  const updateBrandsCompany = () => {
    setLoading(true);
    const body = {
      company_id: company?.id,
      brand_id: selectedBrands?.value
    };
    SuperUser.updateBrandCompany(body).then((response) => {
      console.log('response', response.data.data);
      toast.success("Company's Brand Changed");
    }).catch((error) => {
      console.log('error', error.response);
    }).then(() => {
      setLoading(false);
      emitCustomEvent("closeChangeBrandModal");
    });
  };

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={onClose}
      closable={false}
      footer={null}
    >
    <div className="w-full">
      <button
        className="absolute right-5 m-3 p-3 text-white text-opacity-80 hover:text-opacity-100 text-xl"
        onClick={onClose}
      >
        <i className="far fa-times" />
      </button>

      <div className={"w-full text-center py-10 bg-violet-600"}>
        <span className="block text-xl text-white font-normal">
          {title}
        </span>
      </div>
      <div className="text-left snap-start px-8 pt-6 pb-8 mb-4 w-full">
        {isEnterprise && (
          <div className="my-3">
            <span className={"font-bold block"}>Brands</span>
            <ReactSelect
              value={selectedBrands}
              // isMulti
              theme={(theme) => {
                return {
                  ...theme,
                  borderRadius: 4,
                  spacing: {
                    ...theme.spacing,
                    controlHeight: 47,
                  },
                  colors: {
                    ...theme.colors,
                    primary25: "#A899E2",
                    primary: "#7D66D3",
                  },
                };
              }}
              onChange={(selected) => setSelectedBrands(selected)}
              options={brands.map((brand) => {
                return {
                  value: brand.id,
                  label: brand.name,
                };
              })}
            />
          </div>
        )}
        {/*isEnterprise && (
          <div className="my-3">
            <span className={"font-bold block mt--1"}>
              Locations
            </span>
            <ReactSelect
              value={selectedLocation}
              isMulti
              theme={(theme) => {
                return {
                  ...theme,
                  borderRadius: 4,
                  spacing: {
                    ...theme.spacing,
                    controlHeight: 47,
                  },
                  colors: {
                    ...theme.colors,
                    primary25: "#A899E2",
                    primary: "#7D66D3",
                  },
                };
              }}
              onChange={(selected) => setSelectedLocation(selected)}
              options={locations.map((location) => {
                return {
                  value: location.id,
                  label: location.name,
                };
              })}
            />
          </div>
            )*/}

        <hr className="mt-8" />

        <div className="mt-8 flex">
          <button
            className="w-full rounded-xl p-4 m-1 text-lg border-2 hover:text-white hover:bg-violet-500 border-violet-500 text-violet-500"
            onClick={(e) => {
              e.preventDefault();
              emitCustomEvent("closeModal");
            }}
          >
            {t("common.cancel")}
          </button>
          <button
            className="w-full rounded-xl p-4 m-1 text-white text-lg bg-violet-500 hover:bg-violet-700 disabled:bg-violet-200"
            onClick={(e) => {
              e.preventDefault();
              updateBrandsCompany();
            }}
            disabled={loading}
          >
            {t("common.save")}
            {loading ? <i className="fad fa-circle-notch fa-spin ml-2" /> : ""}
          </button>
        </div>
      </div>
    </div>
    </Modal>
  );
};

export default ChangeBrandModal;
