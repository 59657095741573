import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import User from "../../../api/auth.js";
import Input from "../../../components/form/Input";
import AuthLayout from "../../../components/organisms/Layout/Authlayout";
import { darkmode } from "../../../store/authSlice";
import { heyhireBlackIcon, heyhireIcon } from "../../../assets/images.js";

const SuccessView = ({ resend }) => {
  const { t } = useTranslation();

  return (
    <p className="text-center font-semibold lg:px-20 sm:px-8 lg:mb-[10px] text-14">
      {t("login.if-not-redirected")}&nbsp;
      <span
        className="text-primary-500 hover:text-primary-200 cursor-pointer"
        onClick={resend}
      >
        {t("login.click-here")}
      </span>
    </p>
  );
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const mode = useSelector(darkmode);
  const pathName = location.pathname;
  const _email = localStorage.getItem("passwordResetEmail");
  const token = pathName.split("password-reset/")[1];
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  useEffect(() => {
    document.title = "HeyHire Portal-Reset Password";
    return () => {
      setPassword("");
      setConfirmPassword("");
      setLoading(false);
      setValidationErrors(null);
    };
  }, []);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setPasswordMatchError(false);
    if (name === "password") {
      setPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setValidationErrors(null);
    if (password === "" || password !== confirmPassword) {
      setPasswordMatchError(true);
    } else {
      setLoading(true);
      const body = {
        //email: _email,
        token: token,
        password: password,
        password_confirmation: confirmPassword,
      };
      User.ResetPasswordChange(body)
        .then((response) => {
          localStorage.removeItem("passwordResetEmail");
          toast.success("Your Password has been changed!");
          navigate(`/login`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something Went wrong. Please try again Later.");
        });
    }
  };

  return (
    <AuthLayout
      showLink={false}
      title={
        completed ? t("login.password-redirecting") : t("login.reset-password")
      }
    >
      <div className="flex min-h-full flex-1 flex-col justify-center  sm:px-6 lg:px-8">
        <div className={`sm:mx-auto sm:w-full sm:max-w-md rounded p-2 mx-2`}>
          {!completed ? (
            <>
              <div className="sm:mx-auto sm:w-full sm:max-w-md mb-8">
                <img
                  className="mx-auto w-[160px] lg:w-[250px] lg:mt-8"
                  src={mode ? heyhireBlackIcon : heyhireIcon}
                  alt="logo"
                />
                <h2
                  className={`my-6 text-center text-2xl font-bold leading-9 ${
                    !mode ? "text-white" : "text-gray-900"
                  }`}
                >
                  {t("login.reset-password")}
                </h2>
              </div>

              <div className="text-left snap-start w-full lg:px-8 sm:px-6">
                <div className="my-6 mx-1">
                  <Input
                    name="password"
                    label={t("general-settings.new-password")}
                    type="password"
                    value={password}
                    placeholder={""}
                    icon="far fa-lock"
                    error={
                      validationErrors && validationErrors.password
                        ? validationErrors.password[0]
                        : null
                    }
                    onChange={handleInputChange}
                    darmo={mode}
                  />
                </div>

                <div className="my-6 mx-1">
                  <Input
                    className="mb-1"
                    name="confirmPassword"
                    label={"Confirm " + t("common.password")}
                    type="password"
                    value={confirmPassword}
                    placeholder={""}
                    icon="far fa-lock"
                    error={
                      validationErrors && validationErrors.confirmPassword
                        ? validationErrors.confirmPassword[0]
                        : null
                    }
                    onChange={handleInputChange}
                    darmo={mode}
                  />
                  {passwordMatchError && (
                    <span className="text-xs font-bold text-red-400">
                      Passwords do not match.
                    </span>
                  )}
                </div>

                <div className="mt-3">
                  <button
                    className="flex w-full justify-center rounded-full bg-primary-500 dark:bg-darkGray px-6 py-2 text-sm font-bold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                    label={t("common.continue")}
                    loading={loading}
                    onClick={handleLogin}
                  >
                    Continue
                    {loading && (
                      <i className="fad fa-circle-notch fa-spin ml-2 my-auto" />
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <SuccessView resend={() => setCompleted(false)} />
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
