import React, {useState} from 'react'
import {useEffect} from 'react';
import DisplayCard from '../../../../components/form/DisplayCard'
import {useSettingsContext} from '../SettingsContext';

const Preview = () => {

    const {
      company,
      setCompanyName,
      businessType,
      setBusinessType,
      turnKeyObject,
      setTurnKeyObject,
      scrollUpRef,
    } = useSettingsContext();

    const [locationName,setLocationName] = useState([]);

    const label1 = company.companyName;
    const content1 = 'Business Type :' + (businessType.businessName ? businessType.businessName : " Not selected") + '';

    // useEffect(() => {
    //       const content2 =
    //         "  Location Name :" +
    //         (turnKeyObject.turnKeyLocationName
    //           ? turnKeyObject.turnKeyLocationName
    //           : " Not selected") +
    //         "-------" +
    //         "\r\nLocation URL :" +
    //         (turnKeyObject.turnKeyLocationUrlSlug
    //           ? turnKeyObject.turnKeyLocationUrlSlug
    //           : " Not selected") +
    //         "-------" +
    //         "\r\n Brand Name :" +
    //         (turnKeyObject.turnKeyBrandName
    //           ? turnKeyObject.turnKeyBrandName
    //           : " Not selected") +
    //         "";

    //   setLocationName(content2);

    // }, [turnKeyObject]);

 return (
   <section className="body-font h-full text-gray-600">
     <div className="container mx-auto flex max-w-3xl flex-wrap justify-center rounded-lg bg-white px-5 py-16">
       <div className="flex-wrap md:flex">
         <div className="mt-8 md:mt-0 md:ml-10 w-full">
           <div className="relative flex pb-12">
             <div className="absolute inset-0 flex h-full w-10 items-center justify-center">
               <div className="pointer-events-none h-full w-1 bg-gray-200" />
             </div>
             <div className="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-primary-500 text-white">
               <svg
                 fill="none"
                 stroke="currentColor"
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 strokeWidth={2}
                 className="h-5 w-5"
                 viewBox="0 0 24 24"
               >
                 <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
               </svg>
             </div>
             <DisplayCard
               label="Business Name "
               labelValue={label1}
               content={content1}
             />
           </div>
           <div className="relative flex pb-12">
             <div className="absolute inset-0 flex h-full w-10 items-center justify-center">
               <div className="pointer-events-none h-full w-1 bg-gray-200" />
             </div>
             <div className="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-primary-500 text-white">
               <svg
                 fill="none"
                 stroke="currentColor"
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 strokeWidth={2}
                 className="h-5 w-5"
                 viewBox="0 0 24 24"
               >
                 <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
               </svg>
             </div>
             <DisplayCard label="Details" labelValue={""} content={content1} />
           </div>
           <div className="relative flex pb-12">
             <div className="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-primary-500 text-white">
               <svg
                 fill="none"
                 stroke="currentColor"
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 strokeWidth={2}
                 className="h-5 w-5"
                 viewBox="0 0 24 24"
               >
                 <circle cx={12} cy={5} r={3} />
                 <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3" />
               </svg>
             </div>
             <DisplayCard
               label="Confirmation"
               content="Confirmation yet to be dispatched"
             />
           </div>
         </div>
       </div>
     </div>
   </section>
 );
}

export default Preview