import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropActionDropdown } from "../../components/TableActionDropdown";
import { useCustomEventListener } from "react-custom-events";
import FormJobSeeker from "../../components/molecules/FormJobSeeker";
import Button from "../../components/form/Button";
import useQuery from "../../hooks/useQuery";
import PaginationView from "../../components/molecules/Pagination";
import { DashboardLayout } from "../../components/organisms/Layout";
import { FormModal } from "../../components/organisms/FormModal";
import { ModalDeleteWrapper } from "../../components/organisms/ModalDeleteWrapper";
import JobSeeker from "../../api/JobSeeker";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFormatPhoneNumber from "../../hooks/FormatPhoneNumber";
import { defaultUserImage } from "../../assets/images";
import { encodeIds } from "../../utils";
import Table from "../../components/atoms/Tables/Table";
import IconSelector from "../../components/atoms/Selector/IconSelector";

const JobSeekers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formatPhoneNumber = useFormatPhoneNumber;
  const query = useQuery();
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [jobSeekers, setJobSeekers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLimit, setPagelimit] = useState(query.get("pageSize") || 10);
  const [metaData, setMetaData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    document.title = "HeyHire Portal-Job Seekers";
  }, []);

  useEffect(() => {
    loadJobSeekers();
  }, [query]);

  const loadJobSeekers = () => {
    setLoading(true);
    const page = query.get("page") || 1;
    const sort = query.get("sort") || "";
    const limit = query.get("limit") || pageLimit;
    const sortData = sort ? `sort=${sort}&` : "";
    const pageData = page ? `page=${page}` : "";
    const queryData = sortData + pageData + `&limit=${limit}`;
    JobSeeker.getAll(queryData)
      .then((response) => {
        setJobSeekers(response.data.data);
        setMetaData(response.data.meta);
      })
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error("Something Went Wrong.");
        }
      })
      .then(() => setLoading(false));
  };

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    loadJobSeekers();
  });

  const handlecPageChange = (currentPage, pageSize) => {
    setPagelimit(pageSize);
    setSearchParams({ ...searchParams, page: currentPage, pageSize: pageSize });
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="overflow-x-auto h-full mt-3 pb-12">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Table
              mainHeader={t("job-seekers.job-seekers")}
              headers={
                !loading && jobSeekers?.length > 0
                  ? [
                      t("job-seekers.applicant"),
                      t("common.phone"),
                      t("common.email"),
                      `${t("common.city")} / ${t("common.state")}`,
                      t("common.number-of-applications"),
                      t("common.action"),
                    ]
                  : ["No data available"]
              }
              data={
                !loading &&
                jobSeekers?.map((jobSeeker, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1">
                      <div className="flex items-center">
                        <div className="h-9 w-9 flex-shrink-0 transform transition duration-200 ease-in-out hover:scale-110">
                          <img
                            alt=""
                            src={
                              jobSeeker?.photo?.thumb_url || defaultUserImage
                            }
                            className="h-9 w-9 rounded-full"
                          />
                        </div>
                        <div className="ml-2 md:ml-4">
                          <div className="font-medium text-gray-900 hover:underline">
                            {jobSeeker?.first_name} {jobSeeker.last_name}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {formatPhoneNumber(jobSeeker.phone) || "-"}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">{jobSeeker.email}</div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">{`${jobSeeker.city} / ${jobSeeker.state}`}</div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500">
                      <div className="text-gray-900">
                        {jobSeeker?.num_applications}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                      <IconSelector
                        menus={[
                          {
                            label: "Edit",
                            action: () =>
                              navigate(
                                `/job-seekers/edit/${encodeIds(jobSeeker.id)}`
                              ),
                          },
                          {
                            label: "Delete",
                            action: () => setShowModalDelete(true),
                          },
                          { label: "Resend Welcome Email", action: () => {} },
                        ]}
                      />
                    </td>
                  </tr>
                ))
              }
            />
            <div className="flex w-full h-20 mt-2 justify-end items-center">
              {!loading && (
                <PaginationView
                  current={metaData?.current_page}
                  next={metaData?.to}
                  pageSize={metaData?.per_page}
                  total={metaData?.total}
                  onChange={handlecPageChange}
                />
              )}
            </div>
          </>
        )}
      </div>
      <FormModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        title={t("job-seekers.add-a-job-seeker")}
      >
        <FormJobSeeker />
      </FormModal>

      <ModalDeleteWrapper
        visible={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        title={t("common.delete") + " " + t("job-seekers.job-seekers")}
      />
    </DashboardLayout>
  );
};

export default JobSeekers;
