import axios from "./api";

const Brand = {
  getAll(query=null) {
    return axios.get(`business/brand?${query ? query : ""}`);
  },
  getById(id) {
    return axios.get(`business/brand/${id}`);
  },
  deleteById(id) {
    return axios.delete(`business/brand/${id}`);
  },
  add(brand) {
    return axios.post("business/brand", brand);
  },
  update(id, brand) {
    return axios.put(`business/brand/${id}`, brand);
  },
  uploadImage(id, image) {
    const formData = new FormData();
    formData.append("brand_id", id);
    formData.append("photo", image);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
  

    return axios.post("business/photo/brand", formData, config);
  },
  uploadCoverImage(id, image) {
    const formData = new FormData();
    formData.append("brand_id", id);
    formData.append("photo", image);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return axios.post("business/photo/brand_cover", formData, config);
  },
  getAllBrandForPublicPage(city) {
    return axios.get(`public-profile/heyhire?city=` + city);
  
  }
};

export default Brand;
