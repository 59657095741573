import React from "react";
import "react-loading-skeleton/dist/skeleton.css";

const JobOpeningCardLoading = () => {
  return (
    <li className="rounded-lg bg-white dark:bg-[#2C2D30] dark:border-darkGray shadow-sm border">
      <div className="animate-pulse col-span-1 divide-y divide-warmgray-200 dark:divide-warmgray-600">
        <div className="flex w-full items-center justify-between p-8">
          <div className="h-2.5 bg-gray-300 dark:bg-[#363739] rounded w-1/3 mx-2"></div>
          {/* <div className="flex">
            <div className="flex -space-x-2 overflow-hidden p-6">
              <div className="inline-block h-8 w-8 rounded-full bg-gray-300 dark:bg-[#363739] ring-2 ring-white dark:ring-0"></div>
              <div className="inline-block h-8 w-8 rounded-full bg-gray-300 dark:bg-[#363739] ring-2 ring-white dark:ring-0"></div>
              <div className="inline-block h-8 w-8 rounded-full bg-gray-300 dark:bg-[#363739] ring-2 ring-white dark:ring-0"></div>
              <div className="inline-block h-8 w-8 rounded-full bg-gray-300 dark:bg-[#363739] ring-2 ring-white dark:ring-0"></div>
            </div>
          </div> */}
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-warmgray-200 dark:divide-warmgray-500">
            <div className="flex w-0 flex-1 px-2 py-3">
              <div className="h-2 bg-gray-300 dark:bg-[#363739] rounded w-1/2 mx-auto"></div>
            </div>
            <div className="flex w-0 flex-1 px-2 py-3">
              <div className="h-2 bg-gray-300 dark:bg-[#363739] rounded w-1/2 mx-auto"></div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default JobOpeningCardLoading;
