import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SuperUser from "../../api/SuperUser";
import { toast } from "react-toastify";
import Input from "../../components/form/Input";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DashboardLayout } from "../../components/organisms/Layout";

const EditPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);

  useEffect(() => {
    document.title = "HeyHire Portal-Edit Password";
  }, []);

  const updateUser = async () => {
    if (password === "" || password?.length < 8) {
      setValidationErrors({
        ...validationErrors,
        password: "password not Strong enough!",
      });
    } else {
      setLoading(true);
      setValidationErrors(null);
      const requestBody = {
        user_id: params?.userId,
        password: password,
      };
      SuperUser.changePassword(requestBody)
        .then(() => {
          toast.success(t("general-settings.pssword-updated"));
          navigate(-1);
        })
        .catch((error) => {
          if (error?.response?.status !== 403) {
            toast.error(t("common.something-went-wrong-please-try-again"));
          }
        })
        .then(() => setLoading(false));
    }
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full">
        <h2 className={"text-2xl font-bold"}>
          <Link to={"/admin/manage-business-users"}>
            <i className="far fa-chevron-left text-xl mr-2" />
          </Link>{" "}
          {t("general-settings.edit-password")}
        </h2>
        <hr className="mt-4" />

        <div className="text-left snap-start pt-6 pb-8 mb-4 w-full">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            <Input
              className="my-1"
              name="password"
              label={t("common.password")}
              type="password"
              value={password}
              error={
                validationErrors && validationErrors.password
                  ? validationErrors.password
                  : null
              }
              onChange={(e) => {
                setValidationErrors({ ...validationErrors, password: null });
                setPassword(e.target.value);
              }}
            />
          </div>

          <hr className="my-5" />

          <div className="flex w-full md:w-1/5">
            <button
              className="w-full rounded-xl p-2 py-2.5 m-1 text-white text-lg bg-violet-500 hover:bg-violet-700 disabled:bg-violet-200"
              onClick={(e) => {
                e.preventDefault();
                updateUser();
              }}
              disabled={loading}
            >
              {t("general-settings.update-password")}
              {loading ? (
                <i className="fad fa-circle-notch fa-spin ml-2" />
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EditPasswordPage;
