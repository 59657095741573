import axios from "./api";

const Region = {
  getAll(query = null) {
    return axios.get(`business/region?${query ? query : ""}`);
  },
  getById(id) {
    return axios.get(`business/region/${id}`);
  },
  create(data) {
    return axios.post("business/region", data);
  },
  deleteById(id) {
    return axios.delete(`business/region/${id}`);
  },
  update(id, data) {
    return axios.put(`business/region/${id}`, data);
  },
  getAllByCompanyId(companyId) {
    return axios.get(`business/region/company/${companyId}`);
  },
};

export default Region;
